import {
  Button,
  Dialog,
  Icon,
  Loading,
  setNotification,
  TransitionDiv
} from '@startlibs/components';
import { CheckboxGroup, Errors, SimpleCheckbox, WithForm } from '@startlibs/form';
import {callIfFunction, getColor} from '@startlibs/utils';
import {useEnsureRef, useRefState, useToggle} from '@startlibs/core'
import React, { Suspense, useEffect, useRef, useState, useEvent } from 'react';
import _ from 'lodash/fp'
import styled from 'styled-components';
import {Uploader as UploaderLegacy} from 'uploader-frontend-legacy'
import {Card, SectionHeading} from '../components/PageLayout';
import { DEVICE, DISK, DISPLAY, INSTITUTION, REVIEW, START, UPLOAD } from '../enums/UploaderManagement';
import {EmptyMedicalRecordsList} from './RequestOverview'
import { LoadingPage, PageLoadingSuspense } from '../components/PageLoading';
import { PatientMedicalRelease } from '../patient/PatientMedicalRelease';
import { RecordsManagerNew } from './RecordsManagerNew';
import { RecordsManagerPatient } from './RecordsManagerPatient';
import {authPostFetcher, jwtGetFetcher, jwtPostFetcher} from '../utils/authFetch'
import { buildValidation } from '../utils/validation';
import {getJwt} from '../hooks/useJwt'
import { getStorageHost, setStorageHost } from '../hooks/useStorageHost';
import { getUploaderJwt, setUploaderJwt } from '../hooks/useUploaderJwt';
import {isStateBefore, persistMedicalRecord, updateMedicalRecordsInfo} from './utils'
import {useNotification} from '../components/Notifications'
import {confirmExitPage, useSetConfirmExitPage} from '../hooks/useConfirmExitPage'
import {willUseSuspense} from '../hooks/useSuspense'
import { PatientMedicalReleaseStep } from '../patient/PatientMedicalReleaseStep';
import { AssignmentStatusBox } from '../admin/steps/expertReview/ExpertReviewList';
import { CASE_ARCHIVED, CASE_CLOSED, CASE_REJECTED, CASE_REVIEWED, PENDING_ASSIGNMENT, REQUEST_REJECTED, UNDER_REVIEW, WAITING_ACCEPTANCE } from '../enums/CaseState';
import { desaturate, lighten } from 'polished';
import { useNavigate } from 'react-router';
import {FormattedMessage, useIntl} from "react-intl";

const HAS_HELP_VIDEOS = false

const RecordsCard = styled(Card)`
  overflow: hidden;
  position: relative;
  padding: 0;
  margin-bottom: 1rem;
`

export const EmptyLocationRecordText = styled.div`
  color: ${getColor('gray150')};
  font-size: 13px;
`

export const UploadingRecords = styled.div`
  padding: 0.25rem 0.75rem 0.25rem 0.5rem;
  background-color: ${props => desaturate(0.65, lighten(0.545, getColor("main")(props)))};
  border-radius: 5px;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  min-height: 24px;
  margin-right: 1rem;
  ${Loading} {
    margin-right: 0.5rem;
  }
`

const HeaderTitle = styled.div`
  font-size: 15px;
  font-weight: bold;
`

const HeaderDescription = styled.div`
  font-size: 12px;
`

const StepContainer = styled.div`
  padding-left: 2.75rem;
  position: relative;
  & ~ & {
    margin-top: 1rem;
  }
  ${Button} {
    margin-top: 0.5rem;
  }
`

const StepTitle = styled.div`
  font-size: 15px;
`

const StepDetails = styled.div`
  margin-top: 0.25rem;
`

const StepNumber = styled.div`
  font-size: 15px;
  font-weight: bold;
  background-color: ${getColor('main')};
  color: white;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  border-radius: 50%;
  position: absolute;
  top: -1px;
  left: 0;
`

const CollapsedContent = styled.div`
  transition: all 0.5s ease;
  transform: translateY(20px);
  position: absolute;
  transform-origin: top;
  opacity: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  left: 0;
  right: 0;
  padding: 1.25rem 1.5rem;
  ${props => props.isCollapsed && `
    transform: translateY(0);
    position: relative;
    opacity: 1;
  `}
  ${HeaderTitle} {
    font-size: 14px;
    display: inline-block;
  }
  .uploadedCounter {
    font-size: 12px;
    font-weight: 400;
    display: inline-block;
    margin-left: 1rem;
  }
  .flex {
    display: flex;
    align-items: center;
  }
`
const ExpandedContent = styled.div`
  transition: all 0.5s ease;
  transform: translateY(20px);
  position: absolute;
  left: 0;
  right: 0;
  transform-origin: top;
  opacity: 0;
  padding: 2rem;
  ${props => props.isExpanded && `
    transform: translateY(0);
    position: relative;
    opacity: 1;
  `}
`
const StepFooter = styled.div`
  text-align: right;
  padding-bottom: 2rem;
  padding-top: 1rem;
`
const HelpButton = styled(Button)`
  ${Icon} {
    font-size: 16px;
    margin-left: -2px;
  }
`

const useAuthSuspense = willUseSuspense((url) => jwtGetFetcher(getJwt())(url))
const WithUploaderToken = ({children, requestId}) => {
  const {jwt} = useAuthSuspense('/api/uploaderToken' + (requestId ? "?requestId=" + requestId : ""))
  return children(jwt)
}

export const MedicalRecords = ({readOnly, forceAllowUpload, isPatient, uploaderRef, alwaysSave, caseRequest, allowReorder, optional, children, setCaseRequest, autoGrouping, canOpenWorklist = false, role = '', mode, setMode = () => null, forceStart, setForceStart= () => null, saveDoneRecords = () => null, isRefPhysician}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const {medicalRecordsInfo} = caseRequest
  const unauthorizedRefresh = useToggle()
  const [caseLoaded, setCaseLoaded] = useState(false)
  const [isUseLegacy, setUseLegacy] = useState(false)
  const [isUpdatingGroups, setIsUpdatingGroups] = useState(false)
  const [isUpdatingGroups2, setIsUpdatingGroups2] = useState(false)
  const [options, setOptions] = useState();
  const [step, setStep] = useState(REVIEW)
  const [reload, setReload] = useState(0)
  const formRef = useRef()
  const uploaderRefDevice = useRef()
  const uploaderRefDisk = useRef()
  const [refreshUrl, setRefreshUrl] = useState('')
  
  // Device counters
  const [toBeUploadedDevice, setToBeUploadedDevice] = useState(0)
  const [totalDevice, setTotalDevice] = useState(0)
  const [isUploadingDevice, setIsUploadingDevice] = useState(false)

  // Disk counters
  const [toBeUploadedDisk, setToBeUploadedDisk] = useState(0)
  const [totalDisk, setTotalDisk] = useState(0)
  const [isUploadingDisk, setIsUploadingDisk] = useState(false)

  // Steps hidden control
  const [progress, setProgress] = useState(0)

  const [isEffectProcessing, setIsEffectProcessing] = useState(false);
  const [deferredSuccess, setDeferredSuccess] = useState(null);

  const nextButtonClick = (step) => {
    if(step === DEVICE && options?.disk){
      setStep(DISK)
      progress < 1 ? setProgress(1) : null
    }else{
      if(options?.institution){
        if(progress <= 2){
          setProgress(2)
          setStep(INSTITUTION)
        }else{
          setStep(REVIEW)
        }
      }else{
        confirmExitPage().then(() => {
          // navigate(`/patient/records`)
          setReload(reload+1)
          setCaseLoaded(false)
          setStep(REVIEW)
          setMode(DISPLAY)
          saveDoneRecords()
        })
      }
    }
  }

  const hasMedicalRecords = medicalRecordsInfo && (
    medicalRecordsInfo.dicomStudies?.length ||
    medicalRecordsInfo.nonCompliantDicom?.length ||
    medicalRecordsInfo.nonDicomFiles?.length
  )

  // Normal Uploader Ref
  const ensuredUploaderRef = useEnsureRef(uploaderRef)
  const confirmUploads = () =>
    ensuredUploaderRef.current
      ? ensuredUploaderRef.current.confirm()
      : Promise.resolve()

  // Device Uploader Ref
  const ensuredUploaderRefDevice = useEnsureRef(uploaderRefDevice)
  const confirmUploadsDevice = () =>
    ensuredUploaderRefDevice.current
      ? ensuredUploaderRefDevice.current.confirm()
      : Promise.resolve()

  // Disk Uploader Ref
  const ensuredUploaderRefDisk = useEnsureRef(uploaderRefDisk)
  const confirmUploadsDisk = () =>
    ensuredUploaderRefDisk.current
      ? ensuredUploaderRefDisk.current.confirm()
      : Promise.resolve()

  useSetConfirmExitPage(() => confirmUploads())
  useSetConfirmExitPage(() => confirmUploadsDevice())
  useSetConfirmExitPage(() => confirmUploadsDisk())

  const caseId = caseRequest.requestCode;
  
  var caseUID = ''
  var listModeRes = ''
  var patientUID = ''
  var userRole = 0
  const releasesActive = isStateBefore(caseRequest.state, WAITING_ACCEPTANCE) || caseRequest.state === CASE_REVIEWED

  const performSuccessActions = (values) => {
    setOptions(values); 
    setMode(UPLOAD); 
    setStep(values?.device ? DEVICE : values?.disk ? DISK : INSTITUTION)
    setIsUpdatingGroups(false)
  };

  useEffect(() => {
    if(!hasMedicalRecords || reload){
      setIsEffectProcessing(true);
      let refreshTimeout = -1
      // let checkLockTimeout = -1
      jwtGetFetcher(getJwt())(`/api/storageToken`,{requestId: caseRequest.requestId})
        .then((response) => {
          // set variables
          let responseJson = JSON.parse(response.jwt);
          setStorageHost(response.storageHost)
          setUploaderJwt(responseJson.jwt)
          caseUID = responseJson.caseUID
          listModeRes = responseJson.listMode
          patientUID = responseJson.patientUID
          userRole = responseJson.userRole
          
          refreshTimeout = setInterval(() => {
            jwtGetFetcher(getUploaderJwt())(getStorageHost()+"/auth/refresh")
              .then(({jwt}) => setUploaderJwt(jwt))
              .catch(([v,{status}]) => status === 401 && unauthorizedRefresh.open())
          },10*60*1000)

          // checkLockTimeout = isPatient ? setInterval(() => {
          //   jwtGetFetcher(getJwt())(`/api/patient/viewCaseRequest`,null,{method: "HEAD"})
          //     .then((response) => {
          //       console.log(response)
          //     })
          //     .catch(([v,{status}]) => {
          //       if(status === 569){
          //         navigate("/access")
          //         setNotification({ type: "alert", msg: "This case is locked" })
          //       }
          //     })
          // },5*60*1000) : -1
          setRefreshUrl(
            caseId 
            ? getStorageHost()+"/case/"+caseId+(patientUID ? "?patientUID="+patientUID : "")
            : getStorageHost()+"/record/patient/"+patientUID
          )

          return caseId
          ? jwtGetFetcher(getUploaderJwt())(getStorageHost()+"/case/"+caseId+(patientUID ? "?patientUID="+patientUID : "")) //+(patientUID ? "&collapse=true" : "?collapse=true"))
          : jwtGetFetcher(getUploaderJwt())(getStorageHost()+"/record/patient/"+patientUID).then(medicalRecords => ({medicalRecords}))
        })
        .then((response) => {
          setCaseRequest(caseRequest => ({ ...caseRequest, medicalRecords: response.medicalRecords, group: response.group }));
          setCaseLoaded(true)
          setUseLegacy(false)
          if (deferredSuccess) {
            // Now call the deferred onSuccess logic
            performSuccessActions(deferredSuccess);
            setDeferredSuccess(null); // Reset the deferred action
          }else{
            // After load medical records, check conditions to set start mode automatically
            // (Asked for records and not done records OR medical records loaded is 0) AND (patient has not added medical releases)
            if(
                (caseRequest?.caseFlags?.requestFlags?.askedRecords && !caseRequest?.caseFlags?.patientDoneFlags?.doneRecords && caseRequest.state !== CASE_ARCHIVED) 
              || 
                (
                  (response?.medicalRecords?.length === 0)
                  && (caseRequest.state !== CASE_REVIEWED) 
                  && (caseRequest.state !== CASE_CLOSED )
                  && (caseRequest.state !== UNDER_REVIEW )
                  && (caseRequest.state !== CASE_ARCHIVED)
                  && (!caseRequest?.caseFlags?.patientDoneFlags?.doneMedicalReleases)
                  && (!caseRequest?.caseFlags?.adminMedicalReleaseUpdated)
                )
            ){
              setForceStart(true) 
              setMode(START)
            }
            // After creating case from /refphysician/start, should redirect it to Medical Records --> Start --> Device
            if(isRefPhysician 
              && response?.medicalRecords?.length === 0  
              && (caseRequest.state !== CASE_REVIEWED) 
              && (caseRequest.state !== CASE_CLOSED )
              && (caseRequest.state !== UNDER_REVIEW )
              && (caseRequest.state !== CASE_ARCHIVED)
              && (!caseRequest?.caseFlags?.patientDoneFlags?.doneMedicalReleases)
              && (!caseRequest?.caseFlags?.adminMedicalReleaseUpdated)){
              setOptions({device: true}); setMode(UPLOAD); setStep(DEVICE)
            }
          }
          setIsEffectProcessing(false);
        })
        .catch(([v,{status}]) => {
          if(status === 569){
            navigate("/access")
            setNotification({ type: "alert", msg: intl.formatMessage({
                defaultMessage:"This case is locked",
                description: "Medical records, case locked notification"
            })})
          }
        })
        
      return  () => {
        clearInterval(refreshTimeout)
        // clearInterval(checkLockTimeout)
      }
    }else{
      setCaseLoaded(true)
      setUseLegacy(true)
    }
  }, [hasMedicalRecords,reload])

  useEffect(() => {
    return () => {setCaseRequest(_.unset("medicalRecords")); setCaseLoaded(false)}
  },[])

  useEffect(() => {
     // If after add records wants to add more as Ref Physician should redirect it to Medical Records --> Start --> Device
     if(isRefPhysician 
       && mode == START) {
       setOptions({device: true}); setMode(UPLOAD); setStep(DEVICE)
     }  
    if((mode === DISPLAY) || (mode === START)){
      setTotalDevice(0)
      setIsUploadingDevice(0)
      setToBeUploadedDisk(0)
      setTotalDisk(0)
      if(mode === START){
        setReload(reload+1)
      }
    }else{
      setProgress(options?.device ? 0 : options?.disk ? 1 : 2)
    }

  },[mode])

  const atLeastOneError = (props) => !Object.values(props).filter(_.identity).length && intl.formatMessage({
    defaultMessage: 'Please select at least one option to start',
    description: 'Medical record start error message'
  })
  const preValidation = buildValidation({
    'device': (v, k, props) => atLeastOneError(props),
    'disk': (v, k, props) => atLeastOneError(props),
    'institution': (v, k, props) => atLeastOneError(props)
  })

  if(!hasMedicalRecords && !(new URLSearchParams(window.location.search).has('useLegacy'))){
    if(((!caseRequest.medicalRecords) && (!caseLoaded)) || isUseLegacy) {
      return <LoadingPage/>
    }
    return  <PageLoadingSuspense>
      {
        isPatient && (!releasesActive || caseRequest.state === CASE_REVIEWED || caseRequest.state === PENDING_ASSIGNMENT) && <AssignmentStatusBox>
          <div className="waitingBoxContent">
            <Icon icon="clock"/>
            <h4>
              {/* This case can no longer receive new medical records or releases */}
              <FormattedMessage
                defaultMessage="This case cannot currently receive new medical records"
                description="Medical records, case cannot receive new records box header"
              />
            </h4>
            <p>
              {(caseRequest.state === CASE_REVIEWED || caseRequest.state === CASE_CLOSED) ?
                intl.formatMessage({
                  defaultMessage: "This case was already reviewed, so the records and releases have been locked.",
                  description: "Medical records, case cannot receive new records box reviewed info"
                })
                : caseRequest.state === (CASE_REJECTED || REQUEST_REJECTED || CASE_ARCHIVED) ?
                "" : (caseRequest.state === PENDING_ASSIGNMENT) 
                ? intl.formatMessage({
                    defaultMessage: "An expert will be soon reviewing this case, so the records and releases have been locked.",
                      description: "Medical records, case cannot receive new records box pending assignment info"
                  })
                : intl.formatMessage({
                      defaultMessage: "An expert is currently reviewing this case, so the records and releases have been locked.",
                      description: "Medical records, case cannot receive new records box under review info"
                    })
              }
            </p>
          </div>
        </AssignmentStatusBox>
      }
      <SectionHeading>
        {/* {mode} */}
        {mode === DISPLAY
          ? 
            <HeaderTitle>
              <FormattedMessage
                defaultMessage="Your medical records collected"
                description="Medical records, collected header"
                />
            </HeaderTitle>
          :
            <>
              <HeaderTitle><FormattedMessage
                defaultMessage="Let's collect medical records related to this case."
                description="Medical records, start header"
                />
                <p css="font-weight:400;"><FormattedMessage
                  defaultMessage="These may include: medical imaging or digital pathology, radiology or pathology reports, exam or office notes, other medical reports, videos or pictures of symptoms, etc. "
                  description="Medical records, start instructions"
                /></p>
                {/* <span css="font-weight:400;"> exam results, pathology reports, previous medical reports, videos or pictures of symptoms, etc.</span> */}
              </HeaderTitle>
            </>
        }  
      </SectionHeading>
      
        {(caseRequest?.medicalRecords?.length > 0 || !readOnly || forceAllowUpload) 
          ?
            <>
              {mode === START &&
                <WithForm
                  alwaysSave
                  ref={formRef}
                  values={{
                      device: false,
                      disk: false,
                      institution: false}}
                  preValidation={preValidation}
                  action={(values) => {
                    setIsUpdatingGroups(true)
                    setIsEffectProcessing(true);
                    setDeferredSuccess(values);
                    setReload(reload+1);
                  }}
                  onSuccess={(values) => {
                    
                  }}
                >
                  <Card>
                    <CheckboxGroup
                      framedBoxes highlightSelected
                      label={intl.formatMessage({
                        defaultMessage: "Where are your medical records currently located?",
                        description: "Medical records, start location label"
                      })}
                    >
                      <SimpleCheckbox
                        css="margin-top: .5rem;"
                        path="device"
                        label={intl.formatMessage({
                          defaultMessage: "I have them on a device (i.e. a phone or computer)",
                          description: "Medical records, start location device option"
                        })}
                      />
                      <SimpleCheckbox
                        path="disk"
                        label={intl.formatMessage({
                          defaultMessage: "I have them on a disc (i.e. a CD or DVD)",
                          description: "Medical records, start location disc option"
                        })}
                      />
                      <SimpleCheckbox
                        path="institution"
                        label={intl.formatMessage({
                          defaultMessage: "A healthcare facility has them (i.e. a primary care office, hospital or other facility)",
                          description: "Medical records, start location institution option"
                        })}
                      />
                    </CheckboxGroup>
                    <Errors/>
                    <div css="text-align:right;margin-top:1rem;">
                    {forceStart ? null : <Button
                        onClick={() => {
                          setReload(reload+1);
                          setIsUpdatingGroups2(true)
                          setTimeout(() => {
                            setIsUpdatingGroups2(false)
                            setCaseLoaded(false)
                            setMode(DISPLAY)
                            setStep(REVIEW)
                          }, 1500);
                        }}
                        isLoading={isUpdatingGroups2}
                        >
                      <FormattedMessage
                        defaultMessage="Cancel"
                        description="Cancel button"
                        />
                        </Button>
                      }
                      <Button
                        type="submit"
                        highlight
                        isLoading={isUpdatingGroups}
                      >
                        <FormattedMessage
                          defaultMessage="Start"
                          description="Medical records, start button"
                        />
                      </Button>
                    </div>
                  </Card>
                </WithForm>
              }
              
              {mode === UPLOAD && options &&
                <> 
                  {options?.device && 
                    <>
                    <RecordsCard isCollapsed={step !== DEVICE}>
                      <ExpandedContent isExpanded={step === DEVICE}>
                        <SectionHeading>
                          <div>
                            <HeaderTitle>
                              <FormattedMessage
                                defaultMessage="Let's start with the records on your device"
                                description="Medical records, location device header"
                              />
                            </HeaderTitle>
                            {HAS_HELP_VIDEOS && <span><FormattedMessage
                              defaultMessage="If your records are on a different device, <videoLink>click here</videoLink>"
                              description="Medical records, location device help"
                              values={{
                                videoLink: (chunks) => <a className="link" onClick={() => console.log('clicked here')}>{chunks}</a>
                              }}
                              /></span>}
                          </div>
                        </SectionHeading>
                        <StepContainer>
                          <StepNumber>1</StepNumber>
                            <StepTitle><FormattedMessage
                              defaultMessage="Open the folder on your device where your medical records are located"
                              description="Medical records, location device step 1"
                            /></StepTitle>
                            {HAS_HELP_VIDEOS && <HelpButton outline small highlight rounded icon="play"><FormattedMessage
                              defaultMessage="How to locate them"
                              description="Medical records, location device help button"
                            /></HelpButton>}
                        </StepContainer>
                        <StepContainer>
                          <StepNumber>2</StepNumber>
                            <StepTitle><FormattedMessage
                              defaultMessage="Select all relevant files"
                              description="Medical records, location device step 2"
                            /></StepTitle>
                            <StepDetails><FormattedMessage
                              defaultMessage="Or, if the entire folder is relevant, just select the folder itself"
                              description="Medical records, location device step 2 details"
                            /></StepDetails>
                        </StepContainer>
                        <StepContainer>
                          <StepNumber>3</StepNumber>
                            <StepTitle><FormattedMessage
                              defaultMessage="Drag and drop the selected items into this page"
                              description="Medical records, location device step 3"
                            /></StepTitle>
                            <span><FormattedMessage
                              defaultMessage="Your medical records will be uploaded and will appear listed below"
                              description="Medical records, location device step 3 details"
                            /></span>
                        </StepContainer>
                        <StepContainer>
                          {/* <StepNumber></StepNumber> */}
                            <StepTitle css="margin-left: -2.75rem;"><FormattedMessage
                              defaultMessage="Or use the buttons below to select files from your device and upload them"
                              description="Medical records, location device final instruction"
                            /></StepTitle>
                        </StepContainer>
                        <div css="margin-top: 1.5rem;">
                        {(!caseRequest.medicalRecords) && (!caseLoaded) ?  <LoadingPage/>
                          :
                          <PageLoadingSuspense>
                            <RecordsManagerPatient
                              allowReorder={false}
                              // disabled={false}
                              disabled={(!forceAllowUpload && readOnly) || mode===DISPLAY}
                              uploaderRef={ensuredUploaderRefDevice}
                              // caseRequest={caseRequest}
                              caseRequest={_.unset("medicalRecords",caseRequest)}
                              setCaseRequest={setCaseRequest}
                              setMedicalRecords={(updateRecords) => setCaseRequest(_.update('medicalRecords', (prevRecords) => callIfFunction(updateRecords, prevRecords)))}
                              caseId={caseId}
                              listMode={listModeRes || (!forceAllowUpload && readOnly)}
                              canAddToCase={!(options?.device && options?.disk)}
                              autoGrouping={autoGrouping}
                              patientInfo={caseRequest.patientInfo}
                              canOpenWorklist={canOpenWorklist}
                              isPatient={isPatient}
                              role={role}
                              mode={DEVICE}
                              setIsUploading={setIsUploadingDevice}
                              setToBeUploaded={setToBeUploadedDevice}
                              setTotal={setTotalDevice}
                              total={totalDevice}
                            />
                          </PageLoadingSuspense>
                        }
                        </div>
                      </ExpandedContent> 
                      <CollapsedContent isCollapsed={step !== DEVICE}>
                        <div>
                          <HeaderTitle onClick={()=>setStep(DEVICE)}>
                            <FormattedMessage
                              defaultMessage="Records from device <counter>{uploaded}/{total} uploaded</counter>"
                              description="Medical records, location device uploaded counter"
                              values={{
                                uploaded: totalDevice - toBeUploadedDevice,
                                total: totalDevice,
                                counter: (chunks) => (toBeUploadedDevice > 0 || totalDevice > 0) && <span className="uploadedCounter">{chunks}</span>
                              }}
                            />
                          </HeaderTitle>
                        </div>
                        <div className="flex">
                          {isUploadingDevice ? <UploadingRecords><Loading size={16} borderWidth={3}/><FormattedMessage
                            defaultMessage="Uploading"
                            description="Medical records, location device uploading label"
                          /></UploadingRecords> : null}
                          <a className="link" onClick={()=>setStep(DEVICE)}><FormattedMessage
                            defaultMessage="View records"
                            description="View records button"
                          /></a>
                        </div>
                      </CollapsedContent>
                    </RecordsCard>
                    <TransitionDiv>{step === DEVICE &&
                      <StepFooter>
                        <Button onClick={() => confirmExitPage().then(() =>  { 
                          if(isRefPhysician){
                            setReload(reload+1)
                            setCaseLoaded(false)
                            setMode(DISPLAY)
                            setStep(REVIEW)
                          }else{
                            setMode(START)
                          }
                        })}><FormattedMessage
                          defaultMessage="Previous"
                          description="Previous step button"
                        /></Button>
                        {(toBeUploadedDevice == 0 && totalDevice == 0) &&
                          <Button onClick={() => nextButtonClick(DEVICE)}><FormattedMessage
                            defaultMessage="Skip"
                            description="Skip step button"
                          /></Button>
                        }
                        {(toBeUploadedDevice > 0 || totalDevice > 0) &&
                          <Button highlight onClick={() => nextButtonClick(DEVICE)}><FormattedMessage
                            defaultMessage="Next"
                            description="Next step button"
                          /></Button>
                        }
                      </StepFooter>}
                    </TransitionDiv>
                  </>
                  }
                  {options?.disk &&
                    <>
                    <RecordsCard isCollapsed={step !== DISK} hidden={progress < 1}>
                      <ExpandedContent isExpanded={step === DISK}>
                        <SectionHeading>
                          <HeaderTitle>
                            <FormattedMessage
                              defaultMessage="Now, let's upload the records from discs"
                              description="Medical records, location disc header"
                            />
                          </HeaderTitle>
                          {HAS_HELP_VIDEOS && <HeaderDescription><FormattedMessage
                            defaultMessage="If you cannot read the disc on your device, <videoLink>click here</videoLink>"
                            description="Medical records, location disc help"
                            values={{
                              videoLink: (chunks) => <a className="link" onClick={() => console.log('clicked here')}>{chunks}</a>
                            }}
                            /></HeaderDescription>}
                        </SectionHeading>
                        <StepContainer>
                          <StepNumber>1</StepNumber>
                          <StepTitle><FormattedMessage
                            defaultMessage="Insert the disc into your computer"
                            description="Medical records, location disc step 1"
                          /></StepTitle>
                        </StepContainer>
                        <StepContainer>
                          <StepNumber>2</StepNumber>
                            <StepTitle><FormattedMessage
                              defaultMessage='Click "Select disc" below, select your disc and click "Upload"'
                              description="Medical records, location disc step 2"
                            /></StepTitle>
                            <StepDetails><FormattedMessage
                              defaultMessage="We will scan the disc to find medical records"
                              description="Medical records, location disc step 2 details"
                            /></StepDetails>
                            {HAS_HELP_VIDEOS && <HelpButton outline highlight rounded small icon="play"><FormattedMessage
                              defaultMessage="How to select it"
                              description="Medical records, location disc help button"
                            /></HelpButton>}
                        </StepContainer>
                        <div css="margin-top: 1.5rem;">
                        {(!caseRequest.medicalRecords) && (!caseLoaded) ?  <LoadingPage/>
                          :
                          <PageLoadingSuspense>
                            <RecordsManagerPatient
                              allowReorder={false}
                              // disabled={false}
                              disabled={(!forceAllowUpload && readOnly) || mode===DISPLAY}
                              uploaderRef={ensuredUploaderRefDisk}
                              // caseRequest={caseRequest}
                              caseRequest={_.unset("medicalRecords",caseRequest)}
                              setCaseRequest={setCaseRequest}
                              setMedicalRecords={(updateRecords) => setCaseRequest(_.update('medicalRecords', (prevRecords) => callIfFunction(updateRecords, prevRecords)))}
                              caseId={caseId}
                              listMode={listModeRes || (!forceAllowUpload && readOnly) }
                              canAddToCase={!(options?.device && options?.disk)}
                              autoGrouping={autoGrouping}
                              patientInfo={caseRequest.patientInfo}
                              canOpenWorklist={canOpenWorklist}
                              isPatient={isPatient}
                              role={role}
                              mode={DISK}
                              setIsUploading={setIsUploadingDisk}
                              setToBeUploaded={setToBeUploadedDisk}
                              setTotal={setTotalDisk}
                              total={totalDisk}
                            />
                          </PageLoadingSuspense>
                        }
                        </div>
                      </ExpandedContent> 
                    
                      <CollapsedContent isCollapsed={step !== DISK}> 
                        <div>
                          <HeaderTitle onClick={()=>setStep(DISK)}>
                            <FormattedMessage
                              defaultMessage="Records from disc <counter>{uploaded}/{total} uploaded</counter>"
                              description="Medical records, location disc uploaded counter"
                              values={{
                                uploaded: totalDisk - toBeUploadedDisk,
                                total: totalDisk,
                                counter: (chunks) => (toBeUploadedDisk > 0 || totalDisk > 0) && <span className="uploadedCounter">{chunks}</span>
                              }}
                            />
                          </HeaderTitle>
                          <div><FormattedMessage
                            defaultMessage="Keep your disc in the computer until all files are uplodaded."
                            description="Medical records, location disc keep disc info"
                          /></div>
                        </div>
                        <div className="flex">
                          {isUploadingDisk && <UploadingRecords><Loading size={16} borderWidth={3}/><FormattedMessage
                            defaultMessage="Uploading"
                            description="Medical records, location disc uploading label"
                          /></UploadingRecords>}
                          <a className="link" onClick={()=>setStep(DISK)}><FormattedMessage
                            defaultMessage="View records"
                            description="View records button"
                          /></a>
                        </div>
                      </CollapsedContent>
                    </RecordsCard>
                    <TransitionDiv>{step === DISK &&
                      <StepFooter>
                        <Button onClick={
                          () => {
                            if(options?.device){
                              setStep(DEVICE)
                            }else{
                              confirmExitPage().then(() => setMode(START))
                            }
                          }
                        }>Previous</Button>
                        {(toBeUploadedDisk == 0 && totalDisk == 0) &&
                          <Button onClick={() => nextButtonClick(DISK)}><FormattedMessage
                            defaultMessage="Skip"
                            description="Skip step button"
                          /></Button>
                        }
                        {(toBeUploadedDisk > 0 || totalDisk > 0) &&
                          <Button highlight onClick={() => nextButtonClick(DISK)}><FormattedMessage
                            defaultMessage="Next"
                            description="Next step button"
                          /></Button>
                        }
                      </StepFooter>}
                    </TransitionDiv>
                    </>
                  }
                  {options?.institution && 
                    <>
                    <RecordsCard isCollapsed={step !== INSTITUTION && step !== REVIEW} hidden={progress < 2}>
                      <ExpandedContent isExpanded={step === INSTITUTION || step === REVIEW}>
                        <SectionHeading>
                            <HeaderTitle><FormattedMessage
                              defaultMessage="Records from healthcare facilities"
                              description="Medical records, location institution header"
                            /></HeaderTitle>
                            <HeaderDescription css="font-size:13px;margin-top:0.5rem;"><FormattedMessage
                              defaultMessage="Please list all healthcare facilities in possession of relevant records for this case (contact information is helpful, but optional) and then sign the medical record release form. We will contact them to collect your medical records."
                              description="Medical records, location institution instructions"
                            /></HeaderDescription>
                        </SectionHeading>
                        <PatientMedicalReleaseStep caseRequest={caseRequest} setCaseRequest={setCaseRequest} step={step} setStep={setStep} mode={mode} setProgress={setProgress}/>
                      </ExpandedContent>
                      {/* Collapsed */}
                      <CollapsedContent isCollapsed={step !== INSTITUTION && step !== REVIEW}> 
                        <HeaderTitle onClick={()=>{progress === 3 ? setStep(REVIEW) : setStep(INSTITUTION)}}>
                          Healthcare facilities
                        </HeaderTitle>
                        <a className="link" onClick={()=>{progress === 3 ? setStep(REVIEW) : setStep(INSTITUTION)}}>View</a>
                      </CollapsedContent>
                    </RecordsCard>
                    <TransitionDiv>{(step === INSTITUTION || step === REVIEW) &&
                      <StepFooter>
                        <Button onClick={
                            () => {
                              if(options?.disk){
                                setStep(DISK)
                              }else{
                                if(options?.device){
                                  setStep(DEVICE)
                                }else{
                                  confirmExitPage().then(() =>setMode(START))
                                }
                              }
                            }
                          }
                          >
                          <FormattedMessage
                            defaultMessage="Previous"
                            description="Previous step button"
                            />
                        </Button>
                        {step === REVIEW && <Button highlight onClick={()=>{
                          confirmExitPage()
                            .then(() => {
                              setReload(reload+1)
                              setCaseLoaded(false)
                              setMode(DISPLAY)
                              saveDoneRecords()
                            })
                          }}><FormattedMessage
                          defaultMessage="Proceed"
                          description="Proceed button"
                        /></Button>
                        }
                      </StepFooter>}
                    </TransitionDiv>
                    </>
                  }
                </>
              }

              {(mode === DISPLAY || !isPatient ) &&
                <Card>
                  <RecordsManagerNew
                    allowReorder={false}
                    // disabled={false}
                    disabled={(!forceAllowUpload && readOnly) || mode === DISPLAY}
                    uploaderRef={ensuredUploaderRef}
                    caseRequest={caseRequest}
                    setCaseRequest={setCaseRequest}
                    setMedicalRecords={(updateRecords) => setCaseRequest(_.update('medicalRecords', (prevRecords) => callIfFunction(updateRecords, prevRecords)))}
                    caseId={caseId}
                    listMode={listModeRes || (!forceAllowUpload && readOnly)}
                    autoGrouping={autoGrouping}
                    patientInfo={caseRequest.patientInfo}
                    canOpenWorklist={canOpenWorklist}
                    isPatient={isPatient}
                    role={role}
                    mode={mode}
                    refreshUrl={refreshUrl}
                  />
                  {((!forceAllowUpload && readOnly) || mode === DISPLAY) && (caseRequest?.medicalRecords?.length == 0) && 
                  <EmptyMedicalRecordsList><FormattedMessage
                    defaultMessage="No medical records provided."
                    description="Medical records, no records provided"
                  /></EmptyMedicalRecordsList>}
                </Card>
              }
              {
                unauthorizedRefresh.isOpen &&
                  <Dialog title={intl.formatMessage({
                      defaultMessage: "Your session has expired",
                    description: "Medical records, session expired dialog title"
                    })}>
                    <p><FormattedMessage
                      defaultMessage="Please refresh or reopen this page to proceed."
                      description="Medical records, session expired dialog message"
                    /></p>
                  </Dialog>
              }
              
            </>
          : 
            <Card>
              <EmptyMedicalRecordsList><FormattedMessage
                defaultMessage="No medical records provided."
                description="Medical records, no records provided"
              /></EmptyMedicalRecordsList>
            </Card>
      }
      {mode === DISPLAY && isPatient &&
        <PatientMedicalRelease caseRequest={caseRequest} setCaseRequest={setCaseRequest} step={step} setStep={setStep} mode={mode}/>
      }
      {callIfFunction(children)}
    </PageLoadingSuspense>
   
  }

  return <>
    <SectionHeading>
      <p><FormattedMessage
        defaultMessage="Upload any relevant medical records, including but not limited to: exam results, pathology reports, previous medical reports, videos or pictures of symptoms, etc."
        description="Medical records, already has records instructions"
      /></p>
    </SectionHeading>
    <Card>
      {(!!hasMedicalRecords || !readOnly || forceAllowUpload) ?
        <Suspense fallback={<Loading size={36} borderWidth={5} css="margin:3rem auto;"/>}>
          <RecordsManager
            allowReorder={allowReorder}
            uploaderRef={ensuredUploaderRef}
            disabled={!forceAllowUpload && readOnly}
            caseRequest={caseRequest}
            setMedicalRecords={(updateRecords) => setCaseRequest(_.update('medicalRecordsInfo', (prevRecords) => callIfFunction(updateRecords,prevRecords)))}
          />
        </Suspense>
        : <EmptyMedicalRecordsList><FormattedMessage
          defaultMessage="No medical records provided."
          description="Medical records, no records provided message"
        /></EmptyMedicalRecordsList>
      }
    </Card>
    {callIfFunction(children)}
  </>
}


export const EMPTY_MEDICAL_RECORDS = {dicomStudies: [], nonCompliantDicom: [], nonDicomFiles: []}

export const RecordsManager = ({caseRequest = {}, uploaderRef, allowReorder, disabled, setMedicalRecords, setCaseRequest}) => {
  const [, setNotification] = useNotification()

  const medicalRecordsStore = useRefState(caseRequest.medicalRecordsInfo || EMPTY_MEDICAL_RECORDS)
  const medicalRecordsUpdater = updateMedicalRecordsInfo(authPostFetcher)(medicalRecordsStore, setMedicalRecords, caseRequest.requestId)

  const persistRecord = persistMedicalRecord(medicalRecordsUpdater,(groups) => jwtPostFetcher(getJwt())("/api/admin/medicalRecordsGroups?requestId="+caseRequest.requestId,{groups:{list:groups}},{method:"PUT"}).then(() => setMedicalRecords(_.set('groups.list',groups))))

  const removePersistedRecord = ({studyUID, fileUID, nonCompliant, nonCompliantFile}) => {
    if (studyUID) {
      if (medicalRecordsStore.get().dicomStudies.find(study => study.studyUID === studyUID)) {
        return medicalRecordsUpdater.remove(
          "DICOM_STUDY", studyUID,
          _.update('dicomStudies', _.differenceBy(_.property('studyUID'), _, [{studyUID}]))
        )
      }
    } else if (fileUID) {
      if (medicalRecordsStore.get().nonDicomFiles.find(file => file.uid === fileUID)) {
        return medicalRecordsUpdater.remove(
          "NON_DICOM_FILE", fileUID,
          _.update('nonDicomFiles', _.differenceBy(_.property('uid'), _, [{uid: fileUID}]))
        )
      }
    } else if (nonCompliant) {
      return medicalRecordsUpdater.remove(
        "ALL_NON_COMPLIANT_DICOM",
        "",
        _.set('nonCompliantDicom', nonCompliant)
      )
    } else if (nonCompliantFile) {
      return medicalRecordsUpdater.remove(
        "NON_COMPLIANT_DICOM",
        nonCompliantFile.uid,
        _.update(['nonCompliantDicom'], _.differenceBy(_.property('uid'), _, [{uid: nonCompliantFile.uid}]))
      )
    }
    return Promise.resolve()
  }

  return <WithUploaderToken requestId={caseRequest.requestId}>{jwt =>
    <UploaderLegacy
      withViewAllButton
      downloadEnabled={caseRequest}
      disabled={disabled}
      jwt={jwt}
      allowReorder={allowReorder}
      ref={uploaderRef}
      appJwt={getJwt()}
      persistRecord={persistRecord}
      setNotification={setNotification}
      removePersistedRecord={removePersistedRecord}
      medicalRecords={caseRequest.medicalRecordsInfo || EMPTY_MEDICAL_RECORDS}
      requestId={caseRequest.requestId}
    />
  }</WithUploaderToken>

}
