import React from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components/macro'
import {ProgressBar, ProgressDetails, UploadingRecords, UploadingStatus} from "../AttachmentBoxStyles";
import {
  EmptyFile,
  getFileStateLabel,
  InvalidFilename,
  IS_RETRY_POSSIBLE,
  Quarantined,
  Uploading
} from "../../enums/FileState";
import {FailedLabel} from "../styled/FailedLabel";
import {Icon, Loading} from "@startlibs/components";
import {Failed, Queued} from "../../service/enums/RecordStatus";
import {useUploadQueueSelector} from "../../service/hooks/useUploadQueueSelector";
import {useDoAction} from "../../service/hooks/useDoAction";
import {UploaderAction} from "../../service/UploaderAction";
import {useUIDataSelector} from "../../service/hooks/useUIDataSelector";
import {FormattedMessage, useIntl} from "react-intl";

styled

export const RecordRowProgressStatus = ({record,isMinified, cancelUpload, openDetails, isMultiFile}) => {
  const doAction = useDoAction()
  const intl = useIntl()
  const cancel = () => cancelUpload ? cancelUpload(record) : doAction(UploaderAction.Delete,record)
  const retry = () => doAction(UploaderAction.Retry,record)

  const queuedRecord = useUploadQueueSelector(_.find(_.matchesProperty('key',record.key)))
  const isProcessing = useUIDataSelector('isProcessing')

  const uploadingFilesLength = (queuedRecord?.uploading?.length || 0)
  const uploadedFilesLength = (queuedRecord?.uploaded?.length || 0)
  const nonFailedFilesLength = (queuedRecord?.files?.length || 0) + uploadedFilesLength + uploadingFilesLength
  const failedFilesLength = (queuedRecord?.failed?.length || 0)
  const totalFiles = nonFailedFilesLength + failedFilesLength
  const hasOnlyFailedFiles = failedFilesLength === totalFiles && failedFilesLength > 0

  const showCancel = (!isProcessing || !isMultiFile)

  if (!record.files && record.quarantined) {
    return null
  }

  if (hasOnlyFailedFiles) {
    if (isMultiFile) {
      return <UploadingStatus>
        <ProgressDetails failed>
          <a className="link error" onClick={openDetails}><FormattedMessage
            defaultMessage="{failedFilesLength} file {failedFilesLength, plural, one {upload} other {uploads}} failed"
            description="Uploader, upload failed, multiple files details"
            values={{failedFilesLength}}
          /></a>
          {
            queuedRecord.failed.find(IS_RETRY_POSSIBLE) &&
            <a className="link" onClick={retry}><FormattedMessage
              defaultMessage="Retry {hasMany, select, true {all} other {}}"
              description="Uploader, upload failed, multiple files retry"
              values={{hasMany: failedFilesLength > 1}}
            /></a>
          }
        </ProgressDetails>
      </UploadingStatus>
    }
    return <UploadingStatus>
      <ProgressDetails failed>
        <span><FormattedMessage
          defaultMessage="File upload failed"
          description="Uploader, upload failed, single file failed"
        /></span>
        {
          record.failed === InvalidFilename || record.failed === Quarantined || record.failed === EmptyFile
            ? <a className="link" onClick={cancel}><FormattedMessage
              defaultMessage="Cancel"
              description="Cancel button"
            /></a>
            : <>
              <a className="link" css="margin-right: .25rem;" onClick={retry}><FormattedMessage
                defaultMessage="Retry"
                description="Retry button"
              /></a>
              <a className="link" onClick={cancel}><FormattedMessage
                defaultMessage="Cancel"
                description="Cancel button"
              /></a>
            </>
        }
        <FailedLabel>
           <span className="label-badge">
             <Icon icon="warning"/>
             {getFileStateLabel(intl,record.failed)}
           </span>
        </FailedLabel>
      </ProgressDetails>
    </UploadingStatus>
  }

  if (uploadingFilesLength) {
    return <UploadingStatus>
      {!isMinified && <ProgressDetails>
        <span><FormattedMessage
          defaultMessage="Uploading file{totalFiles, plural, one {} other {s}}..."
          description="Uploader, upload progress, uploading files message"
          values={{totalFiles: uploadingFilesLength}}
        /></span>
        {showCancel && <a className="link" onClick={cancel}><FormattedMessage
          defaultMessage="Cancel"
          description="Cancel button"
        /></a>}
      </ProgressDetails>}
      {!isMinified && <ProgressBar progress={record.progress} completed={record.progress === 100}/>}
      {
        isMinified &&
        <div css="display: flex; align-items: center;">
          <UploadingRecords><Loading size={16} borderWidth={3}/><FormattedMessage
            defaultMessage="Uploading"
            description="Uploader, minified upload progress, uploading files message"
          /></UploadingRecords>
          {showCancel && <a className="link" onClick={cancel}><FormattedMessage
            defaultMessage="Cancel"
            description="Cancel button"
          /></a>}
        </div>
      }
      {
        !isMinified && (
          failedFilesLength > 0
          ? <div className="files-count">
              <a className="link error" onClick={openDetails}><FormattedMessage
                defaultMessage="{failedFilesLength} file {failedFilesLength, plural, one {upload} other {uploads}} failed"
                description="Uploader, upload failed, multiple files details"
                values={{failedFilesLength}}
              /></a>
              <a className="link" onClick={retry}><FormattedMessage
                defaultMessage="Retry {hasMany, select, true {all} other {}}"
                description="Uploader, upload failed, multiple files retry"
                values={{hasMany: failedFilesLength > 1}}
              /></a>
          </div>
          : <div className="files-count"><a className="link" onClick={openDetails}><FormattedMessage
              defaultMessage="{uploadedFilesLength}/{totalFiles} files uploaded"
              description="Uploader, upload progress, uploaded files count"
              values={{uploadedFilesLength, totalFiles}}
              /></a></div>
        )
      }
    </UploadingStatus>
  }

  if (record.status === Queued) {
    return <UploadingStatus>
      <ProgressDetails>
        <span><FormattedMessage
          defaultMessage="Waiting..."
          description="Uploader, upload progress, waiting message"
        /></span>
        {showCancel && <a className="link" onClick={cancel}><FormattedMessage
          defaultMessage="Cancel"
          description="Cancel button"
        /></a>}
      </ProgressDetails>
      {!isMinified && <ProgressBar progress={record.progress} completed={record.progress === 100}/>}
    </UploadingStatus>
  }

  return null
}