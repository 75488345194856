import { Button, Icon, Link, Loading } from '@startlibs/components';
import {getColor, will, wrapLazy} from '@startlibs/utils';
import React, {useEffect, useRef, useState} from 'react'
import {FormattedMessage} from 'react-intl'
import _ from 'lodash/fp'
import styled from 'styled-components';
import {
  CASE_ARCHIVED,
  CASE_DRAFT,
  CASE_REJECTED,
  CASE_REVIEWED,
  isPendingAskingMore,
  PENDING_REQUEST,
  REQUEST_REJECTED,
  WAITING_ACCEPTANCE,
  WAITING_MEDICAL_RECORDS
} from '../../enums/CaseState';
import {
  Card,
  PageContainer,
} from '../../components/PageLayout';
import {DIGITAL} from '../../enums/PaymentMethod';
import {Header} from '../../components/Header'
import {InfoBox} from '../../components/InfoBox';
import {SignatureDate} from '../../request/Payment';
import {WaitingPatientUpdates} from './WaitingPatientUpdates'
import {buildValidation} from '../../utils/validation'
import {getJwt} from '../../hooks/useJwt'
import {hasAcceptedAllTerms} from '../../patient/utils/caseRequestUtils'
// import {isPendingAskingMore} from '../../patient/utils/patientUtils'
import {isStateBefore} from '../../request/utils';
import {jwtGetFetcher, jwtPostFetcher} from '../../utils/authFetch'
import {lazyProviderInfo} from '../../components/WithProvider'
import {useConfirmAskPatientUpdate} from './hooks/useConfirmAskPatientUpdate'
import { useIsLocale} from '../../hocs/IfLocale'
import { useUnarchiveDialog } from '../hooks/useUnarchiveDialog';
import { useNavigate } from 'react-router';
import { PreviousMessages } from './PreviousMessages';
import { PurviewFooter } from '../../components/PurviewFooter';
import { getTimezoneOffset } from '../../utils/utils';

export const PaymentRequestedTag = styled.div`
  font-size: 11px;
  color: ${getColor('warning')};
  display: inline-flex;
  align-items: center;
  line-height: 12px;
  border: 1px solid ${getColor('warning')};
  border-radius: 5px;
  padding: 2px 5px 2px 3px;
  margin-left: .5rem;
  ${Icon} {
    font-size: 15px;
    margin-right: .25rem;
  }
`
export const MarkAsReadButton = styled(Button)`
  position: absolute;
  right: -0.5rem;
  top: -2px;
`
const EmptyPage = styled.div`
  background: ${getColor('gray240')};
  color: ${getColor('gray150')};
  font-size: 14px;
  border-radius: 10px;
  border: 10px;
  padding: 2rem;
  min-height: 8rem;
  display: flex;
  align-items: center;
  justify-content: center;
`
const lazyTerms = wrapLazy((jwt) =>
  jwtGetFetcher(jwt)('/api/serviceTerms')
)

export const ContactPatientStep = ({caseRequest, setCaseRequest}) => {

  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingCollab, setIsLoadingCollab] = useState(false)
  const [shouldReloadData, setShouldReloadData] = useState(false)
  const [threads, setThreads] = useState([])
  const providerInfo = lazyProviderInfo.read()
  const getCollaborations = () => {
    setIsLoadingCollab(true)
    jwtGetFetcher(getJwt())('/api/collaborations',{requestId : caseRequest.requestId})
    .then((response) => {
      setThreads(response)
      if(caseRequest?.collaborationMessagesToRead > 0){
        jwtPostFetcher(getJwt())('/api/collaborations/resetRead?requestId='+caseRequest.requestId, {})
          .then((response) => {
            setTimeout(() => {
              setCaseRequest(caseRequest => {return {...caseRequest, collaborationMessagesToRead: 0}})  
            }, 3000)
          })
          .catch((error) => {
            console.log(error)
          })
      }
    })
    .catch((error) => {
      console.log(error)
    })  
    .finally(() => {
      setIsLoadingCollab(false)
    })
  }
  useEffect(() => {
    getCollaborations()
  },[])

  useEffect(() => {
    if(shouldReloadData){
      setIsLoading(true)
      jwtGetFetcher(getJwt())(`/api/viewCaseRequest?requestCode=${caseRequest.requestCode}&timezoneOffset=${getTimezoneOffset()}`)
      .then((response) => {
        setCaseRequest(response)
        setShouldReloadData(false)
        getCollaborations()   
      })
      .catch((error) => {
        console.log(error)
        setIsLoading(false)
      })
      .finally(() => {
        setIsLoading(false)
      })
    }
  },[shouldReloadData])
    
  
  var allTermsAccepted = ''
  if(providerInfo?.serviceTerms === true){
    allTermsAccepted = hasAcceptedAllTerms(lazyTerms.read(),caseRequest)
  }else{
    allTermsAccepted = true
  }

  const isNotLegal = useIsLocale({not:true,contains:"LEGAL"})
  const confirmDialog = useConfirmAskPatientUpdate(caseRequest, setCaseRequest, isNotLegal, setShouldReloadData)
  const formRef = useRef()
  const needsPayment = !!caseRequest.payments.find(payment => (payment?.paymentMethod === DIGITAL && payment?.chargeValue > 0) && !payment?.paid && !payment?.revoked)
  
  
  const atLeastOneError = (props) => !Object.values(props).filter(_.identity).length && 'Please select at least one update to request.'
  
  useEffect(() => {
    if((providerInfo?.requiresAgreement === true) && (providerInfo?.serviceTerms === true)){
      formRef.current?.setValues(_.set("askForServiceTerms", !caseRequest.acceptanceInfo.acceptedTerms))
    }
  }, [caseRequest.acceptanceInfo.acceptedTerms])


  const wasSentToExpert = !isStateBefore(caseRequest.state)(WAITING_ACCEPTANCE)
  const unarchive = useUnarchiveDialog(caseRequest,_.flow(
    will(setCaseRequest,_.set("state", caseRequest?.whenAcceptedEpochMilli ? CASE_DRAFT : PENDING_REQUEST )),
    will(navigate,`/admin/request/${caseRequest.requestCode}/contact`)
  ),false)

  if(isLoading){
    return <PageContainer>
      <Loading css="margin: 20rem auto" />
    </PageContainer>
  }

  if (caseRequest.state === WAITING_MEDICAL_RECORDS || isPendingAskingMore(caseRequest)) {
    return <>
      <WaitingPatientUpdates 
        caseRequest={caseRequest} 
        setCaseRequest={setCaseRequest}
        setShouldReloadData={setShouldReloadData} 
        thread={threads && threads.length > 0 
            ? threads.filter(t => t.id === caseRequest.requestCollaborationId)[0] 
            : null
        }
        isLoadingCollab={isLoadingCollab}
      />
      {isLoadingCollab && <Loading css="margin: 10rem auto" />}
      {!isLoadingCollab && threads && threads.length > 0 && threads.filter(t => t.id !== caseRequest.requestCollaborationId).length > 0 &&
        <PreviousMessages 
          caseRequest={caseRequest} 
          setCaseRequest={setCaseRequest} 
          threads={threads.filter(t => t.id !== caseRequest.requestCollaborationId)}
          setShouldReloadData={setShouldReloadData}
          createContainer  
        />
      }
      <PurviewFooter/>
    </>
  }

  const isPending = caseRequest.state === PENDING_REQUEST

  return <PageContainer css="padding-bottom: 1rem; min-height: 600px;">
    <Header title={"Messaging"}>
      {
        (caseRequest.state === CASE_ARCHIVED || caseRequest.state === REQUEST_REJECTED) &&
        <Button highlight onClick={unarchive}>Unarchive case</Button>
      }
    </Header>
    {caseRequest.state === CASE_ARCHIVED &&
      <InfoBox lightYellow css="margin-top: -1rem; margin-bottom: 2rem; padding:1.5rem">
        <p css="margin-bottom:0.25rem;"><b>This case was archived by an Administrator</b></p>
        <p css="margin-bottom:1.5rem;">If you need to continue this case, it can be unarchived.</p>
        {caseRequest?.caseInfo?.declineReason ? 
          <>
            <p css="margin-bottom:0.25rem;"><b>Reason given</b>{caseRequest?.caseInfo?.declineReasonSharedPatient == false ? ' (not shared with patient):' : ': '}</p>
            <p css="margin-bottom:0.25rem;"> {caseRequest?.caseInfo?.declineReason} </p>
          </>
        :  
          <>
            <p css="margin-bottom:0.25rem;"><b>Archive reason: </b></p>
            <p css="margin-bottom:0;"> <i>No reason provided</i> </p>
          </>
        }
      </InfoBox>
    }
    {isLoadingCollab && <Loading css="margin: 10rem auto" />}
    {(!isLoadingCollab && threads && threads.length > 0 ) 
      ? <PreviousMessages 
          caseRequest={caseRequest} 
          setCaseRequest={setCaseRequest} 
          threads={threads}
          setShouldReloadData={setShouldReloadData}
          buttonNew={() => <Button
            css="margin-bottom: 1.5rem;"
            onClick={confirmDialog.openWith}
            icon="email"
            highlight
            // disabled={caseRequest.state === CASE_REJECTED || caseRequest.state === CASE_ARCHIVED || 
            //   (wasSentToExpert && !needsPayment && ((isNotLegal && allTermsAccepted) || !isNotLegal))}
            disabled={caseRequest.state === CASE_REJECTED || caseRequest.state === CASE_ARCHIVED}
          >
            New request
          </Button>}  
        />
      : !isLoadingCollab && <Card>
          <Button
            css="margin-bottom: 1.5rem;"
            // type="submit"
            onClick={confirmDialog.openWith}
            icon="email"
            highlight
            // disabled={caseRequest.state === CASE_REJECTED || caseRequest.state === CASE_ARCHIVED || (wasSentToExpert && !needsPayment && ((isNotLegal && allTermsAccepted) || !isNotLegal))}
            disabled={caseRequest.state === CASE_REJECTED || caseRequest.state === CASE_ARCHIVED}
          >
            New request
          </Button>
          <EmptyPage>
            No messages sent yet
          </EmptyPage>
        </Card>
    }
  </PageContainer>
  
}

const TermsAgreementStatus = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1rem;
  margin-bottom: 2rem;
  border-radius: 6px;
  background: ${getColor('gray240')};
  p {
    margin-bottom: 0;
  }
  ${Button} {
    margin-left: 0.5rem;
  }
`

export const formatDateLogs = (millis) => {
  const date = new Date(millis);

  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const month = months[date.getMonth()];
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear();
  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  return `${month} ${day}, ${year} - ${hours}:${minutes} ${ampm}`;
}

export const TermsInfo = ({caseRequest}) => {

  const allSignedByPatient = !caseRequest.acceptanceInfo?.agreements?.find(({byRepresentative}) => byRepresentative)

  return <>
    <TermsAgreementStatus>
      {
        allSignedByPatient
          ? <p css="padding-right: 1rem">This contact has already signed the required <Link
            to={`/admin/request/${caseRequest.requestCode}/agreements`}
            className="link"
          >agreements</Link>.</p>
          : <p css="padding-right: 1rem">The required <Link
            to={`/admin/request/${caseRequest.requestCode}/agreements`}
            className="link"
          >agreements</Link> were completed.</p>
      }
      <div css="flex-shrink: 0;">
        {
          allSignedByPatient
            ? <SignatureDate><Icon icon="check"/>All agreements signed</SignatureDate>
            : <SignatureDate><Icon icon="check"/>All agreements done</SignatureDate>
        }
      </div>
    </TermsAgreementStatus>
  </>
}