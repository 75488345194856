import {ContextMenu, Li} from '@startlibs/components'
import {useNavigate} from 'react-router'
import React from 'react'
import {
  CASE_CLOSED,
  CASE_REVIEWED,
  UNDER_REVIEW,
  WAITING_ACCEPTANCE,
  WAITING_MORE_INFORMATION
} from '../enums/CaseState';
import {useAcceptCase} from './dialogs/useAcceptCase'
import {useDeclineCase} from './dialogs/useDeclineCase'
import {useIntl} from "react-intl";

export const ExpertCaseCardMenu = ({caseRequest, refreshList, isOpen}) => {
  const navigate = useNavigate()
  const intl = useIntl()
  const id = caseRequest.requestId || caseRequest.id
  const acceptCase = useAcceptCase(caseRequest,() => navigate("/expert/case/"+id))
  const declineCase = useDeclineCase(caseRequest,refreshList)


  return isOpen && <ContextMenu>
    {caseRequest.state === CASE_CLOSED && <Li label={intl.formatMessage({
        defaultMessage:"View report",
        description: "Expert case card menu view report label"
      })} icon="report" linkTo={`/expert/case/${id}/report`}/>}
    <Li label={intl.formatMessage({
      defaultMessage:"View case",
      description: "Expert case card menu view case label"
    })} linkTo={`/expert/case/${id}/overview`} />
    {caseRequest.state === WAITING_ACCEPTANCE && !caseRequest.isCoReview && <Li label={intl.formatMessage({
      defaultMessage: "Accept",
      description: "Expert case card menu accept label"
    })} onClick={acceptCase}/>}
    {caseRequest.state === WAITING_ACCEPTANCE && !caseRequest.isCoReview && <Li label={intl.formatMessage({
      defaultMessage: "Decline",
      description: "Expert case card menu decline label"
    })} icon="unavailable" onClick={declineCase}/>}
    {(caseRequest.state === UNDER_REVIEW || caseRequest.state === WAITING_MORE_INFORMATION) && !caseRequest.isCoReview && <Li label={intl.formatMessage({
      defaultMessage:"Edit review",
      description: "Expert case card menu edit review label"
    })} icon="edit-box" linkTo={`/expert/case/${id}/questions`}/>}
    {caseRequest.state === CASE_REVIEWED && <Li label={intl.formatMessage({
      defaultMessage: "Check review",
      description: "Expert case card menu check review label"
    })} linkTo={`/expert/case/${id}/questions`}/>}
  </ContextMenu>
}
