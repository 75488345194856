import React, {useContext, useEffect, useRef} from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components';

import {Button, Loading, Icon, Tooltip} from '@startlibs/components'
import { TextInput } from '@startlibs/form';
import {callIfFunction, getColor} from '@startlibs/utils'
import {useLazyConstant, useToggle, willUseSharedCallback} from '@startlibs/core'
import Papaparse from 'papaparse'

import {useGetState} from '../../hooks/useAsyncState'
import {UploaderConfigContext} from "../../service/UploaderConfigContext";
import {useDoAction} from "../../service/hooks/useDoAction";
import {UploaderAction} from "../../service/UploaderAction";
import {NonCompliantDicom} from "../../enums/RecordFormat";
import {FormattedMessage, useIntl} from "react-intl";

const ImportCsvLink = styled.span`
  display: inline-block;
  position: relative;
  input {
    z-index: 1;
    cursor: pointer;
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    ::-webkit-file-upload-button {
      visibility: hidden;
    }
    ::file-upload-button {
      visibility: hidden;
    }
  }
`
const MetadataInputsContainer = styled.div`
  display: flex;
  margin: 0.75rem 0;
  align-items: center;
  flex-wrap: wrap;
  ${TextInput} {
    margin-right: 0.5rem;
    font-size: 12px;
    flex-basis: 0;
    flex-grow: 1;
  }
  ${Button} ~ ${Button} {
    margin-left: 0.5rem;
`

const Tag = styled(({title, ...props}) => <Tooltip whenEllipsis content={title} instant>
      <div  {...props}/>
    </Tooltip>
    )`
    position: relative;
    border-radius: 20px;
    background: rgba(185, 200, 210, 0.3);
    border: 1px solid rgba(185, 200, 210, 0.6);
    display: inline-block;
    margin-right: 5px;
    margin-top: 5px;
    color: rgba(0,0,0,0.75);
    vertical-align: -5px;
    padding: 0 0.5rem;
    max-width: 160px;
    font-size: 12px;
    line-height: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    ${props => !props.readOnly && `
      padding-right: 20px;
    `}
    ${Icon} {
      font-size: 14px;
      color: rgba(0,0,0,0.4);
      cursor: pointer;
      text-align: center;
      width: 16px;
      line-height: 16px;
      display: inline-block;
      border-radius: 50%;
      position: absolute;
      right: 2px;
      top: 50%;
      transform: translateY(-50%);
      :hover, :active {
        background: rgb(195,40,45,0.15);
        color: ${getColor('alert')};
      }
    }
    ${Loading} {
      position: absolute;
      right: 2px;
      top: 2px;
    }
`

const MetadataError = styled.div`
  color: ${getColor('alert')};
  flex-basis: 100%;
  margin-top: 2px;
`

let i = 0

const getUID = () => Date.now().toString(36) + '-' + i++

const [useContainerBlur, containerBlur] = willUseSharedCallback()
const [useFocusFirst, inputRefs] = willUseSharedCallback()

export const MetadataField = ({record}) => {
  const {disabled: readOnly, setNotification} = useContext(UploaderConfigContext)
  const doAction = useDoAction()

  const metadata = record.metadata
  const setMetadata = (value) => doAction(UploaderAction.SetMetadata, record, value)

  const isAdding = useToggle()

  const [data, rawSetData, getData] = useGetState(_.toPairs(metadata).map(([key, value]) => ({
    key,
    value,
    uid: getUID()
  })))


  const [updateData, repeatsKey, rawUpdateData] = useLazyConstant(() => {
    const executingPromise = {current: null}
    const queuedUpdates = {current: []}
    let lastState = {current: getData()}
    let deferring = -1

    const setData = async (updater) => {
      const prevData = getData()
      const newData = callIfFunction(updater, prevData)
      const newMetadData = _.fromPairs(newData.filter(({transient}) => !transient).map(({key, value}) => [key, value]))
      const promise = setMetadata(newMetadData)
      return promise
        .then(() => rawSetData(newData))
        .catch(() => {
          setNotification({type:"alert", timeout: 4000,msg:(close) => <span>The tag could not be deleted. Please try again.</span>})
          rawSetData(prevData);
          return Promise.reject()
        })
    }

    const executeUpdate = async () => {
      if (executingPromise.current) {
        return
      }

      const currentQueue = queuedUpdates.current

      executingPromise.current = setData(currentData => currentQueue.reduce((data, {updater}) => updater(data), getData()).filter(Boolean))

      try {
        await executingPromise.current
        currentQueue.map(({promiseRef}) => promiseRef.resolve())
        queuedUpdates.current = _.difference(queuedUpdates.current, currentQueue)
      } catch (e) {
        const recentlyQueued = _.difference(queuedUpdates.current, currentQueue)
        if (!recentlyQueued.length) {
          currentQueue.map(({promiseRef}) => promiseRef.reject())
        }
      } finally {
        executingPromise.current = null
        const recentlyQueued = _.difference(queuedUpdates.current, currentQueue)
        if (recentlyQueued.length) {
          await executeUpdate()
        } else {
          lastState.current = getData()
        }
      }
    }

    return [
      (updater) => {
        clearTimeout(deferring)
        deferring = setTimeout(executeUpdate, 50)
        const promiseRef = {}
        promiseRef.current = new Promise((resolve, reject) => {
          promiseRef.resolve = resolve
          promiseRef.reject = reject
        })
        lastState.current = updater(lastState.current)
        queuedUpdates.current = queuedUpdates.current.concat({updater, promiseRef})
        return promiseRef.current
      },
      ({key, uid}) => lastState.current.find((item) => item.key?.toLowerCase() === key?.toLowerCase() && item.uid !== uid),
      (updater) => {
        lastState.current = updater(lastState.current)
        rawSetData(lastState.current)
      }
    ]
  })

  if (record.key === NonCompliantDicom) {
    return null
  }

  return <div
    onBlur={(e) => (e.currentTarget !== e.relatedTarget && !e.currentTarget.contains(e.relatedTarget)) && containerBlur()}
    className="JS-blur-container" css={data.length === 0 ? "display:inline;" : ""}
  >
    {data.map((item, i) =>
      <MetadataItem
        key={item.uid}
        item={item}
        readOnly={readOnly}
        repeatsKey={repeatsKey}
        setItem={(value) => updateData(_.map(i => i.uid === value.uid ? value : i))}
        removeItem={() => updateData(_.differenceBy('uid', _, [item]))}
        isAdding={isAdding}
      />
    )}
    {
      !readOnly && !isAdding.isOpen && <>
        <a
          tabIndex={1}
          className="light-link"
          css="margin-right:0.75rem;margin-top:2px;display:inline-block;"
          onClick={() => {rawUpdateData(_.concat(_, {key: '', value: '', uid: getUID(), transient: true})); isAdding.open()}}
          >Add tag</a>
      </>
    }
  </div>
}

const focusFirst = () => {
  let executed = false
  inputRefs((ref) => {
    if (!executed && ref.current) {
      ref.current.focus()
      executed = true
    }
  })
}

const MetadataItem = ({item, setItem, repeatsKey, removeItem,readOnly, isAdding}) => {
  const intl = useIntl()
  const hasError = useToggle()
  const loading = useToggle()
  const connectionError = useToggle()

  const inputRef = useRef()

  const edit = useToggle(item.transient && item, (newV, prevV) => {
    if (_.isObject(newV) || newV === 0 || prevV === 0) {
      return
    }
    if (newV === false && _.isObject(prevV)) {
      if (!prevV.key) {
        loading.wait(removeItem())
        isAdding.close();
      }
      if (!prevV.key) {
        hasError.openWith('empty')
        isAdding.close();
        return false;
      }
      if (repeatsKey(prevV)) {
        hasError.openWith('repeated')
        return false
      }
    }
    if (_.isObject(newV)) {
      if (hasError.get() && newV.key !== prevV.key) {
        hasError.close()
      }
    }
    if (newV === false) {
      hasError.close()
      confirmValue(prevV)
      return false
    }
  })

  const tryConfirmValue = (e) => {
    if (e.key === 'Enter') {
      edit.isOpen.key.trim().length > 0 
        ? edit.close()
        : edit.openWith(0)
      isAdding.close()
    } else {
      if (e.key === 'Escape') {
        e.preventDefault()
        edit.openWith(0)
        isAdding.close()
      }
    }
  }

  const confirmValue = (value) => {
    connectionError.close()
    loading.wait(
      setItem(_.unset('transient', value))
        .then(() => {edit.openWith(0);isAdding.close()})
        .catch(() => {connectionError.open(); isAdding.close()}))
  }

  useEffect(() => () => {
    if (edit.get() === 0) {
      edit.close()
    }
  }, [edit.isOpen])

  useEffect(() => {
    if (edit.isOpen === false) {
      focusFirst()
    }
  }, [edit.isOpen])

  useEffect(() => () => {
    focusFirst()
  }, [])

  useContainerBlur(() => edit.close())
  useFocusFirst(inputRef)

  if (edit.isOpen) {
    return <MetadataInputsContainer
      onKeyUp={tryConfirmValue}
      onKeyPress={e => e.key === "Enter" && e.preventDefault()}
    >
      <TextInput
        raw
        ref={inputRef}
        hasErrors={hasError.isOpen && !loading.isOpen}
        autoFocus={!edit.isOpen.key}
        placeholder={intl.formatMessage({
          defaultMessage: "Add tag...",
          description: "Uploader, metadata field key placeholder"
        })}
        value={edit.isOpen.key}
        setValue={(v) => edit.openWith(_.set('key', v))}
        disabled={loading.isOpen}
        onKeyUp={tryConfirmValue}
        onKeyPress={e => e.key === "Enter" && e.preventDefault()}
        onClick={(e) => e.stopPropagation()}
        onBlur={(i) => {edit.close;}}
        maxLength={30}
      />
      <TextInput
        raw
        autoFocus={edit.isOpen.key}
        placeholder={intl.formatMessage({
          defaultMessage: "Value",
          description: "Uploader, metadata field value placeholder"
        })}
        value={edit.isOpen.value}
        setValue={(v) => edit.openWith(_.set('value', v))}
        disabled={loading.isOpen}
        hidden
      />
      {loading.isOpen ?
        <Loading/>
        :
        <>
          <Button onClick={() => {loading.wait(removeItem());isAdding.close();}} onlyIcon icon="x"/>
          <Button onClick={edit.close} onlyIcon icon="check" highlight/>
        </>
      }
      {
        hasError.isOpen === "repeated" && <MetadataError><FormattedMessage
          defaultMessage="Tag already added"
          description="Uploader, metadata field error message"
        /></MetadataError>
      }
    </MetadataInputsContainer>
  }
  
  return item.key.length > 0 && <Tag title={item.key} readOnly={readOnly}>
    {item.key}
    {!readOnly && loading.isOpen ? <Loading size={14} borderWidth={3} />
      : <Icon icon="x" onClick={() => {loading.wait(removeItem())}}/>
    }
    </Tag>
}