import React from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components/macro'
import {FileNotFoundErrorBox} from "./AttachmentBoxStyles";
import {useDoAction} from "../service/hooks/useDoAction";
import {UploaderAction} from "../service/UploaderAction";
import {useRecordsSelector} from "../service/hooks/useRecords";
import {FileNotFound} from "../enums/FileState";
import {useUploadQueueSelector} from "../service/hooks/useUploadQueueSelector";
import {FormattedMessage} from "react-intl";

styled

export const FileNotFoundRetry = () => {
  const hasFileNotFoundFailure = useUploadQueueSelector(_.find(({failed,...rest}) => failed && failed.find(({failure}) => failure === FileNotFound)))
  const doAction = useDoAction()
  const retryFilesNotFound = () => doAction(UploaderAction.RetryFilesNotFound)
  return hasFileNotFoundFailure && <FileNotFoundErrorBox>
    <div css="max-width:38rem;">
      <FormattedMessage
        defaultMessage={`<b>Upload failed.</b> Some files seem to have been moved from their original location. Check if the files are available and try again.`}
        description="Uploader, file not found error message"
        values={{
          b: (chunks) => <b>{chunks}</b>
        }}
      />
    </div>
    <div className="link" onClick={retryFilesNotFound}><FormattedMessage
      defaultMessage="Retry all"
      description="Uploader, file not found error, retry all"
    /></div>
  </FileNotFoundErrorBox>
}