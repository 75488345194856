import {Button} from '@startlibs/components';
import {getColor} from '@startlibs/utils';
import React from 'react'
import styled from 'styled-components';
import {
  CardWithIcon,
  PageContainer,
  PageFooter,
  RequestStatusIcon
} from '../../components/PageLayout';
import {Header} from '../../components/Header';
import {PurviewFooter} from '../../components/PurviewFooter';
import { lazyProviderInfo } from '../../components/WithProvider';

const RequestCodeBox = styled.div`
  background: ${getColor('gray240')};
  border: 1px solid ${getColor('gray210')};
  border-radius: 6px;
  display: inline-flex;
  align-items: center;
  padding: 0.5rem 1rem;
  margin-bottom: 0.75rem;
  font-size: 16px;
  min-height: 4rem;
  width: 100%;
  font-size: 16px;
  max-width: 33rem;
  justify-content: space-between;
  flex-wrap: wrap;
  > div {
    margin: 0.5rem 1rem 0.5rem 0;
  }
  ${Button} {
    margin: 0.5rem 0;
    justify-self: flex-end;
    flex-shrink: 0;
  }
`

const RequestSubmittedPage = styled(PageContainer)`
  @media print {
    ${Button} {
      display: none;
    }
    ${RequestCodeBox} {
      max-width: auto;
      display: inline-block;
      width: auto;
    }
  }
`

export const PatientWaiting = ({caseRequest}) => {

  const providerInfo = lazyProviderInfo.read()
  const canAccessCaseOverview = providerInfo?.patientAccessFunctions?.caseOverview

  const {newlyCreated, recentlySubmitted} = caseRequest

  return <><RequestSubmittedPage>
    <Header
      title="Status"
      className="hideOnPrint"
    />
    <CardWithIcon>
      <RequestStatusIcon success={newlyCreated} icon={newlyCreated ? "check" : "clock"}/>
      <h3>
        {
          ((recentlySubmitted || newlyCreated) ? "Thank you! " : "") + "Please wait for updates"
        }
      </h3>
      <p>
        Your request is being reviewed and you will be notified of any
        updates or if additional information is needed.
      </p>
      {newlyCreated && <>
        <p><strong>Use the following code to access your request in the future:</strong></p>
        <RequestCodeBox>
          <div>Expert request code: <b>{caseRequest.requestCode}</b></div>
          <Button small icon="print" onClick={() => window.print()}>Print code</Button>
        </RequestCodeBox>
      </>
      }
    </CardWithIcon>
    <PageFooter>
      {canAccessCaseOverview && <Button.Link to="/patient/overview">Go to overview</Button.Link>}
    </PageFooter>
  </RequestSubmittedPage>
    <PurviewFooter/>
  </>
}
