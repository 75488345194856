import React, {createContext, useEffect, useMemo, useState} from 'react'
import styled from 'styled-components/macro';
import {IntlProvider} from "react-intl";
import {callIfFunction, wrapLazy} from "@startlibs/utils";
import {lazyProviderInfo} from "./WithProvider";
import {shouldAddLocaleUrlParam} from "../utils/getInitLocale";
import {useLocation} from "react-router";

styled;

const lazyLocale = wrapLazy((locale) => locale
    ? import(`intl-compiled/${locale}.json`).then(messages => ([locale, messages]))
    : lazyProviderInfo.promise()
      .then(({locale}) => import(`intl-compiled/${locale}.json`).then(messages => ([locale, messages])))
  // : lazyProviderInfo.promise().then(({locale}) => import(`intl-compiled/en-US.json`).then(messages => (['en-US',messages])))
)

export const LocaleContext = createContext({
  locale: null,
  changeLanguage: () => {
  }
})
const addLocaleToUrl = (locale) => {
  const params = new URLSearchParams(window.location.search)
  if (params.get("locale") === locale) return
  params.set("locale", locale)
  window.top.history.replaceState(null, null, window.location.pathname + "?" + params.toString())
}
export const I18NProvider = ({children, defaultLocale, withUrlParam}) => {
  // Estado para controlar o idioma atual (caso o usuário altere via NavBar, por exemplo)
  const [currentLocale, setCurrentLocale] = useState(() => callIfFunction(defaultLocale) || "en-US")

  // Define o idioma padrão:
  // Se estiver em preview, usa o idioma vindo de previewCase.info.locale (ou "en-US");
  // caso contrário, se o usuário não tiver alterado, lazyLocale usará lazyProviderInfo para obter o idioma.
  const localeToLoad = currentLocale || defaultLocale
  const [messages,setMessages] = useState(() => lazyLocale.use(localeToLoad).read()[1])

  const providerValue = useMemo(() => ({
      locale:currentLocale,
      changeLanguage: (newLocale) =>
        lazyLocale.use(newLocale).promise().then(([locale, messages]) => {
          setCurrentLocale(locale)
          setMessages(messages)
        }).catch(() => {
          lazyLocale.use(defaultLocale).promise().then(([locale,messages]) => {
            setCurrentLocale(locale)
            setMessages(messages)
          })
        })
    })
    , [currentLocale])
  useEffect(() => {
    localStorage.setItem("last-locale", currentLocale)
  }, [currentLocale]);
  return <LocaleContext.Provider value={providerValue}>
    <IntlProvider messages={messages} locale={currentLocale} defaultLocale="en-US">
      {children}
    </IntlProvider>
    {callIfFunction(withUrlParam) && <UpdateLocationParam locale={currentLocale} />}
  </LocaleContext.Provider>
}
const UpdateLocationParam = ({locale}) => {
  const location = useLocation()
  if (new URLSearchParams(location.search).get("locale") !== locale) {
    addLocaleToUrl(locale)
  }
  return null
}