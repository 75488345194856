import { Button, Icon, Link, Tooltip } from '@startlibs/components';
import { callIfFunction, getColor, preventDefault } from '@startlibs/utils';
import {usePopupToggle} from '@startlibs/core';
import React from 'react'
import _ from 'lodash/fp'
import styled, {css} from 'styled-components';
import {
  CASE_ARCHIVED,
  CASE_CLOSED,
  CASE_DRAFT,
  CASE_REJECTED,
  CASE_REVIEWED,
  isPendingAskingMore,
  PENDING_ASSIGNMENT,
  PENDING_REQUEST,
  REQUEST_REJECTED,
  UNDER_REVIEW,
  WAITING_ACCEPTANCE,
  WAITING_APPROVAL,
  WAITING_MEDICAL_RECORDS,
  WAITING_MORE_INFORMATION
} from '../enums/CaseState';
import {formatDateNoUTC} from '../utils/utils'
// import {isPendingAskingMore} from '../patient/utils/patientUtils'

import {FormattedMessage, useIntl} from 'react-intl';
import { IfLocale } from '../hocs/IfLocale';
import {defaultDateFormat, defaultDateTimeFormat} from "../utils/dateFormat";

export const RequestCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: stretch;
  background: white;
  border-radius: 5px;
  border: 1px solid ${getColor('gray210')};
  padding: 0.75rem 1rem;
  cursor: pointer;
  width: 100%;
  position: relative;
  overflow: hidden;
  text-decoration: none;
  color: inherit;
  .main-content {
    flex-grow: 1;
  }
  & ~ & {
    ${props => props.isKanban && `margin: .5rem 0 0 0;`}
  }
  ${props => props.isResults && `margin: .25rem;`}
  ${props => props.viewOnly && css`
    margin-bottom: 1rem;
    cursor: default;
  `}
  .patient-name {
    font-size: 14px;
    font-weight: 600;
    width:  ${props => props.isPriority && props.isLocked ? "calc(100% - 76px)" : props.isLocked ? "calc(100% - 54px)" :  props.isPriority ? "calc(100% - 54px)" : "calc(100% - 2.5rem)"};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: .25rem;
    ${Icon} {
      vertical-align: -1px;
    }
  }
  .code {
    margin-top: 1px;
  }
  .last-update {
    margin-top: 1px;
    font-style: italic;
    color: ${getColor('gray150')};
  }
  .actions-wrapper {
    position: absolute;
    top: .75rem;
    right: .75rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .lock-icon{
      color: ${getColor('gray180')};
      font-size: 20px;  
      width: 2rem;
      height: 2rem;
      ${Icon} {
        margin-top: -2px;
      }
      display: flex;
    }
    .priority-icon {
      display: none;
      align-items: center;
      justify-content: center;
      margin-right: .25rem;
      width: 2rem;
      height: 2rem;
      color: ${getColor('gray180')};
      border-radius: 6px;
      font-size: 20px;
      :hover {
        ${props => !props.isPriority && css`
          color: ${getColor('gray120')};
        `}
        display: flex;
      }
      ${Icon} {
        margin-top: -2px;
      }
      ${props => props.isPriority && css`
        display: flex;
        color: ${getColor('warning')};
        background-color: ${getColor('lightYellow')};
        ${props => !props.viewOnly && css`
          :hover {
            opacity: 75%;
          }
        `}
      `}
    }
  }
  ${props => !props.isExpert && css`
    :hover {
      .patient-name {
        width:  ${props => props.isLocked ? "calc(100% - 76px)" : "calc(100% - 54px)" };
      }
      .priority-icon {
        display: flex;
      }
    }
  `}
  .card-footer {
    margin: 0.75rem -1rem -0.75rem;
    padding: 5px 1rem 5px;
    background: ${getColor('gray240')};
    display: flex;
    justify-content: space-between;
    align-items: center;
    &.coreview {
      background: #FFFFFF;
      border-top: 1px solid ${getColor('gray240')};
    }
    &.action-required {
      background: ${getColor('lightYellow')};
    }
    &.update-received {
      background: ${getColor('lightGreen')};
    }
    &.assigned-to-admin {
      background: ${getColor('lightBlue')};
    }
    .status {
      font-size: 11px;
      color: ${getColor('gray120')};
      display: flex;
      align-items: center;
      line-height: 12px;
      & ~ .status {
        margin-left: .5rem;
      }
      &.update-received {
        color: ${getColor('success')};
      }
      &.action-required {
        color: ${getColor('warning')};
      }
      &.waiting-response {
        color: ${getColor('gray150')};
      }
      &.assigned-to-admin{
        color: ${getColor('mainBlue')};
      }
      ${Icon} {
        font-size: 15px;
        margin-right: .5rem;
        margin-top: 1px;
      }
      .finished-icon {
        background: ${getColor('gray120')};
        border-radius: 15px;
        color: ${getColor('gray240')};
        display: flex;
        align-items: center;
        justify-content: center;
        width: 13px;
        height: 13px;
        margin-right: .25rem;
        ${Icon} {
          font-size: 12px;
          padding-left: 2px;
        }
      }
    }
    .coreview{
      background: ${getColor('lightBlue')};
    }
  }
`

export const CaseRequestCard = ({className, caseRequest, isKanban, isResults, showFooter, contextMenu, categories, onClick, linkTo, toggleAsPriority, editCaseNumber}) => {
  const intl = useIntl()
  const getStatusLabel = (state) => {
    switch (state) {
      case PENDING_REQUEST:
        return <div className="status"><Icon icon="help"/> <span><FormattedMessage
          defaultMessage="Pending request"
          description="Case request card, pending request state label"
        /></span></div>
      case CASE_DRAFT:
      case WAITING_MEDICAL_RECORDS:
      case WAITING_APPROVAL:
      case PENDING_ASSIGNMENT:
      case WAITING_ACCEPTANCE:
      case CASE_REJECTED:
        return <div className="status"><Icon icon="edit-box"/> <span><FormattedMessage
          defaultMessage="Case draft"
          description="Case request card, case draft state label"
        /></span></div>
      case UNDER_REVIEW:
      case WAITING_MORE_INFORMATION:
        return <div className="status"><Icon icon="clock"/> <span><FormattedMessage
          defaultMessage="Expert reviewing"
          description="Case request card, expert reviewing state label"
        /></span></div>
      case CASE_REVIEWED:
        return <div className="status"><Icon icon="check"/> <span><FormattedMessage
          defaultMessage="Expert reviewed"
          description="Case request card, expert reviewed state label"
        /></span></div>
      case CASE_CLOSED:
        return <div className="status">
          <div className="finished-icon"><Icon icon="check"/></div>
          <span><FormattedMessage
            defaultMessage="Completed"
            description="Case request card, completed state label"
          /></span></div>
      case REQUEST_REJECTED:
      case CASE_ARCHIVED:
        return <div className="status"><Icon icon="unavailable"/> <span><FormattedMessage
          defaultMessage="Archived"
          description="Case request card, archived state label"
        /></span></div>
    }
  }

  const contextMenuToggle = usePopupToggle()

  // const contextualStatus = caseRequest.state === CASE_ARCHIVED
  //   ? null
  //   : caseRequest.caseFlags?.adminUpdateReceived
  //     ? <div className="status update-received"><Icon icon="email"/> <span>Update received</span></div>
  //     : ([WAITING_APPROVAL, WAITING_MEDICAL_RECORDS, WAITING_MORE_INFORMATION, WAITING_ACCEPTANCE].indexOf(caseRequest.state) >= 0 || isPendingAskingMore(caseRequest))
  //       ? <div className="status waiting-response"><Icon icon="clock"/> <span>Pending response</span></div>
  //       : [PENDING_ASSIGNMENT, CASE_REJECTED, CASE_REVIEWED].indexOf(caseRequest.state) >= 0
  //         ? <div className="status action-required"><Icon icon="failure"/> <span>Action required</span></div>
  //         : null

  const contextualStatus = caseRequest.state === CASE_ARCHIVED
    ? null
    : caseRequest.caseFlags?.adminUpdateReceived
      ? <div className="status update-received"><Icon icon="email"/> <span><FormattedMessage
        defaultMessage="Update received"
        description="Case request card, update received status label"
      /></span></div>
      : ([WAITING_APPROVAL, WAITING_MEDICAL_RECORDS, WAITING_MORE_INFORMATION].indexOf(caseRequest.state) >= 0 || isPendingAskingMore(caseRequest))
        ? <div className="status waiting-response"><Icon icon="clock"/> <span><FormattedMessage
          defaultMessage="Pending response"
          description="Case request card, pending response status label"
        /></span></div>
        : ([WAITING_ACCEPTANCE].indexOf(caseRequest.state) >= 0)
          ? <div className={"status waiting-response " + (caseRequest?.isAssignedToLoggedAdmin ? " assigned-to-admin" : "")}><span><b><FormattedMessage
            defaultMessage="Assigned to:"
            description="Case request card, assigned to label"
          /></b> {caseRequest?.expertName}</span></div>
          : [UNDER_REVIEW].indexOf(caseRequest.state) >= 0
          ? <div className={"status waiting-response  " + (caseRequest?.isAssignedToLoggedAdmin ? " assigned-to-admin" : "")}><span><b><FormattedMessage
              defaultMessage="Being reviewed by:"
              description="Case request card, being reviewed by label"
            /></b> {caseRequest?.expertName}</span></div>
          : [CASE_REJECTED].indexOf(caseRequest.state) >= 0
            ? <div className={"status action-required"}><Icon icon="failure"/> <span><b><FormattedMessage
                defaultMessage="Rejected by the expert"
                description="Case request card, rejected by the expert status label"
              /></b></span></div>
          : [PENDING_ASSIGNMENT, CASE_REVIEWED].indexOf(caseRequest.state) >= 0
            ? <div className="status action-required"><Icon icon="failure"/> <span><FormattedMessage
                  defaultMessage="Action required"
                  description="Case request card, action required status label"
                /></span></div>
            : null

  return <RequestCard
    as={linkTo ? Link : undefined}
    isKanban={isKanban}
    isResults={isResults}
    viewOnly={!contextMenu}
    onClick={onClick}
    to={linkTo}
    className={className}
    isPriority={caseRequest.markedAsPriorityEpochMilli}
    isLocked={caseRequest.externalAccessBlocked}
  >
    {contextMenu && <div className="actions-wrapper">
      {toggleAsPriority && <div className="priority-icon" onClick={(e) => {e.preventDefault() ; e.stopPropagation(); toggleAsPriority(caseRequest)() }}>
        <Tooltip content={caseRequest.markedAsPriorityEpochMilli
          ? intl.formatMessage({
            defaultMessage: "Remove as priority",
            description: "Case request card remove as priority tooltip"
          })
          : intl.formatMessage({
            defaultMessage:"Mark as priority",
            description: "Case request card mark as priority tooltip"
          })}>
          <Icon icon={caseRequest.markedAsPriorityEpochMilli ? "star" : "star-line"} />
        </Tooltip>
      </div>}
      {caseRequest.externalAccessBlocked && <div className="lock-icon"><Tooltip css='cursor: auto;' content="Case is locked">
          <Icon css='cursor: auto; margin-left:0.25rem;' icon="lock-line"/>
      </Tooltip></div>}
      <Button small onlyDropdown onClick={preventDefault(contextMenuToggle.toggle)}>
        {callIfFunction(contextMenu, contextMenuToggle.isOpen, contextMenuToggle.toggle)}
      </Button>
      </div>
    }
    {!contextMenu && caseRequest.markedAsPriorityEpochMilli && <div className="actions-wrapper">
      <div className="priority-icon" css="margin-right: 0;">
        <Tooltip content={intl.formatMessage({
          defaultMessage: "This case was marked as priority.",
          description: "Case request card priority tooltip"
        })}>
          <div className="priority-icon" onClick={e=> {e.preventDefault();e.stopPropagation()}}>
            <Icon icon="star"/>
          </div>
        </Tooltip>
      </div>
    </div>
  }
    <div className="main-content">
      <div className="patient-name fs-exclude"><Icon icon="user-line"/>
        <FormattedMessage
          defaultMessage="{firstName} {middleName} {lastName}"
          description="Case request card patient name"
          values={{
            firstName: (caseRequest.firstName || caseRequest.patientInfo.firstName),
            middleName: ((caseRequest.middleName ? caseRequest.middleName + ' ' : '') || (caseRequest.patientInfo?.middleName ? caseRequest.patientInfo?.middleName + ' ' : '')),
            lastName: (caseRequest.lastName || caseRequest.patientInfo?.lastName)
          }}
        />
      </div>
      <IfLocale contains="LEGAL">
        <div className="code"><b>Case Number:</b> {
            caseRequest?.legalInfo?.caseNumber?.length > 0 ? 
            caseRequest.legalInfo.caseNumber
            : 
            isKanban && editCaseNumber ?
            <a className="light-link" onClick={(e) => {e.preventDefault() ; e.stopPropagation(); editCaseNumber(caseRequest)() }}>Add</a>
            : null
          }
        </div>
      </IfLocale>
      {/* <div className="code">Case ID: {caseRequest.requestCode}</div> */}
      <div className="code"><b><FormattedMessage defaultMessage="Case ID: " description='CaseRequestCard admin caseid item label'/></b>{caseRequest.requestCode}</div>
      <div className="last-update">
        <FormattedMessage
          defaultMessage="Last updated on <nowrap>{date}</nowrap>"
          description="Case request card last updated label"
          values={{
            nowrap: (chunks) => <span className="nowrap">{chunks}</span>,
            date: formatDateNoUTC(new Date(caseRequest.whenModifiedEpochMilli), defaultDateTimeFormat(intl))
          }}
        />

    </div>
    </div>
    {categories}
    {(isKanban || isResults) && <>
      {showFooter ?
        <div className={"card-footer" + caseRequest?.isAssignedToLoggedAdmin ? " assigned-to-admin" : ""}>
          <span className="general-status">{getStatusLabel(caseRequest.state)}</span>
          {contextualStatus && <span className="contextual-status">{contextualStatus}</span>}
        </div>
        : <>{contextualStatus &&
        <div 
          className={"card-footer " 
            + (caseRequest.caseFlags?.adminUpdateReceived ? "update-received " : "") 
            + ([PENDING_ASSIGNMENT, CASE_REJECTED, CASE_REVIEWED].indexOf(caseRequest.state) >= 0 ? "action-required" : "")
            + (caseRequest?.isAssignedToLoggedAdmin ? " assigned-to-admin" : "")
          }
        >
          <span className={"contextual-status"}>{contextualStatus}</span>
        </div>
            }</>
      }</>
    }
  </RequestCard>
}
