import { Icon, Loading, Popup, Tooltip } from '@startlibs/components';
import { isMobile, callIfFunction, getColor, media } from '@startlibs/utils';
import React, {useMemo, useRef, useState} from 'react'
import _ from 'lodash/fp'
import styled, {css} from 'styled-components'
import {StudyRow} from './StudyRow'
import {lighten} from 'polished'
import {FormattedMessage} from "react-intl";

const WorklistTable = styled.div`
  overflow: auto;
  height: calc(100vh - 18.5rem);
  width: 100%;
  color: ${getColor('gray60')};
  background: white;
  border: 1px solid rgba(0,0,0,0.15);
  border-spacing: 0;
  position: relative;
  ${props => props.isLoading && `
    pointer-events: none;
    ${WorklistTableRow} {
      border-color: transparent;
      > * {
        opacity: 0.1;
        transition: 0.5s ease;
      }
    }
  `}
  ${media.desktop `
    border-radius: 5px;
  `}
  ${props => props.isMobile && css `
      overflow-x: auto;
  `}
`

export const WorklistTableRow = styled.div`
  min-height: 4rem;
  position: relative;
  border-bottom: 1px solid rgb(225,225,225);
  display: flex;
  ${props => props.isSelected && css `
    background: ${props => lighten(0.475, props.theme.colors.success)} !important;
    .tools {
      background: ${props => lighten(0.475, props.theme.colors.success)} !important;
    }
  `}
  ${props => props.isMobile && css `
    min-width: 100%;
  `}
`
export const WorklistTableHead = styled(WorklistTableRow)`
  max-width: 100%;
  align-items: center;
  font-weight: 600;
  background: white;
  top: 0;
  position: sticky;
  z-index: 15;
  border-top-left-radius:5px;
  border-top-right-radius:5px;
  border-color: rgba(0,0,0,0.15) !important;
  > * {
    opacity: 1 !important;
  }
  .tools {
    border-top-right-radius: 5px;
    ${props => props.isMobile && `
      height: 4rem;
    `}
  }
`
export const WorklistTableColumn = styled.div`
  flex-basis: 0;
  flex-grow: 2;
  padding: 0.75rem 0.75rem;
  :first-child {
    padding-left: 1rem;
  }
  :last-child {
    padding-right: 1rem;
  }
  &.patient {
    flex-grow: 2;
  }
  &.study {
    flex-grow: 2;
  }
  &.physician {
    flex-grow: 1;
  } 
  &.institution {
    flex-grow: 1;
  }
  &.uploadDate {
    flex-grow: 1;
    width: 12px;
    flex-basis: inherit;
  }
  &.action{
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 130px;
  }
  &.tools {
    background: white;
    flex-grow: 0;
    width: 72px;
    margin-left: 0px;
    min-width: 46px;
    flex-basis: inherit;
    text-align: right;
    position: sticky;
    right: 0rem;
    z-index: 10;
    :before {
      transition: all 0.5s ease, opacity 0ms;
      content: '';
      position: absolute;
      width: 0;
      left: 0;
      bottom: 0;
      top: 0;
      pointer-events: none;
      background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.05) 100%);
      border-right: 1px solid rgba(0,0,0,0.1);
      opacity: 0;
    }
  }
`

const LoadingTableContent = styled.div `
  position: absolute;
  top: 4rem;
  left: 0;
  right: 0;
  height: 100%;
  height: calc(100% - 4rem);
  max-height: 80vh;
  max-height: calc(80vh - 4rem);
  ${props => !props.isMobile && css`
    ${media.desktop `
      max-width: calc(100vw - 5rem - 15px);
      left: 2.5rem;
    `}
    ${media.mobile `
      max-width: calc(100vw - 15px);
      left: 0;
    `}
  `}
`
const EmptyStudyList = styled.div `
  background: rgb(240,240,240);
  min-height: 8rem;
  height: calc(100% - 49px);
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  max-width: calc(100vw);
  position: sticky;
  left: 0;
`

export const Table = ({userRights, setParams, refreshList, rows, isLoading, blankingWorklist, linkedStudies, setLinkedStudies, records, linkRecord, providerJwt, jwt, setNotification, setLoadingAction, isLoadingAction, viewerLoadUrl, worklistViewerJwt, storageHost}) => {

  const [studies, setStudies] = useState(rows.map((row, i) => _.update('tools.attachNonDicom.content',Number,{...row, id: i})))
  const tableRef = useRef()

  return <WorklistTable ref={tableRef} isLoading={isLoading} isMobile={isMobile()}>
    <WorklistTableHead isMobile={isMobile}>
      <WorklistTableColumn className="patient"><FormattedMessage
        defaultMessage="Patient"
        description="Uploader, link viva studies, patient column"
      /></WorklistTableColumn>
      <WorklistTableColumn className="study"><FormattedMessage
        defaultMessage="Study"
        description="Uploader, link viva studies, study column"
      /></WorklistTableColumn>
      <WorklistTableColumn className="physician"><FormattedMessage
        defaultMessage="Physician"
        description="Uploader, link viva studies, physician column"
      /></WorklistTableColumn>
      <WorklistTableColumn className="institution"><FormattedMessage
        defaultMessage="Institution"
        description="Uploader, link viva studies, institution column"
      /></WorklistTableColumn>
      <WorklistTableColumn className="uploadDate"><FormattedMessage
        defaultMessage="Upload date"
        description="Uploader, link viva studies, upload date column"
      /></WorklistTableColumn>
      <WorklistTableColumn className="tools"> </WorklistTableColumn>
      <WorklistTableColumn className="action"> </WorklistTableColumn>
    </WorklistTableHead>
    {
      studies.length
        ? studies.map(study => 
            <StudyRow 
              setParams={setParams} 
              linkedStudies={linkedStudies} 
              setLinkedStudies={setLinkedStudies} 
              refreshList={refreshList}  
              isLoading={isLoading} 
              key={study.pk} 
              // setLoading={setLoading}
              setLoadingAction={setLoadingAction}
              isLoadingAction={isLoadingAction}
              userRights={userRights}
              study={study} 
              isMobile={isMobile()} 
              records={records}
              linkRecord={linkRecord}
              providerJwt={providerJwt} 
              jwt={jwt} 
              setNotification={setNotification} 
              worklistViewerJwt={worklistViewerJwt} 
              viewerLoadUrl={viewerLoadUrl} 
              storageHost={storageHost}
              setStudy={(update) => setStudies(_.map(s => s.study.pk === study.pk ? callIfFunction(update, s) : s))}
            />
          )
        : blankingWorklist
          ? <EmptyStudyList css="min-height: 15rem;">{!isLoading && <div><h4><FormattedMessage
            defaultMessage="Search to view records"
            description="Uploader, link viva studies, empty search study list"
          /></h4><div><FormattedMessage
            defaultMessage="Insert a search term above to display matching records"
            description="Uploader, link viva studies, empty search study list info"
          /></div></div>}</EmptyStudyList>
          : <EmptyStudyList>{!isLoading && <FormattedMessage
            defaultMessage="No records found"
            description="Uploader, link viva studies, empty study list"
            />}</EmptyStudyList>
    }
    {isLoading && <LoadingTableContent isMobile={isMobile()}><Loading absolute size={40} borderWidth={6}/></LoadingTableContent>}
    {/* <p>Total Selected: {linkedStudies.length}</p> */}
  </WorklistTable>
  
}
