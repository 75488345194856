import React, {useContext, useEffect, useState} from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components';
import {FormattedMessage, useIntl} from 'react-intl'
import {Button, ContextMenu, Dialog, Li, Loading, Tooltip} from '@startlibs/components';
import {callIfFunction, getColor} from '@startlibs/utils'
import {usePopupToggle, useToggle} from '@startlibs/core'

import {ViewAllButton} from './ViewAllButton'
import {useActivityLog} from './hooks/useActivityLog';
import {DicomStudy, NonCompliantDicom} from '../enums/RecordFormat';
import {Other, Pathology} from '../enums/RecordClass';
import {isMultiFile} from './FileinputBox';
import {jwtGetFetcher, jwtPostFetcher} from '../utils/authFetch';
import {UploaderConfigContext} from "../service/UploaderConfigContext";
import {UIAction} from "../service/UIAction";
import {DEVICE, DISK} from "../enums/UploaderStepsManagement";
import {useUIDataSelector} from "../service/hooks/useUIDataSelector";
import {useDoAction} from "../service/hooks/useDoAction";
import {useRecordsSelector} from "../service/hooks/useRecords";
import {useIsUploading, useUploadQueueSelector} from "../service/hooks/useUploadQueueSelector";
import {UploaderAction} from "../service/UploaderAction";
import {getRowsForRecords, getRowsForRecordsStatic} from "../service/utils/recordUtils";
import { GroupAction } from '../service/GroupAction';
import { ADMIN, PROVIDER } from '../enums/UserRoles';
import { getFilenameFromRecord } from './recordGroup/RecordRowActions';
import { isOtherKnowExtension } from '../dicom/FileParser';
import { DicomRoute } from './DicomRoute';
import { desaturate, lighten } from 'polished';

export const UploaderHeaderComponent = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.25rem;
  padding: 1.25rem 0;
  align-items: center;
  border-bottom: 1px solid ${getColor('gray210')};
  margin-top: -2rem;
  position: sticky;
  min-height: 55px;
  ${Button} {
    margin-left: 0.75rem;
  }
  .left-container, .right-container {
    display: flex;
    align-items: center;
  }
`
const HeaderTitle = styled.span`
  font-size: 15px;
  font-weight: bold;
  margin-right: 0.75rem;
`
const SelectedRecordsCounter = styled.span`
  padding: 0.25rem 0.75rem;
  background-color: rgba(0,0,0,0.075);
  border-radius: 5px;
  font-weight: 600;
  min-height: 24px;
`
const UploadingRecords = styled.span`
  padding: 0.25rem 0.75rem 0.25rem 0.5rem;
  background-color: ${props => desaturate(0.65, lighten(0.545, getColor("main")(props)))};
  border-radius: 5px;
  font-weight: 600;
  display: flex;
  align-items: center;
  min-height: 24px;
  .link {
    margin-left: 0.5rem;
    font-size: 11px;
  }
  ${Loading} {
    margin-right: 0.5rem;
  }
`

export const UploaderHeader = () => {
  const intl = useIntl()
  const isUploading = useIsUploading()
  const uploadQueue = useUploadQueueSelector()
  const unclassifiedRecords = useUIDataSelector(_.flow(_.get('groups'),_.find(_.matchesProperty('id','notclass')),_.get('items'))) || []
  const records = useRecordsSelector(getRowsForRecordsStatic).filter(({key}) => !unclassifiedRecords.includes(key))
  const quarantinedRecordsUIDs = records.filter(({quarantined,partiallyQuarantined}) => quarantined || partiallyQuarantined).map(({recordUID}) => recordUID+"")
  const data = useUIDataSelector()
  const doAction = useDoAction()
  const contextMenuMoveGroup = usePopupToggle()
  const groups = useUIDataSelector('groups')
  const {role, config} = useContext(UploaderConfigContext)

  const normalRecordsOnQueue = uploadQueue.map(item => {
    return !(item.recordClass === Other && item.recordFormat === Other && !(isOtherKnowExtension(item) || config?.role === PROVIDER || item.doNotHide))
  }).filter(Boolean).length
  
  // console.log({data})
  const completedRecords = data?.completedRecords?.length
  // const totalRecords = data?.totalRecords
  // const toBeUploaded = data?.toBeUploaded
  // setData(_.update('totalRecords', (i = 0) => i + recordsToUpload.length))
  // setData(_.update('toBeUploaded', (i = 0) => i + recordsToUpload.length))
  // const {unprocessedFiles, isProcessing, processedFiles, dialogs: {cancelProcessing}} = useUIDataSelector()

  const {
    mode,

    worklistViewerJwt: expertViewJwt,
    appJwt: jwt,
    requestId,

    withViewAllButton,
    withoutDelete,
    allowDownload,
    allowDownloadMedicalImages,
    
    listMode,
    disabled,

    linkedStudies,
    apiEndpoints,

    setNotification,
    setLinkedStudies,
    canRouteDicoms,
    canCreatePacs
  } = useContext(UploaderConfigContext)

  const disableDelete = withoutDelete || listMode || disabled
  const {dicomViewer: viewerLoadUrl,downloadFiles,shortTokenUrl: maybeShortTokenUrl} = apiEndpoints

  const shortTokenUrl = maybeShortTokenUrl || (() => requestId
    ? jwtGetFetcher(callIfFunction(expertViewJwt))(`/api/shortDownloaderToken`,{requestId})
    : jwtGetFetcher(callIfFunction(expertViewJwt))(`/api/shortDownloaderToken`))

  const openActivityLog = () => doAction(UIAction.ToggleActivityLogDialog)
  const setSelectMode = (value) => doAction(UIAction.SetSelectMode,value)
  const setSelectedRecords = (value) => doAction(UIAction.SetSelectedRecords,value)

  const isSelectMode = data.selectMode
  const selectedRecords = data.selectedRecords

  const [activityLog] =  useActivityLog()
  const deleteDialog = useToggle()
  const loading = useToggle()
  const downloadLoading = useToggle()
  let nonCompliantFiles = 0
  
  // NonCompliant Dicom Files are grouped - so it must count as only one record
  _.map((item) =>{
    let record = records.find((record) => record.recordUID === item)
    if(record?.format === "NonCompliantDicom"){
      nonCompliantFiles = nonCompliantFiles+1
    }
  },selectedRecords)

  const selectedLength = nonCompliantFiles === 0 
    ? selectedRecords.length
    : selectedRecords.length - nonCompliantFiles + 1

  const keysToRecordUID = (keys) => keys.map((key) => records.find((record) => record.key === key)?.recordUID).filter(v => v)
  const keysToRecordUIDLodash = _.flow(
    _.map(
      (key) => records.find((record) => record.key === key)?.recordUID 
    ),
    _.filter(v => v)
  )

  const handleDownload = () => {
    if (selectedLength === 0) {
      setNotification({
        type: "alert",
        timeout: 4000,
        msg: (close) => <span>Select at least one record and try again</span>
      });
    } else {
      const filteredSelectedRecords = records.filter(({ key }) => selectedRecords.includes(key));
      try{
        if (role !== PROVIDER) {
          const descriptions = filteredSelectedRecords.map((record) => {
            try {
              return getFilenameFromRecord(intl,record);
            } catch (e) {
              console.log(e);
              return null;
            }
          });

          const noCache = new Date().getTime();
          jwtPostFetcher(expertViewJwt)(`/api/audit/study/downloadRequested?requestId=${requestId}&nocache=${noCache}`, {
          // jwtPostFetcher(expertViewJwt)(`/api/audit/study/downloadRequested?requestId=${requestId}`, {
            recordDescriptions: descriptions.filter(v => v)
          });
        }
      }catch(e){
        console.log(e)
      }
      downloadLoading.wait(shortTokenUrl(requestId))
        .then((response) => {
          // const filteredSelectedRecords = records.filter(({ key }) => selectedRecords.includes(key));
          callIfFunction(downloadFiles(response.jwt, doAction(UploaderAction.getRecordsUID, filteredSelectedRecords)));
        })
        .catch((error) => {
          // Handle error if necessary
          console.log(error);
        });
    }
  };
  
  const removeSelectedFiles = async () => {
    try {
      await doAction(UploaderAction.DeleteMany,records.filter(({key}) => selectedRecords.includes(key)))
    } catch (e) {

    } finally {
      setSelectMode(false)
      setSelectedRecords([])
      loading.close()
      return deleteDialog.close()
    }
      
  }
  if (mode === DEVICE || mode === DISK) {
    return null
  }
  return records.length >= 1 ? <>
    <UploaderHeaderComponent>
        {isSelectMode 
          ? 
            <>
              <div className="left-container">
                <HeaderTitle>
                  <FormattedMessage
                    defaultMessage="Select records"
                    description="Uploader, select records mode label"
                  />
                </HeaderTitle>
                {selectedLength > 0 && <SelectedRecordsCounter>
                  <FormattedMessage
                    defaultMessage="{selectedLength} {selectedLength, plural, one {record} other {records}} selected"
                    description="Uploader, select records mode, selected records counter"
                    values={{selectedLength}}
                  /></SelectedRecordsCounter>}
              </div>
              <div className="right-container">
                <a className="light-link gray" 
                  onClick={() => {
                    const allRecords = []
                    if (allowDownloadMedicalImages) {
                      records.map(item => {
                        allRecords.push(item.key)
                      })
                    } else {
                      let hasFilesNotSelectable = false
                      records.map(item => {
                        if (
                          (item.format === DicomStudy) ||
                          ((item.recordClass === Pathology) && (isMultiFile(item.format)))
                        ) {
                          hasFilesNotSelectable = true
                        } else {
                          allRecords.push(item.key)
                        }
                      })
                      if(hasFilesNotSelectable){
                        setNotification({type:"alert", timeout: 4000,msg:(close) => <span>Some medical records were not able to be selected.</span>})
                      }
                    }
                    setSelectedRecords(allRecords)
                  }
                }><FormattedMessage
                  defaultMessage="Select all" description="Uploader, select records mode, select all"
                /></a>
              <a className="light-link gray" css="margin-left:0.75rem;" onClick={() => setSelectedRecords([])}>
                <FormattedMessage
                  defaultMessage="Clear" description="Uploader, select records mode, clear selection"
                />
              </a>
              {/* {!withoutDelete &&  */}
              {!disableDelete &&
                <Button small hover="alert" icon="delete" 
                  onClick={() => {selectedLength === 0 
                    ? setNotification({type:"alert", timeout: 4000,msg:(close) => <span><FormattedMessage
                        defaultMessage="Select at least one record and try again"
                        description="Uploader, select records mode, select at least one record to delete error message"
                      /></span>})
                    : deleteDialog.open()}}><FormattedMessage
                  defaultMessage="Delete"
                  description="Uploader, select records mode, delete button"
                /></Button> }
              {allowDownload && (
                <Button
                  small
                  isLoading={downloadLoading.isOpen}
                  icon="download"
                  // Commenting this out, to keep the same functionality as before
                  // Users are able to download files even if they are quarantined, in order to check.
                  // disabled={selectedRecords.find(id => quarantinedRecordsUIDs.includes(id))}
                  onClick={handleDownload}
                ><FormattedMessage
                  defaultMessage="Download" description="Uploader, select records mode, download button"
                  />
                </Button>
              )}
              {!listMode && !disabled && role === ADMIN && 
                <Button disabled={selectedLength <= 0} small style={{minHeight: '24.2px'}} withDropdown onClick={() => contextMenuMoveGroup.open()}>
                  <FormattedMessage
                    defaultMessage="Move to"
                    description="Uploader, select records mode, move to group button"
                  />
                  {
                    contextMenuMoveGroup.isOpen &&
                    <ContextMenu className="noIcons">
                      {groups.map(group =>
                        <Li
                          key={group.id}
                          label={group.name ? group.name : <FormattedMessage
                            defaultMessage="Remove from group"
                            description="Uploader, select records mode, remove from group"
                          />}
                          onClick={() => {
                            let recordsToMove = records.filter(({key}) => selectedRecords.includes(key))
                            recordsToMove.forEach(record => {
                                groups.forEach(sourceGroup => {
                                  if(sourceGroup.items.includes(record.key)){
                                    // DO NOT ALLOW TO MOVE NONCOMPLIANT DICOMS TO HIDDEN FILES
                                    if((record.key === NonCompliantDicom || record?.quarantined == true) && (group.id === "notclass")){
                                      return
                                    }
                                    doAction(GroupAction.SimpleMoveRecord, record, sourceGroup.id, group.id)
                                  }
                                })
                                setSelectMode(false)
                                setSelectedRecords([])
                            })
                          }}
                        />
                      )}
                    </ContextMenu>
                }</Button>
              }
              <Button small outline style={{minHeight: '24.2px'}} onClick={() => {setSelectedRecords([]); setSelectMode(false)}}><FormattedMessage
                defaultMessage="Cancel"
                description="Uploader, select records mode, cancel button"
              /></Button>
              </div>
            </>
          :
            <>
              <div className="left-container">
                <HeaderTitle>
                  <FormattedMessage
                    defaultMessage="{quantity} {quantity, plural, one {record} other {records}}"
                    description="Uploader, header title"
                    values={{quantity: records.length}}
                  />
                </HeaderTitle>

                {isUploading ? 
                  <UploadingRecords>
                    <Loading size={16} borderWidth={3}/>Uploading <a className="link activity-log-link" onClick={openActivityLog}>
                    <FormattedMessage
                      defaultMessage="Upload log"
                      description="Uploader, header, upload log link"
                    /></a>
                  </UploadingRecords>
                :
                  activityLog.length > 0 && <a className="link activity-log-link" onClick={openActivityLog}>
                    <FormattedMessage
                      defaultMessage="Upload log"
                      description="Uploader, header, upload log link"
                  /></a>
                }
              </div>
              <div className="right-container">
                {(allowDownload || (!withoutDelete === undefined)) && <a className="link" onClick={() => setSelectMode(true)}><FormattedMessage
                    defaultMessage="Select records"
                    description="Uploader, select records mode button"
                /></a>}
                {withViewAllButton && 
                  <ViewAllButton 
                    viewerLoadUrl={viewerLoadUrl}
                    jwt={jwt}
                  />
                }
                {canRouteDicoms && 
                  <Tooltip content={intl.formatMessage({
                    defaultMessage: "Export DICOM records",
                    description: "Uploader, export DICOM records tooltip"
                    })}>
                    <DicomRoute
                      viewerLoadUrl={viewerLoadUrl}
                      jwt={jwt}
                    />
                  </Tooltip>
                }

              </div>
            </>
        }
    </UploaderHeaderComponent>
    
    {deleteDialog.isOpen &&
      <Dialog
        closeDialog={deleteDialog.close}
        title={<><FormattedMessage
          defaultMessage="Delete medical {selectedLength, plural, one {record} other {records}}"
          description="Uploader, delete records dialog title"
          values={{selectedLength}}
          /></>}
        footer={<>
          <Button onClick={deleteDialog.close}><FormattedMessage
            defaultMessage="Cancel"
            description="Cancel button"
          /></Button>
          <Button alert isLoading={loading.isOpen} onClick={loading.willWait(removeSelectedFiles)}><FormattedMessage
            defaultMessage="Delete"
            description="Delete button"
          /></Button>
        </>}
      >
        <p css="font-size:15px!important"><b>
          <FormattedMessage
            defaultMessage="This will permanently delete {selectedLength} {selectedLength, plural, one {record} other {records}}"
            description="Uploader, delete records dialog message"
            values={{selectedLength}}
          /></b>.</p>
        <p><FormattedMessage
          defaultMessage="Would you like to proceed?"
          description="Uploader, delete records dialog confirmation"
        /></p>
      </Dialog>
      }
    </>
    
    : <></>
}