import React, { useEffect } from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components/macro'
import {Button, Dialog, Loading} from "@startlibs/components";


import {useDoAction} from "../../service/hooks/useDoAction";

import {UIAction} from "../../service/UIAction";
import { UploaderAction } from '../../service/UploaderAction';
import { useToggle } from '@startlibs/core';
import {FormattedMessage, useIntl} from "react-intl";

styled

export const CancelDicomDialog = ({data}) => {
  const intl = useIntl()
  const doAction = useDoAction()
  const closeDialog = () => doAction(UIAction.SetCancelDialog, false)

  const cancelLoading = useToggle()
  const deleteLoading = useToggle()
  
  const record = data.dialogs.cancelDialog
  
  const deleteRecord = async () => {
    // await deleteLoading.wait(doAction(UploaderAction.Delete,record))
    await deleteLoading.wait(doAction(UploaderAction.DeleteDicom,record))
    // cancelDialog.close()
    closeDialog()
  }
  const cancelUpload = async () => {
    await cancelLoading.wait(doAction(UploaderAction.CancelRecord,record))
    closeDialog()
  }
  
  
  return <Dialog
  closeDialog={closeDialog}
  title={intl.formatMessage({
    defaultMessage: "Cancel DICOM study upload",
    description: "Uploader, cancel DICOM study dialog, title"
  })}
  footer={<>
    <Button alert 
      isLoading={deleteLoading.isOpen} 
      onClick={deleteRecord}
    ><FormattedMessage
      defaultMessage="Delete entire medical record"
      description="Uploader, cancel DICOM study dialog, delete entire record button"
    /></Button>
    <Button alert 
      isLoading={cancelLoading.isOpen} 
      onClick={cancelUpload}
    ><FormattedMessage
      defaultMessage="Stop remaining uploads only"
      description="Uploader, cancel DICOM study dialog, stop remaining uploads button"
    /></Button>
  </>}
>
<p><FormattedMessage
  defaultMessage="Would you like to cancel only the remaining file uploads in queue or also delete all files already uploaded
  to this medical record?"
  description="Uploader, cancel DICOM study dialog, confirmation message"
/></p>
  </Dialog>

}
