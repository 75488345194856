import React from 'react'
import _ from 'lodash/fp'
import {useToggle} from '@startlibs/core'
import {Button} from '@startlibs/components'
import {jwtPostFetcher} from '../../utils/authFetch'
import {getJwt} from '../../hooks/useJwt'
import {InfoBox} from '../../components/InfoBox'
import {useUploadCustomReport} from './useUploadCustomReport'
import {useConfirmAnswers} from './useConfirmAnswers'
import {ConfirmDialog, useConfirmDialog} from '../../hooks/useConfirmDialog'
import {using} from '@startlibs/utils'
import {useNavigate} from 'react-router'
import {hasAFieldsFilled} from '../../request/utils'
import {FormattedMessage, useIntl} from "react-intl";

export const CustomReportInfoBox = ({caseRequest,setCaseRequest}) => {
  const navigate = useNavigate()
  const intl = useIntl()

  const replacePDF = useUploadCustomReport(caseRequest,setCaseRequest,{isReplacing:true})
  const confirmNonFilledDialog = useConfirmDialog(() => <ConfirmDialog
        css="max-width:48rem;"
        title={intl.formatMessage({
          defaultMessage: 'Enable fields to proceed',
          description: 'Custom report not filled dialog title',
        })}
        onSuccess={() => caseRequest.requestCode ? navigate(`/admin/request/${caseRequest.requestCode}/review`) : navigate(`/expert/case/${caseRequest.requestId}/questions`) }
        confirm={<Button highlight><FormattedMessage
          defaultMessage="Edit expert review"
          description="Custom report not filled dialog, go to expert review button"
        /></Button>}
      >
        <p css="margin-top:2rem">
          <FormattedMessage
            defaultMessage='This report has no available "Questions & answers" and "Additional information".'
            description="Custom report not filled dialog message"
          />
        </p>
        <p><FormattedMessage
          defaultMessage="Please review and enable at least one before proceeding."
          description="Custom report not filled dialog message confirmation"
        /></p>
      </ConfirmDialog>
    )

  const confirmAnswers = () => {
    if (hasAFieldsFilled(caseRequest.caseInfo)) {
      return Promise.resolve()
    } else {
      confirmNonFilledDialog()
      return new Promise(() => {})
    }
  }

  const loading = useToggle()


  const updateUseCustomReport = (v) => (v ? Promise.resolve() : confirmAnswers()).then(() =>
    loading.wait(() =>
      jwtPostFetcher(getJwt())(`/api/useCustomReport?requestId=${caseRequest.requestId}`,{useCustomReport:v})
      .then(() => setCaseRequest(_.set('report.useCustomReport',v)))
    )
  )

  const updateToDefaultReport = () => updateUseCustomReport(false)

  const updateToCustomReport = () => updateUseCustomReport(true)


  return <InfoBox flex lightBlue>
    <div>
      {
      caseRequest.report?.useCustomReport
        ? <FormattedMessage
          defaultMessage="A custom PDF was uploaded and is serving as the case report."
          description="Custom report used info box message"
        />
        : <FormattedMessage
          defaultMessage="Using the default report, but a custom PDF is also available."
          description="Custom report available info box message"
        />
      }
    </div>
    <div>
    {
      caseRequest.report?.useCustomReport
        ? <>
        <Button small alpha isLoading={loading.isOpen} onClick={updateToDefaultReport}><FormattedMessage
          defaultMessage="Use default report"
          description="Custom report use default report button"
        /></Button>
        <Button small alpha icon="upload" onClick={replacePDF}><FormattedMessage
          defaultMessage="Replace PDF"
          description="Custom report replace PDF button"
        />
        </Button>
      </>
    : <Button small alpha isLoading={loading.isOpen} onClick={updateToCustomReport}><FormattedMessage
          defaultMessage="Use the uploaded PDF instead"
          description="Custom report use uploaded PDF button"
        /></Button>
    }
    </div>
  </InfoBox>
}
