import React from 'react'

export const MonthDayYear = 'MonthDayYear'
export const YearMonthDay = 'YearMonthDay'
export const DayMonthYear = 'DayMonthYear'



export const DATE_FORMAT_LABELS = (intl) => ({
  [MonthDayYear]:intl.formatMessage({
    defaultMessage: 'Month Day, Year',
    description: 'Date format label selection: Month Day Year ',
  }),
  [YearMonthDay]:intl.formatMessage({
    defaultMessage: 'Year, Month Day',
    description: 'Date format label selection: Year Month Day ',
  }),
  [DayMonthYear]:intl.formatMessage({
    defaultMessage: 'Day Month Year',
    description: 'Date format label selection: Day Month Year ',
  }),
})
export const DATE_FORMAT_STR = {
  [MonthDayYear]:'LL dd, yyyy',
  [YearMonthDay]:'yyyy, LL dd',
  [DayMonthYear]:'dd LL yyyy',
}

export const DATE_FORMAT_OPTIONS = (intl) => Object.keys(DATE_FORMAT_LABELS(intl))
