import { lighten } from 'polished';
import React, { forwardRef, useState, useCallback } from 'react';
import _ from 'lodash/fp';
import styled from 'styled-components';

import { ContextMenu, Li, ListSeparator, Button, Dialog, Loading, Icon, Tooltip} from '@startlibs/components';
import { formatDate, getColor } from '@startlibs/utils';
import {useToggle, usePopupToggle, willUseSharedCallback, useRefState} from '@startlibs/core'

import { ADMIN, PROVIDER } from '../enums/UserRoles';
import {
  AttachmentActions,
  AttachmentBox,
  AttachmentBoxFlexContainer,
  AttachmentCheckbox,
  AttachmentDescription,
  AttachmentDetails,
  AttachmentIcon,
  AttachmentInfoContainer,
  DraggableCornerIcon,
  DropdownButton,
  DropdownIcon,
  ProgressBar,
  ProgressDetails,
  RejectLabel,
  TextButton,
  UploadedDate,
  UploadingRecords,
  UploadingStatus
} from './AttachmentBoxStyles';
import {DicomStudy} from '../enums/RecordFormat'
import {MetadataField} from './recordGroup/MetadataField'
import {NotesField} from './recordGroup/NotesField'
import { Pathology, Radiology } from '../enums/RecordClass';
import { RecordStateFooter } from './RecordStateFooter';
import { Rejected, Submitted } from '../enums/RecordState';
import {dicomDateToDate, formatDateNoUTC} from '../utils'
import {
  FileNotFound,
  getFileStateLabel,
  IS_RETRY_POSSIBLE,
  Quarantined
} from '../enums/FileState';
import { isMultiFile } from './FileinputBox';
import { useNewDownload, useViewStudy } from '../service/utils/downloadFile';
import {useRegisterOpenDetails} from './hooks/useActivityLog'
import {useUploader} from '../hooks/useUploader'
import {useAddRetryFilesNotFound} from './hooks/useFilesNotFound'
import { DEVICE, DISK } from '../enums/UploaderStepsManagement';
import {useIntl} from "react-intl";

const [useRegisterCancel,callCancels] = willUseSharedCallback()

export const DicomBox =
  ({
     study,
     allowDownload,
     allowDownloadMedicalImages,
     disabled,
     isDragDisabled,
     hasUnprocessedFiles,
     withoutDelete,
     jwt,
     appJwt,
     onFinishUpload,
     onCancel,
     onFailure,
     retry,
     removeStudyFromQueue,
     removeStudy,
     setSeriesList,
     noCancel,
     isApp,
     role,
     currentGroup,
     moveTo,
     viewerLoadUrl,
     setAttachment,
     downloadLoadUrl,
     downloadUrl,
     dragProvided,
     attachmentGroups,
     addGroupAndMove,
     setAttachmentGroups,
     setNotification,
     isSelectMode, 
     selectedRecords, 
     setSelectedRecords,
     mode,
     expertViewJwt,
     requestId
  }) => {

  const studyDetailsDialog = useToggle()
  useRegisterOpenDetails((key) => key === study.studyUID && studyDetailsDialog.open())

  const cancelDialog = useToggle()
  const deleteDialog = useToggle()
  const loading = useToggle()
  const loadingState = useToggle()
  const partialProgressMap = useRefState(() => new Map())
  const [partialProgress,setRawPartialProgress] = useState(0)

  const contextMenu = usePopupToggle()

  const uploadingInstances = study.instances.filter(instance => instance.fileToUpload)

  const queuedInstances = study.instances.filter(instance => instance.sourceFile)
  const doneInstances = study.instances.filter(instance => !instance.sourceFile || !IS_RETRY_POSSIBLE(instance))
  const failedInstances = study.instances.filter(instance => instance.failed || instance.quarantined)

  const uploaded = doneInstances.length
  const total = study.instances.length

  const progress = (uploaded + partialProgress)/total
  const [keepProgress,setKeepProgress] = useState(uploaded && hasUnprocessedFiles)

  const emptyList = []
  const StudyUIDsList = emptyList.concat(study.recordUID)
  
  const setPartialProgress = useCallback((instance,progress) => {
    const map = partialProgressMap.get()
    if (instance) {
      map.set(instance,progress)
    }
    setRawPartialProgress(_.sum(uploadingInstances.map(instance => map.get(instance) || 0)))
  },[uploadingInstances])

    React.useEffect(() => {
      setPartialProgress()
    },[study.instances])


  React.useEffect(() => {
    if (hasUnprocessedFiles && !keepProgress) {
      setKeepProgress(true)
    }
    if (!hasUnprocessedFiles && keepProgress) {
      setKeepProgress(false)
    }
  },[uploaded])

  const isFinished = uploaded === total && !keepProgress

  const studyDate = dicomDateToDate(study.studyDate)

  const cancelQueue = () => {
    removeStudyFromQueue(study)
    callCancels()
  }

  function handleClick(id){
    // If contains the id. Must remove it
    if(selectedRecords.findIndex(listItem => listItem === id) >= 0){
      // Found
      const filtered = selectedRecords.filter(listItem => listItem !== id)
      setSelectedRecords(filtered)
    }else{
      // Do not contains. 
      // Check if it is allowed to select it for download
      if((!allowDownloadMedicalImages) && ((study.format === DicomStudy) ||
            ((study.recordClass === Pathology) && (isMultiFile(study.format))))){
        // Not allowed 
      } else {
        // Allowed - add to list
        const added = selectedRecords.concat(id)
        setSelectedRecords(added)
      }
    }
  }

  const cancelAndRemove = () => {
    cancelQueue()
    return removeStudy(study)
  }

  const retryAll = () => {
    failedInstances.filter(IS_RETRY_POSSIBLE).forEach((instance) => retry(instance)())
  }
  useAddRetryFilesNotFound(failedInstances.find(_.matchesProperty(['failed',FileNotFound])), retryAll)

  const isMinified = () => (mode === DEVICE || mode === DISK);

  const [openViewerLink,viewerIsLoading] = useViewStudy(appJwt,[study],viewerLoadUrl)
  const [tryDownload,downloadIsLoading,downloadIsStating,downloadFailed] = useNewDownload(expertViewJwt,StudyUIDsList,downloadUrl,study.studyDescription, requestId)

  const hasOnlyFailedFiles = queuedInstances.length === failedInstances.length && failedInstances.length > 0

  const setNotes = (value) => setAttachment(_.set('notes.note', value))
  const setMetadata = (value) => {return setAttachment(_.set('metadata', value)) }
  const setState = (value) => setAttachment(_.set('state', value))

  if (currentGroup === "notclass" && !hasOnlyFailedFiles && role !== PROVIDER){
    return <div ref={dragProvided.innerRef}
      {...dragProvided.draggableProps}
      {...dragProvided.dragHandleProps}>
    </div>
  }

    return  (
      (mode === DEVICE && study.mode === DEVICE) || 
      (mode === DISK && study.mode === DISK)  ||
      !isMinified()
     )  && <AttachmentBox
    ref={dragProvided.innerRef}
    {...dragProvided.draggableProps}
    {...dragProvided.dragHandleProps}
    failedUpload={hasOnlyFailedFiles} isApp={isApp} mode={mode}
    // hasFooter={false && study.state && isFinished && !hasOnlyFailedFiles && (role === ADMIN)}
    isSelectMode={isSelectMode}
    disabledColor={!allowDownloadMedicalImages && isSelectMode}
    onClick={isSelectMode ? (() => {allowDownloadMedicalImages ? handleClick(study.recordUID) : null}) : undefined}
    isSelected={selectedRecords.findIndex(listItem => listItem === study.recordUID) >= 0}
    // unavailable={study.state === Rejected && isFinished && !hasOnlyFailedFiles && (role === ADMIN)}
    >
      {!isDragDisabled && <DraggableCornerIcon icon="draggable-corner"/>}
      <AttachmentIcon icon="dicom" css="font-size:44px;" mode={mode}/>
    {
      !isFinished && (hasOnlyFailedFiles ?
      <UploadingStatus>
      {uploaded > 0 && <ProgressDetails>{uploaded}/{total} files successfully uploaded</ProgressDetails>}
      <ProgressDetails failed>
        <a className="link error" onClick={studyDetailsDialog.open}>{failedInstances.length} file upload{failedInstances.length > 1 && 's'} failed</a>
        {!!failedInstances.find(IS_RETRY_POSSIBLE) && <a className="link" onClick={retryAll}>Retry{failedInstances.length > 1 && ' all'}</a>}
      </ProgressDetails>
      </UploadingStatus>
      : <UploadingStatus>
        <ProgressDetails css="display: flex; align-items: center;">
          {uploadingInstances.length
            ? isMinified() 
              ? <UploadingRecords><Loading size={16} borderWidth={3}/>Uploading</UploadingRecords> 
              : <span>Uploading files...</span>
            : <span>Waiting...</span>
          }
          {!noCancel && <a className="link" onClick={cancelDialog.open}>Cancel</a> }
        </ProgressDetails>
        { !isMinified() && <ProgressBar progress={progress * 100} completed={isFinished}/>}
        {failedInstances.length > 0 ?
          (!isMinified() && <div className="files-count"><a className="link error" onClick={studyDetailsDialog.open}>{failedInstances.length} file upload{failedInstances.length > 1 && 's'} failed</a> <a className="link" onClick={retryAll}>Retry{failedInstances.length > 1 && ' all'}</a></div>)
          :
          (!isMinified() && <div className="files-count"><a className="link" onClick={studyDetailsDialog.open}>{uploaded}/{total} files uploaded</a></div>)
        }
      </UploadingStatus>
    )}
    <AttachmentInfoContainer>
      <AttachmentDescription className="fs-exclude">
        <b>{study.studyDescription ? study.studyDescription : "DICOM Study"} {!isNaN(studyDate) && `(${formatDate(studyDate,'MM-dd-yyyy')})`}</b>  { !isMinified() && <a className="light-link" onClick={studyDetailsDialog.open}>Details</a>}
      </AttachmentDescription>
      {!isMinified() && <AttachmentDetails className="fs-exclude"><b>Modalities: </b>{study.modalities?.join(", ")} ({study.seriesUIDs?.length} series)</AttachmentDetails>}
      {!isMinified() && <AttachmentDetails className="fs-exclude"><b>Patient: </b>{study.patientName ? study.patientName.replace(/\^/g, ' ') : null}</AttachmentDetails>}
      {role === ADMIN && <NotesField readOnly={disabled} setNotes={setNotes} notes={study.notes?.note} />}
      {role === ADMIN && <MetadataField readOnly={disabled} setMetadata={setMetadata} metadata={study.metadata} setNotification={setNotification} />}
    </AttachmentInfoContainer>

    {isSelectMode 
      ? 
        <div>
          <AttachmentCheckbox raw setValue={() => {handleClick(study.recordUID)}} value={selectedRecords.findIndex(listItem => listItem === study.recordUID) >= 0}/>
        </div>
      :
        <AttachmentActions>
          <div className="buttons-wrapper">
            {
              isFinished && !study.isNew && !isApp &&
                <>
                  {
                    !isMinified() && allowDownloadMedicalImages && <TextButton isLoading={downloadIsLoading} onClick={tryDownload}>
                    {
                      downloadIsLoading ?
                        <><Loading size={15} borderWidth={3}/>Preparing...</>
                        :
                        downloadIsStating ?
                          <><Icon icon="check"/>Started</>
                          :
                          <><Icon icon="download"/>Download</>
                    }
                  </TextButton>
                  }
                  {!isMinified() && <TextButton isLoading={viewerIsLoading} onClick={openViewerLink}><Icon icon="view"/>View</TextButton>}
                </>
            }
            {
              isFinished && !disabled && !withoutDelete && !isMinified() &&
              <>
              <DropdownButton onClick={contextMenu.open}>
                <DropdownIcon icon="arrow-down"/>
                {
                  contextMenu.isOpen && !isMinified() &&
                  <ContextMenu>
                    {!isDragDisabled && <Li label="Move to..." icon="move-group">
                      <ContextMenu my="top left" at="top right">
                        {attachmentGroups.map(group =>
                          group.name
                            ? <Li disabled={currentGroup === group.id} icon={currentGroup === group.id && 'check'} label={group.name} onClick={() => moveTo(group.id)}/>
                            : null
                        )}
                        <ListSeparator/>
                        <Li label="Move to a new group" icon="plus-circle" onClick={() => moveTo(addGroupAndMove())}/>
                        {currentGroup !== 'ungrouped' &&
                          <ListSeparator/>
                        }
                        {currentGroup !== 'ungrouped' &&
                          <Li disabled={currentGroup === 'ungrouped'} icon="remove-from-group" label={"Remove from this group"}
                              onClick={() => {
                                let ungroup = attachmentGroups.filter(g => g.id === 'ungrouped')
                                if (ungroup.length > 0) {
                                  moveTo("ungrouped")
                                }else{
                                  setAttachmentGroups(() => {
                                    let groups = attachmentGroups.filter(g => g.id !== 'ungrouped')
                                    return [{ name: '', id: 'ungrouped', items: [] }].concat(groups)
                                  })
                                  moveTo("ungrouped")
                                }
                              }}
                          />
                        }
                      </ContextMenu>
                    </Li>}
                    {/* {role === ADMIN ?
                      study.state === Rejected ?
                        (loadingState.isOpen ? <Li disabled label="Undo reject" icon="x-circle-undo" onClick={() => loadingState.wait(setState(Submitted))}/> :
                          <Li label="Undo reject" icon="x-circle-undo" onClick={() => loadingState.wait(setState(Submitted))}/>
                        )
                        :
                        (loadingState.isOpen ? <Li disabled label="Reject record" icon="x-circle" onClick={() => loadingState.wait(setState(Rejected))}/> :
                          <Li label="Reject record" icon="x-circle" onClick={() => loadingState.wait(setState(Rejected))}/>
                        )
                      : null
                    } */}
                    <Li label="Delete record" icon="delete" onClick={deleteDialog.open}/>
                  </ContextMenu>
                }
              </DropdownButton>
              </>
            }
            {isFinished && !disabled && !withoutDelete && isMinified() && <a className="link" onClick={deleteDialog.open}>Delete</a>}
          </div>
          <AttachmentBoxFlexContainer>
          {
            !isMinified() && study.uploadDate && isFinished && <UploadedDate>
              Uploaded: {formatDateNoUTC(new Date(study.uploadDate),"MM/dd/yyyy - hh:mm")}
            </UploadedDate>
          }
          {/* {study.state === Rejected && isFinished && !hasOnlyFailedFiles && (role === ADMIN) && <RejectLabel>Rejected {(loadingState.isOpen ? <Loading size={18} css="margin-left: 0.5rem;"/> :
              <Button small alpha noFocus onClick={() => loadingState.wait(setState(Submitted))}>Undo</Button>)}
            </RejectLabel>} */}
          {study.state !== Rejected && loadingState.isOpen && <Loading size={18} css="margin: 0.5rem 0 -0.5rem 0.5rem;"/> }
          </AttachmentBoxFlexContainer>
        </AttachmentActions>
    }
    {
      studyDetailsDialog.isOpen &&
      <StudyDetailsDialog
        closeDialog={studyDetailsDialog.close}
        onCancel={onCancel}
        doneInstances={doneInstances}
        queuedInstances={queuedInstances}
        uploadingInstances={uploadingInstances}
        cancelDialog={cancelDialog}
        study={study}
        retry={retry}
        uploaded={uploaded}
        total={total}
        progress={progress}
        retryAll={failedInstances.length > 0 && retryAll}
        failedInstances={failedInstances}
        isFinished={isFinished}
        hasOnlyFailedFiles={hasOnlyFailedFiles}
      />
    }
    {uploadingInstances.map(instance =>
      <UploadInstance
        jwt={jwt}
        setProgress={setPartialProgress}
        key={instance.instanceUID}
        instance={instance}
        onCancel={onCancel(instance)}
        onFinishUpload={onFinishUpload(instance)}
        onFailure={onFailure(instance)}
        setSeriesList={setSeriesList}
      />
    )}

    {
      cancelDialog.isOpen &&
      <Dialog
        closeDialog={cancelDialog.close}
        title="Cancel DICOM study upload"
        footer={<>
          <Button alert isLoading={loading.isOpen} onClick={loading.willWait(cancelAndRemove)}>Delete entire medical record</Button>
          <Button alert onClick={() => { cancelQueue(); cancelDialog.close()}}>Stop remaining uploads only</Button>
        </>}
      >
      <p>Would you like to cancel only the remaining file uploads in queue or also delete all files already uploaded to this medical record?</p>
      </Dialog>
    }
    {
      deleteDialog.isOpen &&
      <Dialog
        closeDialog={deleteDialog.close}
        title="Confirm medical record deletion"
        footer={<>
          <Button onClick={deleteDialog.close}>Cancel</Button>
          <Button alert isLoading={loading.isOpen} onClick={loading.willWait(cancelAndRemove)}>Delete entire medical record</Button>
        </>}
      >
      <p>You are about to delete the following medical record:</p>
      <AttachmentBox css="margin-bottom:1rem;">
        <AttachmentIcon icon="dicom" css="font-size:44px;" className="desktop-only"/>
        |<AttachmentInfoContainer>
          <AttachmentDescription className="fs-exclude"><b>{study.studyDescription} {!isNaN(studyDate) && `(${formatDate(studyDate,'MM-dd-yyyy')})`}</b></AttachmentDescription>
          <AttachmentDetails className="fs-exclude"><b>Modalities: </b>{study.modalities?.join(", ")} ({study.seriesUIDs?.length} series)</AttachmentDetails>
          <AttachmentDetails className="fs-exclude"><b>Patient: </b>{study.patientName}</AttachmentDetails>
        </AttachmentInfoContainer>
      </AttachmentBox>
      <p><b>Are you sure you want to delete it?</b> Once confirmed this cannot be undone.</p>
      </Dialog>
    }
    <Tooltip content={!allowDownloadMedicalImages && isSelectMode ? 'Selection not allowed' : ''}/>
  </AttachmentBox>
}

const FilesListHeader = styled.div `
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`
const FilesList = styled.div `
  border-radius: 5px;
  height: 16rem;
  border: 1px solid ${getColor('gray210')};
  overflow: hidden;
  > div {
    overflow: auto;
    height: calc(100% + 1px);
  }
`
const FailedUploads = styled.div `
  color: ${getColor('alert')};
  margin-top: 1rem;
  font-weight: 600;
  margin-bottom: -1rem;
  background: ${props => lighten(0.51, props.theme.colors.alert)};
  border: 1px solid #e09f9f;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  ${Icon} {
    font-size: 18px;
    vertical-align: -4px;
    margin-right: 0.5rem;
  }
`

const StudyDetailsDialog = ({closeDialog, cancelDialog, onCancel, study, retry, retryAll, uploadingInstances, queuedInstances, doneInstances, studyDate, uploaded, total, progress, failedInstances, isFinished, hasOnlyFailedFiles}) => {
  return <Dialog
    title="DICOM study details"
    closeDialog={closeDialog}
    footer={<Button onClick={closeDialog}>Close</Button>}
  >
    <AttachmentBox css="padding:1rem;margin-bottom:1rem">
      <AttachmentInfoContainer>
        <AttachmentDescription className="fs-exclude" css="font-size:14px;"><b>{study.studyDescription} {!isNaN(studyDate) && `(${formatDate(studyDate,'MM-dd-yyyy')})`}</b></AttachmentDescription>
        <AttachmentDetails className="fs-exclude">
          <b>Patient:</b> {study.patientName ? study.patientName.replace(/\^/g, ' ') : null}
        </AttachmentDetails>
        <AttachmentDetails className="fs-exclude">
          <b>Modalities: </b>{study.modalities.join(", ")} ({study.seriesUIDs.length} series)
        </AttachmentDetails>
        <AttachmentDetails className="fs-exclude">
          <b>Referring physician:</b> {study.referringPhysician ? study.referringPhysician.replace(/\^/g, ' ') : null}
        </AttachmentDetails>
        <AttachmentDetails>
          <b>Study UID:</b> {study.studyUID}
        </AttachmentDetails>
      </AttachmentInfoContainer>
    </AttachmentBox>
    {study.instances.length > 0 && <>
      {isFinished ?
        <FilesListHeader><div><b>Upload completed</b> ({uploaded}/{total} files uploaded)</div></FilesListHeader>
        : (hasOnlyFailedFiles ?
        <FilesListHeader><div><b>Files uploaded</b> ({uploaded}/{total})</div><a className="link" onClick={cancelDialog.open}>Cancel</a></FilesListHeader>
        :
        <FilesListHeader><div><b>Uploading files</b> ({uploaded}/{total} uploaded)</div><a className="link" onClick={cancelDialog.open}>Cancel upload</a></FilesListHeader>
      )}
      <ProgressBar progress={progress * 100} completed={isFinished} failed={hasOnlyFailedFiles} css="margin: 0.5rem 0;"/>
      <FilesList>
        <div>
        {study.instances.map(instance =>
          <InstanceRow
            key={instance.instanceUID}
            instance={instance}
            retry={retry}
            onCancel={onCancel}
            isUploading={uploadingInstances.indexOf(instance) >= 0}
            isQueued={queuedInstances.indexOf(instance) >= 0}
            isDone={doneInstances.indexOf(instance) >= 0}
            className="fs-exclude"
          />
        )}
        </div>
      </FilesList>
      {

      }
      {retryAll && <FailedUploads><Icon icon="failure"/>{failedInstances.length} {failedInstances.length > 1 ? "file uploads have failed." : "file upload has failed."} {!!failedInstances.find(IS_RETRY_POSSIBLE) && <a className="link" onClick={retryAll}>Retry{failedInstances.length > 1 && ' all'}</a>}</FailedUploads>}
    </>}
  </Dialog>
}

const InstanceRow = styled(({instance, isUploading, isQueued, retry, onCancel, className}) => {
  const intl = useIntl()
  return <div className={className}>
    {instance.fileName}
    {
      (instance.failed || instance.quarantined)
        ? <span className="failed">
        {getFileStateLabel(intl, instance.failed || Quarantined)}
          {IS_RETRY_POSSIBLE(instance) && <a className="link" css="margin: 0 .5rem;" onClick={retry(instance)}>Retry</a>}
          <a className="link" onClick={onCancel(instance)}>Cancel</a>
    </span>
        : isUploading
          ? <span><b><Loading size={12} borderWidth={2}/>Uploading</b></span>
          : isQueued
            ? <span>Waiting</span>
            : <span>Uploaded</span>
    }
  </div>
})`
  padding: 1rem;
  border-bottom: 1px solid ${getColor('gray210')};
  display: flex;
  justify-content: space-between;
  span {
    flex-shrink: 0;
    margin-left: 1.5rem;
  }
  .failed {
    color: ${getColor('alert')};
  }
  ${Loading} {
    display: inline-block;
    margin-right: 4px;
    vertical-align: -1px;
  }
`

const UploadInstance = forwardRef(({instance, jwt, onFinishUpload, setSeriesList, onCancel, onFailure, setProgress}, ref) => {

  const params = {instanceUID: instance.instanceUID, studyUID: instance.studyUID, class: Radiology, format: DicomStudy}

  const [progress, cancel] = useUploader(instance.fileToUpload, {
    jwt,
    params,
    onSuccess:onFinishUpload,
    onCancel,
    onFailure
  })

  useRegisterCancel((i) => {
    cancel()
  })

  React.useEffect(() => {
    setProgress(instance,progress)
  },[progress])

  return null

})
