import {Button, SplitColumnsContainer} from '@startlibs/components';
import {TextInput} from '@startlibs/form';
import {getColor} from '@startlibs/utils';
import React from 'react';
import _ from 'lodash/fp';
import styled from 'styled-components';
import {buildValidation, confirmEmails, emailValidation, required} from '../utils/validation';
import {jwtPostFetcher} from '../utils/authFetch'
import {getJwt} from '../hooks/useJwt'
import {ConfirmDialog} from '../hooks/useConfirmDialog'
import { REVIEWER_TYPE_EXPERT } from '../enums/UserRole';

const ExpertEmailBox = styled.div`
    padding: 1.5rem 1.5rem 1rem;
    background: ${getColor('gray240')};
    border-radius: 5px;
    margin-bottom: 1rem;
`


const preValidation = buildValidation({
  email: [required, emailValidation, confirmEmails('confirmEmail')],
  confirmEmail: [required],
})

export const NewExpertShareDialog = ({notifyAndClose, selectExpert, setExperts, closeDialog}) => {

  const onSuccess = (values, serverId) => {
    const expert = {...values, ...serverId, justCreated: true, expertEmail: values.email, reviewerType: REVIEWER_TYPE_EXPERT}
    setExperts(_.concat(expert))
    selectExpert(expert)
    closeDialog()
  }
  const onFailure = (e,[resp,{status}],form) => status === 409 && form.setError("email","There's an expert already registered in the system with that email.")

  const addExpert = ({email}) => jwtPostFetcher(getJwt())("/api/expert", {email})

  return <ConfirmDialog
    title="Add expert"
    action={addExpert}
    preValidation={preValidation}
    onSuccess={onSuccess}
    onFailure={onFailure}
    closeDialog={closeDialog}
    confirm={<Button highlight>Add expert</Button>}
  >
    <p>Type the expert's email below to add them to your experts list</p>
    <ExpertEmailBox>
      <SplitColumnsContainer viewportMinWidth={540}>
        <TextInput path="email" label="Expert's email" placeholder="expert@email.com"/>
        <TextInput path="confirmEmail" label="Confirm email" placeholder="expert@email.com"/>
      </SplitColumnsContainer>
    </ExpertEmailBox>
  </ConfirmDialog>
}
