import {Button, Icon, Loading} from '@startlibs/components';
import {TextInput} from '@startlibs/form'
import {useToggle, willUseSharedCallback} from '@startlibs/core'
import React, {useContext, useEffect} from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components';

import {AttachmentDescription, AttachmentDetails, DropdownIcon} from '../AttachmentBoxStyles';
import {UploaderConfigContext} from "../../service/UploaderConfigContext";
import {useDoAction} from "../../service/hooks/useDoAction";
import {UploaderAction} from "../../service/UploaderAction";
import {NonCompliantDicom} from "../../enums/RecordFormat";
import {media} from "@startlibs/utils";
import { jwtPostFetcher } from '../../utils/authFetch';
import {FormattedMessage} from "react-intl";

const EditDetailButton = styled.div`
  display: inline-block;
  padding: 4px;
  border-radius: 5px;
  margin: -0.25rem 0 -0.25rem -4px;
  position: relative;
  max-width: 83%;
  ${media.max(520)`
    margin-top: -2px;
    margin-bottom: -4px;
  `}
  ${props => props.onClick && `:hover {
    background: rgba(0,0,0,0.06);
    cursor: pointer;
  }`}
  ${Icon} {
    font-size: 18px;
    margin-left: 0.25rem;
    vertical-align: -3px;
  }
  ${DropdownIcon} {
    font-size: 12px;
    vertical-align: -1px
  }
  flex-grow: 1;
`

export const DescriptionField = ({record}) => {
  const descriptionToggle = useToggle()

  const {
    requestId,
    disabled,
    worklistViewerJwt: expertViewJwt,
  } = useContext(UploaderConfigContext)
  const doAction = useDoAction()

  const description = record.description
  const setDescription = (value) => doAction(UploaderAction.SetDescription, record, value)

  const confirmDescription = () => {
    try{
      jwtPostFetcher(expertViewJwt)(`/api/audit/study/fileRenamed?requestId=${requestId}`, {
        oldName: description,
        newName: descriptionToggle.isOpen
      })
    }catch(e){
      console.log(e)
    }
    setDescription(descriptionToggle.isOpen)
    descriptionToggle.close()
  }

  const tryConfirmDescription = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      confirmDescription()
    } else if (e.key === 'Escape') {
      e.preventDefault()
      descriptionToggle.close()
    }
  }

  return <AttachmentDescription className="fs-exclude"><b className="nowrap"><FormattedMessage
    defaultMessage="Description"
    description="Uploader, record description label"
    />: </b>
    {
      _.isString(descriptionToggle.isOpen)
        ? <TextInput
          raw
          autoFocus
          onKeyUp={tryConfirmDescription}
          onKeyPress={e => e.key === "Enter" && e.preventDefault()}
          onBlur={confirmDescription}
          maxLength={255}
          value={descriptionToggle.isOpen}
          setValue={(v) => descriptionToggle.openWith(v)}
        />
        : <EditDetailButton onClick={disabled ? undefined : descriptionToggle.willOpenWith(description)}>
          {description}
          {!disabled && <Icon icon="edit-box"/>}
        </EditDetailButton>
    }
  </AttachmentDescription>
}