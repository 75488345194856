import {Button, Dialog, DialogFooter} from '@startlibs/components'
import {Errors, Field, TextInput, useProvideErrors, WithForm} from '@startlibs/form'
import React, { useRef, useState } from 'react'
import _ from 'lodash/fp'
import {getJwt} from '../../hooks/useJwt'
import {jwtPostFetcher} from '../../utils/authFetch'
import { useToggle } from '@startlibs/core'
import styled from 'styled-components'
import { getColor } from '@startlibs/utils'
import { IfLocale } from '../../hocs/IfLocale'
import {FormattedMessage, useIntl} from "react-intl";
import {Form} from "react-router-dom";

const ExpertReviewList = styled.div`
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid ${getColor('gray210')};
`

const ExpertReviewItem = styled.div`
  padding: 1rem 1.25rem;
  background: white;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${getColor('gray210')};
  :last-child {
    margin-bottom: -1px;
  }
  .info-wrapper {
    display: flex;
    .expert {
      font-size: 14px;
      max-width: 305px;
    }
    .speciality {
      font-size: 13px;
      color: rgba(0,0,0,0.5);
      margin-top: 2px;
    }
  }
  .access-role {
    flex-shrink: 0;
    color: rgba(0,0,0,0.4);
    font-size: 14px;
  }
`

export const ListExpertsDialog = ({listExpertsPopup, coReviewersList, userInfo}) => {
  const intl = useIntl()
  return <Dialog 
    title={intl.formatMessage({defaultMessage:"Experts",description:"List case experts dialog title"})}
    closeDialog={listExpertsPopup.close}
    >
      <p css="font-size:14px!important;"><FormattedMessage
        defaultMessage="The following Experts have access to this case:"
        description="List case experts dialog description"
      /></p>
      
      <ExpertReviewList>
        <ExpertReviewItem >
          <div className="info-wrapper">
            <div className="expert">
              <span><FormattedMessage
                defaultMessage="<strong>{firstName} {lastName}</strong> (you)"
                description="List case experts dialog current user info"
                values={{
                  strong: (chunks) => <strong>{chunks}</strong>,
                  firstName: userInfo.firstName,
                  lastName: userInfo.lastName
                }}
              /></span>
            </div>
          </div>
          <div className="access-role">
            <i><FormattedMessage
              defaultMessage="Assigned expert"
              description="List case experts dialog assigned expert label"
            /> </i>
          </div>
        </ExpertReviewItem>

        {coReviewersList.map((expert) => 
          <ExpertReviewItem >
            <div className="info-wrapper">
              <div className="expert">
                <span><FormattedMessage
                  defaultMessage="<strong>{firstName} {lastName}</strong> ({email})"
                  description="List case experts dialog, expert info"
                  values={{
                    strong: (chunks) => <strong>{chunks}</strong>,
                    firstName: expert.firstName,
                    lastName: expert.lastName,
                    email: expert.email
                  }}
                /></span>
                <IfLocale not contains="LEGAL">
                  {expert.specialization?.length > 0 &&
                    <div className="speciality">
                      <FormattedMessage
                        defaultMessage="Speciality: {specialities}"
                        description="List case experts dialog, expert specialities"
                        values={{
                          specialities: (expert.specialization || []).join(", ")
                        }}
                      />
                    </div>
                  }
                </IfLocale>
              </div>
            </div>
            <div className="access-role">
              <i><FormattedMessage
                defaultMessage="Read only"
                description="List case experts dialog, expert read only label"
               /></i>
            </div>
          </ExpertReviewItem>
        )}
      </ExpertReviewList>
  </Dialog> 
}