import {Combobox, DatePicker, TextInput} from '@startlibs/form'
import {SplitColumnsContainer} from '@startlibs/components'
import React, { useState } from 'react'
import {MedicalRecordLocationForm} from './MedicalRecordLocationForm';
import { PROVIDERS_LABELS, PROVIDERS_OPTIONS } from '../medicalRelease/ReleaseLocationForms';
import { BIOPSY_FACILITIES,
  HOSPITALS,
  PHYSICIAN_OR_PROVIDER,
  SCAN_FACILITIES,
  OTHER_FACILITIES } from '../../enums/MedicalRecordLocationType';
import StyledTelInput from '../../components/StyledTelInput';
import {useIntl} from "react-intl";

export const OtherFacilities = ({i,form,path,disabled,toggle}) => {
  const intl = useIntl()
  const [type, setType] = useState(form.getValue([...path,i,"locationType"]))

  return <MedicalRecordLocationForm disabled={disabled} form={form} path={path} i={i} toggle={toggle}>
    <SplitColumnsContainer>
      <Combobox 
        // path="locationType"
        path={[...path, i, "locationType"]}
        options={PROVIDERS_OPTIONS(intl)}
        getLabel={v => PROVIDERS_LABELS(intl)[v]}
        label={intl.formatMessage({
          defaultMessage: "Institution type",
          description: "Medical record location form, institution type input label"
        })}
        onChange={(v) => { setType(v)}}
      />
    </SplitColumnsContainer>
    <SplitColumnsContainer>
      <TextInput
        disabled={disabled}
        path={[...path, i, "name"]}
        mandatory
        label={
          type == OTHER_FACILITIES 
          || type == SCAN_FACILITIES 
          || type == BIOPSY_FACILITIES 
          ?
            intl.formatMessage({
              defaultMessage: "Facility",
              description: "Medical record location form, institution type, facility label"
            })
          : type == HOSPITALS 
            ? intl.formatMessage({
                defaultMessage: "Hospital",
                description: "Medical record location form, institution type, hospital label"
            }): intl.formatMessage({
                defaultMessage: "Physician or provider",
              description: "Medical record location form, institution type, physician or provider label"
              })
        }
      />
        
      {type == PHYSICIAN_OR_PROVIDER && <TextInput
          disabled={disabled}
          path={[...path, i, "type"]}
          label={intl.formatMessage({
            defaultMessage: "Speciality",
            description: "Medical record location form, institution type, speciality input label"
          })}
        />
      }
      {type == SCAN_FACILITIES && <TextInput
          disabled={disabled}
          path={[...path, i, "type"]}
          label={intl.formatMessage({
            defaultMessage: "Type of scan",
            description: "Medical record location form, institution type, type of scan input label"
          })}
        />
      }
      <TextInput
        disabled={disabled}
        path={[...path, i, "city"]}
        label={intl.formatMessage({
          defaultMessage: "City/State",
          description: "Medical record location form, institution type, city/state input label"
        })}
        mandatory
      />
    </SplitColumnsContainer>
    <SplitColumnsContainer>
      <TextInput
        disabled={disabled}
        path={[...path, i, "contactName"]}
        label={intl.formatMessage({
          defaultMessage: "Contact name",
          description: "Medical record location form, institution type, contact name input label"
        })}
        helpText={intl.formatMessage({
          defaultMessage: "(Optional)",
          description: "Optional input"
        })}
      />
      <TextInput
        disabled={disabled}
        path={[...path, i, "email"]}
        label={intl.formatMessage({
          defaultMessage: "Email",
          description: "Medical record location form, institution type, email input label"
        })}
        helpText={intl.formatMessage({
          defaultMessage: "(Optional)",
          description: "Optional input"
        })}
      />
      {/* <TextInput
        disabled={disabled}
        path={[...path, i, "phoneNumber"]}
        label="Phone/Fax"
        helpText="(Optional)"
      /> */}
      <StyledTelInput
        disabled={disabled}
        path={[...path, i, "phoneNumber"]}
        form={form}
        label={intl.formatMessage({
          defaultMessage: "Phone/Fax",
          description: "Medical record location form, institution type, phone/fax input label"
        })}
        helpText={intl.formatMessage({
          defaultMessage: "(Optional)",
          description: "Optional input"
        })}
        initOptions={{
          initialCountry: intl.formatMessage({
            defaultMessage: "us",
            description: "Default country code for phone number"
          }),
          separateDialCode: false,
          autoPlaceholder: "aggressive",
          formatOnDisplay: true,
          placeholderNumberType: "FIXED_LINE_OR_MOBILE",
          nationalMode: false,
          countryOrder: [intl.formatMessage({
            defaultMessage: "us",
            description: "Default country code for phone number"
          })]
        }}
        tabIndex={1}
      />
    </SplitColumnsContainer>
  </MedicalRecordLocationForm>
}