import {Combobox, Field, TextInput, useInput} from '@startlibs/form';
import {useRefState} from '@startlibs/core'
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import {useIntl} from "react-intl";

const keepZeroInFront = (v, keep) => (v < 10 && keep && v !== 0) ? "0" + v : v

const formatHours = (hour) => {
  const intHour = Math.abs(parseInt(hour) % 12)
  if (isNaN(intHour))
    return ""

  if (intHour === 0) {
    return "12"
  }
  if (intHour <= 9) {
    return "0" + intHour
  }
  return intHour
}
const formatMinutes = (minutes) => {
  const intMinutes = Math.abs(parseInt(minutes) % 60)
  if (isNaN(intMinutes)) {
    return ""
  }
  if (intMinutes <= 9) {
    return "0" + intMinutes
  }
  return intMinutes
}

const updateValue = (newValue,position) => (oldValue) => {
  const [hours,minutes] = oldValue.split(":")
  return position === 0
    ? [newValue,minutes].join(":")
    : [hours,newValue].join(":")
}

export const TimeInput = styled(({path, raw, className, noLabel, label, ...rest}) => {
  const intl = useIntl()
  const [formValue = "", setFormValue, {hasErrors}] = useInput({path, ...rest}, raw)

  const [hours, rawSetHours] = useState(() => formatHours(formValue.split(":")[0] || ""))
  const [minutes, rawSetMinutes] = useState(() => formatMinutes(formValue.split(":")[1] || ""))

  const [ampm, rawSetAmpm] = useState(() => parseInt(formValue.split(":")[0]) > 11 ? "PM" : "AM")

  const minuteRef = useRef()
  const hoursRefState = useRefState(hours)

  const setHours = (originalV) => {
    const v = originalV.replace(/[^0-9]/g,"")
    if (v === "") {
      hoursRefState.set("")
      rawSetHours("")
      setFormValue(updateValue("",0))
    }
    const typedBefore = v.indexOf(v) > 0
    const cleanHours = typedBefore ? v.slice(0, 2) : v.slice(-2)
    const int = parseInt(cleanHours)
    if (int <= 24) {
      hoursRefState.set(int)
      rawSetHours(cleanHours)
      setFormValue(updateValue(ampm === "AM" ? int % 12 : ((int % 12)+12),0))
      if (parseInt(int + "0") > 24 && !typedBefore || cleanHours === "01" || cleanHours === "02" || cleanHours === "00") {
        minuteRef.current.focus()
      }
    }
  }
  const setMinutes = (originalV) => {
    const v = originalV.replace(/[^0-9]/g,"")
    if (v === "") {
      rawSetMinutes("")
      setFormValue(updateValue("",1))
    }
    const typedBefore = v.indexOf(minutes) > 0
    const cleanMinutes = typedBefore ? v.slice(0, 2) : v.slice(-2)
    const int = parseInt(cleanMinutes)
    if (int < 60) {
      rawSetMinutes(cleanMinutes)
      setFormValue(updateValue(cleanMinutes,1))
    }
  }
  const hourBlur = () => {
    rawSetHours(formatHours)
    const hoursInt = parseInt(hoursRefState.get())
    if (hoursInt > 12 && ampm === "AM") {
      setAmpm("PM")
    }
  }

  const setAmpm = (v) => {
    rawSetAmpm(v)
    setFormValue((value) => {
      const [hour = "",minutes = ""] = value.split(":")
      const intHour = parseInt(hour)
      return (isNaN(intHour) ? "" : (v === "AM" ? intHour % 12 : ((intHour % 12)+12))) + ":" + minutes
    })
  }

  return <div className={className}>
    <Field label={noLabel ? null : (label || intl.formatMessage({defaultMessage:"Time",description:"Time input label"}))}>
      <div className="inputs-wrapper">
        <TextInput
          raw
          hasErrors={hasErrors}
          value={hours}
          setValue={setHours}
          constraint="###"
          onFocus={(e) => e.target.select()}
          onBlur={hourBlur}
          placeholder={intl.formatMessage({
            defaultMessage: "HH",
            description: "Time input hours placeholder"
          })}
        />
        <span className="divider"> : </span>
        <TextInput
          raw
          hasErrors={hasErrors}
          value={minutes}
          constraint="###"
          ref={minuteRef}
          setValue={setMinutes}
          onFocus={(e) => e.target.select()}
          onBlur={() => rawSetMinutes(formatMinutes)}
          placeholder={intl.formatMessage({
            defaultMessage: "MM",
            description: "Time input minutes placeholder"
          })}
        />
        <div className="field-fix">
          <Combobox
            raw
            options={["AM", "PM"]}
            value={ampm}
            getLabel={v => v === "AM"
              ? intl.formatMessage({defaultMessage:"AM",description:"AM time input label"})
              : intl.formatMessage({defaultMessage:"PM",description:"PM time input label"})
            }
            setValue={setAmpm}
          />
        </div>
      </div>
    </Field>
  </div>

})`
.inputs-wrapper {
  display: flex;
  align-items: center;
  input {
    flex-basis: 30%;
    flex-grow: 1;
    min-width: 5rem;
  }
  .divider {
    margin: 0.25rem;
    color: rgba(0,0,0,0.5);
  }
  .field-fix {
    margin-left: .5rem;
    flex-basis: 30%;
    min-width: 10rem;
  }
  .field-fix > div:first-child {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .fake-input {
    display: block;
  }
}
`
