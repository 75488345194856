import { lighten } from 'polished';
import React, { forwardRef, useState, useCallback } from 'react';
import _ from 'lodash/fp';
import styled from 'styled-components';

import { ContextMenu, Li, Button, ListSeparator, Dialog, Loading, Icon, Tooltip } from '@startlibs/components';
import { callIfFunction, getColor, wrapLazy } from '@startlibs/utils';
import {useToggle, usePopupToggle, willUseSharedCallback, useRefState, WithLazyResource} from '@startlibs/core'

import { ADMIN } from '../enums/UserRoles';
import {
  AttachmentActions,
  AttachmentBox,
  AttachmentBoxFlexContainer,
  AttachmentBoxFooter,
  AttachmentCheckbox,
  AttachmentDescription,
  AttachmentDetails,
  AttachmentIcon,
  AttachmentInfoContainer,
  DraggableCornerIcon,
  DropdownButton,
  DropdownIcon,
  ProgressBar,
  ProgressDetails,
  RejectLabel,
  TextButton,
  UploadedDate,
  UploadingRecords,
  UploadingStatus
} from './AttachmentBoxStyles';
import { DicomStudy, FormatToLabel } from '../enums/RecordFormat';
import { InfoBox } from './ViewAllButton';
import {MetadataField} from './recordGroup/MetadataField'
import {NotesField} from './recordGroup/NotesField'
import { Pathology } from '../enums/RecordClass';
import { RecordStateFooter } from './RecordStateFooter';
import { Rejected, Submitted } from '../enums/RecordState';
import { formatDateNoUTC } from '../utils';
import {FileNotFound, getFileStateLabel, IS_QUARANTINED, IS_RETRY_POSSIBLE, Quarantined} from '../enums/FileState'
import { isMultiFile } from './FileinputBox';
import {jwtGetFetcher, jwtPostFetcher} from '../utils/authFetch'
import { useNewDownload, useViewRecord } from '../service/utils/downloadFile';
import {useRegisterOpenDetails} from './hooks/useActivityLog'
import {useUploader} from '../hooks/useUploader'
import {useAddRetryFilesNotFound} from './hooks/useFilesNotFound'
import { DEVICE, DISK } from '../enums/UploaderStepsManagement';
import {useIntl} from "react-intl";

const [useRegisterCancel,callCancels] = willUseSharedCallback()

export const PathologyMultiFilebox =
  ({
    record,
    allowDownload,
    allowDownloadMedicalImages,
    disabled,
    isDragDisabled,
    hasUnprocessedFiles,
    currentGroup,
    withoutDelete,
    jwt,
    appJwt,
    onFinishUpload,
    finishMultiFile,
    onCancel,
    onFailure,
    retry,
    removeRecord,
    setAttachment,
    role,
    setSeriesList,
    noCancel,
    isApp,
    viewerUrl,
    shortTokenUrl,
    downloadLoadUrl,
    downloadUrl,
    moveTo,
    attachmentGroups,
    dragProvided,
    addGroupAndMove,
    setAttachmentGroups,
    setNotification,
    isSelectMode,
    selectedRecords,
    setSelectedRecords,
    mode,
    expertViewJwt,
    requestId
  }) => {

  const studyDetailsDialog = useToggle()
  const quarantinedDetailsDialog = useToggle()
  const cancelDialog = useToggle()
  const deleteDialog = useToggle()
  const loading = useToggle()
  const loadingState = useToggle()
  const multiFileUID = useToggle()
  const partialProgressMap = useRefState(() => new Map())
  const finishedQuantity = useRefState(() => 0)
  const [partialProgress,setRawPartialProgress] = useState(0)

  const isQuarantined = record.quarantined || record.instances?.find(IS_QUARANTINED)

  const contextMenu = usePopupToggle()

  useRegisterOpenDetails((key) => key === record.key && studyDetailsDialog.open())

  const uploadingInstances = record.instances.filter(instance => instance.fileToUpload)
  const queuedInstances = record.instances.filter(instance => instance.sourceFile)
  const doneInstances = record.instances.filter(instance => !instance.sourceFile || !IS_RETRY_POSSIBLE(instance))
  const failedInstances = record.instances.filter(instance => instance.failed)

  const uploaded = doneInstances.length
  const total = record.instances.length

  const progress = (uploaded + partialProgress)/total
  const [keepProgress,setKeepProgress] = useState(uploaded && hasUnprocessedFiles)

  const emptyList = []
  const recordsList = emptyList.concat(record.recordUID)
  
  React.useEffect(() => {
    if (record.instances?.length) {
      jwtPostFetcher(appJwt)("/uploader/init-multifile")
        .then((response) => multiFileUID.openWith(response.multiFileUID))
    }
  },[])

  
  function handleClick(id){
    // If contains the id. Must remove it
    if(selectedRecords.findIndex(listItem => listItem === id) >= 0){
      // Found
      const filtered = selectedRecords.filter(listItem => listItem !== id)
      setSelectedRecords(filtered)
    }else{
      // Do not contains. 
      // Check if it is allowed to select it for download
      if((!allowDownloadMedicalImages) && ((record.format === DicomStudy) ||
            ((record.recordClass === Pathology) && (isMultiFile(record.format))))){
        // Not allowed  
      } else {
        // Allowed - add to list
        const added = selectedRecords.concat(id)
        setSelectedRecords(added)
      }
    }
  }

  const finishMultiFileRequest = () => jwtPostFetcher(appJwt)("/uploader/end-multifile",{
    multiFileUID:multiFileUID.get(),
    uploadedFiles:record.instances.map(({path,filename,extension}) => path+"/"+filename+"."+extension),
    format: record.format,
    infoDescription: record.description,
    class: record.recordClass
  })

  const finishUpload = (instance,response) => {
    if (finishedQuantity.get() + 1 + failedInstances.filter(IS_QUARANTINED).length === record.instances.length) {
      finishMultiFileRequest().then((endResponse) => {
        onFinishUpload(instance)(response)
        finishMultiFile(record,endResponse)
      })
    } else {
      finishedQuantity.set(i => i + 1)
      onFinishUpload(instance)(response)
    }
  }

  const onFailureWithFinish = (instance) => (error) => {
    if (error === Quarantined && finishedQuantity.get() + 1 + failedInstances.filter(IS_QUARANTINED).length === record.instances.length) {
      return finishMultiFileRequest().then( () =>
        onFailure(instance)(error)
      )
    } else {
      return onFailure(instance)(error)
    }
  }


  const setPartialProgress = useCallback((instance,progress) => {
    const map = partialProgressMap.get()
    if (instance) {
      map.set(instance,progress)
    }
    setRawPartialProgress(_.sum(uploadingInstances.map(instance => map.get(instance) || 0).concat(failedInstances.filter((v) => !IS_RETRY_POSSIBLE(v)).map(v => 1))))
  },[uploadingInstances])

    React.useEffect(() => {
      setPartialProgress()
    },[record.instances])


  React.useEffect(() => {
    if (hasUnprocessedFiles && !keepProgress) {
      setKeepProgress(true)
    }
    if (!hasUnprocessedFiles && keepProgress) {
      setKeepProgress(false)
    }
  },[uploaded])

  const isFinished = uploaded === total && !keepProgress


  const cancelRecord = () => {
    onCancel(record)
    callCancels()
    return Promise.resolve()
  }


  const cancelAndRemove = () => {
    return removeRecord(record)
  }

  const retryAll = () => {
    failedInstances.filter(IS_RETRY_POSSIBLE).forEach((instance) => retry(instance)())
  }
  useAddRetryFilesNotFound(failedInstances.find(_.matchesProperty(['failed',FileNotFound])), retryAll)

  const [tryDownload,downloadIsLoading,downloadIsStating,downloadFailed] = useNewDownload(expertViewJwt,recordsList,downloadUrl,record.description,requestId)
  const [openViewerLink,viewerIsLoading] = useViewRecord(expertViewJwt, record, requestId, shortTokenUrl)

  const hasOnlyFailedFiles = queuedInstances.length === failedInstances.length && failedInstances.length > 0

  const isMinified = () => (mode === DEVICE || mode === DISK);

  const setNotes = (value) => setAttachment(_.set('notes.note', value))
  const setMetadata = (value) => setAttachment(_.set('metadata', value))
  const setState = (value) => setAttachment(_.set('state', value))

  return (
    (mode === DEVICE && record?.instances[0]?.mode === DEVICE) || 
    (mode === DISK && record?.instances[0]?.mode === DISK)  ||
    !isMinified()
   )  && <AttachmentBox
    ref={dragProvided.innerRef}
    {...dragProvided.draggableProps}
    {...dragProvided.dragHandleProps}
    failedUpload={hasOnlyFailedFiles && !isQuarantined} isApp={isApp}
    hasFooter={isQuarantined || false && record.state && isFinished && !hasOnlyFailedFiles && (role === ADMIN)}
    unavailable={isQuarantined || false && (record.state === Rejected  && isFinished && !hasOnlyFailedFiles && (role === ADMIN))}
    isSelectMode={isSelectMode}
    disabledColor={!allowDownloadMedicalImages && isSelectMode}
    onClick={isSelectMode ? (() => {allowDownloadMedicalImages ? handleClick(record.recordUID) : null}) : undefined}
    isSelected={selectedRecords.findIndex(listItem => listItem === record.recordUID) >= 0}
    mode={mode}
  >
      {!isDragDisabled && <DraggableCornerIcon icon="draggable-corner"/>}
    <AttachmentIcon icon="pathology" css="font-size: 28px;" mode={mode}/>
    {
      !isFinished && (hasOnlyFailedFiles ?
      <UploadingStatus>
      {uploaded > 0 && !isMinified() && <ProgressDetails>{uploaded}/{total} files successfully uploaded</ProgressDetails>}
      <ProgressDetails failed>
        <a className="link error" onClick={studyDetailsDialog.open}>{failedInstances.length} file upload{failedInstances.length > 1 && 's'} failed</a>
        <a className="link" onClick={retryAll}>Retry{failedInstances.length > 1 && ' all'}</a>
      </ProgressDetails>
      </UploadingStatus>
      : <UploadingStatus>
        
        <ProgressDetails css="display: flex; align-items: center;">
          {uploadingInstances.length
            ? isMinified()
              ? <UploadingRecords><Loading size={16} borderWidth={3}/>Uploading</UploadingRecords>
              : <span>Uploading files...</span>
            : <span>Waiting...</span>
          }
          { !noCancel && <a className="link" onClick={cancelDialog.open}>Cancel</a> }
        </ProgressDetails>
        {!isMinified() && <ProgressBar progress={progress * 100} completed={isFinished}/>}
        {failedInstances.length > 0 ?
          <div className="files-count"><a className="link error" onClick={studyDetailsDialog.open}>{failedInstances.length} file upload{failedInstances.length > 1 && 's'} failed</a> {!!failedInstances.find(IS_RETRY_POSSIBLE) && <a className="link" onClick={retryAll}>Retry{failedInstances.length > 1 && ' all'}</a>}</div>
          : !isMinified() 
            ? <div className="files-count"><a className="link" onClick={studyDetailsDialog.open}>{uploaded}/{total} files uploaded</a></div>
            : null
        }
      </UploadingStatus>
    )}
    <AttachmentInfoContainer>
      <AttachmentDescription className="fs-exclude">
        <b>{record.description}</b> {record.instances?.length>0 && <a className="light-link" onClick={studyDetailsDialog.open}>Details</a>}
      </AttachmentDescription>
      <AttachmentDetails><b>Format: </b>{FormatToLabel[record.format] || record.format}</AttachmentDetails>
      {role === ADMIN && <NotesField readOnly={disabled} setNotes={setNotes} notes={record.notes?.note} />}
      {role === ADMIN && <MetadataField readOnly={disabled} setMetadata={setMetadata} metadata={record.metadata} setNotification={setNotification} />}
      </AttachmentInfoContainer>
    {isSelectMode 
      ? 
        <AttachmentCheckbox raw setValue={() => {handleClick(record.recordUID)}} value={selectedRecords.findIndex(listItem => listItem === record.recordUID) >= 0}/>
      :
        <AttachmentActions>
          <div className="buttons-wrapper">
            {
              isFinished && !record.isNew && !isApp && !isMinified() && 
                <>
                  {
                    allowDownloadMedicalImages && <TextButton disabled={isQuarantined} isLoading={downloadIsLoading} onClick={tryDownload}>
                    {
                      downloadIsLoading ?
                        <><Loading size={15} borderWidth={3}/>Preparing...</>
                        :
                        downloadIsStating ?
                          <><Icon icon="check"/>Started</>
                          :
                          <><Icon icon="download"/>Download</>
                    }
                  </TextButton>
                  }
                  <TextButton
                    disabled={isQuarantined}
                    // onClick={() => window.top.open(callIfFunction(viewerUrl,record),"_blank")}
                    onClick={openViewerLink}
                    isLoading={viewerIsLoading}
                  >
                    {viewerIsLoading ? <Loading size={15} borderWidth={3}/> : <Icon icon="view"/>}
                    View
                  </TextButton>
                </>
            }
            {
              isFinished && !disabled && !withoutDelete && !isMinified() && 
              <DropdownButton onClick={contextMenu.open}>
                <DropdownIcon icon="arrow-down"/>
                {
                  contextMenu.isOpen &&
                  <ContextMenu>
                    {!isDragDisabled && <Li label="Move to..." icon="move-group">
                      <ContextMenu my="top left" at="top right">
                        {attachmentGroups.map(group =>
                          group.name 
                            ? <Li disabled={currentGroup === group.id} icon={currentGroup === group.id && 'check'} label={group.name} onClick={() => moveTo(group.id)}/>
                            : null
                        )}
                        <ListSeparator/>
                        <Li label="Move to a new group" icon="plus-circle" onClick={() => moveTo(addGroupAndMove())}/>
                        {currentGroup !== 'ungrouped' &&
                          <ListSeparator/>
                        }
                        {currentGroup !== 'ungrouped' &&
                          <Li disabled={currentGroup === 'ungrouped'} icon="remove-from-group" label={"Remove from this group"} 
                              onClick={() => { 
                                let ungroup = attachmentGroups.filter(g => g.id === 'ungrouped')
                                if (ungroup.length > 0) {
                                  moveTo("ungrouped")
                                }else{
                                  setAttachmentGroups(() => {
                                    let groups = attachmentGroups.filter(g => g.id !== 'ungrouped')
                                    return [{ name: '', id: 'ungrouped', items: [] }].concat(groups)
                                  })
                                  moveTo("ungrouped")
                                }
                              }}
                          />
                        }
                      </ContextMenu>
                    </Li>}
                    {/* {role === ADMIN ?
                      record.state === Rejected ? 
                        (loadingState.isOpen ? <Li disabled label="Undo reject" icon="x-circle-undo" onClick={() => loadingState.wait(setState(Submitted))}/> :
                          <Li label="Undo reject" icon="x-circle-undo" onClick={() => loadingState.wait(setState(Submitted))}/>
                        )
                        :
                        (loadingState.isOpen ? <Li disabled label="Reject record" icon="x-circle" onClick={() => loadingState.wait(setState(Rejected))}/> :
                          <Li label="Reject record" icon="x-circle" onClick={() => loadingState.wait(setState(Rejected))}/>
                        )
                      : null
                    } */}
                    <Li label="Delete record" icon="delete" onClick={deleteDialog.open}/>
                  </ContextMenu>
                }
              </DropdownButton>
            }
            {isFinished && !disabled && !withoutDelete && isMinified() && <a className="link" onClick={deleteDialog.open}>Delete</a>}
          </div>
          <AttachmentBoxFlexContainer>
            {
               !isMinified() && record.uploadDate && isFinished && <UploadedDate>
                Uploaded: {formatDateNoUTC(new Date(record.uploadDate),"MM/dd/yyyy - hh:mm")}
              </UploadedDate>
            }
            {/* {record.state === Rejected && isFinished && !hasOnlyFailedFiles && (role === ADMIN) && <RejectLabel>Rejected {(loadingState.isOpen ? <Loading size={18} css="margin-left: 0.5rem;"/> :
              <Button small alpha noFocus onClick={() => loadingState.wait(setState(Submitted))}>Undo</Button>)}
            </RejectLabel>} */}
            {record.state !== Rejected && loadingState.isOpen && <Loading size={18} css="margin: 0.5rem 0 -0.5rem 0.5rem;"/> }
          </AttachmentBoxFlexContainer>
        </AttachmentActions>
    }
    {
      isQuarantined ?
        <AttachmentBoxFooter alert>
            <div><b>Status: </b>Quarantined</div>
            <div>This medical record file has potentially harmful data. <a className="link" onClick={record.quarantined ? quarantinedDetailsDialog.open : studyDetailsDialog.open}>View details</a></div>
        </AttachmentBoxFooter>
        : null
    }
    {
      studyDetailsDialog.isOpen &&
      <StudyDetailsDialog
        closeDialog={studyDetailsDialog.close}
        onCancel={onCancel}
        doneInstances={doneInstances}
        queuedInstances={queuedInstances}
        uploadingInstances={uploadingInstances}
        cancelDialog={cancelDialog}
        record={record}
        retry={retry}
        uploaded={uploaded}
        total={total}
        progress={progress}
        retryAll={failedInstances.length > 0 && retryAll}
        failedInstances={failedInstances}
        isFinished={isFinished}
        hasOnlyFailedFiles={hasOnlyFailedFiles}
      />
    }
    {
      quarantinedDetailsDialog.isOpen &&
      <QuarantinedDetailsDialog
        closeDialog={quarantinedDetailsDialog.close}
        jwt={jwt}
        record={record}
      />
    }
    {
      multiFileUID.isOpen && uploadingInstances.map(instance =>
      <UploadInstance
        jwt={jwt}
        record={record}
        multiFileUID={multiFileUID.get()}
        setProgress={setPartialProgress}
        key={instance.path + "/" +instance.filename+ "." +instance.extension}
        instance={instance}
        onCancel={onCancel}
        onFinishUpload={(response) => finishUpload(instance,response)}
        onFailure={onFailureWithFinish(instance)}
        setSeriesList={setSeriesList}
      />
    )}

    {
      cancelDialog.isOpen &&
      <Dialog
        closeDialog={cancelDialog.close}
        title="Cancel pathology records upload"
        footer={<>
          <Button onClick={cancelDialog.close}>Continue upload</Button>
          <Button alert isLoading={loading.isOpen} onClick={loading.willWait(cancelRecord)}>Confirm and remove record</Button>
        </>}
      >
      <p>This pathology record will be removed.</p>
      <p>Are you sure you want to proceed?</p>
      </Dialog>
    }
    {
      deleteDialog.isOpen &&
      <Dialog
        closeDialog={deleteDialog.close}
        title="Confirm medical record deletion"
        footer={<>
          <Button onClick={deleteDialog.close}>Cancel</Button>
          <Button alert isLoading={loading.isOpen} onClick={loading.willWait(cancelAndRemove)}>Delete record</Button>
        </>}
      >
      <p>You are about to delete the following medical record:</p>
      <AttachmentBox css="margin-bottom:1rem;">
        <AttachmentIcon icon="pathology" css="font-size: 28px;" className="desktop-only"/>
        <AttachmentInfoContainer>
          <AttachmentDescription className="fs-exclude">
            <b>{record.description}</b>
            <AttachmentDetails><b>Format: </b>{FormatToLabel[record.format] || record.format}</AttachmentDetails>
          </AttachmentDescription>
        </AttachmentInfoContainer>
      </AttachmentBox>
      <p><b>Are you sure you want to delete it?</b> Once confirmed this cannot be undone.</p>
      </Dialog>
    }
    <Tooltip content={!allowDownloadMedicalImages && isSelectMode ? 'Selection not allowed' : ''}/>
  </AttachmentBox>
}

const FilesListHeader = styled.div `
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`
const FilesList = styled.div `
  border-radius: 5px;
  height: 16rem;
  border: 1px solid ${getColor('gray210')};
  overflow: hidden;
  > div {
    overflow: auto;
    height: calc(100% + 1px);
  }
`
const FailedUploads = styled.div `
  color: ${getColor( 'alert')};
  margin-top: 1rem;
  font-weight: 600;
  margin-bottom: -1rem;
  background: ${props => lighten(0.51, props.theme.colors.alert)};
  border: 1px solid #e09f9f;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  ${Icon} {
    font-size: 18px;
    vertical-align: -4px;
    margin-right: 0.5rem;
  }
`

const StudyDetailsDialog = ({closeDialog, cancelDialog, onCancel, record, retry, retryAll, uploadingInstances, queuedInstances, doneInstances, studyDate, uploaded, total, progress, failedInstances, isFinished, hasOnlyFailedFiles}) => {
  const quarantinedFiles = failedInstances?.filter(IS_QUARANTINED) || []
  return <Dialog
    title="Pathology record details"
    closeDialog={closeDialog}
    footer={<Button onClick={closeDialog}>Close</Button>}
  >
    <AttachmentBox css="padding:1rem;margin-bottom:1rem">
      <AttachmentInfoContainer>
        <AttachmentDescription className="fs-exclude" css="font-size:14px;">
          <b>{record.description}</b>
        </AttachmentDescription>
      </AttachmentInfoContainer>
    </AttachmentBox>
    {record.instances.length > 0 && <>
      {isFinished ?
        <FilesListHeader><div><b>Upload completed</b> ({uploaded}/{total} files uploaded)</div></FilesListHeader>
        : (hasOnlyFailedFiles ?
        <FilesListHeader><div><b>Files uploaded</b> ({uploaded}/{total})</div><a className="link" onClick={cancelDialog.open}>Cancel</a></FilesListHeader>
        :
        <FilesListHeader><div><b>Uploading files</b> ({uploaded}/{total} uploaded)</div><a className="link" onClick={cancelDialog.open}>Cancel upload</a></FilesListHeader>
      )}
      <ProgressBar progress={progress * 100} completed={isFinished} failed={hasOnlyFailedFiles} css="margin: 0.5rem 0;"/>
      <FilesList>
        <div>
        {_.orderBy(['failed','filename'],['asc','asc'],record.instances).map(instance =>
          <InstanceRow
            key={instance.path + "/" +instance.filename+ "." +instance.extension}
            instance={instance}
            retry={retry}
            onCancel={onCancel}
            isUploading={uploadingInstances.indexOf(instance) >= 0}
            isQueued={queuedInstances.indexOf(instance) >= 0}
            isDone={doneInstances.indexOf(instance) >= 0}
          />
        )}
        </div>
      </FilesList>
      {!!failedInstances.find(IS_RETRY_POSSIBLE) && retryAll && <FailedUploads><Icon icon="failure"/>{failedInstances.length} {failedInstances.length > 1 ? "file uploads have failed." : "file upload has failed."} <a className="link" onClick={retryAll}>Retry{failedInstances.length > 1 && ' all'}</a></FailedUploads>}
    </>}
    {quarantinedFiles.length>0 && <InfoBox error withIcon css="margin-top: 1rem; font-weight: 600;"><Icon icon="warning"/>This medical record is quarantined as {quarantinedFiles.length} uploaded file(s) are harmful.</InfoBox>}
  </Dialog>
}

const lazyQuarantined = wrapLazy((jwt,recordUID) => jwtGetFetcher(jwt)(`/record/${recordUID}/quarantine`))

const QuarantinedDetailsDialog = ({closeDialog, record, jwt}) => {
  return <Dialog
    loading={false}
    title="Pathology record details"
    closeDialog={closeDialog}
    footer={<Button onClick={closeDialog}>Close</Button>}
  >
    <AttachmentBox css="padding:1rem;margin-bottom:1rem">
      <AttachmentInfoContainer>
        <AttachmentDescription className="fs-exclude" css="font-size:14px;">
          <b>{record.description}</b>
        </AttachmentDescription>
      </AttachmentInfoContainer>
    </AttachmentBox>
    <WithLazyResource value={lazyQuarantined.use(jwt,record.recordUID)}>{ quarantinedFiles => <>
      <FilesList>
        <div>
          {quarantinedFiles.map(instance =>
            <InstanceRow
              key={instance.path + "/" +instance.filename+ "." +instance.extension}
              instance={instance}
              as={QuarantinedInstanceRow}
            />
          )}
        </div>
      </FilesList>
      <InfoBox error withIcon css="margin-top: 1rem; font-weight: 600;"><Icon icon="warning"/>This medical record is quarantined as {quarantinedFiles.length} uploaded file(s) are harmful.</InfoBox>
    </>}</WithLazyResource>
  </Dialog>
}

const QuarantinedInstanceRow = ({className,instance}) => <div className={className}>
  {instance.originalFilename}
  <span className="failed">
    {getFileStateLabel(Quarantined)}
  </span>
</div>

const InstanceRow = styled(({instance, isUploading, isQueued, retry, onCancel, className, quarantinedInstance}) => {
  const intl = useIntl()
  return <div className={className}>
    {
      quarantinedInstance
        ? instance.originalFilename
        : instance.filename + "." + instance.extension
    }
    {
      (instance.failed || quarantinedInstance)
        ? <span className="failed">
        {getFileStateLabel(intl, instance?.failed || Quarantined)}
          {!quarantinedInstance && IS_RETRY_POSSIBLE(instance) && <a className="link" css="margin: 0 .5rem;" onClick={retry(instance)}>Retry</a>}
      </span>
        : isUploading
          ? <span><b><Loading size={12} borderWidth={2}/>Uploading</b></span>
          : isQueued
            ? <span>Waiting</span>
            : <span>Uploaded</span>
    }
  </div>
})`
  padding: 1rem;
  border-bottom: 1px solid ${getColor('gray210')};
  display: flex;
  justify-content: space-between;
  span {
    flex-shrink: 0;
    margin-left: 1.5rem;
  }
  .failed {
    color: ${getColor('alert')};
  }
  ${Loading} {
    display: inline-block;
    margin-right: 4px;
    vertical-align: -1px;
  }
`

const UploadInstance = forwardRef(({instance, record, multiFileUID, jwt, onFinishUpload, setSeriesList, onCancel, onFailure, setProgress}, ref) => {

  const params = {multiFileUID, multiFilePath: instance.path, class: record.recordClass, format: record.format}

  const [progress, cancel] = useUploader(instance.fileToUpload, {
    jwt,
    params,
    onSuccess:onFinishUpload,
    onCancel,
    onFailure
  })

  useRegisterCancel((i) => {
    cancel()
  })

  React.useEffect(() => {
    setProgress(instance,progress)
  },[progress])

  return null

})
