import {Button, Dialog} from '@startlibs/components'
import React from 'react'
import styled from 'styled-components';
import { Card, PageContainer, PageFooter } from '../components/PageLayout';
import {Header} from '../components/Header'
import {PaymentCard} from '../request/payment/PaymentCard'
import {PurviewFooter} from '../components/PurviewFooter'
import {WAITING_MEDICAL_RECORDS} from '../enums/CaseState'
import {isDigitalPaymentPending} from '../request/utils'
import { PatientFeedback } from '../components/PatientFeedback';
import { lazyProviderInfo } from '../components/WithProvider';
import { getFirstPage } from './utils/patientUtils';

const PaymentListContainer = styled.div `
  & ~ & {
    margin-top: 2rem;
  }
`

export const PatientPayments = ({caseRequest, setCaseRequest, feedbackPopup, isRefPhysician}) => {

  const providerInfo = lazyProviderInfo.read()
  const canAccessCaseStatus = providerInfo?.patientAccessFunctions?.caseStatus

  const pendingPayments = caseRequest.payments.filter(isDigitalPaymentPending)
  const paidPayments = caseRequest.payments.filter(payment => payment.paid)

  return <><PageContainer>
    <Header title="Payment">
    </Header>

    <Card>
      {
        pendingPayments.length > 0 &&
        <PaymentListContainer>
          <p>This case has a payment due. Please check the details below to proceed with the payment:</p>
          <PaymentList payments={pendingPayments} caseRequest={caseRequest}/>
        </PaymentListContainer>
      }
      {
        paidPayments.length > 0 &&
        <PaymentListContainer>
          <p><b>Payment history:</b></p>
          <PaymentList payments={paidPayments} caseRequest={caseRequest}/>
        </PaymentListContainer>
      }
      </Card>
      {(caseRequest.state === WAITING_MEDICAL_RECORDS || caseRequest.caseFlags?.requestFlags?.askForPayments === true ) &&
        <PageFooter>
          {canAccessCaseStatus 
            ? <Button.Link to="/patient/status">Return to case status</Button.Link>
            : <Button.Link to={getFirstPage(caseRequest,providerInfo,isRefPhysician)}>Return</Button.Link>
          }
        </PageFooter>
      }
    </PageContainer>
    <PurviewFooter/>
    {feedbackPopup.isOpen && <Dialog title="Share feedback" closeDialog={feedbackPopup.close}>
      <PatientFeedback caseRequest={caseRequest} feedbackPopup={feedbackPopup} isPopup />
      </Dialog>
    }
  </>
}


export const PaymentList = ({caseRequest, payments}) =>
  payments.map(payment =>
    <PaymentCard
      payment={payment}
      caseRequest={caseRequest}
      hideDateAdded
      paymentButton={isDigitalPaymentPending(payment) &&
      <Button.Link highlight to={`/patient/checkout/${caseRequest.payments.indexOf(payment)}`}>Proceed to payment</Button.Link>}
    />)
