import {Button, Icon, Loading, SplitColumnsContainer} from '@startlibs/components';
import {getColor, getFetcher, parseDate} from '@startlibs/utils';
import {useToggle} from '@startlibs/core'
import {useParams} from 'react-router';
import React, {Suspense, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {AssignmentStatusBox,} from '../admin/steps/expertReview/ExpertReviewList';
import {Card, PageContainer, PageFooter, SectionHeading} from '../components/PageLayout';
import {ConfirmDialog, useConfirmDialog} from '../hooks/useConfirmDialog'
import {FieldDetail, ReadOnlyField} from '../request/RequestOverview';
import {GENDER_LABELS} from '../enums/Gender'
import {Header} from '../components/Header';
import {Navbar} from '../components/Navbar'
import {ProviderReleaseError} from './errors/ProviderReleaseError'
import {PurviewFooter} from '../components/PurviewFooter';
import {RecordsManager} from '../request/MedicalRecords'
import {ReleaseDetails, ReleaseFormDetails} from '../request/medicalRelease/ReleaseLocationCard';
import {CASE_ARCHIVED, CASE_CLOSED, CASE_REJECTED, CASE_REVIEWED, REQUEST_REJECTED, UNDER_REVIEW, WAITING_ACCEPTANCE, WAITING_MORE_INFORMATION} from '../enums/CaseState'
import {calculateAge, createDateReformatter} from '../utils/utils';
import {getJwt, setJwt} from '../hooks/useJwt'
import {isStateBefore} from '../request/utils'
import {jwtGetFetcher, jwtPostFetcher} from '../utils/authFetch'
import {lazyProviderInfo} from '../components/WithProvider'
import {willUseSuspense, WithLazyResource} from '../hooks/useSuspense'
import { getUploaderJwt, setUploaderJwt } from '../hooks/useUploaderJwt';
import { getStorageHost, setStorageHost } from '../hooks/useStorageHost';
import { RecordsManagerNew } from '../request/RecordsManagerNew';
import { PROVIDER } from '../enums/UserRoles';
import {FormattedMessage, useIntl} from 'react-intl';
import {defaultDateFormat} from "../utils/dateFormat";

const RequestDetailsCard = styled(Card)`
  position: relative;
  ${ReadOnlyField} {
    margin-bottom: 0;
  }
  ${SplitColumnsContainer} {
    padding-right: 13rem;
  }
  ${ReleaseFormDetails} {
    padding-top: 1.25rem;
    margin-top: 1.25rem;
  }
  .DocumentLink {
    text-decoration: none;
    color: ${getColor('main')};
    span {
      text-decoration: underline;
    }
    ${Icon} {
      vertical-align: text-top;
      font-size: 17px;
      text-decoration: none;
      margin-right: 4px;
    }
  }
  .view-auth {
    position: absolute;
    bottom: 3rem;
    right: 2.5rem;
    z-index: 2;
  }
`

const PatientCard = styled.div`
  display:block;
  background: white;
  border-radius: 5px;
  border: 1px solid ${getColor('gray210')};
  padding: 1rem;
  width: 100%;
  text-decoration: none;
  color: inherit;
  margin-bottom: 1rem;
  strong {
    font-size: 14px;
  }
`

const ExpiredLinkWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  text-align: center;
  max-width: 400px;
  ${Card} {
    margin-bottom: 1rem;
    padding-bottom: 4rem;
  }
  ${Icon} {
    font-size: 5rem;
    color: ${getColor('gray180')};
  }
  h1 {
    font-size: 18px;
    margin: 1rem auto;
    color: ${getColor('main')};
  }
  p {
    margin: 0 .25rem;
  }
`
const SuspendedLabel = styled(Button) `
  box-shadow: none;
  pointer-events: none;
  opacity: 0.75;
  position: absolute;
  top: 1.5rem;
  right: 2.5rem;
  z-index: 2;
  color: ${getColor('warning')};
  &:before {
    border-color: ${getColor('warning')};
  }
`

const useSuspense = willUseSuspense((code) =>
    
    Promise.all([
      getFetcher(`/api/locationForm/${code}`),
      getFetcher(`/api/uploaderTokenFromTimedLink/${code}`)
        .catch(([body, resp]) => {
          return resp.status === 401 
          ? Promise.resolve({}) 
          : Promise.reject([body, resp])})
    ]),
  true
)

const useMedicalRecordsSuspense = willUseSuspense(() =>
  jwtGetFetcher(getJwt())(`/api/timedMedicalRecords`)
)

const useMedicalRecordsSuspenseNew = willUseSuspense(() =>
    jwtGetFetcher(getJwt())(`/api/storage/timedMedicalRecords/${getUploaderJwt()}`) 
)

export const ProviderUploadRecords = () => {
  const {code} = useParams()
  return <ProviderReleaseError><AuthentitcatedProviderUploadRecords code={code} /></ProviderReleaseError>
}

const AuthentitcatedProviderUploadRecords = ({code}) => {
  const intl = useIntl()
  const [releaseInfo, {jwt, newUploader, uploaderJWT, storageHost}] = useSuspense(code)
  var caseUID = 0;
  if (newUploader){
    let responseJson = JSON.parse(uploaderJWT);
    setUploaderJwt(responseJson.jwt)
    setStorageHost(storageHost)
    caseUID = responseJson.caseUID
  }

  const providerInfo = lazyProviderInfo.read()
  
  setJwt(jwt)
  const dobDate = parseDate(releaseInfo.dob,new Date(),'MM-dd-yyyy')
  const yearsOld = calculateAge(dobDate)

  const dateFormatter = createDateReformatter('MM-dd-yyyy', defaultDateFormat(intl))
  const gender = GENDER_LABELS[releaseInfo.gender]
  const uploaderRef = useRef()
  const submitted = useToggle()

  const isAuthorized = releaseInfo.medicalRecordsReleaseLicense.signature

  const date = new Date()
  date.setHours(0)

  const expiredDate =  (releaseInfo.suspend || parseDate(releaseInfo.medicalRecordLocationItem.dateExpiration, date, 'MM-dd-yyyy').getTime() < date.getTime()) && !jwt
  const LockedStatusList = [CASE_ARCHIVED, REQUEST_REJECTED, CASE_REJECTED, WAITING_ACCEPTANCE, UNDER_REVIEW, WAITING_MORE_INFORMATION, CASE_REVIEWED, CASE_CLOSED]
  const lockedByStatus = LockedStatusList.includes(releaseInfo.caseState) || !jwt
  // const lockedByStatus = !isStateBefore(releaseInfo.caseState,WAITING_ACCEPTANCE) && !jwt
  
  const confirmFinish = useConfirmDialog(<ConfirmDialog
    title={intl.formatMessage({
      defaultMessage:"All complete",
      description:"Medical records request, upload confirmation dialog title"
    })}
    confirm={<Button highlight><FormattedMessage
      defaultMessage="Proceed"
      description="Medical records request, upload confirmation dialog proceed button"
    /></Button>}
    action={() => jwtPostFetcher(getJwt())("/api/notifyMedicalRecordUploadedByInstitution")}
    onSuccess={submitted.open}
    notify={intl.formatMessage({
      defaultMessage: "The institution was notified of the provided records.",
      description: "Medical records request, upload confirmation dialog success message"
    })}
  >
    <FormattedMessage
      defaultMessage={`<p>This will notify <strong>{providerName}</strong> that the requested records for the following patient have now been uploaded:</p>
          <PatientCard></PatientCard>
          <p>You grant <strong>{providerName}</strong> access to those records.</p>
          <p>Are you sure you want to proceed?</p>`
      }
      description="Medical records request, upload confirmation dialog content"
      values={{
        providerName: providerInfo.name,
        strong: (msg) => <strong>{msg}</strong>,
        p: (msg) => <p>{msg}</p>,
        PatientCard: () => <PatientCard className='fs-exclude'>
          <div><strong>{releaseInfo.firstName} {releaseInfo.middleName} {releaseInfo.lastName} (<FormattedMessage
            defaultMessage="{gender, select, Male {Male} Female {Female} Nonbinary {Non-binary} other {Other}}"
            description="Patient gender"
            values={{gender:gender?.replace("-",'')}}
          />)</strong></div>
          <div><FormattedMessage
            defaultMessage="Date of birth: {date}"
            description="Patient date of birth"
            values={{date: dateFormatter(releaseInfo.dob)}}
          /></div>
        </PatientCard>
      }}
    />

  </ConfirmDialog>)

  return <>
    <Navbar withLocale/>
    <PageContainer>
      <Header
        title={intl.formatMessage({
          defaultMessage: "Request for medical records",
          description: "Medical records request, header"
        })}
      />
      {!submitted.isOpen ? <>
          <SectionHeading>
            <h3>{
              (expiredDate || lockedByStatus)
                ? intl.formatMessage({
                  defaultMessage: "Medical records requested",
                  description: "Medical records request, expired or locked header"
                })
                : isAuthorized
                  ? intl.formatMessage({
                    defaultMessage: "Please provide medical records as described and authorized below",
                    description: "Medical records request, authorized header"
                  })
                  : intl.formatMessage({
                    defaultMessage: "Please provide medical records as described below",
                    description: "Medical records request, not authorized header"
                  })
            }</h3>
            <p><FormattedMessage
              defaultMessage="You have been requested to provide the following medical records in your possession:"
              description="Medical records request, instructions"
            /></p>
          </SectionHeading>
          <RequestDetailsCard>
            {
              expiredDate &&
               <SuspendedLabel expiredLabel outline small>
                 <FormattedMessage
                   defaultMessage="Expired authorization"
                    description="Medical records request, expired authorization label"
                 />
               </SuspendedLabel>
            }
            <SplitColumnsContainer>
              <ReadOnlyField>
                <label><FormattedMessage description='ProviderUploadRecords label records for' defaultMessage='Records for patient:' /></label>
                <FieldDetail className='fs-exclude'>{releaseInfo.firstName} {releaseInfo.middleName} {releaseInfo.lastName} (<FormattedMessage
                  defaultMessage="{gender, select, Male {Male} Female {Female} Nonbinary {Non-binary} other {Other}}"
                  description="Patient gender"
                  values={{gender:gender?.replace("-",'')}}
                />)</FieldDetail>
                <div><FieldDetail className='fs-exclude'>
                  <FormattedMessage
                    defaultMessage="Date of birth: {date}"
                    description="Patient date of birth"
                    values={{date: dateFormatter(releaseInfo.dob)}}
                  /> (<FormattedMessage
                    defaultMessage="{yearsOld} years old"
                    description="Patient age"
                    values={{yearsOld}}
                  />)</FieldDetail></div>
              </ReadOnlyField>
              {isAuthorized &&
              <ReadOnlyField>
                <label><FormattedMessage
                  defaultMessage="Between dates"
                  description="Medical records request, date range label"
                />:</label>
                <FieldDetail><FormattedMessage
                  defaultMessage="Start date"
                  description="Medical records request, date range start date label"
                />: {dateFormatter(releaseInfo.medicalRecordLocationItem.dateFrom)}</FieldDetail>
                <div><FieldDetail><FormattedMessage
                  defaultMessage="End date"
                  description="Medical records request, date range end date label"
                  />: {dateFormatter(releaseInfo.medicalRecordLocationItem.dateTo)}</FieldDetail>
                </div>
              </ReadOnlyField>
              }
            </SplitColumnsContainer>
            {
              isAuthorized && <>
                <Button.a
                  className="view-auth"
                  small
                  target="_blank"
                  href={"/provider/medicalRelease/" + releaseInfo.timedCode}
                ><FormattedMessage
                  defaultMessage="View"
                  description="View button"
                /></Button.a>
              </>
            }
            <ReleaseDetails release={releaseInfo} isExpired={expiredDate}/>
          </RequestDetailsCard>
        </>
        : <Card>
          <h3><FormattedMessage
            defaultMessage="The institution has been notified regarding the medical records you provided."
            description="Medical records request, notification success message"
          /></h3>
          <PatientCard className='fs-exclude'>
            <div><strong><FormattedMessage
              defaultMessage="Patient"
              description="Patient label"
              />: {releaseInfo.firstName} {releaseInfo.middleName} {releaseInfo.lastName} ((<FormattedMessage
              defaultMessage="{gender, select, Male {Male} Female {Female} Nonbinary {Non-binary} other {Other}}"
              description="Patient gender"
              values={{gender:gender?.replace("-",'')}}
            />))</strong></div>
            <div><FormattedMessage
              defaultMessage="Date of birth"
              description="Patient date of birth label"
              />: {dateFormatter(releaseInfo.dob)}</div>
          </PatientCard>
          <p><FormattedMessage
            defaultMessage="If you'd like to add more medical records still, please click below."
            description="Medical records request, add more records message"
          /></p>
          <Button onClick={submitted.close}><FormattedMessage
            defaultMessage="Add more medical records"
            description="Medical records request, add more records button"
          /></Button>
        </Card>}
      {
        jwt && <>
          <SectionHeading>
            <h3>
              {submitted.isOpen ? intl.formatMessage({
                defaultMessage: "Medical records files submitted",
                description: "Medical records request, files submitted header"
              }): intl.formatMessage({
                defaultMessage:"Medical record uploader",
                description:"Medical records request, uploader header"
              })}
            </h3>
            {!submitted.isOpen &&
            <p>
              <FormattedMessage
                defaultMessage={`If no additional instructions have been provided, please
              upload all medical records matching the criteria described above,
              including but not limited to exam results, pathology reports,
              medical reports, etc.`}
                description="Medical records request, uploader instructions"
              />
            </p>}
          </SectionHeading>
          <Card>
            <Suspense fallback={<Loading size={36} borderWidth={5} css="margin:3rem auto;"/>}>
            {newUploader ? 
              <WithMedicalRecordsNew>{(medicalRecords, setMedicalRecords, caseRequest) =>
                <RecordsManagerNew
                  allowReorder={false}
                  disabled={expiredDate || lockedByStatus}
                  uploaderRef={uploaderRef}
                  caseRequest={caseRequest}
                  medicalRecords={medicalRecords}
                  setMedicalRecords={setMedicalRecords}
                  caseId={caseUID}
                  listMode={expiredDate || lockedByStatus}
                  role={PROVIDER}
                  autoGrouping={false}
                />
              }</WithMedicalRecordsNew>
            :
              <WithMedicalRecords>{(medicalRecordsInfo, setMedicalRecords) =>
                <RecordsManager
                  disabled={submitted.isOpen}
                  uploaderRef={uploaderRef}
                  caseRequest={{medicalRecordsInfo}}
                  setMedicalRecords={setMedicalRecords}
                />
              }</WithMedicalRecords>
            }</Suspense>
          </Card>
        </>
      }
      {
        (expiredDate || lockedByStatus) &&
        <AssignmentStatusBox>
          <div className="waitingBoxContent">
            <Icon icon="clock"/>
            <h4>
              <FormattedMessage
                defaultMessage="It's no longer possible to submit medical records"
                description="Medical records request, expired or locked status title"
              />
            </h4>
            <p>
              {
                expiredDate
                ? intl.formatMessage({
                    defaultMessage: "This authorization has expired and it is no longer accepting new medical records",
                    description: "Medical records request, expired status message"
                })
                : intl.formatMessage({
                    defaultMessage: "This request is no longer accepting new medical records",
                    description: "Medical records request, locked status message"
                })
              }
            </p>
          </div>
        </AssignmentStatusBox>
      }
      {(!submitted.isOpen && jwt) && !(expiredDate || lockedByStatus) &&
      <PageFooter>
        <Button
          icon="email"
          highlight
          onClick={() => uploaderRef.current.confirm().then(confirmFinish)}
        ><FormattedMessage
          defaultMessage="Complete and notify institution"
          description="Medical records request, complete and notify button"
        /></Button>
      </PageFooter>
      }
    </PageContainer>
    <PurviewFooter/>
  </>
}
const WithMedicalRecords = ({children}) => {
  const [medicalRecords, setMedicalRecords] = useState(useMedicalRecordsSuspense())
  return children(medicalRecords, setMedicalRecords)
}

const WithMedicalRecordsNew = ({children}) => {
  const [medicalRecords, setMedicalRecords] = useState(useMedicalRecordsSuspenseNew())
  const caseRequest = {}
  caseRequest.medicalRecords = medicalRecords
  return children(medicalRecords, setMedicalRecords, caseRequest)
}
