import { Button, SplitColumnsContainer } from '@startlibs/components';
import {
  Combobox,
  Field,
  IconRadioBox,
  RadioboxGroup,
  ToggleCheckbox
} from '@startlibs/form';
import React from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components';
import {ConfirmDialog} from '../../../hooks/useConfirmDialog'
import {DATE_FORMAT_LABELS, DATE_FORMAT_OPTIONS} from '../../../enums/ReportDateFormat'
import {LOGO_INFO_CENTER, LOGO_LEFT_INFO_RIGHT, LOGO_RIGHT_INFO_LEFT} from '../../../enums/ReportHeaderLayout'
import {PAPER_SIZE_LABELS, PAPER_SIZE_OPTIONS} from '../../../enums/ReportPaperSize'
import {getJwt} from '../../../hooks/useJwt'
import {jwtFormFetcher} from '../../../utils/authFetch'
import {useIntl} from "react-intl";

const ReportLayoutRadioGroup = styled(RadioboxGroup)`
  display: flex;
  margin-bottom: 1.5rem;
  label {
    flex-basis: 33%;
    ~ label {
      margin-left: .5rem;
    }
  }
`

export const EditLayoutDialog = ({caseRequest,setCaseRequest,closeDialog}) => {
  const intl = useIntl()
  return <ConfirmDialog
    closeDialog={closeDialog}
    onSuccess={(values) => setCaseRequest(_.set('reportData',values))}
    values={caseRequest.reportData}
    title='Edit report layout preferences'
    confirm={<Button highlight>Save</Button>}
    action={jwtFormFetcher(getJwt())(`/api/admin/reportData?requestId=${caseRequest.requestId}`, {method:'PUT'})}
  >
    <Field label="Report layout preferences">
      <ReportLayoutRadioGroup
        path='reportLayout.headerLayout'
      >
        <IconRadioBox
          fieldValue={LOGO_LEFT_INFO_RIGHT}
          label="Logo to the left and info to the right"
          icon="hlogo-left"
        />
        <IconRadioBox
          fieldValue={LOGO_INFO_CENTER}
          label="Logo and info to the center"
          icon="hlogo-center"
        />
        <IconRadioBox
          fieldValue={LOGO_RIGHT_INFO_LEFT}
          label="Logo to the right and info to the left"
          icon="hlogo-right"
        />
      </ReportLayoutRadioGroup>
    </Field>

    <Field label="Include in the report:">

      {/* <ToggleCheckbox
        path='reportLayout.frontBackCover'
        label="Front and back cover"
      /> */}

      <ToggleCheckbox
        path='reportLayout.expertProfileDetails'
        label="Expert profile details"
      />
      <ToggleCheckbox
        path='reportLayout.disclaimerText'
        label="Disclaimer text"
      />
      {/* <ToggleCheckbox
        path='reportLayout.institutionDetails'
        label="Institution details footer in last page"
      /> */}

    </Field>

    <SplitColumnsContainer>
      <Field label="Paper size">
        <Combobox
          path='reportLayout.paperSize'
          options={PAPER_SIZE_OPTIONS}
          getLabel={v => PAPER_SIZE_LABELS[v]}
        />
      </Field>
      <Field label="Date format">
        <Combobox
          path='reportLayout.dateFormat'
          options={DATE_FORMAT_OPTIONS(intl)}
          getLabel={v => DATE_FORMAT_LABELS(intl)[v]}
        />
      </Field>
    </SplitColumnsContainer>

  </ConfirmDialog>
}