import {useNavigate, useLocation} from 'react-router'
import { Button, Dialog, DialogContent, Icon } from '@startlibs/components';
import {parseDate, smoothScroll} from '@startlibs/utils';
import {useToggle} from '@startlibs/core'
import React, {useEffect, useRef, useState} from 'react'
import _ from 'lodash/fp'
import { AssignmentStatusBox } from './expertReview/ExpertReviewList';
import {
  CASE_CLOSED,
  CASE_DRAFT,
  isPendingAskingMore,
  PENDING_REQUEST,
  UNDER_REVIEW,
  WAITING_ACCEPTANCE,
  WAITING_APPROVAL,
  WAITING_MEDICAL_RECORDS,
  WAITING_MORE_INFORMATION
} from '../../enums/CaseState';
import {Header} from '../../components/Header';
import {PageContainer, PageFooter} from '../../components/PageLayout';
import {PatientAndContactDetails} from '../../request/PatientAndContactDetails'
import {PurviewFooter} from '../../components/PurviewFooter';
import {WaitingMedicalRecordsBox} from './info/WaitingMedicalRecordsBox'
import {getJwt} from '../../hooks/useJwt'
// import {isPendingAskingMore} from '../../patient/utils/patientUtils'
import { isStateBefore } from '../../request/utils';
import {jwtFormFetcher, jwtGetFetcher, jwtPostFetcher} from '../../utils/authFetch'
import {useArchiveDialog} from '../hooks/useArchiveDialog'
import {useConfirmAskPatientUpdate} from './hooks/useConfirmAskPatientUpdate'
import {useConfirmExit} from './hooks/useConfirmExit'
import {useExitSave} from './hooks/useExitSave'
import {FormattedMessage, useIntl} from 'react-intl'
import { useSetConfirmExitPage } from '../../hooks/useConfirmExitPage';
import { ConfirmDialog, useConfirmDialogWithProps } from '../../hooks/useConfirmDialog';
import styled from 'styled-components';
import { CountryRegionData } from 'react-country-region-selector';

export const ConfirmChangesDialog = styled(ConfirmDialog)`
  max-width: 387px;
  ${DialogContent} {
    min-height: 0;
  }
`

export const PatientStep = ({caseRequest,setCaseRequest}) => {
  const location = useLocation()
  const navigate = useNavigate()
  const intl = useIntl()

  const [isSubmiting, setIsSubmiting] = useState(false)

  const formRef = useRef()
  const submitRef = useRef()
  const acceptLoading = useToggle()
  const acceptJurisdictionWarning = useToggle()

  const [acceptedJurisdictions, setAcceptedJurisdictions] = useState([])

  useEffect(() => {
  
    jwtGetFetcher(getJwt())("/api/admin/acceptedJurisdictions").then(jurisdictions => {

      const transformedJurisdictions = jurisdictions.map(jurisdiction => {
        let newJurisdiction = jurisdiction
        
        newJurisdiction.allAvailableStates = 
          CountryRegionData
            .find(country => {
              return country[0] === jurisdiction.country;
            })[2].split("|").map(state => state.split("~")[0]);
        
        return newJurisdiction;
      });
      setAcceptedJurisdictions(transformedJurisdictions);
      
    })
    .catch(e => {
      console.log(e)
      // setNotification({ type: "alert", msg: "Failed to load accepted jurisdictions" })
    })
    
  }, [])

  const [archiveReasons, setArchiveReasons] = useState([])
  
  const refreshArchiveReasons = () => {
    jwtGetFetcher(getJwt())('/api/admin/archiveReasons')
      .then(response => {
        setArchiveReasons(response)
      })
      .catch(err => {
        console.log(err)
      })
  }
  useEffect(() => {
    refreshArchiveReasons()
  },[])

  const action = jwtFormFetcher(getJwt())('/api/admin/caseRequest?requestId='+caseRequest.requestId,{method: "PUT"})
  // const accept = (values) => acceptLoading.wait(action(values).then(() => jwtPostFetcher(getJwt())('/api/admin/acceptCaseRequest?requestId='+caseRequest.requestId))).then(() => values)
  const accept = () => {

    const patientCountryAndStateEmpty = caseRequest?.patientInfo?.address?.country == '' && caseRequest?.patientInfo?.address?.state == ''
    const patientCountryAndStateNotExists= caseRequest?.patientInfo?.address == null
    const patientCountryAndStateNull= caseRequest?.patientInfo?.address?.country == null && caseRequest?.patientInfo?.address?.state == null
    const patientCountry = caseRequest?.patientInfo?.address?.country === 'USA' ? 'US' : caseRequest?.patientInfo?.address?.country
    const patientState = caseRequest?.patientInfo?.address?.state
    const patientCountryInList = patientCountry && CountryRegionData.find(country => country[0] === patientCountry || country[1] === patientCountry)
    const patientStateInList = patientState && patientCountryInList && patientCountryInList[2].split("|")
      .find(state => state.split("~")[0] === patientState || state.split("~")[1] === patientState)
    const fullCountryName = patientCountryInList && patientCountryInList[0]
    const fullStateName = patientStateInList && patientStateInList.split("~")[0]
    const showSelects = (!!fullCountryName && !!fullStateName) || (patientCountryAndStateEmpty) || (patientCountryAndStateNotExists) || (patientCountryAndStateNull)

    const country = formRef.current.getValue('patientInfo.address.country')
    const state = formRef.current.getValue('patientInfo.address.state')
    
    if(acceptedJurisdictions && acceptedJurisdictions.length > 0 && country && showSelects && state && !acceptedJurisdictions.find(jurisdiction => jurisdiction.country === country && jurisdiction.states.includes(state))){
      acceptJurisdictionWarning.open()
      return
    }
    
    formRef.current.willSubmitForm()
      .then(()=> jwtPostFetcher(getJwt())('/api/admin/acceptCaseRequest?requestId='+caseRequest.requestId))
    
  }
  const isPending = caseRequest.state === PENDING_REQUEST

  useEffect(() => {
    formRef.current.setHasChanged(false)
  }, [])


  // This auto saves (without validation) the case request when the user leaves the page / click back button / close tab/window
  // useExitSave(({state,...values}) => {
  //   setCaseRequest(_.assign(_,values))
  //   return action(values)
  // },formRef)

  // This works on default browser close tab/window
  useConfirmExit(formRef)

  const confirmExitDialog = useConfirmDialogWithProps(({exit, save}) => <ConfirmChangesDialog
      title={'Save changes'}
      closeButton={
        <Button hover="alert" onClick={exit}>Discard</Button>}
      alternativeButton={
        <Button onClick={() => {confirmExitDialog.close();}}>Continue editing</Button>}
      confirm={
        <Button
          success onClick={() => {
          confirmExitDialog.close();
          smoothScroll(submitRef.current);
          save().then(exit)
        }}>Save</Button>}
    >
      <p css="max-width: 24rem;">
        <b>{'There are unsaved changes on this page. Would you like to save them?'}</b>
      </p>
    </ConfirmChangesDialog>
  )

  const confirmExitPage = () => () => {
    if (!formRef.current) {
      return false
    }
    
    // var saveAction = () => {
    //   setIsSubmiting(true); 
    //   return action(formRef.current.getTransformedValues())
    //     .then(()=>{
    //       setIsSubmiting(false);
    //       setCaseRequest(_.assign(_,formRef.current.getTransformedValues()))
    //     })
    // }

    var saveAction = () => {
      setIsSubmiting(true); 
      return formRef.current.willSubmitForm()
      .then(()=>{
        setIsSubmiting(false);
        setCaseRequest(_.assign(_,formRef.current.getTransformedValues()))
      })
      .catch(()=>{
        setIsSubmiting(false);
        throw new Error('Please fill all required fields')
      })
    }
    
    
    if (formRef && formRef.current && formRef.current.hasChanged) {
      return new Promise((exit) => {
        confirmExitDialog.openWith({exit, save: saveAction})
      })
    }
  }

  const onSuccess = (values) => {
    if (isPending) {
      setCaseRequest(_.flow(
        _.assign(_,values),
      _.set('state',CASE_DRAFT)
      ))
    } else {
      setCaseRequest(_.assign(_,values))
    }
    navigate('../releases')
  }

  const rejectRequest = useArchiveDialog(caseRequest,() => {refreshArchiveReasons();navigate("/admin")}, archiveReasons)

  const confirmAskRecords = useConfirmAskPatientUpdate(caseRequest,setCaseRequest)

  const readOnly =  [UNDER_REVIEW,WAITING_MORE_INFORMATION,WAITING_MEDICAL_RECORDS,WAITING_APPROVAL,WAITING_ACCEPTANCE, CASE_CLOSED].indexOf(caseRequest.state) >= 0 || isPendingAskingMore(caseRequest)
  const wasSentToExpert = !isStateBefore(caseRequest.state)(WAITING_ACCEPTANCE)
  const caseCompletedWithoutReport = caseRequest.state === CASE_CLOSED && !caseRequest.report?.customReport && !caseRequest.report?.useCustomReport && !caseRequest.report

  useSetConfirmExitPage(confirmExitPage(false))
  return <><PageContainer>
    <Header title={<FormattedMessage
      description="PatientStep title"
      defaultMessage="Patient & contact details"
    />}>
      {
        (isPending && !readOnly)
        && <>
          <Button onClick={rejectRequest}>Decline and archive</Button>
          {/* <Button isLoading={acceptLoading.isOpen} onClick={() => formRef.current.willSubmitForm()} highlight>Accept for review</Button> */}
          <Button isLoading={acceptLoading.isOpen} onClick={() => accept()} highlight>Accept for review</Button>
        </>
      }
    </Header>
    <WaitingMedicalRecordsBox caseRequest={caseRequest} setCaseRequest={setCaseRequest} />
    {readOnly && wasSentToExpert &&
      <AssignmentStatusBox reviewed={caseRequest.state === CASE_CLOSED}>
        <div className="waitingBoxContent">
          <Icon icon={caseRequest.state === CASE_CLOSED ? 'check' : 'clock'}/>
          <h4>
            {caseRequest.state === CASE_CLOSED ? 'This case is already completed.' : 'Pending review'}
          </h4>
          <p>{caseRequest.state === CASE_CLOSED
            ? caseCompletedWithoutReport 
              ? 'The case has been marked as completed, but no report was generated.'
              : intl.formatMessage({
                defaultMessage:"The report is already concluded and available for the patient contact.",
                description:"Report concluded warning available to contact label"
              })
            : 'There is an expert currently reviewing this case.'}
          </p>
        </div>
      </AssignmentStatusBox>
    }
    <PatientAndContactDetails
      scrollToContact={location?.state?.scrollToContact}
      scrollToPrimaryConcern={location?.state?.scrollToPrimaryConcern}
      readOnly={readOnly}
      formRef={formRef}
      values={_.flow(_.unset('categories'),_.update('patientInfo.dob', (date) => parseDate(date, null, 'MM-dd-yyyy')))(caseRequest)}
      caseRequest={caseRequest}
      // action={isPending ? accept : action}
      action={action}
      onSuccess={onSuccess}
      isAdmin
      acceptedJurisdictions={acceptedJurisdictions}
    >{form =>
      <PageFooter>
        {
          (isPending && !readOnly)
            ? <>
            <Button onClick={rejectRequest}>Decline and archive</Button>
            {/* <Button ref={submitRef} isLoading={acceptLoading.isOpen} onClick={() => formRef.current.willSubmitForm()} highlight>Accept for review</Button> */}
            <Button ref={submitRef} isLoading={acceptLoading.isOpen} onClick={() => accept()} highlight>Accept for review</Button>
          </>
        : !readOnly && <Button ref={submitRef} isLoading={form.isLoading || isSubmiting} type="submit" highlight>Save and advance</Button>
        }
      </PageFooter>
    }</PatientAndContactDetails>
    {acceptJurisdictionWarning.isOpen && <Dialog
       title="Attention"
       closeDialog={acceptJurisdictionWarning.close}
       footer={<> 
        <Button onClick={acceptJurisdictionWarning.close}>Cancel</Button>
        <Button onClick={rejectRequest}>Reject</Button>
        <Button highlight onClick={() => {
          acceptJurisdictionWarning.close()
          formRef.current.willSubmitForm()
            .then(()=> jwtPostFetcher(getJwt())(`/api/admin/acceptCaseRequest?requestId=${caseRequest.requestId}&outside=true`))
          }}>Proceed</Button>
      </>}
    >
      <p><b>{formRef.current.getValue('patientInfo.address.state')} - {formRef.current.getValue('patientInfo.address.country')}</b> is not within your accepted jurisdictions.</p>
      <p>Do you want to proceed?</p>
    </Dialog>}

  </PageContainer>
  <PurviewFooter />
</>
}
