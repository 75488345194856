import {Button, Dialog} from '@startlibs/components'
import {Fill} from '@startlibs/core'
import {useNavigate} from 'react-router'
import {parseDate, will} from '@startlibs/utils'
import React, {useRef} from 'react'
import _ from 'lodash/fp'
import {Header} from '../components/Header'
import {LogoutButton} from '../components/Navbar'
import {isPendingAskingMore, PENDING_REQUEST, WAITING_MEDICAL_RECORDS} from '../enums/CaseState'
import {PageContainer, PageFooter} from '../components/PageLayout'
import {PatientAndContactDetails} from '../request/PatientAndContactDetails'
import {PurviewFooter} from '../components/PurviewFooter'
import {jwtFormFetcher} from '../utils/authFetch'
import {getJwt} from '../hooks/useJwt'
import {confirmAndNavigate} from '../hooks/useConfirmExitPage'
import {setNotification, willSetNotification} from '../components/Notifications'
import {useInvalidState} from './utils/useInvalidState'
// import {isPendingAskingMore} from './utils/patientUtils'
import { PatientFeedback } from '../components/PatientFeedback'

const formatCategories = _.update('categories', (categories) => (categories || []).filter(_.identity).map(({id})=> id))

export const PatientContactDetails = ({caseRequest, setCaseRequest, setLoggedin, feedbackPopup}) => {

  const readOnly = caseRequest.state !== WAITING_MEDICAL_RECORDS && !isPendingAskingMore(caseRequest)
  const formRef = useRef()
  const navigate = useNavigate()

  const action = (values) => jwtFormFetcher(getJwt())('/api/caseRequest', {method: "PUT"})(formatCategories(values))
  const onSuccess = ({state, ...values}) => setCaseRequest(
    _.flow(
      _.assign(_, values),
      _.set('caseFlags.patientDoneFlags.donePatientDetails',true)
    ))

  const invalidState = useInvalidState({setCaseRequest})

  return <>
    <Fill name="Navbar-Action">
      <LogoutButton onClick={confirmAndNavigate('/access',navigate)} navigate="/access">Discard request</LogoutButton>
    </Fill>
    <PageContainer>
      <Header
        title="Patient & contact details"
      >
      </Header>
      <PatientAndContactDetails
        formRef={formRef}
        // isPatient
        values={_.update('patientInfo.dob', (date) => parseDate(date, null, 'MM-dd-yyyy'), caseRequest)}
        caseRequest={caseRequest}
        action={action}
        onSuccess={_.flow(onSuccess, willSetNotification("Changes saved successfully."), will(navigate, "/patient/status"))}
        onFailure={(values,[,{status}]) => {
          if(status === 562){ 
            return invalidState() 
          }
          if(status === 569){
            navigate("/access")
            setNotification({type:"alert", msg:"This case is locked"})
          }
        }}
        readOnly={readOnly}
      >{form =>
        !readOnly &&
        <PageFooter>
          <Button.Link to="/patient/status">Cancel</Button.Link>
          <Button isLoading={form.isLoading} type="submit" highlight>Save changes</Button>
        </PageFooter>
      }</PatientAndContactDetails>
    </PageContainer>
    <PurviewFooter/>
    {feedbackPopup.isOpen && <Dialog title="Share feedback" closeDialog={feedbackPopup.close}>
        <PatientFeedback caseRequest={caseRequest} feedbackPopup={feedbackPopup} isPopup />
      </Dialog>
    }
  </>
}
