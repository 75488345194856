import {Button, Link} from '@startlibs/components'
import {TextInput} from '@startlibs/form'
import React from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components';
import {CaseRequestCard} from '../../CaseRequestCard'
import {ConfirmDialog, useConfirmDialog} from '../../../hooks/useConfirmDialog'
import {ContactCheckbox, ContactCheckboxGroup} from '../../../components/ContactCard'
import { ContactSelectorHeading } from './useConfirmFinishCase';
import {InfoBox} from '../../../components/InfoBox'
import {LOCAL_PHYSICIAN, REF_PHYSICIAN} from '../../../enums/ContactRelationType'
import {buildValidation} from '../../../utils/validation'
import {getContact, PRIMARY} from '../../../enums/ContactRelationKind'
import {getJwt} from '../../../hooks/useJwt'
import {jwtFormFetcher} from '../../../utils/authFetch'
import {PendingInfo} from '../info/PendingInfo'
import {useIntl} from 'react-intl'
import { lazyProviderInfo } from '../../../components/WithProvider'

export const PendingBox = styled(InfoBox)`
  ul {
    margin-bottom: .5rem;
    padding-left: 1.25rem;
  }
  li {
    list-style-type: disc;
  }
  li:only-child {
    list-style-type: none;
    margin-left: -1.25rem;
  }
`

export const useNotifyClosedCase = (caseRequest) => {

  const providerInfo = lazyProviderInfo.read()

  const caseContactsNotifications = providerInfo?.caseContactsNotifications

  const referringPhysicianAsSecondary = caseRequest.contactDetails.contactRelationType !== "REF_PHYSICIAN" && caseRequest.referringPhysician.email
  const localPhysicianAsSecondary = caseRequest.contactDetails.contactRelationType !== "LOCAL_PHYSICIAN" && caseRequest.localPhysician.email
  const intl = useIntl()

  const primary = getContact(intl, caseRequest, PRIMARY)
  const physician = getContact(intl, caseRequest, REF_PHYSICIAN)
  const localPhysician = getContact(intl, caseRequest, LOCAL_PHYSICIAN)

  const requiredCheck = (v, k, props) => (referringPhysicianAsSecondary || localPhysicianAsSecondary) && !props.sendToCaseContact && !props.sendToReferringPhysician && !props.sendToLocalPhysician && "No contact was selected."

  return useConfirmDialog(<ConfirmDialog
    confirmChanges={false}
    isLoading={false}
    notify="Email notification submitted."
    transform={_.update('sendToCaseContact', (v) => v || !referringPhysicianAsSecondary)}
    preValidation={buildValidation({'sendToCaseContact': requiredCheck, 'sendToReferringPhysician': requiredCheck, 'sendToLocalPhysician': requiredCheck})}
    title="Notify contact"
    action={jwtFormFetcher(getJwt())("/api/admin/sendReportNotification?requestId=" + caseRequest.requestId)}
    confirm={<Button highlight disabled={!caseContactsNotifications} icon="email">Notify</Button>}
  >
    <p>Inform selected contacts that the report for the following case is available:</p>
    <CaseRequestCard caseRequest={caseRequest}/>

    {!caseContactsNotifications 
      ? <InfoBox lightYellow>Communication is disabled</InfoBox>
      : <>
          <ContactSelectorHeading>
            {referringPhysicianAsSecondary || localPhysicianAsSecondary ?
            "Select which contact(s) you would like to notify:"
            : "An email will be sent to the following contact:"
            }
            <Link
              className="link"
              to={`/admin/request/${caseRequest.requestCode}/patient`}
              state={{scrollToContact: true}}
            >Edit contacts</Link>
          </ContactSelectorHeading>
          <ContactCheckboxGroup>
            <ContactCheckbox
              path="sendToCaseContact"
              email={primary.email}
              name={primary.name}
              key="patient"
              type={primary.relationType}
            />
            {referringPhysicianAsSecondary && <ContactCheckbox
                path="sendToReferringPhysician"
                name={physician.name}
                email={physician.email}
                type="Referring physician"
                key="Referring physician"
              />
            }
            {localPhysicianAsSecondary && <ContactCheckbox
                path="sendToLocalPhysician"
                name={localPhysician.name}
                email={localPhysician.email}
                type="Local physician"
                key="Local physician"
              />
            }
          </ContactCheckboxGroup>
          <TextInput
            label="Message"
            path="message"
            textarea
            autoResize
            descText="This will be included in the email notification."
          />
          <PendingInfo caseRequest={caseRequest} hideServiceTerms={!providerInfo.serviceTerms || !providerInfo.requiresAgreement}/>
        </>
    }
  </ConfirmDialog>)
}
