import React, {useEffect, useMemo} from 'react'
import {Routes, Route} from 'react-router'
import {Notifications as StartlibsNotifications} from '@startlibs/components'
import {callIfFunction, customTheme, wrapLazy} from '@startlibs/utils';
import {createGlobalStyle, ThemeProvider} from 'styled-components';
import {AdminRoutes} from './admin/AdminRoutes'
import {CheckScrollOnBottom} from './components/CheckScrollOnBottom'
import {ExpertRoutes, InitExpertJwt} from './expert/ExpertRoutes'
import { HelpButton, ResponsiveNotice } from './components/PageLayout';
import {LandingPage} from './pages/LandingPage'
import {MedicalReleaseDocument} from './pages/MedicalReleaseDocument'
import {Notifications} from './components/Notifications'
import {PageLoadingSuspense} from './components/PageLoading'
import {PatientRoutes} from './patient/PatientRoutes'
import {ProviderErrorBoundary} from './pages/errors/ProviderError'
import {ProviderTitle} from './components/ProviderTitle'
import {ProviderUploadRecords} from './pages/ProviderUploadRecords'
import {ShareRoutes} from './share/ShareRoutes'
import StartlibsConfig from './UIConfig/StartlibsConfig'
import StyledTheme from './UIConfig/StyledTheme'
import {AdminLogin} from './admin/AdminLogin'
import {SSONotFoundError} from './pages/errors/SSONotFoundError'
import {PreviewForReport} from './request/reviewedRequest/PreviewForReport'
import {lazyProviderInfo} from './components/WithProvider'
import {useFavicon} from './hooks/useFavicon'
import {IntlProvider, useIntl} from 'react-intl'
import {WithLazyResource} from './hooks/useSuspense'
import {BrowserRouter} from "react-router-dom";
import { Slot, StartlibsProvider, willUseSharedState } from '@startlibs/core';
import {Redirect} from "./components/Redirect";
import { CustomSafariStyle, ImageSlateStyle, TableSlateStyle } from './components/slateEditor/SlateStyles';
import { createCustomColorTheme } from './UIConfig/createCustomColorTheme';
import {I18NProvider} from "./components/I18NProvider";
import {getInitLocale, I18NInitEnable, shouldAddLocaleUrlParam} from "./utils/getInitLocale";

export const [usePreviewCase, , setPreviewCase] = willUseSharedState([])

const Identity = React.forwardRef(({children}, ref) => <React.Fragment>{children}
  <div ref={ref}/>
</React.Fragment>)

const DefaultStyle = createGlobalStyle`
  ${customTheme('DefaultStyle')};
`

const IntlStartlibsProvider = ({children}) => {
  const intl = useIntl()
  const startlibsConfig = useMemo(() => StartlibsConfig(intl),[intl.locale])
  return <StartlibsProvider value={startlibsConfig}>{children}</StartlibsProvider>
}

const INTL_PATHS = ['/expert/*','/provider/medicalRelease/*','/uploadMedicalRecords/*','/patient/*','/access/*']

const App = () => {
  // var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  var ua = navigator.userAgent;
  var isSafari165 = false
  var isSafari = /^((?!chrome|android).)*safari/i.test(ua);
  var safariVersion;

  if (isSafari) {
    var safariVersionMatch = ua.match(/Version\/(\d+\.\d+)/);
    if (safariVersionMatch && safariVersionMatch[1]) {
      safariVersion = parseFloat(safariVersionMatch[1]);
      if(safariVersion >= 16.5){
        isSafari165 = true
      }
    }
  }
  // const locale = new URLSearchParams(window.location.search).get("locale") || 'en-US' // en-US-LEGAL
  // const maxDuration = useToggle(1)
  // useEffect(() => {
  //   setTimeout(() => maxDuration.openWith(10000), 1000)
  // }, [])
  const [[previewCase,previewReady]] = usePreviewCase()
  const isPdfServer = process.env.PDF_SERVER


  // This has the default colors from expertview - Blue colors.
  // This will be passed to the theme when printing pdf - to prevent errors.
  const customColors = {
    main: "#008bd2",
    secondary: "#1F70A0",
    timeline: "#ddecf4",
    timelineCheck: "#008bd2", 
    enhancedContrastColor: "#0B67A2"
  }

  return <BrowserRouter><WithLazyResource fallback={null} value={lazyProviderInfo}>{providerInfo =>
  <ThemeProvider theme={createCustomColorTheme(previewCase ? customColors : providerInfo.customColors)}>
    <Routes>
      {INTL_PATHS.map(path => <Route key={path} path={path} element={<I18NInitEnable/>}/>)}
    </Routes>
    <I18NProvider
      defaultLocale={previewCase ? previewCase.info.locale || "en-US" : getInitLocale}
      withUrlParam={shouldAddLocaleUrlParam}
    >
    <IntlStartlibsProvider>
      <>
        <DefaultStyle />
        <TableSlateStyle />
        <ImageSlateStyle />
        {isSafari && isSafari165 && <CustomSafariStyle />}
        <CheckScrollOnBottom/>
        <ProviderTitle/>
        <ProviderErrorBoundary>
          <PageLoadingSuspense>
            {
              previewCase
                ? <PreviewForReport {...previewCase} previewReady={previewReady} isPdfPrinter/>
                : <Routes component={Identity}>
                  <Route path="/share/:jwt" element={<ShareRoutes login/>}/>
                  <Route path="/shared/:jwt" element={<ShareRoutes/>}/>
                  <Route path="/expert/*" element={<ExpertRoutes/>}/>
                  <Route path="/admin/*" element={<AdminRoutes />}/>
                  <Route path="/patient/*" element={<PatientRoutes />}/>
                  <Route path="/nurse/*" element={<PatientRoutes isNurse/>}/>
                  <Route path="/refphysician/*" element={<PatientRoutes isRefPhysician />}/>
                  <Route path="/access/*" element={<PatientRoutes />}/>
                  <Route path="/access-case" element={<PatientRoutes />}/>
                  <Route path="/invalid-login" element={<SSONotFoundError />}/>
                  <Route path="/provider/medicalRelease/:code" element={<MedicalReleaseDocument/>}/>
                  <Route path="/uploadMedicalRecords/:code" element={<ProviderUploadRecords/>}/>
                  <Route path="/" element={<LandingPage />}/>
                  {!isPdfServer && <Route path="*" element={<Redirect to="/" noThrow/>}/>}
                </Routes>
            }
          </PageLoadingSuspense>
        </ProviderErrorBoundary>
        <Notifications/>
        <StartlibsNotifications/>
        <React.Suspense fallback={null}><SetFavicon/></React.Suspense>
        <Routes component={Identity}>
          <Route path="/mobile-app/*" element={<Identity/>}/>
          <Route path="/cms-experts/*" element={<Identity />}/>
          <Route path="*" element={<HelpButton className="JS-fix-dialog-scroll"/>}/>
        </Routes>
        <div style={{position: 'absolute', top: 0, left: 0, right: 0, zIndex: 1000}}>
          <Slot name="Popup"/>
        </div>
        <div style={{position: 'absolute', top: 0, left: 0, right: 0, zIndex: 200}}>
          <Slot name="Portal"/>
          <Slot name="Dialog"/>
          <Slot name="Confirm-Dialog"/>
          <Slot name="Notification"/>
          <Slot name="Notifications"/>
        </div>
        {/* <ResponsiveNotice /> */}
      </>
    </IntlStartlibsProvider>
    </I18NProvider>
  </ThemeProvider>}
</WithLazyResource>
  </BrowserRouter>
}

const SetFavicon = () => {

  const {favicon} = lazyProviderInfo.read()

  useFavicon(favicon)
  return null
}

class EnsureLoggedIn extends React.Component {
  state = {}

  componentDidCatch(error) {
    if (error.invalidateCache) {
      callIfFunction(error.invalidateCache)
      this.setState({navigate: true})
    }
  }

  static getDerivedStateFromError(error) {
    return {hasError: true}
  }

  render() {
    return null
    // return !this.state.hasError
    //        ? <LoggedIn {...this.props}/>
    //        : this.state.navigate
    //          ? <Redirect to="/access"/>
    //          : null
  }
}

export default App
