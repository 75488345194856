import React from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components/macro'
import {getColor} from "@startlibs/utils";
import {lighten} from "polished";
import {Button, Dialog, Icon, Loading, Tooltip} from "@startlibs/components";
import {AttachmentBox, AttachmentDescription, AttachmentInfoContainer, ProgressBar} from "../AttachmentBoxStyles";
import {getFileStateLabel, IS_RETRY_POSSIBLE, Quarantined, Uploading} from "../../enums/FileState";
import {InfoBox} from "../ViewAllButton";
import {useUploadQueueSelector} from "../../service/hooks/useUploadQueueSelector";
import {mergeQueueRecord} from "../../service/useRecordStateManager";
import {Failed, Queued, Uploaded} from "../../service/enums/RecordStatus";
import {UploaderAction} from "../../service/UploaderAction";
import {useDoAction} from "../../service/hooks/useDoAction";
import {FormattedMessage, useIntl} from "react-intl";

const FilesListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`
const FilesList = styled.div`
  border-radius: 5px;
  height: 16rem;
  border: 1px solid ${getColor('gray210')};
  overflow: hidden;
  > div {
    overflow: auto;
    height: calc(100% + 1px);
  }
`
const FailedUploads = styled.div`
  color: ${getColor('alert')};
  margin-top: 1rem;
  font-weight: 600;
  margin-bottom: -1rem;
  background: ${props => lighten(0.51, props.theme.colors.alert)};
  border: 1px solid #e09f9f;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  ${Icon} {
    font-size: 18px;
    vertical-align: -4px;
    margin-right: 0.5rem;
  }
`

const queueRecordToInstances = ({failed = [], uploaded = [], uploading = [], files = []} = {}) =>
  [
    ...failed.map(_.set('status', Failed)),
    ...uploaded.map(_.set('status', Uploaded)),
    ...uploading.map(_.set('status', Uploading)),
    ...files.map(_.set('status', Queued))
  ]

export const RecordDetailsDialog = ({record,...props}) => {
  const uploadingRecord = useUploadQueueSelector(_.find(['key', record.key]))
  const recordFiles = mergeQueueRecord(record.queueHistory, uploadingRecord)

  return <RecordRowDetailsDialog record={record} recordFiles={recordFiles} {...props}/>
}
const RecordRowDetailsDialog = (
  {
    title,
    subtitle,
    closeDialog,
    record,
    cancelUpload,
    deleteFile,
    info,
    withCancelSingleFile,
    recordFiles
  }) => {

  const doAction = useDoAction()
  const instances = queueRecordToInstances(recordFiles)
  const hasOnlyFailedFiles = recordFiles.failed?.length === instances.length
  const total = instances.length
  const uploaded = recordFiles.uploaded?.length || 0
  const isFinished = record.status === Uploaded
  const progress = record.progress

  const retry = (file) => doAction(UploaderAction.RetryFile,record,file)
  const retryAll = () => doAction(UploaderAction.Retry,record)

  return <Dialog
    title={title}
    closeDialog={closeDialog}
    footer={<Button onClick={closeDialog}><FormattedMessage
      defaultMessage="Close"
      description="Close button"
    /></Button>}
  >
    {subtitle}
    {(info || record.description) &&
      <AttachmentBox css="padding:1rem;margin-bottom:1rem">
        <AttachmentInfoContainer>
          {
            info ||
            <AttachmentDescription className="fs-exclude" css="font-size:14px;">
              <b>{record.description}</b>
            </AttachmentDescription>
          }
        </AttachmentInfoContainer>
      </AttachmentBox>
    }
    {
      instances.length > 0 && <>
        {isFinished ?
          <FilesListHeader>
            <div><b><FormattedMessage
              defaultMessage="Upload completed"
              description="Uploader, record details dialog, upload completed"
            /></b> <FormattedMessage
              defaultMessage="({uploaded}/{total} files uploaded)"
              description="Uploader, record details dialog, files uploaded"
              values={{uploaded, total}}
            /></div>
          </FilesListHeader>
          : (hasOnlyFailedFiles ?
              <FilesListHeader>
                <div><b><FormattedMessage
                  defaultMessage="Files uploaded"
                  description="Uploader, record details dialog, files uploaded, only failures"
                /></b> ({uploaded}/{total})</div>
                <a className="link" onClick={cancelUpload}><FormattedMessage
                  defaultMessage="Cancel"
                  description="Cancel button"
                /></a></FilesListHeader>
              :
              <FilesListHeader>
                <div><b><FormattedMessage
                  defaultMessage="Uploading files"
                  description="Uploader, record details dialog, uploading files"
                /></b> <FormattedMessage
                  defaultMessage="({uploaded}/{total} uploaded)"
                  description="Uploader, record details dialog, files uploaded"
                  values={{uploaded, total}}
                /></div>
                <a className="link" onClick={cancelUpload}><FormattedMessage
                  defaultMessage="Cancel upload"
                  description="Uploader, record details dialog, cancel upload"
                /></a></FilesListHeader>
          )}
        <ProgressBar progress={progress} completed={isFinished} failed={hasOnlyFailedFiles}
                     css="margin: 0.5rem 0;"/>
        <FilesList>
          <div>
            {_.orderBy(['failed', 'filename'], ['asc', 'asc'], instances).map(instance =>
              <InstanceRow
                key={instance.key}
                instance={instance}
                retry={retry}
                isUploading={instance.status === Uploading}
                isQueued={instance.status === Queued}
                isDone={instance.status === Uploaded}
                deleteFile={deleteFile}
                cancelFile={withCancelSingleFile && (() => doAction(UploaderAction.CancelSingleFile,record,instance.file))}
                className="fs-exclude"
              />
            )}
          </div>
        </FilesList>
        {
          !!recordFiles.failed?.length &&
          <FailedUploads>
            <Icon icon="failure"/>
            <FormattedMessage
              defaultMessage="{failed} file {failed, plural, one {upload} other {uploads}} have failed."
              description="Uploader, record details dialog, failed uploads"
            />
            {
              !!recordFiles.failed?.find(IS_RETRY_POSSIBLE) &&
              <a className="link" onClick={retryAll}><FormattedMessage
                defaultMessage="Retry{failed, plural, one {} other { all}}"
                description="Retry button"
                values={{failed: recordFiles.failed.length}}
              /></a>
            }
          </FailedUploads>
        }
      </>
    }
    {
      recordFiles.quarantined?.length > 0 &&
      <InfoBox error withIcon css="margin-top: 1rem; font-weight: 600;">
        <Icon icon="warning"/>
        <FormattedMessage
          defaultMessage="This medical record is quarantined as {quarantined} uploaded {quarantined, plural, one {file} other {files}} are harmful"
          description="Uploader, record details dialog, quarantined files"
          values={{quarantined: recordFiles.quarantined.length}}
        />
      </InfoBox>
    }
  </Dialog>
}

const InstanceRow = styled(({instance, isUploading, isQueued, retry, cancelFile, deleteFile, className}) => {
  const intl = useIntl()
  return <div
    className={className}>
    <div>
      {instance.filename + "." + instance.extension}
      {instance?.metadata?.errorMessage?.length > 0 && <Tooltip content={instance.metadata.errorMessage}>
        <div><Icon icon="warning" style={{color: 'orange', fontSize: '18px', marginLeft: '0.4rem', verticalAlign: 'bottom'}}/></div>
      </Tooltip>}
    </div>
    {
      (instance.status === Failed || instance.quarantined)
        ? <span className="failed">
        {getFileStateLabel(intl, instance.failure || Quarantined)+" "}
          {!instance.quarantined && IS_RETRY_POSSIBLE(instance) &&
            <a className="link" css="margin: 0 .5rem;" onClick={() => retry(instance.file)}><FormattedMessage
              defaultMessage="Retry"
              description="Retry button"
            /></a>}
          {!instance.quarantined && cancelFile && <a className="link" onClick={cancelFile}><FormattedMessage
            defaultMessage="Cancel"
            description="Cancel button"
          /></a>}
          {instance.quarantined && deleteFile && <a className="link" onClick={() => deleteFile(instance)}><FormattedMessage
            defaultMessage="Delete"
            description="Delete button"
          /></a>}
    </span>
        : isUploading
          ? <span><b><Loading size={12} borderWidth={2}/><FormattedMessage
            defaultMessage="Uploading"
            description="Uploader, record details dialog, uploading"
          /></b></span>
          : isQueued
            ? <span><FormattedMessage
              defaultMessage="Waiting"
              description="Uploader, record details dialog, waiting"
            /></span>
            : <span><FormattedMessage
              defaultMessage="Uploaded"
              description="Uploader, record details dialog, uploaded"
            />{deleteFile && <a className="link" onClick={() => deleteFile(instance)}><FormattedMessage
              defaultMessage="Delete"
            description="Delete button"
            /></a>}</span>
    }
  </div>
})`
  padding: 1rem;
  border-bottom: 1px solid ${getColor('gray210')};
  display: flex;
  justify-content: space-between;
  span {
    flex-shrink: 0;
    margin-left: 1.5rem;
  }
  .failed {
    color: ${getColor('alert')};
  }
  ${Loading} {
    display: inline-block;
    margin-right: 4px;
    vertical-align: -1px;
  }
`