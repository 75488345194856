import React from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components';

import {Button, Icon} from '@startlibs/components';
import {customTheme, getColor, using} from '@startlibs/utils'

import {getFileStateLabel, IS_FAILURE} from '../../enums/FileState';
import {formatDateNoUTC} from '../../utils'
import {openDetails, useActivityLog} from '../hooks/useActivityLog'
import {UIAction} from "../../service/UIAction";
import {useRecordsSelector} from "../../service/hooks/useRecords";
import {useDoAction} from "../../service/hooks/useDoAction";
import {FormattedMessage, useIntl} from "react-intl";
import {defaultDateTimeFormat} from "../../utils/intlDateFormat";

const getDetailKey = (instane) => instane.recordKey || instane.studyUID

export const ActivityLog = () => {
  const intl = useIntl()
  const doAction = useDoAction()
  const close = () => doAction(UIAction.ToggleActivityLogDialog)

  const [activityLog] =  useActivityLog()

  return <ActivityLogContainer>
    <div className="main-header">
      <h3><FormattedMessage
        defaultMessage="Upload log"
        description="Uploader, activity log dialog, title"
      /></h3>
      <Button small onClick={close}><FormattedMessage
        defaultMessage="Return to records"
        description="Uploader, activity log dialog, close button"
      /></Button>
    </div>
    <div className="table-wrapper">
    <div className="row table-head">
      <div className="cell timestamp">
        <FormattedMessage
          defaultMessage="Time"
          description="Uploader, activity log dialog, time column"
        />
      </div>
      <div className="cell details">
        <FormattedMessage
          defaultMessage="File"
          description="Uploader, activity log dialog, file column"
        />
      </div>
      <div className="cell state">
        <FormattedMessage
          defaultMessage="Status"
          description="Uploader, activity log dialog, status column"
        />
      </div>
    </div>
    {
      _.orderBy(['updateDate'],['desc'],activityLog).map(logItem => using(getDetailKey(logItem))(detail =>
        <div className="row" key={logItem.key}>
          <div className="cell timestamp">{formatDateNoUTC(logItem.updateDate,defaultDateTimeFormat(intl,"-"))}</div>
          <div className="cell details">
            <span className="file-name fs-exclude">
              {logItem.file.name}
            </span>
            {false && detail && <a className="link" onClick={() => openDetails(detail)}>Details</a>}
          </div>
          <div className={"cell state " + (IS_FAILURE(logItem.state) ? "failure" : "")}>{IS_FAILURE(logItem.state) && <Icon icon="warning" />}{getFileStateLabel(intl, logItem.state)}</div>
        </div>
      ))
    }
  </div>
  </ActivityLogContainer>
}

const ActivityLogContainer = styled.div`
  .main-header {
    margin-top: -1rem;
    display: flex;
    align-items: center;
    padding: 1rem 0;
    border-bottom: 1px solid ${getColor('gray240')};
    background: white;
    position: sticky;
    top: 5.5rem;
    h3 {
      margin: 0 1rem 0 0;
      flex-grow: 1;
    }
  }
  .table-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .row {
      width: 100%;
      flex-basis: 100%;
      display: flex;
      padding: 1rem;
      border-bottom: 1px solid ${getColor('gray240')};
      &.table-head {
        font-weight: 600;
        color: ${getColor('gray60')};
        position: sticky;
        top: 115px;
        background: white;
      }
      .timestamp {
        flex-basis: 120px;
        flex-shrink: 0;
      }
      .details {
        flex-grow: 1;
        margin: 0 2rem;
        font-weight: 600;
        .file-name {
          color: ${getColor('gray120')};
        }
        a {
          margin-left: .5rem;
        }
      }
      .state {
        flex-shrink: 0;
        flex-basis: 130px;
        display: inline-flex;
        align-items: center;
        ${Icon} {
          font-size: 14px;
          margin-right: .5rem;
        }
        &.failure {
          color: ${getColor('alert')};
        }
      }
    }
  }
  ${customTheme("ActivityLogContainer")};
`
