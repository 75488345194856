import { SplitColumnsContainer } from '@startlibs/components'
import { Combobox, DatePicker, FormValue, RadioboxGroup, SimpleRadiobox, TextInput } from '@startlibs/form'
import { getColor } from '@startlibs/utils';
import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { Card, SectionHeading } from '../../components/PageLayout';
import { DATE_FORMAT } from '../../config/DateFormat'
import {FormattedMessage, useIntl} from 'react-intl'
import { ComboboxContainer } from './ContactDetails';
import { FEMALE, GENDER_LABELS, MALE, NONBINARY, OTHER } from '../../enums/Gender';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import { getFormattedStates } from '../PatientAndContactDetails';
import { InfoBox } from '../../components/InfoBox';
import { lazyProviderInfo } from '../../components/WithProvider';
import {defaultDateFormat} from "../../utils/dateFormat";
// import {useRecentlySaved} from '../associates/hooks/RecentlySaved'

export const AddressHeading = styled.div`
  font-size: 13px;
  color: ${getColor('gray150')};
  padding-bottom: .25rem;
  border-bottom: 1px solid ${getColor('gray210')};
  margin: 1.5rem 0;
`

export const StyledCountryDropdown = styled(CountryDropdown)`
  width: 100%;
  font-size: 14px;
  border: 1px solid #d2d2d2;
  border-radius: 6px;
  color: #3c3c3c;
  background: white;
  position: relative;
  line-height: 34px;
  display: block;
  height: 3rem;
  padding: 0 2.25rem 0 0.7rem;
  overflow: auto;
  outline: 0;
  outline: none;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  
  &:focus {
    border-color: ${getColor('gray180')};
    box-shadow: 0px 0px 0px 2px rgba(0,0,0,0.1);
  }
`
export const StyledRegionDropdown = styled(RegionDropdown)`
  width: 100%;
  font-size: 14px;
  border: 1px solid #d2d2d2;
  border-radius: 6px;
  color: #3c3c3c;
  background: white;
  position: relative;
  line-height: 34px;
  display: block;
  height: 3rem;
  padding: 0 2.25rem 0 0.7rem;
  overflow: auto;
  outline: 0;
  outline: none;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  
  &:focus {
    border-color: ${getColor('gray180')};
    box-shadow: 0px 0px 0px 2px rgba(0,0,0,0.1);
  }
`
export const StyledRegionsDiv = styled.div`
position: relative;
margin-bottom: 1rem;
text-align: left;
  label {
    font-weight: 500;
    display: inline-block;
    font-size: 14px;
    margin-bottom: 0.5rem;
    font-weight: 600;
  }
`

const VIEWPORT_MIN_WIDTH = 500

export const PatientDetails = ({ readOnly, isAdmin, form, countryList, showSelects, acceptedJurisdictions, isPatientCreating=false}) => {
  const intl = useIntl()
  const providerInfo = lazyProviderInfo.read()
  const captchaEnabled = providerInfo.captchaEnabled

  return <>
    <SectionHeading><h3>
      {
        <FormattedMessage
          defaultMessage='{isAdmin, select, true {Patient} false {Patient details} other {}}'
          description='PatientDetails title'
          values={{ isAdmin }}
        />
      }</h3></SectionHeading>
    <Card css="padding-bottom:1.5rem;">
      <SplitColumnsContainer viewportMinWidth={VIEWPORT_MIN_WIDTH} margin="big">
        <TextInput
          disabled={readOnly}
          type="text"
          path="patientInfo.firstName"
          label="First name"
          placeholder="Ex: John"
          maxLength={255}
          mandatory
        />
        <TextInput
          disabled={readOnly}
          type="text"
          path="patientInfo.middleName"
          label="Middle name"
          placeholder="Ex: James"
          maxLength={255}
        />
        <TextInput
          disabled={readOnly}
          type="text"
          path="patientInfo.lastName"
          label="Last name"
          placeholder="Ex: Doe"
          maxLength={255}
          mandatory
        />
      </SplitColumnsContainer>
      <SplitColumnsContainer viewportMinWidth={VIEWPORT_MIN_WIDTH} margin="big">
        <DatePicker
          disabled={readOnly}
          weekStartsMonday
          label="Date of birth"
          placeholder={`Ex: ${defaultDateFormat(intl).toUpperCase()}`}
          format={defaultDateFormat(intl)}
          path="patientInfo.dob"
          mandatory
        />
        <ComboboxContainer readOnly={readOnly} className='fs-exclude'><Combobox
          disabled={readOnly}
          className="combobox fs-exclude"
          placeholder="Please select"
          label={"Gender"}
          path='patientInfo.gender'
          options={[MALE, FEMALE, NONBINARY, OTHER]}
          getLabel={v => GENDER_LABELS[v]}
          mandatory
        /></ComboboxContainer>

        {/* <RadioboxGroup disabled={readOnly} mandatory label="Gender" horizontal path="patientInfo.gender" framedBoxes wide>
          <SimpleRadiobox fieldValue="M" label="Male" className='fs-exclude'/>
          <SimpleRadiobox fieldValue="F" label="Female" className='fs-exclude'/>
          <SimpleRadiobox fieldValue="O" label="Other" className='fs-exclude'/>
        </RadioboxGroup> */}
      </SplitColumnsContainer>
      <AddressHeading>
        <FormattedMessage
          defaultMessage='Patient mailing address'
          description='PatientDetails address heading'
          values={{ isAdmin }}
        />
      </AddressHeading>
      <SplitColumnsContainer viewportMinWidth={VIEWPORT_MIN_WIDTH} margin="big">
        {showSelects ? <>
          <ComboboxContainer readOnly={readOnly} className='fs-exclude'><Combobox
            // withWindowing={true}
            disabled={readOnly}
            className="combobox fs-exclude"
            placeholder="Please select country"
            label={"Country"}
            path='patientInfo.address.country'
            options={countryList}
            mandatory
            onChange={(val) => {
              var states = getFormattedStates(val)
              if(states.length > 0){
                if(states.findIndex(x => x === form.getValue('patientInfo.address.state')) == -1){
                  form.setValue('patientInfo.address.state', null)
                }
              }
            }}
          /></ComboboxContainer>
          <FormValue path="patientInfo.address.country">{country => 
            <ComboboxContainer readOnly={readOnly} className='fs-exclude'>
              <Combobox
                disabled={readOnly}
                className="combobox fs-exclude"
                placeholder="Please select state"
                label={"State/Province"}
                mandatory
                path='patientInfo.address.state'
                options={getFormattedStates(country)}
                // onChange={(val) => setRegion(val)}
              />
            </ComboboxContainer>
          }</FormValue>
        </> : 
        <>
          <TextInput
            disabled={readOnly}
            type="text"
            path="patientInfo.address.country"
            label="Country"
            placeholder="Ex: USA"
            maxLength={255}
            mandatory
          />
          <TextInput
            disabled={readOnly}
            type="text"
            path="patientInfo.address.state"
            label="State"
            placeholder="Ex: New York"
            maxLength={255}
            mandatory
          />
        </>}
      </SplitColumnsContainer>
      {isAdmin && acceptedJurisdictions && showSelects && acceptedJurisdictions.length > 0 && <FormValue path="patientInfo.address.country">{country => <>
        <FormValue path="patientInfo.address.state">{state => {
          return <>
            <div>{acceptedJurisdictions.find(jurisdiction => jurisdiction.country === country && jurisdiction.states.includes(state)) 
              ? null
              : (country != '' && state != '' && state != null && state != undefined && 
                  <InfoBox lightYellow style={{marginTop: '0.75rem', marginBottom: '1.5rem'}}>Attention: <b>{state} - {country}</b> is not within your accepted jurisdictions.</InfoBox>
                )
            }</div>
          </>
        }}</FormValue>
      </>}</FormValue>}
      <SplitColumnsContainer viewportMinWidth={VIEWPORT_MIN_WIDTH} margin="big">
        <TextInput
          disabled={readOnly}
          type="text"
          path="patientInfo.address.city"
          label="City"
          placeholder="Ex: New York"
          maxLength={255}
          mandatory
        />
        <TextInput
          disabled={readOnly}
          type="text"
          path="patientInfo.address.zipCode"
          label="Postal code"
          placeholder="Ex: 12345"
          maxLength={255}
          mandatory
        />
      </SplitColumnsContainer>
      <SplitColumnsContainer viewportMinWidth={VIEWPORT_MIN_WIDTH} margin="big">
        <TextInput
          disabled={readOnly}
          type="text"
          path="patientInfo.address.address"
          label="Street address - Line 1"
          placeholder="Ex: 001 Street Name"
          maxLength={255}
          mandatory
        />
        <TextInput
          disabled={readOnly}
          type="text"
          path="patientInfo.address.address2"
          label="Street address - Line 2"
          placeholder="Ex: PO BOX 001"
          maxLength={255}
        />
      </SplitColumnsContainer>
      {isPatientCreating && !captchaEnabled && <div aria-hidden="true" className="alternate-address"><TextInput
        disabled={readOnly}
        type="text"
        path="patientInfo.alternateAddress"
        label=" "
        placeholder="Ex: 001 Street Name"
        maxLength={255}
        autoComplete="Xas9d0a9sda20asdo82oasd921ada"
        // className="alternate-address"
        tabIndex="-1"
      /></div>}
      
      {/* Current */}
      {/* <TextInput
        disabled={readOnly}
        type="text"
        path="patientInfo.address.address"
        label="Street address"
        placeholder="Ex: 001 Street Name"
        maxLength={255}
        mandatory
      />
      <SplitColumnsContainer viewportMinWidth={VIEWPORT_MIN_WIDTH} margin="big">
        <TextInput
          disabled={readOnly}
          type="text"
          path="patientInfo.address.city"
          label="City"
          placeholder="Ex: New York"
          maxLength={255}
          mandatory
        />
        <TextInput
          disabled={readOnly}
          type="text"
          path="patientInfo.address.state"
          label="State"
          placeholder="Ex: New York"
          maxLength={255}
          mandatory
        />
      </SplitColumnsContainer>
      <SplitColumnsContainer viewportMinWidth={VIEWPORT_MIN_WIDTH} margin="big">
        <TextInput
          disabled={readOnly}
          type="text"
          path="patientInfo.address.country"
          label="Country"
          placeholder="Ex: USA"
          maxLength={255}
          mandatory
        />
        <TextInput
          disabled={readOnly}
          type="text"
          path="patientInfo.address.zipCode"
          label="Postal code"
          placeholder="Ex: 12345"
          maxLength={255}
          mandatory
        />
      </SplitColumnsContainer> */}
    </Card>
  </>
}
