import React from 'react'


const capitalize = (value) => value.slice(0,1).toUpperCase() + value.slice(1).toLowerCase()

const MONTHS = (intl) => ({
  '0': intl.formatMessage({defaultMessage:'january',description:'month of january'}),
  '1': intl.formatMessage({defaultMessage:'february',description:'month of february'}),
  '2': intl.formatMessage({defaultMessage:'march',description:'month of march'}),
  '3': intl.formatMessage({defaultMessage:'april',description:'month of april'}),
  '4': intl.formatMessage({defaultMessage:'may',description:'month of may'}),
  '5': intl.formatMessage({defaultMessage:'june',description:'month of june'}),
  '6': intl.formatMessage({defaultMessage:'july',description:'month of july'}),
  '7': intl.formatMessage({defaultMessage:'august',description:'month of august'}),
  '8': intl.formatMessage({defaultMessage:'september',description:'month of september'}),
  '9': intl.formatMessage({defaultMessage:'october',description:'month of october'}),
  '10': intl.formatMessage({defaultMessage:'november',description:'month of november'}),
  '11': intl.formatMessage({defaultMessage:'december',description:'month of december'}),
})

const WEEKDAYS = (intl) => ({
  '0': intl.formatMessage({defaultMessage:'sunday',description:'weekday of sunday'}),
  '1': intl.formatMessage({defaultMessage:'monday',description:'weekday of monday'}),
  '2': intl.formatMessage({defaultMessage:'tuesday',description:'weekday of tuesday'}),
  '3': intl.formatMessage({defaultMessage:'wednesday',description:'weekday of wednesday'}),
  '4': intl.formatMessage({defaultMessage:'thursday',description:'weekday of thursday'}),
  '5': intl.formatMessage({defaultMessage:'friday',description:'weekday of friday'}),
  '6': intl.formatMessage({defaultMessage:'saturday',description:'weekday of saturday'}),
})


export const i18nDateMessages = (intl) => (type, n, capitalized) => capitalized !== false ? capitalize(type === 'month'
  ? MONTHS(intl)[n]
  : WEEKDAYS(intl)[n]) : type === 'month'
  ? MONTHS(intl)[n]
  : WEEKDAYS(intl)[n]