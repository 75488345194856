import {Dialog, Button, Loading, Icon, ContextMenu, Li} from '@startlibs/components'
import { formatDate, getColor, getFetcher, postFetcher } from '@startlibs/utils'
import React, { useContext, useEffect, useState } from 'react'
import _ from 'lodash/fp'
import styled, { css } from 'styled-components';
import { FormValue, SimpleCheckbox, TextInput, WithForm } from '@startlibs/form';
import { usePopupToggle, useToggle } from '@startlibs/core';
import { setNotification } from '../../../../../src/javascripts/components/Notifications';
import { dicomDateToDate } from '../../utils';
import { AddPacsNodeDialog } from './AddPacsNodeDialog';
import { UploaderConfigContext } from '../../service/UploaderConfigContext';
import { jwtPostFetcher } from '../../utils/authFetch';
import { DicomRouterContext } from '../../../../../src/javascripts/utils/DicomRouterContext';
import { CREATED } from '../../../../../src/javascripts/utils/utils';
import { desaturate, lighten, transparentize, darken } from 'polished';
import {FormattedMessage, useIntl} from "react-intl";
import {defaultDateFormat} from "../../utils/intlDateFormat";

const LoadingDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`

const SearchInput = styled.div`
  position: relative;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 100;
  > input {
    word-break: normal;
    padding-right: 2.75rem;
    padding-left: 2.75rem;
    border-radius: 0px;
    border: none;
    border-bottom: 1px solid rgba(0,0,0,0.18);
    :focus {
      box-shadow: none;
      border-bottom: 1px solid rgba(0,0,0,0.18);
    }
  }
`

const ClearSearchButton = styled(Icon)`
  color: ${getColor('gray90')};
  font-size: 12px;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  width: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
  border-radius: 50%;
  background: rgba(0,0,0,0.1);
  :hover {
    background: #f7d3d4;
    color: ${getColor('alert')};
  }
`

const SearchIcon = styled(Icon)`
  position: absolute;
  z-index: 100;
  left: 8px;
  bottom: 7px;
  font-size: 22px;
  color: ${getColor('gray150')};
`

const EmptyList = styled.div `
  background: ${getColor('gray240')};
  color: ${getColor('gray120')};
  height: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  flex-grow: 1;
`

const StyledPacsRow = styled.div`
  cursor: pointer;
  &:nth-child(even) {
    background-color: ${props => desaturate(0.65, lighten(0.545, getColor('main')(props)))}
  }
  &:hover {
    background: ${props => transparentize(0.85, getColor('main')(props))};
  }
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  // justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid ${getColor('gray210')};
  padding: 1rem;
  &:last-child {
    border-bottom: 0px;
  }
  .info{
    padding-left: 0.5rem;
    width: 400px;
  }
  .info-box {
    width: 390px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.15rem;
  }
  ${props => props.selected && css`
    &:nth-child(odd), &:nth-child(even) {
      background-color: ${getColor('main')};
      color: white;
      &:hover {
        background: ${getColor('main')};
      }
    }
    ${DropdownButton} {
      background: rgba(255,255,255,0.2);
      color: white;
      :hover,:active {
        background: rgba(255,255,255,0.25);
      }
    }
  `}
  label {
    margin-bottom: 0;
  }
`

const ListArea = styled.div`
  border: 1px solid ${getColor('gray210')};
  border-radius: 6px;
  min-height: 200px;
  max-height: 320px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin-bottom: 1rem;
  .table-footer {
    padding: 0.5rem;
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    background: white;
    width: 100%;
    box-shadow: 0 -1px 0 0 rgb(210,210,210);
    border-radius: 0px 0px 5px 5px;
    position: sticky;
    bottom: -1px;
    margin-top: auto;
  }
  ::-webkit-scrollbar {
    width: 16px;
    height: 16px;
    background: rgba(0,0,0,0.05);
    border-radius: 0 5px 5px 0;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0,0,0,0.25);
    border-radius: 100px;
    background-clip: padding-box;
    border: 4px solid transparent;
    min-height: 30px;
    min-width: 30px;
    &:hover {
      background: rgba(0,0,0,0.35);
      background-clip: padding-box;
      border: 4px solid transparent;
    }
  }
`

const TableLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  h4 {
    margin-bottom: 0.5rem;
    font-size: 14px;
  }
`

const DropdownButton = styled(Icon)`
  ${Icon} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: 14px;
  }
  color: ${getColor('secondary')};
  cursor: pointer;
  border-radius: 50%;
  display: block;
  background: ${props => transparentize(0.875, darken(0.012, desaturate(0.4, getColor('main')(props))))};
  width: 2rem;
  height: 2rem;
  position: relative;
  flex-shrink: 0;
  :hover {
    background: ${props => transparentize(0.825, darken(0.012, desaturate(0.4, getColor('main')(props))))};
  }
  }
`

const MultipleSelected = styled.div`
width: 100%;
display: flex;
border: 1px solid ${getColor('gray210')};
border-radius: 6px;
color: ${getColor('gray90')};
padding: 1rem;
font-size: 15px;
font-weight: 600;
margin-bottom: 1.25rem;
justify-content: space-between;
`

export const DicomRouteDialog = ({studyList, closeDialog, firstDicomRouterJwt, previousDialog}) => {
  const intl = useIntl()
  const { dicomsRoutingPanelToggle, setRoutingDicomsList} = useContext(DicomRouterContext)

  const { dicomRouterJwt, setDicomRouterJwt } = useContext(DicomRouterContext)
  const [isLoading, setIsLoading] = useState(true)
  const [isDeleting, setIsDeleting] = useState(false)
  const [search, setSearch] = useState('')
  const [pacsNodes, setPacsNodes] = useState([

  ])
  const filteredPacsNodes = pacsNodes?.filter(pacsNode => pacsNode?.name?.toLowerCase()?.includes(search?.toLowerCase()))
  const [selectedPacsNodes, setSelectedPacsNodes] = useState([

  ])
  const config = useContext(UploaderConfigContext)
  const {
    canRouteDicoms,
    canCreatePacs,
    worklistViewerJwt: expertViewJwt,
    requestId
  } = config
  const [loadingError, setLoadingError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const addEditPacs = useToggle()
  const deletePacs = useToggle()
  const study = studyList.length === 1 ? studyList[0] : null

  let description = study 
    ? study?.info?.modalities + (study?.info?.modalities && " - ") + (study?.description || "DICOM Study") + " " + (study.info.studyDate ? ("(" + formatDate(dicomDateToDate(study?.info?.studyDate), defaultDateFormat(intl,"-"))+")") : '')
    : ''

    useEffect(() => {

      setDicomRouterJwt(firstDicomRouterJwt)

      // Function to refresh the token
      const refreshDicomRouterToken = () => {
        jwtPostFetcher(expertViewJwt)("/api/admin/dicomRouterToken")
          .then(r => {
            setDicomRouterJwt(r.jwt);
          })
          .catch(error => {
            console.error("Error refreshing token:", error);
          });
      };
  
      // Refresh the token immediately on component mount
      // refreshDicomRouterToken();
  
      // Set up the interval to refresh the token every 5 minutes 
      const intervalId = setInterval(refreshDicomRouterToken, 1000 * 60 * 4 );
  
      // Clear the interval when the component is unmounted or dependencies change
      return () => clearInterval(intervalId);
    }, [expertViewJwt, firstDicomRouterJwt]);
    
  useEffect(() => {
    setIsLoading(true)
    getFetcher((window.DICOM_ROUTER_ORIGIN || "")  +`/destinations?jwt=${firstDicomRouterJwt}`)
      .then((response) => {
        setPacsNodes(response)
      })
      .catch((err) => {
        setLoadingError(true)
        setErrorMessage(<FormattedMessage
          defaultMessage="<p>It was not possible to load the available PACS Nodes.</p><p>Please reload the page and try again.</p>"
          description="Uploader, dicom route dialog, no PACS nodes error message"
        />)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])
  
  return <>
    <WithForm
      action={(values) => {
      }}
      values={{selectedPacsNodes: []}}
      >{form =>
        <FormValue path="selectedPacsNodes">{ responsiveListOfSelectedPacsNodes =>
          <Dialog
            title={intl.formatMessage({
            defaultMessage: "DICOM Route",
            description: "Uploader, dicom route dialog, title"
          })}
            closeDialog={closeDialog}
            footer={() => {
              return <>
                <Button onClick={() => {
                  closeDialog();
                }}><FormattedMessage
                defaultMessage="Cancel"
                description="Cancel button"
              /></Button>
                <Button highlight
                  type="submit"
                  // disabled={selectedPacsNodes.length === 0}
                  // disabled={form.getValues('selectedPacsNodes')?.length === 0}
                  disabled={responsiveListOfSelectedPacsNodes?.length === 0}
                  onClick={() => {
                  
                    const selectedPacsNodes = form.getValues('selectedPacsNodes')
                    studyList.forEach(study => {
                      let description = study 
                        ? study?.info?.modalities + (study?.info?.modalities && " - ") + (study?.description || "DICOM Study") + " " + (study.info.studyDate ? ("(" + formatDate(dicomDateToDate(study?.info?.studyDate), defaultDateFormat(intl,"-"))+")") : '')
                        : ''
                      selectedPacsNodes.forEach(pacsNode => {
                        postFetcher(
                          (window.DICOM_ROUTER_ORIGIN || "") +`/dicom-routing?jwt=${dicomRouterJwt}`,
                          {
                            destinationId: pacsNode.id,
                            studyIUID: study?.studyUID,
                            studyDescription: description ? description : study?.study?.studyDescription,
                            patientName: study.info.patientName,
                            user: ''
                          },{
                            method: 'POST'
                          })
                          .then(
                            (response) =>{
                              
                              jwtPostFetcher(expertViewJwt)(`/api/audit/study/routing?requestId=${requestId}`, {
                                studyDescription: (description ? description : study?.study?.studyDescription) || "",
                                patientName: study.info.patientName || "",
                                studyUid:  study?.studyUID,
                                routingId: response,
                                pacsNodeId:  pacsNode.id,
                                pacsNodeName: pacsNode.name,
                                pacsNodeIp: pacsNode.aeHost,
                                pacsNodePort: pacsNode.aePort,
                                pacsNodeTitle: pacsNode.aeTitle || ""
                              })
                              
                              var newRouting = {
                                study: study,
                                pacsNode: pacsNode,
                                status: CREATED,
                                id: response
                              }


                              
                              setRoutingDicomsList(routingDicomsList => 
                                {
                                  // push new routing to list
                                  return [...routingDicomsList, newRouting]
                                }
                              )
                            }
                          )
                          .catch((err) => {
                            console.log(err)
                          })
                      })
                    })
                    setSelectedPacsNodes([])
                    setPacsNodes([])
                    dicomsRoutingPanelToggle.open()
                    closeDialog()
                  }}
                ><FormattedMessage
                defaultMessage="Send"
                  description="Send button"
              />
                </Button>
              </>
            }}
          >
            <MultipleSelected>
              <div>
                {study
                  ? description
                  : <FormattedMessage
                  defaultMessage="{studyListLength} studies selected"
                  description="Uploader, dicom route dialog, multiple studies selected"
                  values={{studyListLength: studyList.length}}
                />
                }
              </div>
              <a 
                className='link' 
                style={{cursor: 'pointer', textDecoration: 'none'}} 
                onClick={() => {
                  previousDialog.open()
                  closeDialog()
                }}
              >
                <b><FormattedMessage
                defaultMessage="Change selection"
                description="Uploader, dicom route dialog, change selection link"
              /></b>
              </a>
            </MultipleSelected>
            <>
              <TableLabel>
                <h4><FormattedMessage
                defaultMessage="PACS Nodes"
                description="Uploader, dicom route dialog, PACS nodes label"
              /></h4>
              </TableLabel>
              <ListArea>
                <SearchInput>
                  <SearchIcon icon="search" />
                  <TextInput raw
                    value={search}
                    setValue={setSearch}
                    placeholder={intl.formatMessage({
                    defaultMessage: "Search nodes",
                    description: "Uploader, dicom route dialog, search nodes placeholder"
                  })}
                  />
                  {search.length > 0 && <ClearSearchButton icon="x" onClick={() => {
                    setSearch('')
                    // setSelectedPacsNodes([])
                    // form.setValues(values => _.unset('selectedPatient', values))
                  }} />}
                </SearchInput>
                {isLoading
                  ? <LoadingDiv>
                    <Loading size={24} borderWidth={4} />
                  </LoadingDiv>
                  : <>
                    {loadingError
                      ? <EmptyList><div>{errorMessage}</div></EmptyList>
                      : <>
                        {pacsNodes.length === 0 && search.length === 0 && <>
                          <EmptyList><div><FormattedMessage
                          defaultMessage="No PACS Node added yet"
                          description="Uploader, dicom route dialog, no PACS nodes added yet"
                        /></div></EmptyList>
                        </>}
                        {filteredPacsNodes && filteredPacsNodes.length > 0 && <>

                          {filteredPacsNodes.map((node, i) =>
                            <FormValue path="selectedPacsNodes">{(s, setS) => {
                              return <PacsRow
                                // selected={selectedPacsNodes.findIndex(n => n.id === node.id) > -1}
                                // setSelectedPacsNodes={setSelectedPacsNodes}
                                selected={s && s?.findIndex(n => n.id === node.id) > -1}
                                selectedPacsNodes={s}
                                setSelectedPacsNodes={setS}
                                node={node}
                                form={form}
                                addEditPacs={addEditPacs}
                                deletePacs={deletePacs}
                                canCreatePacs={canCreatePacs}
                              />
                            }
                            }</FormValue>
                          )}
                        </>}
                        {search && search.length > 0 && filteredPacsNodes.length == 0 &&
                          <EmptyList><FormattedMessage
                          defaultMessage="No records found"
                          description="Uploader, dicom route dialog, no records found"
                        /></EmptyList>
                        }
                      </>}
                  </>
                }
              </ListArea>
            </>
            {canCreatePacs && <Button css="width:100%;" onClick={() => addEditPacs.open()}><FormattedMessage
            defaultMessage="Add PACS Node"
            description="Uploader, dicom route dialog, add PACS node button"
          /></Button>}
          </Dialog>
        }</FormValue>
    }</WithForm>
    {addEditPacs.isOpen &&
      <AddPacsNodeDialog 
        pacs={addEditPacs.isOpen} 
        closeDialog={addEditPacs.close} 
        isNew={addEditPacs.isOpen == true} 
        setPacsNodes={setPacsNodes}
        dicomRouterJwt={dicomRouterJwt}
      />
    }
    {deletePacs.isOpen &&
      <Dialog
        title={intl.formatMessage({
          defaultMessage: "Confirm delete",
          description: "Uploader, dicom route dialog, confirm delete title"
        })}
        closeDialog={deletePacs.close}
        footer={<>
          <Button onClick={deletePacs.close}><FormattedMessage
            defaultMessage="Close"
            description="Close button"
          /></Button>
          <Button highlight isLoading={isDeleting}
          onClick={() => { 
            setIsDeleting(true)
            postFetcher(
              (window.DICOM_ROUTER_ORIGIN || "")  +`/destinations/${deletePacs.isOpen?.id}?jwt=${dicomRouterJwt}`,
              null,{
                method: 'DELETE'
              })
              .then((response) => {
                setPacsNodes((prev) => {
                  return prev.filter(n => n.id !== deletePacs.isOpen?.id)
                })
                setNotification({
                  type: "success",
                  message: intl.formatMessage({
                    defaultMessage: "PACS node removed",
                    description: "Uploader, dicom route dialog, PACS node removed notification"
                  })
                })
                
                deletePacs.close()
              })
              .catch((err) => {
                console.log(err)
                setNotification({type:"alert",msg:<FormattedMessage
                    defaultMessage="It was not possible to remove the PACS node. Some DICOMs may have been routed to it."
                    description="Uploader, dicom route dialog, PACS node remove error message"
                  />})
              })
              .finally(() => {
                setIsDeleting(false)
              })
          }}><FormattedMessage
            defaultMessage="Yes, remove this PACS"
            description="Uploader, dicom route dialog, yes remove PACS button"
          /></Button>
        </>}
      >
        <FormattedMessage
          defaultMessage="<p>This will remove {pacsName}</p><p>Are you sure you want to remove this PACS node?</p>"
          description="Uploader, dicom route dialog, remove PACS node confirmation message"
          values={{pacsName: deletePacs.isOpen?.name}}
        />
    </Dialog>

    }
  </>

}

const PacsRow = ({selected, node, selectedPacsNodes, setSelectedPacsNodes, form, addEditPacs, deletePacs, canCreatePacs}) => {
  const intl = useIntl()
  const contextMenu = usePopupToggle()
  // const dob = dicomDateToDate(patient.patient.patientDOB)
  // const formattedDob = isNaN(dob.getTime()) ? "" : formatNoUTCDate(dob, DATE_FORMAT, undefined, true)

  return <StyledPacsRow selected={selected} 
    onClick={() => selected 
      // ? setSelectedPacsNodes(selectedPacsNodes => selectedPacsNodes.filter(n => n.id !== node.id))
      // : setSelectedPacsNodes(selectedPacsNodes => [...selectedPacsNodes, node])
      ?  setSelectedPacsNodes(selectedPacsNodes.filter(n => n.id !== node.id))
      : setSelectedPacsNodes([...selectedPacsNodes, node])
    }> 
      <SimpleCheckbox 
        // selected={selected} 
        // path="selectedPacsNodes" 
        // fieldValue={node}

        raw
        key={node.id}
        value={selectedPacsNodes.indexOf(node) >= 0}
        setValue={() => form.setValues(_.set(
          'selectedPacsNodes',
          selectedPacsNodes.indexOf(node) >= 0
            ? _.without([node], selectedPacsNodes)
            : [node, ...selectedPacsNodes]
        ))}
      />
    <div className='info'>
      <div><b>{node.name}</b></div>
      <div className='info-box'>
        <div><b><FormattedMessage
          defaultMessage="IP"
          description="Uploader, dicom route dialog, PACS node IP label"
          />:</b> {node.aeHost}</div>
        <div><b><FormattedMessage
          defaultMessage="Port"
          description="Uploader, dicom route dialog, PACS node port label"
          />:</b> {node.aePort}</div>
        <div><b><FormattedMessage
          defaultMessage="AET"
          description="Uploader, dicom route dialog, PACS node AET label"
          />:</b> {node.aeTitle}</div>
        {/* <div css="min-width:113px;"><b>DOB:</b> {formattedDob}</div> */}
      </div>
    </div>
    
    {canCreatePacs && <DropdownButton onClick={(e) => {
        contextMenu.open()
        e.preventDefault()
        e.stopPropagation()
      }}>
      <Icon icon="arrow-down"/>
      {contextMenu.isOpen &&
        <ContextMenu>
          <Li label={intl.formatMessage({
            defaultMessage: "Edit",
            description: "Uploader, dicom route dialog, edit PACS node context menu item"
          })} icon="edit" onClick={() => {addEditPacs.openWith(node)}}/>
          <Li label={intl.formatMessage({
            defaultMessage: "Remove",
            description: "Uploader, dicom route dialog, remove PACS node context menu item"
          })} icon="delete" onClick={() => {deletePacs.openWith(node)}}/>
        </ContextMenu>
      }
    </DropdownButton>}
    {/* <div className="check" css="position:relative">
      <PacsRadioBox selected={selected} path="selectedPatient" fieldValue={node}/>
      {selected && <Icon icon="check"/>}
    </div> */}
  </StyledPacsRow>
}
