import React from 'react'
import _ from 'lodash/fp'
import {
  CASE_ARCHIVED,
  CASE_CLOSED, CASE_DRAFT, isPendingAskingMore, PENDING_REQUEST, REQUEST_REJECTED,
  WAITING_APPROVAL,
  WAITING_MEDICAL_RECORDS,
  WAITING_MORE_INFORMATION
} from '../../enums/CaseState'
import {isReadOnlyShare} from './caseRequestUtils'
import { isStateBefore } from '../../request/utils'

// export const isPendingAskingMore = (caseRequest) => caseRequest.state === PENDING_REQUEST && Object.values(caseRequest.caseFlags?.requestFlags || {}).find(_.identity)

export const getFirstPage = (caseRequest, providerInfo, isRefPhysician) => {

  const readOnlyShare = isReadOnlyShare(caseRequest)
  const showTerms = providerInfo?.serviceTerms && !isRefPhysician

  const isCurrentStateBefore = isStateBefore(caseRequest && caseRequest.state)
  const disableTerms = isCurrentStateBefore(PENDING_REQUEST)
  
  const canAccessCaseStatus = providerInfo?.patientAccessFunctions?.caseStatus && !readOnlyShare
  const canAccessCaseOverview = providerInfo?.patientAccessFunctions?.caseOverview && !readOnlyShare
  const canAccessMessaging = providerInfo?.patientAccessFunctions?.messaging && !readOnlyShare
  const canAccessPatientContactDetails = providerInfo?.patientAccessFunctions?.patientContactDetails && !readOnlyShare
  const canAccessPayment = providerInfo?.patientAccessFunctions?.payment && !readOnlyShare
  const canAccessCaseReport = providerInfo?.patientAccessFunctions?.reportData && !readOnlyShare

  const isPendingAgreements = !caseRequest?.acceptanceInfo?.acceptedTerms || caseRequest.caseFlags?.requestFlags?.askForServiceTerms
  
  // const isPendingAgreements = caseRequest.caseFlags?.requestFlags?.askForServiceTerms
  
  // If there are agreements to be signed, after authentication we go directly to the Agreements menu. 
  // If there are no pending Agreements, the system will fall back to Medical records.
  if (readOnlyShare) {
    return '/patient/overview'
  } else if (canAccessCaseStatus && caseRequest.state === WAITING_MEDICAL_RECORDS || caseRequest.caseFlags?.requestFlags?.askForPayments || isPendingAskingMore(caseRequest)) {
    return '/patient/status'
  } else if (caseRequest.caseFlags?.requestFlags?.askedMedicalRelease && caseRequest.state === CASE_DRAFT) {
    return '/patient/release'
  } else if (canAccessCaseReport && caseRequest.state === CASE_CLOSED) {
    return '/patient/report'
  } else if (canAccessCaseStatus) {
    return '/patient/status'
  } else if (isPendingAgreements && showTerms && !disableTerms) {
    return '/patient/agreements'
  } else {
    return '/patient/records'
  }
  
}