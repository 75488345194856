import {Button} from '@startlibs/components';
import {getColor} from '@startlibs/utils';
import React from 'react'
import _ from 'lodash/fp';
import styled from 'styled-components';
import {CardWithIcon, CardWithIconMinHeigth, PageContainer, PageFooter, RequestStatusIcon} from '../../components/PageLayout';
import {Header} from '../../components/Header';
import {InfoBox} from '../../components/InfoBox';
import {PurviewFooter} from '../../components/PurviewFooter';
import {formatCurrency} from '../../utils/index';
import {lazyProviderInfo} from '../../components/WithProvider';
import {DIGITAL, OFFLINE} from '../../enums/PaymentMethod'
import { getPredefinedReasonLabel } from '../../enums/PredefinedReason';

const RequestCodeBox = styled.div`
  background: ${getColor('gray240')};
  border: 1px solid ${getColor('gray210')};
  border-radius: 6px;
  display: inline-flex;
  align-items: center;
  padding: 0.5rem 1rem;
  margin-bottom: 0.75rem;
  font-size: 16px;
  min-height: 4rem;
  width: 100%;
  font-size: 16px;
  max-width: 33rem;
  justify-content: space-between;
  flex-wrap: wrap;
  > div {
    margin: 0.5rem 1rem 0.5rem 0;
  }
  ${Button} {
    margin: 0.5rem 0;
    justify-self: flex-end;
    flex-shrink: 0;
  }
`

const RequestSubmittedPage = styled(PageContainer)`
  @media print {
    ${Button} {
      display: none;
    }
    ${RequestCodeBox} {
      max-width: auto;
      display: inline-block;
      width: auto;
    }
  }
`

export const PatientRejected = ({caseRequest}) => {

  const providerInfo = lazyProviderInfo.read()
  const offlineCharge = _.sum(caseRequest.payments.filter(payment => payment.paid && payment.paymentMethod === OFFLINE).map(payment => payment.chargeValue))
  const digitalCharge = _.sum(caseRequest.payments.filter(payment => payment.paid && payment.paymentMethod === DIGITAL).map(payment => payment.chargeValue))
  const allCharges = offlineCharge + digitalCharge

  const canAccessCaseOverview = providerInfo?.patientAccessFunctions?.caseOverview

  return <><RequestSubmittedPage>
      <Header
        title="Status"
      >
      </Header>
      <CardWithIconMinHeigth>
        <RequestStatusIcon icon="unavailable"/>
        <h3>This case has been archived</h3>
        {/* <h3>The institution is not moving forward with this case.</h3> */}
        {/* <p>Your request will not be reviewed by an expert this time.</p> */}
        {caseRequest.caseInfo?.declineReasonSharedPatient == true && <p>The reason provided by the institution was:</p>}
        {caseRequest.caseInfo?.declineReasonSharedPatient == true && <InfoBox>
          {getPredefinedReasonLabel(caseRequest?.caseInfo?.declinePredefinedReason)}
          {<br />}
          {caseRequest.caseInfo.declineReason}
        </InfoBox>}
        {allCharges > 0 &&
          <p>
            {offlineCharge === 0 ?
              <span>The <b>{providerInfo.currency.label}{formatCurrency(Number(digitalCharge))}</b> charged to your credit card will be refunded in the next few days.</span>
              : <span>Any payments you have made for this Expert View request will be refunded to you.</span>
            }
          </p>
        }
      </CardWithIconMinHeigth>
      <InfoBox lightBlue css="margin-top: -1rem;">
        Feel free to contact us if you have any questions: <strong>{providerInfo.address.phoneNumber}</strong>.
      </InfoBox>
      <PageFooter>
        {canAccessCaseOverview && <Button.Link to="/patient/overview">Go to overview</Button.Link>}
      </PageFooter>
  </RequestSubmittedPage>
  <PurviewFooter />
</>}
