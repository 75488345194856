import React, { useEffect } from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components/macro'
import {Button, Dialog, Loading} from "@startlibs/components";


import {useDoAction} from "../../service/hooks/useDoAction";

import {UIAction} from "../../service/UIAction";
import { UploaderAction } from '../../service/UploaderAction';
import { useToggle } from '@startlibs/core';
import { RecordDetailsDialog } from '../recordGroup/RecordDetailsDialog';
import { DicomDetailsInfoBox } from '../recordGroup/DicomRecordRow';
import {useIntl} from "react-intl";

styled

export const DicomDetailsDialog = ({data}) => {
  const intl = useIntl()
  const doAction = useDoAction()
  const closeDialog = () => doAction(UIAction.SetDicomDetailsDialog, false)

  
  const cancelLoading = useToggle()
  const deleteLoading = useToggle()
  
  const record = data.dialogs.dicomDetailsDialog

  const cancelDialog = () => doAction(UIAction.SetCancelDialog, record)
  
  return <RecordDetailsDialog
    title={intl.formatMessage({
      defaultMessage: "DICOM study details",
      description: "Uploader, DICOM study details dialog, title"
    })}
    closeDialog={closeDialog}
    cancelUpload={cancelDialog}
    record={record}
    withCancelSingleFile
    info={<DicomDetailsInfoBox record={record}/>}
  />
  
}
