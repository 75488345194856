import { Button, Icon } from '@startlibs/components';
import {useNavigate} from 'react-router'
import { smoothScroll } from '@startlibs/utils';
import {useToggle} from '@startlibs/core';
import React, {useEffect, useRef, useState} from 'react';
import _ from 'lodash/fp'
import {AddButton} from '../components/AddButton';
import {
  AssignmentStatusBox,
} from '../admin/steps/expertReview/ExpertReviewList';
import {
  CASE_ARCHIVED,
  CASE_CLOSED,
  CASE_REJECTED,
  CASE_REVIEWED,
  REQUEST_REJECTED,
  WAITING_ACCEPTANCE,
  WAITING_MEDICAL_RECORDS
} from '../enums/CaseState';
import {
  Card,
  PageContainer,
  PageFooter,
  SectionHeading
} from '../components/PageLayout';
import {ConfirmDialog, useConfirmDialog, useConfirmDialogWithProps} from '../hooks/useConfirmDialog'
import {Header} from '../components/Header'
import {MEDICAL_RELEASE_PATH} from '../request/medicalRelease/ReleaseLocationForms'
import {MedicalReleaseForm} from '../request/MedicalReleaseForm'
import {PageLoadingSuspense} from '../components/PageLoading'
import {PurviewFooter} from '../components/PurviewFooter'
import {ReleaseList} from './ReleaseList'
import {ReleaseLocationCard} from '../request/medicalRelease/ReleaseLocationCard'
import {getJwt} from '../hooks/useJwt';
import {isStateBefore} from '../request/utils';
import {jwtFormFetcher, jwtGetFetcher, jwtPostFetcher} from '../utils/authFetch'
import {locationIsNotEmpty} from '../request/forms/MedicalRecordLocationForm'
import { setNotification } from '../components/Notifications';
import {useSetConfirmExitPage} from '../hooks/useConfirmExitPage';
import {willUseSuspense} from '../hooks/useSuspense'
import { HOSPITALS, SCAN_FACILITIES, BIOPSY_FACILITIES, PHYSICIAN_OR_PROVIDER, OTHER_FACILITIES } from '../enums/MedicalRecordLocationType';
import { INSTITUTION, REVIEW, UPLOAD } from '../enums/UploaderManagement';
import { EmptyMedicalRecordsList } from '../request/RequestOverview';
import {FormattedMessage, useIntl} from "react-intl";

const EditItemButton = ({release, setRelease}) => {
  const intl = useIntl()
  const suspend = useConfirmDialog(<ConfirmDialog
      title={intl.formatMessage({
        defaultMessage: "Suspend authorization",
        description: "Medical release, suspend authorization dialog title"
      })}
    closeLabel={intl.formatMessage({
      defaultMessage:"Cancel",
      description: "Cancel button"
    })}
      action={() => jwtPostFetcher(getJwt())("/api/medicalRecordLocationForm", {locationFormId: release.id}, {method: "DELETE"})}
      confirm={<Button alert><FormattedMessage
        defaultMessage="Suspend authorization"
        description="Medical release, suspend authorization dialog, suspend button"
      /></Button>}
      onSuccess={() => setRelease(_.set('suspend', true))}
      notify={intl.formatMessage({
        defaultMessage: "Authorization suspended successfully.",
        description: "Medical release, authorization suspended successfully notification"
      })}
    >
    <FormattedMessage
      defaultMessage={`<p>You are about to suspend a medical records release authorization, meaning no new medical records requests to the following healthcare provider will be authorized:</p>
{releaseLocationCard}
<p>Are you sure you want to suspend this authorization?</p>
<p>Medical records provided prior to this date, as authorized by this institution, will still be valid and will not be revoked.</p>
`}
      description="Medical release, suspend authorization dialog content"
      values={{
        releaseLocationCard: <ReleaseLocationCard location={release.medicalRecordLocationItem}/>,
        p: msg => <p>{msg}</p>
      }}
      />

    </ConfirmDialog>
  )

  return <Button small alpha onClick={suspend}><FormattedMessage
    defaultMessage="Suspend authorization"
    description="Mdical release, suspend authorization button"
  /></Button>
}

const DeleteItemButton = ({release, setReleases}) => {
  const intl = useIntl()
  const suspend = useConfirmDialog(<ConfirmDialog
      title="Remove provider"
    closeLabel={intl.formatMessage({
      defaultMessage:"Cancel",
      description: "Cancel button"
    })}
      action={() => jwtPostFetcher(getJwt())("/api/medicalRecordLocationForm", {locationFormId: release.id}, {method: "DELETE"})}
      confirm={<Button alert><FormattedMessage
        defaultMessage="Remove"
        description="Remove button"
      /></Button>}
      onSuccess={() => setReleases(_.reject(_.matchesProperty('id', release.id)))}
      notify={intl.formatMessage({
          defaultMessage: "Provider successfully removed.",
        description: "Medical release, provider successfully removed notification"
      })}
    >
      <FormattedMessage
        defaultMessage={`<p>You are about to remove a healthcare provider from the list of authorized medical records release providers.</p>
{releaseLocationCard}
<p>Are you sure you want to proceed?</p>
<p>
  Medical records provided prior to this date, as authorized by
  this institution, will still be accessible.
</p>`}
description="Medical release, remove provider dialog content"
values={{
  releaseLocationCard: <ReleaseLocationCard location={release.medicalRecordLocationItem}/>,
  p: msg => <p>{msg}</p>
}}
        />
    </ConfirmDialog>
  )

  return <Button small alpha onClick={suspend}><FormattedMessage
    defaultMessage="Remove"
    description="Remove buttton"
  /></Button>
}

const getValues = (releases) => ({
  [MEDICAL_RELEASE_PATH]: _.reject(_.get('medicalRecordsReleaseLicense.signature'), releases)
    .map(({medicalRecordLocationItem, id}, i) => ({
        ...medicalRecordLocationItem,
        serverId: id,
        id: Date.now() + "-" + i
      })
    )
})
const useAuthSuspense = willUseSuspense((requestId) =>
  jwtGetFetcher(getJwt())(`/api/medicalRecordLocationForms`, {requestId})
)

export const PatientMedicalRelease = (props) => <>
  <LoadedPatientMedicalRelease {...props}/>
</>


export const LoadedPatientMedicalRelease = ({caseRequest, setCaseRequest, step, setStep, mode}) => {
  const intl = useIntl()
  const [releases, setReleases] = useState(useAuthSuspense(caseRequest.requestId))

  const refreshReleases = () =>
    jwtGetFetcher(getJwt())(`/api/medicalRecordLocationForms`, {requestId: caseRequest.requestId})
      .then(setReleases)


  const formRef = useRef()
  const navigate = useNavigate()
  const submitRef = useRef()

  const releasesActive = isStateBefore(caseRequest.state, WAITING_ACCEPTANCE) || caseRequest.state === CASE_REVIEWED

  const SHOW_ALL_EMPTY = {
    [MEDICAL_RELEASE_PATH]: [HOSPITALS, SCAN_FACILITIES, BIOPSY_FACILITIES, PHYSICIAN_OR_PROVIDER].map((locationType, i) => ({
      id: Date.now() + '-' + i,
      locationType
    }))
  }

  const addAuthorizations = useToggle(!releases.length && releasesActive)
  
  !addAuthorizations.isOpen && step === INSTITUTION && (releasesActive || releases.length > 0) && addAuthorizations.openWith(getValues(releases))
  addAuthorizations.isOpen && step === REVIEW && releases.length == 0 && addAuthorizations.close()
  const action = (values) => jwtFormFetcher(getJwt())('/api/medicalRecordLocationForm', {method: 'PUT'})(values)
    .then(() => setCaseRequest(_.unset('needReleases')))
    .then(refreshReleases)
    .then(addAuthorizations.close)

  const saveLoading = useToggle()
  const saveAction = () => formRef.current
    ? saveLoading.wait(jwtPostFetcher(getJwt())('/api/medicalRecordLocationForm', formRef.current.getTransformedValues(), {method: 'PUT'}))
    : Promise.resolve()

  const onSuccess = (values) => {
    setNotification("Changes saved successfully.")
    addAuthorizations.close()
    setStep(REVIEW)
    if (caseRequest.state === WAITING_MEDICAL_RECORDS) {
      navigate("/patient/status")
    } else {
      addAuthorizations.close()
    }
    setCaseRequest(_.flow(
      _.unset('needReleases'),
      _.set('caseFlags.patientDoneFlags.doneMedicalReleases', true),
    ))
  }

  const confirmExitDialog = useConfirmDialogWithProps(({exit, save, isSubmittingCase}) => <ConfirmDialog
      title={isSubmittingCase ? 'Would you like to save changes before submitting?' : 'Cancel adding records locations?'}
      closeButton={false}
      alternativeButton={<Button hover="alert" onClick={exit}>Discard changes to locations</Button>}
      confirm={<Button
        success onClick={() => {
        confirmExitDialog.close();
        smoothScroll(submitRef.current);
        save().then(exit)
      }}
      >Save locations</Button>}
    >
      <p>
        {isSubmittingCase ? 'There are unsaved changes in this page. Would you like to save them before submitting?'
          : 'You are about to leave this page without saving the changes to the medical records locations.'
        }
      </p>
      {!isSubmittingCase && <p>Are you sure you want to cancel adding these locations?</p>}
    </ConfirmDialog>
  )

  const confirmExitPage = (isSubmittingCase) => () => {
    if (!formRef.current) {
      return
    }
    const records = formRef.current.getValue(MEDICAL_RELEASE_PATH)
    if (!!records.find(locationIsNotEmpty)) {
      return new Promise((exit) => {
        confirmExitDialog.openWith({exit, save: formRef.current.submitForm, isSubmittingCase})
      })
    }
  }
  useSetConfirmExitPage(confirmExitPage(false))
  return <>
    
      {step !== INSTITUTION && mode !== UPLOAD &&
        <Header title={intl.formatMessage({
          defaultMessage: "Healthcare facilities",
          description: "Medical records release header"
        })}> </Header>
      }
      
      {/* {
        !releasesActive && <AssignmentStatusBox>
          <div className="waitingBoxContent">
            <Icon icon="clock"/>
            <h4>
              This case can no longer receive new medical records or releases
            </h4>
            <p>
              {caseRequest.state === (CASE_REVIEWED || CASE_CLOSED) ?
                "This case was already reviewed. "
                : caseRequest.state === (CASE_REJECTED || REQUEST_REJECTED || CASE_ARCHIVED) ?
                "" : "There is an expert reviewing the case. "
              }
              Requesting providers for records and editing releases are locked.</p>
          </div>
        </AssignmentStatusBox>
      } */}
      {(!releasesActive && releases.length === 0) && 
        <Card>
          <EmptyMedicalRecordsList><FormattedMessage
            defaultMessage="No facilities were added."
            description="Medical records release, no facilities were added message"
          /></EmptyMedicalRecordsList>
        </Card>
      }
      {
        ((!addAuthorizations.isOpen && (releasesActive || releases.length > 0)) && (step === REVIEW)) && <>
          <Card css={mode === UPLOAD ? "padding: 0; border: 0px;" : "" }>
            {releases.length  == 0 && <EmptyMedicalRecordsList><FormattedMessage
              defaultMessage="No facilities were added."
              description="Medical records release, no facilities were added message"
            /></EmptyMedicalRecordsList>}
            <ReleaseList
              isPatient
              setReleases={setReleases}
              releases={releases}
              caseRequest={caseRequest}
              suspendedButton={(releases) => <Button
                small alpha
                onClick={() => {addAuthorizations.openWith(getValues(releases));  setStep(INSTITUTION)}}
              ><FormattedMessage
                defaultMessage="Sign"
                description="Sign medical release button"
              /></Button>}
              editItemButton={(release, setRelease) =>
                release.medicalRecordsReleaseLicense?.signature
                  ? <EditItemButton release={release} setRelease={setRelease}/>
                  : <DeleteItemButton release={release} setReleases={(update) => setReleases(update)}/>
              }
            >{releases =>
              releasesActive && mode === UPLOAD &&
              <AddButton onClick={() => {addAuthorizations.openWith(getValues(releases)); setStep(INSTITUTION)}}><FormattedMessage
                defaultMessage="Add institution"
                description="Add institution button"
              /></AddButton>
            }</ReleaseList>
          </Card>
          {/* {caseRequest.state === WAITING_MEDICAL_RECORDS &&
            <PageFooter>
              <Button.Link to="/patient/status">Cancel</Button.Link>
              <Button
                onClick={() =>
                  jwtPostFetcher(getJwt())("/api/doneMedicalReleases")
                    .then(() => {
                      setCaseRequest(_.set('caseFlags.patientDoneFlags.doneMedicalReleases', true));
                      setNotification("Changes saved successfully");
                      navigate("/patient/status")
                      addAuthorizations.close()

                    })
                }
                highlight
              >Save</Button>
            </PageFooter>
          } */}
        </>
      }
      {
        // addAuthorizations.isOpen && step !== REVIEW &&
        addAuthorizations.isOpen && step !== REVIEW &&
        <MedicalReleaseForm
          setCaseRequest={setCaseRequest}
          withReleaseSignoff
          alwaysSave
          values={addAuthorizations.isOpen}
          optional
          formRef={formRef}
          caseRequest={caseRequest}
          onSuccess={onSuccess}
          action={action}
          step={step}
          mode={mode}
          toggle={() => {addAuthorizations.close(); setStep(REVIEW)}}
        >{(form, clearButton, isEmpty) =>
          <PageFooter>
            {
              isEmpty
                ? <>
                  <Button onClick={() => {addAuthorizations.close(); setStep(REVIEW)}}><FormattedMessage
                    defaultMessage="Skip"
                    description="Skip step button"
                  /></Button>
                </>
                : <>
                  <Button onClick={() => {addAuthorizations.close(); setStep(REVIEW)}}><FormattedMessage
                    defaultMessage="Cancel"
                    description="Cancel button"
                  /></Button>
                  {clearButton}
                  <Button
                    ref={submitRef}
                    highlight
                    type="submit"
                    isLoading={form.isLoading}
                  ><FormattedMessage
                    defaultMessage="Save"
                    description="Save button"
                  /></Button>
                </>
            }

          </PageFooter>
        }
        </MedicalReleaseForm>
      }
  </>
}
