import { Field } from '@startlibs/form';
import { Icon } from '@startlibs/components';
import React from 'react'
import styled from 'styled-components';
import {SignagureImageContainer, SignatureDate} from '../../../../request/Payment'
import {formatDateNoUTC} from '../../../../utils/utils'
import {FormattedMessage, useIntl} from "react-intl";
import {defaultDateTimeFormat, simpleDateTimeFormat} from "../../../../utils/dateFormat";

const ConfirmedByAdmin = styled.div `
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  b {
    margin-right: 0.5rem;
  }
  ${SignatureDate} {
    margin: 0.5rem 0;
  }
`

export const TermSignature = ({caseRequest, isPatient}) => {
  const intl = useIntl()
  return caseRequest.acceptanceInfo.externallyAccepted
    ? <ConfirmedByAdmin css="margin-bottom:0.5rem">
      <b><FormattedMessage
        defaultMessage="The administrator informed that you already agreed to this term."
        description="Patient service agreement, already agreed by admin"
      /></b>
      <SignatureDate><Icon icon="check"/><FormattedMessage
        defaultMessage="Marked as agreed on {date}"
        description="Patient service agreement, marked as agreed by admin info"
        values={{
          date: formatDateNoUTC(new Date(caseRequest.acceptanceInfo.acceptedTermsDate), defaultDateTimeFormat(intl))
        }}
      /></SignatureDate>
    </ConfirmedByAdmin>
    : <>{isPatient && <p><b><FormattedMessage
      defaultMessage="You already confirmed to have read and agreed to the above service agreement."
      description="Patient service agreement, already agreed by patient"
    /></b></p>}
      <SignagureImageContainer css="position: relative; margin-top: 0.5rem;">
        <img src={caseRequest.acceptanceInfo.signature}/>
        <SignatureDate><Icon icon="check"/>
          <FormattedMessage
            defaultMessage="Signed on {date}"
            description="Patient service agreement, signed date"
            values={{
              date: <span>{formatDateNoUTC(new Date(caseRequest.acceptanceInfo.acceptedTermsDate), simpleDateTimeFormat(intl))}</span>
            }}
          />
         </SignatureDate>
      </SignagureImageContainer>
    </>
}
