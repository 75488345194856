import { Icon } from '@startlibs/components';
import { getColor } from '@startlibs/utils';
import React from 'react'
import styled from 'styled-components';
import {CardHeader, SignInCard, SignInLayout} from '../../components/SigninLayout'
import {FormattedMessage} from "react-intl";

const EmailVerificationCard = styled(SignInCard)`
  max-width: 40rem;
  padding: 3rem 2rem;
  h3 {
    color: rgba(0,0,0,0.6);
  }
  p {
    margin: 0 auto;
    max-width: 23rem;
    color: rgba(0,0,0,0.6);
  }
  ${Icon} {
    font-size: 56px;
    color: rgba(0,0,0,0.2);
    margin: 1.5rem 0 0.5rem;
    display: block;
  }
`

const SuccessMessage = styled.div `
  padding: 1rem;
  background: ${getColor('success')};
  border-radius: 6px;
  color: white;
  font-size: 13px;
  max-width: 40rem;
  margin: 2rem auto 0;
  text-align: center;
`

export const EmailVerificationPage = ({providerInfo}) => {
  return <SignInLayout providerInfo={providerInfo}>
    <EmailVerificationCard>
      <CardHeader css="margin-bottom:0;">
        <h1><FormattedMessage
          defaultMessage="You need to complete your account"
          description="Email verification page title"
        /></h1>
        <Icon icon="email"/>
        <FormattedMessage
          defaultMessage={`<h3>We've sent you a registration email</h3>
        <p>Please access the provided email and click the registration link there to continue.</p>`}
          description="Email verification page message"
          values={{
            p: chunks => <p>{chunks}</p>,
            h3: chunks => <h3>{chunks}</h3>
          }}
          />
      </CardHeader>
    </EmailVerificationCard>
    <SuccessMessage><FormattedMessage
      defaultMessage="Registration link successfully sent to your e-mail."
      description="Email verification success message"
    /></SuccessMessage>
  </SignInLayout>
}