import { Button, Loading } from '@startlibs/components'
import { parseDate } from '@startlibs/utils'
import _ from 'lodash/fp'
import { useRefState, useToggle } from '@startlibs/core'
import React, { Suspense, useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { enums, Uploader, Uploader2 } from 'uploader-frontend'
// import {Uploader} from 'uploader-frontend-legacy'
import { Uploader as UploaderLegacy } from 'uploader-frontend-legacy'
import { CASE_CLOSED, CASE_REVIEWED } from '../enums/CaseState'
import { Card, SectionHeading } from '../components/PageLayout'
import { EMPTY_MEDICAL_RECORDS, EMPTY_MEDICAL_RECORDS_LOCATION_FORM } from './MedicalRecords'
import { NotProvided } from '../admin/steps/ExpertAssignmentStep'
import { authPostFetcher, jwtGetFetcher, jwtPostFetcher } from '../utils/authFetch'
import { getJwt } from '../hooks/useJwt'
import { downloadFiles, persistMedicalRecord, updateMedicalRecordsInfo, viewFileSrc } from './utils'
import {calculateAge, createDateReformatter, isRichText, safeMultipleNewLineToBr} from '../utils/utils'
import { fullNameToName, getRelationLabel } from '../enums/ContactRelationKind'
import { getUploaderJwt, setUploaderJwt } from '../hooks/useUploaderJwt'
import { useNotification } from '../components/Notifications'
import { PageLoadingSuspense } from '../components/PageLoading'
import { callIfFunction } from '@startlibs/utils'
import { LoadingPage } from 'sharecomponents/src/components/PageLoading'
import { getStorageHost, setStorageHost } from '../hooks/useStorageHost'
import {using} from '@startlibs/utils'
import { EXPERT } from '../enums/UserRoles'
import {IfLocale} from '../hocs/IfLocale'
import {FormattedMessage, useIntl} from 'react-intl'
import { PATIENT } from '../enums/ContactRelationType'
import { RichTextDisplay } from '../components/RichTextDisplay'
import { QuestionsAndAdditionalOverview } from './QuestionsAndAdditionalOverview'
import {defaultDateFormat, defaultReformatDate} from "../utils/dateFormat";


export const FieldsList = styled.div`
  margin: -0.5rem;
  ${props => props.twoColumns && `
    display: flex;
    flex-wrap: wrap;
    > * {
      flex-basis: 46%;
      flex-grow: 1;
      &.full-width {
        flex-basis: 100%;
      }
    }
    ${ReadOnlyField} {
      margin: 0.5rem 0.5rem 1.5rem 0.5rem;
    }
  `}
  .contact-relationship {
    text-transform: capitalize;
    :before {
      content: ' ';
    }
  }
  .address-concat {
    span ~ span:before {
      content: ', '
    }
  }
  .physician-contacts {
    span ~ span:before {
      content: ' | ';
    }
  }
`
const ReadOnlyQuestion = styled.div`
  font-size: 14px;
  font-weight: 600;
  label {
    display: block;
    margin-bottom: 0.25rem;
    font-size: 12px;
    color: rgba(0,0,0,0.4);
    font-weight: 400;
  }
  & ~ & {
    margin-top: 1rem;
  }
  .answer {
    font-weight: 400;
    margin-top: .25rem;
  }
`

export const ReadOnlyField = styled.div`
  font-size: 14px;
  margin-bottom: 1.5rem;
  label {
    display: block;
    font-weight: 600;
    margin-bottom: 0.25rem;
  }
`
export const FieldDetail = styled.span`
  color: rgba(0,0,0,0.4);
  font-size: 13px;
`

export const EmptyMedicalRecordsList = styled.div`
  text-align: center;
  color: rgba(0,0,0,0.4);
  padding: 1rem 0;
  font-size: 14px;
 `

export const SpacedDiv = styled.div`
  display: block;
  height: 460px;
`

const GENDER_LABELS = (intl) => ({
  M: intl.formatMessage({defaultMessage:"Male",description:"Gender label: male"}),
  F: intl.formatMessage({defaultMessage:"Female",description:"Gender label: female"}),
  O: intl.formatMessage({defaultMessage:"Gender: other",description:"Gender label: other"}),
  N: intl.formatMessage({defaultMessage:'Gender: Non-binary',description:"Gender label: non-binary"})
})
const IdentityFn = (({ children }) => children())

const apiEndpoints = {
  dicomViewer: (studies) => Promise.resolve({ viewerURL: getStorageHost()+`/view/records/${studies.filter(_.get('recordUID')).map(_.get("recordUID")).join("|")}?t=${getUploaderJwt()}` }),
  nonDicomViewer: (record) => getStorageHost()+`/view/record/${record.recordUID}?t=${getUploaderJwt()}`,
  shortTokenUrl: (requestId) => jwtGetFetcher(getJwt())(`/api/shortDownloaderToken`,{requestId: requestId}),
  downloadRecord: (record) => getStorageHost()+`/download/record/${record.recordUID}?t=${getUploaderJwt()}`,
  storageHost: () => getStorageHost(),
  loadDownloadRecord: (record, filename) => record.nonCompliantFiles
    ? jwtGetFetcher(getUploaderJwt())(getStorageHost()+`/download/async/nonDicoms/${record.nonCompliantFiles.map(_.get("recordUID")).join("-")}`).then(({ fileUID }) => ({ url: () => getStorageHost()+`/download/file/${fileUID}?t=${getUploaderJwt()}${filename ? '&originalFilename=' + encodeURIComponent(filename) : ""}` }))
    : jwtGetFetcher(getUploaderJwt())(getStorageHost()+`/download/async/${record.recordUID}`).then(({ fileUID }) => ({ url: () => getStorageHost()+`/download/file/${fileUID}?t=${getUploaderJwt()}${filename ? '&originalFilename=' + encodeURIComponent(filename) : ""}` }))
    ,
  downloadFiles: (shortJwt, records) => {downloadFiles(shortJwt, records)},
  viewFileSrc: (shortJwt, recordId) => viewFileSrc(shortJwt, recordId),
    
}

const fileFields = ['fileName', 'fileExtension', 'fileType', 'fileSize', 'uid', 'description', 'docType', 'locationFormId', 'uploadDate']


export const RequestOverview = ({ caseRequest, setCaseRequest, withoutRecords, allowUpload, allowDownload, allowDownloadMedicalImages, uploaderRef, setMedicalRecords, noContacts, withClinicalSummary, role, isReadOnlyShare = false, showHiddenRecords = true }) => {

  const unauthorizedRefresh = useToggle()
  const { medicalRecordsInfo } = caseRequest
  const [medicalRecordsNewUploader, setMedicalRecordsNewUploader] = useState()

  const hasMedicalRecords = medicalRecordsInfo && (
    medicalRecordsInfo.dicomStudies.length ||
    medicalRecordsInfo.nonCompliantDicom.length ||
    medicalRecordsInfo.nonDicomFiles.length
  )
  const dobDate = parseDate(caseRequest.patientInfo.dob, new Date(), "MM-dd-yyyy")

  // Ask for colaboration and new share will make use of permissions
  const showCaseOverview =  (!isReadOnlyShare || (isReadOnlyShare && caseRequest.permission.caseOverview))
  // If can add note, show all on the same page
  const showMedicalRecords =  !isReadOnlyShare || (isReadOnlyShare && caseRequest?.permission?.addNote)

    
  var caseUIDJson = ''
  const [caseUID, setCaseUID] = useState('')

  var listModeRes = ''
  var patientUID = ''
  var userRole = 0
  var storageHost = ''

  var allowReorder = false
  var autoGrouping = true
  const intl = useIntl()

  useEffect(() => {
    if (!hasMedicalRecords) {

      let refreshTimeout = -1
      jwtGetFetcher(getJwt())(`/api/storageToken`, caseRequest.requestId ? {requestId: caseRequest.requestId} : null)
        .then((response) => {

          let responseJson = JSON.parse(response.jwt);
          setStorageHost(response.storageHost)
          setUploaderJwt(responseJson.jwt)
          setCaseUID(responseJson.caseUID)
          caseUIDJson = responseJson.caseUID
          listModeRes = responseJson.listMode
          patientUID = responseJson.patientUID
          userRole = responseJson.userRole

          refreshTimeout = setInterval(() => {
            jwtGetFetcher(getUploaderJwt())(getStorageHost()+"/auth/refresh")
              .then(({ jwt }) => setUploaderJwt(jwt))
              .catch(([v, { status }]) => status === 401 && unauthorizedRefresh.open())
          }, 10 * 60 * 1000)

          return caseUIDJson
            ? jwtGetFetcher(getUploaderJwt())(getStorageHost()+"/case/" + caseUIDJson + (patientUID ? "?patientUID=" + patientUID : ""))
            : jwtGetFetcher(getUploaderJwt())(getStorageHost()+"/record/patient/" + patientUID).then(medicalRecords => ({ medicalRecords }))
        })
        .then((response) => {
          setCaseRequest(caseRequest => ({ ...caseRequest, medicalRecords: response.medicalRecords, group: response.group }));
        })

      return () => {
        clearInterval(refreshTimeout)
      }
    }
  }, [])

  const referringPhysician = caseRequest.referringPhysician

  const hasReferringPhyisicianAsSecondary = referringPhysician && (
    (fullNameToName(referringPhysician.fullName) || "").length > 0 ||
    (referringPhysician.email || "").length > 0 ||
    (referringPhysician.phoneNumber || "").length > 0
  )
  const medicalRecordsStore = useRefState(caseRequest.medicalRecordsInfo || EMPTY_MEDICAL_RECORDS)

  // const dobDate = parseDate(caseRequest.patientInfo.dob, new Date(), "MM-dd-yyyy")
  const patientAndContactDetails = <>
    <SectionHeading><h3><FormattedMessage description='RequestOverview patient details title' defaultMessage='Patient & contact details' /></h3></SectionHeading>

    <Card css="padding-bottom:1rem">
      <FieldsList twoColumns>
        <ReadOnlyField><label><FormattedMessage description="patient label" defaultMessage="Patient"/></label>
          <div className='fs-exclude'>
            <FormattedMessage
              defaultMessage="{firstName} {middleName} {lastName}"
              description="RequestOverview patient name"
              values={{
                firstName: (caseRequest.firstName || caseRequest.patientInfo.firstName),
                middleName: ((caseRequest.middleName ? caseRequest.middleName + ' ' : '') || (caseRequest.patientInfo?.middleName ? caseRequest.patientInfo?.middleName + ' ' : '')),
                lastName: (caseRequest.lastName || caseRequest.patientInfo?.lastName)
              }}
            />
            <FieldDetail> ({GENDER_LABELS(intl)[caseRequest.patientInfo.gender]})</FieldDetail></div>
        </ReadOnlyField>
        <ReadOnlyField><label><FormattedMessage
          defaultMessage="Date of birth"
          description="RequestOverview date of birth label"
        /></label>
          <div className='fs-exclude'>{defaultReformatDate(intl, "MM-dd-yyyy", caseRequest.patientInfo.dob)} <FieldDetail><FormattedMessage
            defaultMessage="({age} years old)"
            description="RequestOverview age label"
            values={{ age: calculateAge(dobDate) }}
          /></FieldDetail></div>
        </ReadOnlyField>
        {!noContacts && <>
          <ReadOnlyField><label><FormattedMessage
            defaultMessage="Address"
            description="RequestOverview address label"
          /></label>
            {caseRequest.patientInfo.address ? <>
                <div className='fs-exclude'>{caseRequest.patientInfo.address.address}</div>
                <div className="address-concat">
                  {caseRequest.patientInfo.address.city && <span className='fs-exclude'>{caseRequest.patientInfo.address.city}</span>}
                  {caseRequest.patientInfo.address.state && <span className='fs-exclude'>{caseRequest.patientInfo.address.state}</span>}
                  {caseRequest.patientInfo.address.country && <span className='fs-exclude'>{caseRequest.patientInfo.address.country}</span>}
                  {caseRequest.patientInfo.address.zipCode && <span className='fs-exclude'>{caseRequest.patientInfo.address.zipCode}</span>}
                </div>
              </>
              : <NotProvided/>
            }
          </ReadOnlyField>
          <ReadOnlyField><label><FormattedMessage
            defaultMessage="Contact"
            description="RequestOverview contact label"
          /></label>
            <div className='fs-exclude'>{caseRequest.contactDetails.email} | {caseRequest.contactDetails.phoneNumber}</div>
            {caseRequest?.contactDetails?.contactRelationType !== "PATIENT" &&
            <div><span className='fs-exclude'><FormattedMessage
              defaultMessage="Name:"
              description="RequestOverview contact name label"
            /> {fullNameToName(caseRequest?.contactDetails?.fullName)}</span>
              <IfLocale not contains="LEGAL">
                <FieldDetail className="contact-relationship">
                  ({caseRequest.contactDetails?.contactRelationType === "OTHER" ?
                  caseRequest.contactDetails?.otherRelationType?.toLowerCase()
                  : (caseRequest.contactDetails?.contactRelationType === "REF_PHYSICIAN" ?
                    // "Referring physician" : caseRequest.contactDetails.contactRelationType.toLowerCase())
                    "Referring physician" : getRelationLabel(intl,caseRequest?.contactDetails?.contactRelationType,caseRequest?.contactDetails?.otherRelationType) )
                })
                </FieldDetail>
              </IfLocale>
            </div>
            }
          </ReadOnlyField>
        </>}
        <IfLocale not contains="LEGAL">
          {(caseRequest.contactDetails.contactRelationType === "REF_PHYSICIAN" || hasReferringPhyisicianAsSecondary) &&
            <>{noContacts ?
              <>{(caseRequest.contactDetails.contactRelationType === "REF_PHYSICIAN" && fullNameToName(caseRequest.contactDetails.fullName) || fullNameToName(caseRequest.referringPhysician.fullName)) && <>
                <ReadOnlyField><label><FormattedMessage
                  defaultMessage="Referring physician"
                  description="RequestOverview referring physician label"
                /></label>
                  {caseRequest.contactDetails.contactRelationType === "REF_PHYSICIAN" ?
                    <div>{fullNameToName(caseRequest.contactDetails.fullName)}</div> : (fullNameToName(caseRequest.referringPhysician.fullName) &&
                      <div>{fullNameToName(caseRequest.referringPhysician.fullName)}</div>)
                  }
                </ReadOnlyField>
              </>}</>
              :
              <ReadOnlyField><label><FormattedMessage
                defaultMessage="Referring physician"
                description="RequestOverview referring physician label"
              /></label>
                {caseRequest.contactDetails.contactRelationType === "REF_PHYSICIAN" ?
                  <div>{fullNameToName(caseRequest.contactDetails.fullName)}</div> : (fullNameToName(caseRequest.referringPhysician.fullName) &&
                    <div>{fullNameToName(caseRequest.referringPhysician.fullName)}</div>)
                }
                <div>{caseRequest.contactDetails.contactRelationType === "REF_PHYSICIAN" ?
                  <span>{caseRequest.contactDetails.email} | {caseRequest.contactDetails.phoneNumber}</span>
                  :
                  <span className="physician-contacts">
                          {caseRequest.referringPhysician.email && <span>{caseRequest.referringPhysician.email}</span>}
                    {caseRequest.referringPhysician.phoneNumber &&
                    <span>{caseRequest.referringPhysician.phoneNumber}</span>}
                        </span>
                }</div>
              </ReadOnlyField>
            }</>
          }
        </IfLocale>
      </FieldsList>
    </Card>
  </>

  const primaryConcern = showCaseOverview && <>
    <SectionHeading>
      <h3>
        <FormattedMessage description='RequestOverview primary concern title' defaultMessage='Primary concern' /> 
      </h3>
    </SectionHeading>
    <Card css="padding-bottom:1rem">
      {caseRequest.caseInfo.intakeDiagnosis && <ReadOnlyField className='fs-exclude'>
        <IfLocale not contains="LEGAL"><label><FormattedMessage
          defaultMessage="Primary diagnosis:"
          description="RequestOverview primary diagnosis label"
        /></label></IfLocale>
        {caseRequest.caseInfo.intakeDiagnosis}
      </ReadOnlyField>}

      {/* <ReadOnlyField>
        <IfLocale not contains="LEGAL"><label>Reason for seeking this medical opinion:</label></IfLocale>
        <div dangerouslySetInnerHTML={{ __html: safeMultipleNewLineToBr(caseRequest.caseInfo.clinicalSummary) }}/>
      </ReadOnlyField> */}
      <IfLocale not contains="LEGAL">
        <ReadOnlyField className='fs-exclude'>
          <label>
            {caseRequest.caseInfo.hasBeenTreatedOrSurgeryBefore
              ? <FormattedMessage
                defaultMessage="Patient has been treated or had surgery for this condition already."
                description="RequestOverview patient has been treated or had surgery message"
              />
              : <FormattedMessage
                defaultMessage="Patient has not been treated nor had surgery for this condition yet."
                description="RequestOverview patient has not been treated nor had surgery message"
              />
            }
          </label>
          {/* {caseRequest.caseInfo.hasBeenTreatedOrSurgeryBefore && caseRequest.caseInfo.treatedOrSurgeryBeforeDescription} */}
          {caseRequest.caseInfo.hasBeenTreatedOrSurgeryBefore && 
            <div dangerouslySetInnerHTML={{ __html: safeMultipleNewLineToBr(caseRequest.caseInfo.treatedOrSurgeryBeforeDescription) }}/>
            }
        </ReadOnlyField>
        {caseRequest.caseInfo.hasNewDiagnosisChangedTreatmentOrRecommendation !== null && <ReadOnlyField className='fs-exclude'>
          <label>
            {caseRequest.caseInfo.hasNewDiagnosisChangedTreatmentOrRecommendation
              ? <FormattedMessage
                defaultMessage="Patient had new diagnosis or had changed treatment/recommendation for this condition."
                description="RequestOverview patient had new diagnosis or had changed treatment message"
              />
              : <FormattedMessage
                defaultMessage="Patient did not have new diagnosis nor had changed treatment/recommendation for this condition."
                description="RequestOverview patient did not have new diagnosis nor had changed treatment message"
              />
            }
          </label>
          {caseRequest.caseInfo.hasNewDiagnosisChangedTreatmentOrRecommendation && caseRequest.caseInfo.newDiagnosisChangedTreatmentOrRecommendationDescription}
        </ReadOnlyField>}
      </IfLocale>
      <ReadOnlyField className='fs-exclude'>
        <IfLocale not contains="LEGAL"><label><FormattedMessage
          defaultMessage="Reason for seeking this medical opinion:"
          description="RequestOverview reason for seeking this medical opinion label"
        /></label></IfLocale>
        <div dangerouslySetInnerHTML={{ __html: safeMultipleNewLineToBr(caseRequest.caseInfo.clinicalSummary) }}/>
      </ReadOnlyField>
    </Card>
  </>

  const clinicalSummary = withClinicalSummary && showCaseOverview &&
    <>
      <SectionHeading><h3><FormattedMessage description='RequestOverview clinical summary title' defaultMessage='Clinical summary' /></h3></SectionHeading>
      <Card css="padding-bottom:1rem">
        {/* <ReadOnlyField dangerouslySetInnerHTML={{ __html: safeMultipleNewLineToBr(caseRequest.caseInfo.caseSummary) }} /> */}
        <ReadOnlyField className='fs-exclude'>
          <RichTextDisplay css="font-size: 14px;" className="overview fs-exclude" content={caseRequest.caseInfo.caseSummary} useSafeNewLine={!isRichText(caseRequest.caseInfo.caseSummary)}/>
        </ReadOnlyField>
      </Card>
    </>

  const questions =  showCaseOverview && <>
    <SectionHeading>
      <h3><FormattedMessage
        defaultMessage="Questions"
        description="RequestOverview questions title"
      /></h3>
    </SectionHeading>
    <Card>
      {caseRequest.caseInfo.questionsConsultant ? <>
          {caseRequest.caseInfo.questionsConsultant.length === 0 && <EmptyMedicalRecordsList><FormattedMessage
            defaultMessage="No questions provided."
            description="RequestOverview no questions provided message"
          /></EmptyMedicalRecordsList>}
          {(caseRequest.caseInfo.questionsConsultant || []).map(({ question, answer, id }, i) =>
            <ReadOnlyQuestion key={id}>
              <label>Question {i + 1}</label>
              <div className='fs-exclude'>{question ? (question.charAt(0).toUpperCase() + question.substring(1)) : <NotProvided />}</div>
              {(caseRequest.state === CASE_REVIEWED || caseRequest.state === CASE_CLOSED) && !isRichText(answer) &&
                <div className="answer fs-exclude">{
                  answer 
                  ? (answer.charAt(0).toUpperCase() + answer.substring(1)) 
                  : <NotProvided />}
                </div>
              }
              {(caseRequest.state === CASE_REVIEWED || caseRequest.state === CASE_CLOSED) && isRichText(answer) &&
                <div className="answer fs-exclude">{
                  answer 
                  ? <RichTextDisplay content={answer} className="answer overview fs-exclude" /> 
                  : <NotProvided />}
                </div>
              }
            </ReadOnlyQuestion>
          )} </>
        : <EmptyMedicalRecordsList><FormattedMessage
          defaultMessage="No questions provided."
          description="RequestOverview no questions provided message"
        /></EmptyMedicalRecordsList>
      }
    </Card>
  </>

  if (!hasMedicalRecords) {
    
    if (!caseRequest.medicalRecords) {
      return <SpacedDiv><LoadingPage /></SpacedDiv>
    }

    return <>
      <PageLoadingSuspense>
        {patientAndContactDetails}
        {primaryConcern}
        {clinicalSummary}
        {/* {questions} */}
        {showCaseOverview && <QuestionsAndAdditionalOverview caseRequest={caseRequest} withAnwsers={true}/>}
        {
          !withoutRecords && showMedicalRecords && <>
            <SectionHeading>
              <h3><FormattedMessage
                defaultMessage="Medical record files"
                description="RequestOverview medical record files title"
              /></h3>
            </SectionHeading>
            <Card>
              {
              ((caseRequest.medicalRecords.length > 0 && role !== EXPERT) || (role === EXPERT && caseRequest.medicalRecords.filter((item) => item.state !== "Rejected").length > 0) || allowUpload) 
                ?
                  <RequestOverviewNew
                    caseRequest={caseRequest}
                    setCaseRequest={setCaseRequest}
                    records={medicalRecordsNewUploader}
                    uploaderRef={uploaderRef}
                    allowReorder={allowReorder}
                    allowUpload={allowUpload}
                    allowDownload={allowDownload}
                    allowDownloadMedicalImages={allowDownloadMedicalImages}
                    setMedicalRecords={(updateRecords) => setCaseRequest(_.update('medicalRecords', (prevRecords) => callIfFunction(updateRecords, prevRecords)))}
                    autoGrouping={autoGrouping}
                    storageHost={storageHost}
                    userRole={userRole}
                    role={role}
                    unauthorizedRefresh={unauthorizedRefresh}
                    caseUID={caseUID}
                    showHiddenRecords={showHiddenRecords}
                  />
                : 
                  <EmptyMedicalRecordsList><FormattedMessage
                    defaultMessage="No medical records provided."
                    description="RequestOverview no medical records provided message"
                  /></EmptyMedicalRecordsList>
              } 
            </Card>
          </>
        }
      </PageLoadingSuspense>
    </>
  }

  const GetUploaderToken = allowUpload || IdentityFn
  const medicalRecordsUpdater = updateMedicalRecordsInfo(authPostFetcher)(medicalRecordsStore, setMedicalRecords, caseRequest.requestId)
  const persistRecord = persistMedicalRecord(medicalRecordsUpdater)
  // const dobDate = parseDate(caseRequest.patientInfo.dob, new Date(), "MM-dd-yyyy")

  return <>
    {patientAndContactDetails}
    {primaryConcern}
    {clinicalSummary}
    {questions}
    {
      !withoutRecords && <>
        <SectionHeading>
          <h3><FormattedMessage
            defaultMessage="Medical record files"
            description="RequestOverview medical record files title"
          /></h3>
        </SectionHeading>
        <Card>
          {(hasMedicalRecords || allowUpload) ?
            <Suspense fallback={<Loading size={36} borderWidth={5} css="margin:3rem auto;" />}>
              <GetUploaderToken requestId={caseRequest.requestId}>{(jwt) =>
                <UploaderLegacy
                  withViewAllButton
                  jwt={jwt}
                  disabled={!allowUpload}
                  allowDownload={allowDownload && allowDownloadMedicalImages}
                  withoutDelete={!allowUpload}
                  ref={uploaderRef}
                  medicalRecords={medicalRecordsInfo || EMPTY_MEDICAL_RECORDS}
                  persistRecord={persistRecord}
                  appJwt={getJwt()}
                  requestId={caseRequest.requestId}
                />
              }</GetUploaderToken>
            </Suspense>
            :
            <EmptyMedicalRecordsList><FormattedMessage
              defaultMessage="No medical records provided."
              description="RequestOverview no medical records provided message"
            /></EmptyMedicalRecordsList>
          }
        </Card>
      </>
    }
  </>

}

export const RequestOverviewNew = ({records, caseRequest = {}, uploaderRef, allowReorder, allowDownload, allowDownloadMedicalImages, allowUpload, disabled, setMedicalRecords, autoGrouping, unauthorizedRefresh, role,caseUID = '', showHiddenRecords = true }) => {

  const [, setNotification] = useNotification()
  const caseId = caseUID || caseRequest.requestCode;
  // To use with Medical Records state rejected 
  // const medicalRecordsStore = useRefState(() => (role === EXPERT ? caseRequest.medicalRecords.filter((item) => item.state !== "Rejected")||[] : caseRequest.medicalRecords ||[]).map((file) => ({...file,...file.info})), (prev,current) => setMedicalRecords(current))
  const medicalRecordsStore = useRefState(() => (caseRequest.medicalRecords ||[]).map((file) => ({...file,...file.info})), (prev,current) => setMedicalRecords(current))
  const persistRecord = (record) => {
    medicalRecordsStore.set(_.unionBy(_.property('recordUID'), [record]))
    return Promise.resolve()
  }

 const persistGroups = useMemo(() => {
    let lastUpdateGroups = Promise.resolve()
    let isUpdatingGroups = false
    let nextUpdateGroups = null

    const syncUpdateGroup = () => {
      if (isUpdatingGroups) {
        return lastUpdateGroups
      } else {
        const next = () => {
          isUpdatingGroups = true
          const currentGroups = nextUpdateGroups
          const updateGroups = (groups) => jwtPostFetcher(getUploaderJwt())(getStorageHost()+"/groups/case/"+caseId,{info:groups.filter(({transient})=>!transient)},{method:"PUT"})
          // .then(() => setMedicalRecords(_.set('groups.list',groups)))
          return updateGroups(nextUpdateGroups)
            .catch(e => {
              isUpdatingGroups = false
              return Promise.reject(e)
            })
            .then(() => {
              isUpdatingGroups = false
              if (currentGroups !== nextUpdateGroups) {
                syncUpdateGroup()
              }
            })

        }
        lastUpdateGroups = next()
        return lastUpdateGroups
      }
    }
    return (groups) => {
      nextUpdateGroups = groups
      return syncUpdateGroup()
    }
  },[])

  const persistRecordInfo = (updatedRecord,currentInfo) => {
    
    const notesPromise = updatedRecord?.notes?.note !== currentInfo?.notes?.note
      ? jwtPostFetcher(getUploaderJwt())(getStorageHost()+`/record/${updatedRecord.recordUID}/notes`,{note:updatedRecord?.notes?.note}, {method: 'PUT'})
      : Promise.resolve()
    const infoPromise = (!currentInfo || currentInfo.docType !== updatedRecord.docType || currentInfo.description !== updatedRecord.description)
      ? jwtPostFetcher(getUploaderJwt())(getStorageHost()+"/medicalRecord/info/"+updatedRecord.recordUID,{..._.pick(fileFields,updatedRecord),type:'NonDicomFile'},{method:"PUT"})
      : Promise.resolve()

    const metaPromise = !_.isEqual(updatedRecord?.metadata,currentInfo?.metadata)
      ? jwtPostFetcher(getUploaderJwt())(getStorageHost()+`/record/${updatedRecord.recordUID}/metadata`,updatedRecord?.metadata, {method: 'PUT'})
      : Promise.resolve()

    const statePromise = (updatedRecord?.state !== currentInfo?.state && currentInfo?.state)
      ? jwtPostFetcher(getUploaderJwt())(getStorageHost()+`/record/${updatedRecord.recordUID}/state`,updatedRecord?.state, {method: 'PUT'})
      : Promise.resolve()

    return Promise.all([notesPromise,infoPromise,metaPromise,statePromise])
      .then(() =>
        medicalRecordsStore.set(_.unionBy(_.property('recordUID'), [updatedRecord]))
      )
  }

  const removePersistedRecord = (recordUID) => {
    const removePromise =
      recordUID === enums.MetaRecordType.ALL_NON_COMPLIANT_DICOM
      ? using(medicalRecordsStore.get().filter(record => record.format === enums.RecordFormat.NonCompliantDicom))(nonCompliants =>
        nonCompliants.length
          ? jwtPostFetcher(getUploaderJwt())(getStorageHost()+'/record/'+nonCompliants.map(_.get('recordUID')).join("-"),undefined,{method:"DELETE"})
          : Promise.resolve({})
        )
      : medicalRecordsStore.get().find(record => record.recordUID === recordUID)
        ? jwtPostFetcher(getUploaderJwt())(getStorageHost()+'/record/'+recordUID,undefined,{method:"DELETE"})
        : Promise.resolve(false)

     return removePromise.then(() =>
       medicalRecordsStore.set(_.differenceBy(_.property('recordUID'), _ , [{recordUID}]))
     )
  }

  return <>
      <Uploader2
        withViewAllButton 
        listMode={!allowUpload} // using hides the Upload log
        downloadEnabled={caseRequest}
        allowDownload={allowDownload}
        allowDownloadMedicalImages={allowDownloadMedicalImages}
        disabled={!allowUpload} 
        jwt={getUploaderJwt}
        apiEndpoints={apiEndpoints}
        allowReorder={allowReorder}
        ref={uploaderRef}
        autoGrouping={autoGrouping}
        groups={caseRequest.group?.info}
        appJwt={getUploaderJwt}
        persistRecord={persistRecord}
        persistRecordInfo={persistRecordInfo}
        removePersistedRecord={removePersistedRecord}
        persistGroups={persistGroups}
        setNotification={ caseRequest.requestId ? setNotification : () => {} }
        medicalRecords={medicalRecordsStore.get()}
        // role={'PATIENT'}
        role={role}
        requestId={caseRequest.requestId}
        worklistViewerJwt={getJwt}
        caseId={caseId}
        canAddHiddenFileToCase={false}
        showHiddenRecords={showHiddenRecords}
      />
  </>
}