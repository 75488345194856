import React, { useEffect, useRef, useState } from 'react'
import { lazyProviderInfo } from '../components/WithProvider'
import { Card, PageContainer } from '../components/PageLayout'
import { Header } from '../components/Header'
import { Button, Dialog, Icon, Loading } from '@startlibs/components'
import { ThreadCard } from '../admin/steps/ThreadCard'
import { Errors, FormValue, TextInput, WithForm } from '@startlibs/form'
import { buildValidation, required } from '../utils/validation'
import { setNotification } from '../components/Notifications'
import { getJwt } from '../hooks/useJwt'
import { jwtGetFetcher, jwtPostFetcher } from '../utils/authFetch'
import { getTimezoneOffset } from '../utils/utils'
import { getColor } from '@startlibs/utils'
import styled from 'styled-components'
import { PatientFeedback } from '../components/PatientFeedback'
import { useNavigate } from 'react-router'

const ReturnButton = styled(Button) `
  box-shadow: none;
  color: ${getColor('gray60')};
  font-weight: bold;
  margin-bottom: 1.5rem;
  ${Icon} {
    font-size: 20px;
  }
`

export const PatientContactInstitution = ({caseRequest,setCaseRequest,feedbackPopup }) => {

  const navigate = useNavigate()
  const VIEW = 'VIEW';
  const NEW = 'NEW';
  const REPLY = 'REPLY';
  const providerInfo = lazyProviderInfo.read()
  const [shouldReloadData, setShouldReloadData] = useState(false)
  const [threads, setThreads] = useState([])
  const [isLoadingCollab, setIsLoadingCollab] = useState(true)
  const [isLoading, setIsLoading] = useState(true)  
  const [isSending, setIsSending] = useState(false)
  const [mode, setMode] = useState(VIEW)
  const [replyId, setReplyId] = useState(null)
  const formRef = useRef()
  const msgFieldRef = useRef()
  const titleFieldRef = useRef()
  const replyAction = (id) => {
    setReplyId(id)
    setMode(REPLY)
  }

  const preValidation = buildValidation({
    'title': (v) => !v && mode === NEW && required(),
    'message': [
      (v) => !v && required(),
      (v) => v?.length > 3000 && 'Message: Maximum character limit of 3000 reached.'
    ]
    // 'message': (v) => !v && required(),
    // 'message': (v) => v?.length > 1000 && 'Message: Maximum character limit of 1000 reached.'
  })
  
  const getCollaborations = () => {
    setIsLoadingCollab(true)
    jwtGetFetcher(getJwt())('/api/collaborations',{requestId : caseRequest.requestId})
    .then((response) => {
      setThreads(response)
      if(caseRequest?.collaborationMessagesToRead > 0){
        jwtPostFetcher(getJwt())('/api/collaborations/resetRead?requestId='+caseRequest.requestId, {})
          .then((response) => {
            setTimeout(() => {
              setCaseRequest(caseRequest => {return {...caseRequest, collaborationMessagesToRead: 0}})  
            }, 3000)
          })
          .catch((error) => {
            console.log(error)
          })
      }
    })
    .catch((error) => {
      console.log(error)
    })  
    .finally(() => {
      setIsLoading(false)
      setIsLoadingCollab(false)
    })
  }

  useEffect(() => {
    getCollaborations()
  },[])

  useEffect(() => {
    if(shouldReloadData){
      setIsLoading(true)
      // jwtGetFetcher(getJwt())(`/api/viewCaseRequest?requestCode=${caseRequest.requestCode}&timezoneOffset=${getTimezoneOffset()}`)
      jwtGetFetcher(getJwt())(`/api/patient/viewCaseRequest?requestCode=${caseRequest.requestCode}&timezoneOffset=${getTimezoneOffset()}`)
      .then((response) => {
        setCaseRequest(response)
        setShouldReloadData(false)
        getCollaborations()   
      })
      .catch((error) => {
        console.log(error)
        setIsLoading(false)
      })
      .finally(() => {
        setIsLoading(false)
      })
    }
  },[shouldReloadData])

  useEffect(() => {
    if(!isLoadingCollab && threads && threads.length === 0){
      setMode(NEW)
    } 
  },[isLoadingCollab])

  useEffect(() => {
    if(mode === NEW){
      titleFieldRef.current.focus()
    }
    if(mode === REPLY){
      msgFieldRef.current.focus()
    }
  },[mode])

  if(isLoading){
    return <PageContainer >
    <Header title={'Messaging'}></Header>
      <Card>
      <Loading css="margin: 10rem auto" />
      </Card>
    </PageContainer>
  }

  return <PageContainer >
    <Header title={'Messaging'}></Header>
    <Card>
    
      {mode === VIEW && <>
        <p>Use the space below to communicate with the institution. You will be notified of new messages via email.</p>
        {!isLoadingCollab && threads && threads.length > 0 && <Button icon="email" css="margin-bottom:1.5rem;" highlight onClick={() => setMode(NEW)}>Send a new message</Button>}

        {/* {previousMessages && previousMessages.length > 0 && previousMessages.map((message) => { */}
        {isLoadingCollab && <Loading css="margin: 10rem auto" />}
        {/* {!isLoadingCollab && threads && threads.length > 0 && <h3>Previous messages</h3>} */}
        {!isLoadingCollab && threads && threads.length > 0 && threads.map((thread) => {
          return <ThreadCard 
            thread={thread} 
            key={thread.id}
            caseRequest={caseRequest} 
            replyAction={replyAction}
            isReplying={false}
            // setShouldReloadData={setShouldReloadData} 
          />
        })}
      </>}
      {(mode === NEW || mode === REPLY) && <>
        {threads && threads.length > 0 
          ? <ReturnButton 
            noShadow rounded color="gray240" icon="return"
            onClick={() => {
            setMode(VIEW)
            setReplyId(null)
          }}>View all messages</ReturnButton>
          : <p>Use the space below to communicate with the institution. You will be notified of new messages via email.</p>
        }
        {replyId && replyId > 0 && replyId != null && <ThreadCard 
          thread={threads.find((thread) => thread.id === replyId)} 
          caseRequest={caseRequest} 
          isReplying/>}
        <WithForm
          preValidation={preValidation}
          action={(values) => {
            setIsSending(true)
            return mode === NEW 
            // ? jwtPostFetcher(getJwt())("/api/collaborations?requestId="+caseRequest.requestId+"", {...values, title: 'New message from patient'} ,{method: 'POST'}) 
            ? jwtPostFetcher(getJwt())("/api/collaborations?requestId="+caseRequest.requestId+"", values ,{method: 'POST'}) 
            : jwtPostFetcher(getJwt())(`/api/collaborations/${replyId}/messages?requestId=${caseRequest.requestId}`, {...values} ,{method: 'POST'})
          }}
          onSuccess={(values) => {
            setNotification("Message sent")
            setIsSending(false)
            setMode(VIEW)
            setShouldReloadData(true) 
          }}
          ref={formRef}
          
          onFailure={(error, status) => {
            if(status && status?.length > 0){
              if(status[1].status === 569){
                navigate("/access")
                setNotification({ type: "alert", msg: "This case is locked" })
              }
            }
            setIsSending(false)
          }}
        >{form => 
          <>
            {mode === NEW && threads && threads.length > 0 && <h3>New message</h3>}
            {mode === NEW && <TextInput
              path="title"
              mandatory={true}
              placeholder="Enter a subject"
              label="Subject"
              maxLength={255}
              ref={titleFieldRef}
            />}
            <TextInput
              textarea
              autoResize
              ref={msgFieldRef}
              path="message"
              mandatory={true}
              onChange={(e) => {
                if(e.length > 3000){
                  form.addErrors({message: 'Message: Maximum character limit of 3000 reached.'})
                }else{
                  form.clearErrors('message')
                }
              }}
              placeholder="Type your message here..."
              label={mode === NEW ? 'Write your message' : 'Reply message'}
              bellowDescText={
                <FormValue path="message">{message => {
                  if(message){
                    return <span style={{ 
                      display: 'block',
                      width: '100%',
                      textAlign: 'right'
                    }}>{message?.length+"/3000"}</span>
                  }
                }}</FormValue>
              }
            />
            <Errors css="margin-bottom: 1rem;"/>
            <div css="text-align: right">
              <Button
                highlight
                type="submit"
                isLoading={isSending}
                icon="email"
              >
                Send message
              </Button>
            </div>
          </>
        }</WithForm>
      </>}
    </Card>
    {feedbackPopup.isOpen && <Dialog title="Share feedback" closeDialog={feedbackPopup.close}>
        <PatientFeedback caseRequest={caseRequest} feedbackPopup={feedbackPopup} isPopup />
      </Dialog>
    }
  </PageContainer>
}
