import { Button, Loading } from '@startlibs/components';
import {TextInput} from '@startlibs/form'
import {useToggle, willUseSharedCallback} from '@startlibs/core'
import React, {useContext, useEffect} from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components';

import { AttachmentDetails } from '../AttachmentBoxStyles';
import {UploaderConfigContext} from "../../service/UploaderConfigContext";
import {useDoAction} from "../../service/hooks/useDoAction";
import {UploaderAction} from "../../service/UploaderAction";
import {NonCompliantDicom} from "../../enums/RecordFormat";
import {FormattedMessage, useIntl} from "react-intl";

export const [useRegisterCloseNotes, closeNotes] = willUseSharedCallback()

const AttachmentNote = styled(AttachmentDetails)`
  .textInputWrapper {
    position: relative;
  }
  ${Button} {
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
  }
  ${TextInput} {
    margin: 0.25rem 0 0.75rem;
    font-size: 12px;
    padding-bottom: 2.75rem;
  }
`
const InputLoading = styled(Loading)`
  box-shadow: 0 0 0 5px #e2e6e9;
  background: #e2e6e9;
`

export const NotesField = ({record}) => {
  const intl = useIntl()

  const {disabled: readOnly} = useContext(UploaderConfigContext)
  const doAction = useDoAction()

  const notes = record.notes?.note
  const setNotes = (value) => doAction(UploaderAction.SetNotes, record, value)

  const edit = useToggle(false, (newV,prevV) => {
    if (newV === 0 || prevV === 0) { return }
    if (newV === false) {
      confirmValue(prevV)
      return false
    }
  })


  useRegisterCloseNotes(edit.close)

  const loading = useToggle()
  const connectionError = useToggle()

  const tryConfirmValue = (e) => {
    if (e.key === 'Escape') {
      e.preventDefault()
      edit.openWith(0)
    }
  }
  const confirmValue = (value) => {
    connectionError.close()
    loading.wait(
      setNotes(value)
      .then(() => edit.openWith(0))
      .catch(() => connectionError.open()))
  }

  useEffect(() => () => {
    if (edit.get() === 0) {
      edit.close()
    }
  },[edit.isOpen])

  if (record.key === NonCompliantDicom) {
    return null
  }

  if (_.isString(edit.isOpen)) {
    return <AttachmentNote
      className="JS-blur-container"
      onBlur={(e) => (e.currentTarget !== e.relatedTarget && !e.currentTarget.contains(e.relatedTarget)) && edit.close()}
    ><div><b><FormattedMessage
      defaultMessage="Note"
      description="Uploader, note field label"
      />:</b></div>
    <div className="textInputWrapper">
      <TextInput
        raw
        autoFocus
        onKeyUp={tryConfirmValue}
        onKeyPress={e => e.key === "Enter" && e.preventDefault()}
        onClick={(e) => e.stopPropagation()}
        onBlur={edit.close}
        textarea
        minHeight={60}
        placeholder={intl.formatMessage({
          defaultMessage: "Type here...",
          description: "Uploader, note field placeholder"
        })}
        value={edit.isOpen}
        autoResize
        disabled={loading.isOpen}
        setValue={(v) => {edit.openWith(v)}}
      />
      {
        loading.isOpen ?
        <InputLoading absolute/>
        :
        <Button small onClick={edit.close} icon="check" highlight><FormattedMessage
          defaultMessage="Save"
          description="Save button"
        /></Button>
      }
      </div>
    </AttachmentNote>
  }

  if (!notes) {
    if (readOnly) { return null }
    return <a className="light-link" css="margin-right:0.75rem;margin-top:2px;display:inline-block;" onClick={edit.willOpenWith(notes || "")}><FormattedMessage
      defaultMessage="Add note"
      description="Uploader, add note button"
    /></a>
  }

  return <AttachmentNote><b><FormattedMessage
    defaultMessage="Note"
    description="Uploader, note field label"
    />: </b><span>{notes}
  </span>{!readOnly && <a className="light-link nowrap" css="margin-left:0.25rem;" tabIndex={1} onClick={edit.willOpenWith(notes || "")}><FormattedMessage
    defaultMessage="Edit"
    description="Edit button"
  /></a>}
  </AttachmentNote>
}