import {formatDate, getColor} from '@startlibs/utils'
import {useFormValue} from '@startlibs/form'
import React, { useEffect } from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components';
import {MEDICAL_RELEASE_PATH} from './ReleaseLocationForms'
import {formatDateNoUTC} from '../../utils/utils'
import {getContact, getRelationLabel} from '../../enums/ContactRelationKind'
import {useIntl} from 'react-intl'
import { lazyProviderInfo } from '../../components/WithProvider';
import { OTHER } from '../../enums/ContactRelationType';
import * as ReactDOMServer from 'react-dom/server';
import { useIsLocale } from '../../hocs/IfLocale';
import {defaultDateFormat} from "../../utils/dateFormat";

const List = styled.ul` {
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}`

export const ReleaseTerms = styled(({caseRequest,focus,className,htmlTerm,setHtmlTerm,isNew=false}) => {

  const providerInfo = lazyProviderInfo.read()
  const [locations = []] = useFormValue(MEDICAL_RELEASE_PATH)
  const [dateFrom] = useFormValue("dateFrom")
  const [dateTo] = useFormValue("dateTo")
  const [dateExpiration] = useFormValue("dateExpiration")

  const easyFormatDate = (date) => date ? formatDate(date, defaultDateFormat(intl)) : ""
  const intl = useIntl()
  const isNotLegal = useIsLocale({not:true,contains:"LEGAL"})
  const contact = getContact(intl, caseRequest)
  
  const patientName = `${caseRequest?.patientInfo?.firstName} ${caseRequest?.patientInfo?.middleName ? caseRequest?.patientInfo?.middleName : ''} ${caseRequest?.patientInfo?.lastName}`
  const patientDob = caseRequest?.patientInfo?.dob
  const entityName = providerInfo.name
  const contactRelationType = getRelationLabel(intl,caseRequest.contactDetails.contactRelationType,'representative')

  useEffect(() => {
    if(isNew){
      setHtmlTerm(ReactDOMServer.renderToStaticMarkup(
        <ReleaseTermsContent
          patientName={patientName}
          contactName={contact.name}
          contacRelationType={contactRelationType}
          entityName={entityName}
          locations={locations}
          focus={focus}
          dateTo={easyFormatDate(dateTo)}
          dateFrom={easyFormatDate(dateFrom)}
          dateExpiration={easyFormatDate(dateExpiration)}
          patientDob={easyFormatDate(new Date(patientDob)) === "NaN/NaN/NaN" ? patientDob.replace('-','/').replace('-','/') : easyFormatDate(new Date(patientDob))}
          isNew={isNew}
          isNotLegal={isNotLegal}
        />))
    }
  },[locations,dateFrom,dateTo,dateExpiration])

  return <div className={className}>
    <ReleaseTermsContent
      patientName={patientName}
      contactName={contact.name}
      contacRelationType={contactRelationType}
      entityName={entityName}
      locations={locations}
      focus={focus}
      dateTo={easyFormatDate(dateTo)}
      dateFrom={easyFormatDate(dateFrom)}
      dateExpiration={easyFormatDate(dateExpiration)}
      patientDob={easyFormatDate(new Date(patientDob)) === "NaN/NaN/NaN" ? patientDob.replace('-','/').replace('-','/') : easyFormatDate(new Date(patientDob))}
      isNew={isNew}
      isNotLegal={isNotLegal}
    />
  </div>
})`
  background: white;
  border: 1px solid ${getColor('gray210')};
  border-radius: 5px;
  display: block;
  margin-bottom: 2rem;
  overflow: hidden;
  font-size: 12px;
  .releaseTermsContent {
    max-height: 25rem;
    overflow: auto;
    padding: 1.5rem;
  }
  p {
    font-size: 12px;
  }
`

export const ReleaseTermsContent = ({locations,contactName,contacRelationType,entityName,dateTo,dateFrom,dateExpiration,patientName,isNew=false,signedTerm=null,patientDob,isNotLegal,focus = () => {}}) => 
  {

    const decodeHtmlEntity = (str) => {
      const regex = /&#x([0-9a-fA-F]+);/g;
      const match = str && str?.match(regex) || [];
      match && match?.map((item) => {
        const hex = item.replace('&#x','').replace(';','')
        const replacement = String.fromCharCode(parseInt(hex, 16))
        str = str.replace(item,replacement)
      })
      // change &quot; and &amp;
      str = str.replace('&quot;','"').replace('&quot;','"')
      str = str.replace('&amp;','&').replace('&amp;','&')
      // change &gt; and &lt;
      str = str.replace('&gt;','>').replace('&gt;','>')
      str = str.replace('&lt;','<').replace('&lt;','<')
      return str;
    }

    let singleInstitutionTerm = ''
    if((!isNew) && signedTerm && signedTerm?.length > 0 ){
      
      try{
        // get information from signed term
        const regex = /<b class="fs-exclude">([^<]*)<\/b>/g;
        const match = signedTerm && signedTerm?.match(regex) || [];
        
        // get location from locations
        // const location = locations[0].name
        const location = locations.map(_.get('name')).filter(_.identity)
        const locationsString = match && match?.filter((item) => (decodeHtmlEntity(item)).includes(location) && item.includes(',')) || [];
        
        // signed term contains locations as follows: <b class="fs-exclude">location 1, location 2, location 3</b>
        // here, we replace the locations with the actual location - displaying only one location that is selected.
        singleInstitutionTerm = signedTerm 
        locationsString.map((item) => {
          singleInstitutionTerm = singleInstitutionTerm?.replace(item,`<b class="fs-exclude">${location}</b>`)
        })
      }catch(e){
        singleInstitutionTerm = signedTerm 
      }
    }
    
  return isNew ? 
    <div className="releaseTermsContent">
      {isNotLegal // Medical EV
        ? <>
          <p>I, <b className='fs-exclude'>{contactName}</b>{contacRelationType !== OTHER ? `, a ${contacRelationType}` : ' '} with the legal authority to make such a request for the Patient hereby authorize <b className='fs-exclude'>{locations.length > 0 ? locations.map(_.get('name')).filter(_.identity).join(", ") : <a onClick={focus("locations")} className="link">[Add locations]</a>}</b> and all health care providers (“Provider”) who have been involved in the treatment of <b className='fs-exclude'>{patientName}</b> (“Patient”), (Date of Birth <b className='fs-exclude'>{patientDob}</b>) to disclose Protected Health Information (PHI), as defined in the HIPAA Privacy Rule, about Patient to <b>{entityName}</b>, as well as Nimble Co., LLC (DBA Purview), a HIPAA designated “Business Associate” of {entityName} as follows.</p>
          <ol>
            <li>I request Patient’s Protected Health Information in Provider’s files or records, covering the time period starting on <b>{dateFrom ? dateFrom : <a onClick={focus("dateFrom")} className="link" >[Define date]</a>}</b> and ending on <b>{dateTo ? dateTo : <a onClick={focus("dateTo")} className="link">[Define date]</a>}</b> without regard to whether the Protected Health Information was created by or received by Provider.</li>
            <li>I specifically authorize Provider to disclose Patient’s entire medical record with respect to the time period described in Paragraph 1 above.</li>
            <li>I further authorize and direct Provider to send Patient’s Protected Health Information in their possession relating to the time period stated in Paragraph 1 by electronic means, if practicable, using the Purview secure and confidential system or other electronic means.</li>
            <li>I authorize Provider and their staff to engage in telephonic or electronic communication with {entityName} and its Business Associate, Purview.</li>
            <li>I authorize Provider to disclose or discuss any schedule of appointments made on Patient’s behalf prior to the actual appointment dates with {entityName} and its Business Associate, Purview.</li>
            <li>I authorize and request {entityName} to disclose to Purview and to all of the Patient’s Providers the remote consultation report and to discuss the remote consultation report and the Patient’s clinical condition with the Patient’s Providers.</li>
          </ol>
          <p>Exception: Patient’s medical records may include information regarding diagnosis and treatment of drug, alcohol, Acquired Immune Deficiency Syndrome (AIDS), HIV serology, or psychiatric disorders. This release specifically authorizes the disclosure of the patient’s complete health record <u>except</u> records for the following conditions, unless specifically requested in writing signed by the Patient or a parent or legal representative of the Patient:</p>
          <ol>
            <li>Mental health</li>
            <li>Communicable diseases including, but not limited to, HIV and AIDS</li>
            <li>Alcohol/drug abuse treatment</li>
            <li>Genetic information and testing</li>
          </ol>
          <p>Those receiving this information will be advised that federal regulations prohibit their making any further disclosure without my written consent or as otherwise permitted by such regulations. </p>
          <p>This Protected Health Information is being disclosed at my request to Purview and {entityName}. I understand a copy of these records may be retained by Purview and/or {entityName} in each of their electronic records systems.</p>
          <p>As set forth above, I authorize Purview to collect the Protected Health Information (PHI) of the Patient, store the PHI in its electronic system, and disclose the PHI to {entityName}. I also specifically authorize {entityName} to access that information and to disclose the consultation report it prepares on the Patient’s behalf to Purview and my Providers. </p>
          <p>You may be assured and should be secure in the fact that Purview is bound by the same privacy standards as the receiving health care organization ({entityName} and its physicians) pursuant to a Business Associate Agreement (BAA) entered into between Purview and the receiving health care organization that complies with applicable laws. </p>
          <p>The further disclosure of this Protected Health Information, other than {entityName} shall be controlled by me. I hereby release Provider of any liability for the release of information in accordance with this Authorization for the Use and Disclosure of Protected Health Information.</p>
          <p>This authorization shall be in force until <b>{dateExpiration ? dateExpiration : <a onClick={focus("dateExpiration")} className="link">[Define date]</a>}</b>, at which time this authorization to use or disclose this Protected Health Information shall expire.</p>
          <p>I understand that I have the right to revoke this authorization, in writing, at any time by sending written notification to <b className='fs-exclude'>{locations.length > 0 ? locations.map(_.get('name')).filter(_.identity).join(", ") : <a onClick={focus("locations")} className="link">[Add locations]</a>}</b>. I understand that a revocation is not effective to the extent that Provider or any of its agents or employees has relied on this authorization to use or disclose the Protected Health Information in accordance with this authorization prior to the time they receive the revocation.</p>
          <p>I understand and represent further that I have signed this authorization of my own free will, without coercion or payment, I will not hold any of the above authorized accountable for their actions relating to the disclosure of Protected Health Information pursuant to this Authorization.</p>
          <div className="nobreak">
            <p>I understand that I have the right to:</p>
            <ul className="ulitens">
              <li>Inspect or copy Patient’s Protected Health Information as permitted by applicable laws.</li>
              <li>Withdraw and cancel this Authorization at any time, though such withdrawal will not be applicable to records or discussions held prior to the date of withdrawal.</li>
              <li>Refuse to sign this authorization.</li>
              <li>The signing of this form does not condition treatment, payment, benefit eligibility or enrollment activities of the Patient’s Providers.</li>
            </ul>
          </div>
        </>
        // LEGAL EV
        : <> 
          <p>I, <b className='fs-exclude'>{contactName}</b>{contacRelationType !== OTHER ? `, a ${contacRelationType === 'Client' ? 'Patient' : contacRelationType}` : ' '} hereby authorize <b className='fs-exclude'>{locations.length > 0 ? locations.map(_.get('name')).filter(_.identity).join(", ") : <a onClick={focus("locations")} className="link">[Add locations]</a>}</b> and direct the recipient of this release, a health care provider (“Provider”) who has been involved in the treatment of <b className='fs-exclude'>{patientName}</b> (“Patient”), to disclose the following protected health information about Patient to Purview, who is specifically authorized to receive and collect these records at my request.</p>
          <ol>
            <li>Patient’s personal health care information in Provider’s files or records, covering the time period starting on <b>{dateFrom ? dateFrom : <a onClick={focus("dateFrom")} className="link" >[Define date]</a>}</b> and ending on <b>{dateTo ? dateTo : <a onClick={focus("dateTo")} className="link">[Define date]</a>}</b>, without regard to whether the personal health information was created by or received by Provider.</li>
            <li>I specifically authorize Provider to disclose Patient’s entire medical record with respect to the time period described in Paragraph 1 above, without the necessity to remove any information about Patient in that record, including images and similar data.</li>
            <li>I further authorize and direct Provider to send Patient’s personal health information in their possession relating to the time period stated in Paragraph 1 by electronic means, if practicable, using this system, which is secure and confidential.</li>
            <li>I authorize Provider and their staff to engage in telephonic or electronic communication with Purview.</li>
            <li>I authorize Provider to disclose or discuss any schedule of appointments made on Patient’s behalf prior to the actual appointment dates.</li>
          </ol>
          <p>Patient’s medical records may include information regarding diagnosis and treatment of drug, alcohol, acquired immune deficiency syndrome (AIDS), (HIV serology), or psychiatric disorders. Those receiving this information will be advised that federal regulations (42 CFR part 2) prohibit their making any further disclosure without my written consent or as otherwise permitted by such regulations. You may retain a copy of these records.</p>
          <p>The further disclosure of this protected health information shall be controlled by me. I hereby release Provider of any liability for the release of information to you in accordance with this HIPAA compliant authorization. I understand that I have the right to revoke this authorization, in writing, at any time by sending written notification to the Provider.</p>
          <p>This authorization shall be in force until <b>{dateExpiration ? dateExpiration : <a onClick={focus("dateExpiration")} className="link">[Define date]</a>}</b>, at which time this authorization to use or disclose this protected health information expires. I understand that a revocation is not effective to the extent that Provider or any of its agents or employees has relied on this authorization to use or disclose the protected health information in accordance with this authorization prior to the time they receive the revocation.</p>
          <p>I understand and represent further that I have signed this authorization of my own free will, without coercion or payment, and that information used or disclosed pursuant to this authorization may be subject to re-disclosure by you and may no longer be protected by federal or state law.</p>
          <div className="nobreak">
            <p>I understand that I have the right to:</p>
            <ul className="ulitens">
              <li>Inspect or copy Patient’s protected health information to be used or disclosed as permitted under federal law (or state law to the extent the state law provides greater access rights.)</li>
              <li>Refuse to sign this authorization. The signing of this form does not condition treatment, payment, benefit eligibility or enrollment activities of my providers.</li>
            </ul>
          </div>
        </>
      }
    </div> 
    : singleInstitutionTerm && singleInstitutionTerm?.length > 0 
      ? <div dangerouslySetInnerHTML={{__html: singleInstitutionTerm}}/>
      : <div className="releaseTermsContent">
          <p>I, <b className='fs-exclude'>{contactName}</b>{contacRelationType !== OTHER ? `, a ${contacRelationType}` : ' '} hereby authorize <b className='fs-exclude'>{locations.length > 0 ? locations.map(_.get('name')).filter(_.identity).join(", ") : <a onClick={focus("locations")} className="link">[Add locations]</a>}</b> and direct the recipient of this release, a health care provider (“Provider”) who has been involved in the treatment of <b className='fs-exclude'>{patientName}</b> (“Patient”), to disclose the following protected health information about Patient to <b>{entityName}</b>.</p>

          <ol>
            <li>Patient’s personal health care information in Provider’s files, covering the time period starting on <b>{dateFrom ? dateFrom : <a onClick={focus("dateFrom")} className="link" >[Define date]</a>}</b> and ending on <b>{dateTo ? dateTo : <a onClick={focus("dateTo")} className="link">[Define date]</a>}</b>, without regard to whether the personal health information was created by or received by Recipient.</li>
            <li>I specifically authorize Provider to disclose Patient’s entire medical record with respect to the time period described in Paragraph 1 above, without the necessity to remove any information about Patient in that record, including images and similar data.</li>
            <li>I further authorize and direct Provider to send Patient’s personal health information in their possession relating to the time period stated in Paragraph 1 by electronic means, if practicable, using this system, which is secure and confidential.</li>
            <li>I authorize Provider and their staff to engage in telephonic or electronic communication with our representatives.</li>
            <li>I authorize Provider to disclose or discuss any schedule of appointments made on Patient’s behalf prior to the actual appointment dates.</li>
          </ol>

          <p>Patient’s medical records may include information regarding diagnosis and treatment of drug, alcohol, acquired immune deficiency syndrome (aids), (hiv serology), or psychiatric disorders. Those receiving this information will be advised that federal regulations (42 CFR part 2) prohibit their making any further disclosure without my written consent or as otherwise permitted by such regulations.</p>

          <p>This protected health information is being disclosed in order to enable a remote medical review. You may retain a copy of these records.</p>

          <p>The further disclosure of this protected health information shall be controlled by me. I hereby release Provider of any liability for the release of information to you in accordance with this HIPAA compliant authorization. I understand that I have the right to revoke this authorization, in writing, at any time by sending written notification to the Provider.</p>
          
          {/* <br/> */}

          {/* <p><b>Section B</b></p> */}

          {/* <p>This authorization shall be in force and until <b>{dateExpiration ? dateExpiration : <a onClick={focus("dateExpiration")} className="link">[Define date]</a>}</b> at which time this authorization to use or disclose this protected health information expires.</p> */}

          <p>This authorization shall be in force until <b>{dateExpiration ? dateExpiration : <a onClick={focus("dateExpiration")} className="link">[Define date]</a>}</b>, at which time this authorization to use or disclose this protected health information expires.</p>

          <p>I understand that a revocation is not effective to the extent that Provider or any of its agents or employees has relied on this authorization to use or disclose the protected health information in accordance with this authorization prior to the time they receive the revocation.</p>

          <p>I understand and represent further that I have signed this authorization of my own free will, without coercion or payment, and that information used or disclosed pursuant to this authorization may be subject to re-disclosure by you and may no longer be protected by federal or state law. I will not hold Provider accountable for your actions following Provider’s disclosure of my information to you.</p>

          <div className="nobreak">
            <p>I understand that I have the right to:</p>

            <List>
              <li>Inspect or copy Patient’s protected health information to be used or disclosed as permitted under federal law (or state law to the extent the state law provides greater access rights.)</li>
              <li>Refuse to sign this authorization. The signing of this form does not condition treatment, payment, benefit eligibility or enrollment activities of my providers.</li>
            </List>

          </div>
        </div>
  }  
