import {Button, ConfirmDialog, DefaultConfirmDialog} from '@startlibs/components'
import React from 'react'
import {i18nDateMessages} from './i18nDateMessages'
import {FormattedMessage} from "react-intl";

export default (intl) => ({
  dialogs: {
    'submit-draft': ({submit, cancel, isLoading, discard}) =>
      <ConfirmDialog
        title={intl.formatMessage({
          defaultMessage: "Confirm changes made in this step",
          description: "Default confirm changes dialog title"
        })}
        closeDialog={cancel}
        onConfirm={submit}
        footer={<>
          <Button onClick={cancel}><FormattedMessage
            defaultMessage="Cancel"
            description="Cancel button"
          /></Button>
          <Button hover="alert" onClick={discard}><FormattedMessage
            defaultMessage="Discard changes"
            description="Discard changes button"
          /></Button>
          <Button autoFocus success isLoading={isLoading} onClick={submit}><FormattedMessage
            defaultMessage="Save changes"
            description="Save changes button"
          /></Button>
        </>}
      >
        <FormattedMessage
          defaultMessage={`<p>You are about to navigate to a different step.</p>
        <p>Would you like to keep the changes made in the current step or to discard them?</p>`}
          description="Default confirm changes dialog content"
          values={{
            p: (...chunks) => <p>{chunks}</p>
          }}
        />
      </ConfirmDialog>,
    'discard-expert-edit': ({submit, cancel, isLoading, discard}) =>
      <ConfirmDialog
        css={`max-width: 50rem;`}
        title={intl.formatMessage({
          defaultMessage: "Changes to this profile were not saved yet",
          description: "Discard expert edit dialog title"
        })}
        closeDialog={cancel}
        onConfirm={submit}
        footer={<>
          <Button onClick={cancel}><FormattedMessage
            defaultMessage="Cancel"
            description="Cancel button"
          /></Button>
          <Button hover="alert" onClick={discard}><FormattedMessage
            defaultMessage="Discard changes"
            description="Discard changes button"
          /></Button>
          <Button autoFocus success isLoading={isLoading} onClick={submit}><FormattedMessage
            defaultMessage="Save changes"
            description="Save changes button"
          /></Button>
        </>}
      >
        <FormattedMessage
          defaultMessage={`<p>You are about to leave this page but its contents were not saved yet. </p>
        <p>How would you like to proceed?</p>`}
          description="Discard expert edit dialog content"
          values={{
            p: (...chunks) => <p>{chunks}</p>
          }}
        />
      </ConfirmDialog>,
    'discard-expert-create': ({submit, cancel, isLoading, discard}) =>
      <ConfirmDialog
        css={`max-width: 50rem;`}
        title={intl.formatMessage({
          defaultMessage: "Changes to this profile were not saved yet",
          description: "Discard expert create dialog title"
        })}
        closeDialog={cancel}
        onConfirm={submit}
        footer={<>
          <Button onClick={cancel}><FormattedMessage
            defaultMessage="Cancel"
            description="Cancel button"
          /></Button>
          <Button hover="alert" onClick={discard}><FormattedMessage
            defaultMessage="Discard expert"
            description="Discard expert button"
          /></Button>
          <Button autoFocus success isLoading={isLoading} onClick={submit}><FormattedMessage
            defaultMessage="Save and add expert"
            description="Save and add expert button"
          /></Button>
        </>}
      >
        <FormattedMessage
          defaultMessage={`<p>You are about to leave this page but its contents were not saved yet. </p>
        <p>How would you like to proceed?</p>`}
          description="Discard expert create dialog content"
          values={{
            p: (...chunks) => <p>{chunks}</p>
          }}
        />

      </ConfirmDialog>,
    'discard-remote-log': ({submit, cancel, isLoading, discard}) =>
      <ConfirmDialog
        title={intl.formatMessage({
          defaultMessage: "Confirm discarding changes",
          description: "Discard remote log dialog title"
        })}
        closeDialog={cancel}
        onConfirm={submit}
        footer={<>
          <Button onClick={cancel}><FormattedMessage
            defaultMessage="Cancel"
            description="Cancel button"
          /></Button>
          <Button hover="success" isLoading={isLoading} onClick={submit}><FormattedMessage
            defaultMessage="Save changes"
            description="Save changes button"
          /></Button>
          <Button alert autoFocus onClick={discard}><FormattedMessage
            defaultMessage="Discard changes"
            description="Discard changes button"
          /></Button>
        </>}
      >
        <FormattedMessage
          defaultMessage={`
          <p>You are about to discard changes made to this consultation.</p>
        <p>Would you like to keep the changes or to discard them?</p>
          `}
          description="Discard remote log dialog content"
          values={{
            p: (...chunks) => <p>{chunks}</p>
          }}
        />
      </ConfirmDialog>,
    'discard-draft': ({submit, cancel, isLoading, discard}) => <DefaultConfirmDialog
      onCancel={cancel}
      onConfirm={discard}
      isLoading={isLoading}
      onSaveInstead={submit}
    />
  },
  confirmDialogLabels: {
    title: intl.formatMessage({
      defaultMessage: 'Your changes will not be saved',
      description: 'Default confirm dialog title'
    }),
    content: intl.formatMessage({
      defaultMessage: 'The changes made in this screen were not saved. Would you like to quit anyway?',
      description: 'Default confirm dialog content'
    }),
    confirm: intl.formatMessage({
      defaultMessage: 'Save changes',
      description: 'Save changes button'
    }),
    discard: intl.formatMessage({
      defaultMessage: 'Discard changes',
      description: 'Discard changes button'
    }),
    cancel: intl.formatMessage({
      defaultMessage: 'Cancel',
      description: 'Cancel button'
    })
  },
  labels: {
    EditableBox: {
      save: intl.formatMessage({
        defaultMessage: 'Save',
        description: 'Save button'
      }),
      saveNew: intl.formatMessage({
        defaultMessage: 'Save',
        description: 'Save new button'
      }),
      cancel: intl.formatMessage({
        defaultMessage: 'Cancel',
        description: 'Cancel button'
      })
    },
    RichText: {
      linkTextError: intl.formatMessage({
        defaultMessage: 'Text can not be empty',
        description: 'Rich text link text error message'
      }),
      linkUrlError: intl.formatMessage({
        defaultMessage: 'Please check the URL',
        description: 'Rich text link url error message'
      }),
      popupLinkText: intl.formatMessage({
        defaultMessage: 'Text to display',
        description: 'Rich text link text input label'
      }),
      popupLinkUrl: intl.formatMessage({
        defaultMessage: 'Url',
        description: 'Rich text link url input label'
      }),
      popupRemove: intl.formatMessage({
        defaultMessage: 'Remove link',
        description: 'Rich text remove link button'
      }),
      popupCancel:intl.formatMessage({
        defaultMessage: 'Cancel',
        description: 'Cancel button'
      }),
      popupConfirm:intl.formatMessage({
        defaultMessage: 'Apply',
        description: 'Apply button'
      }),
    }
  },
  FormUploadingImageError: intl.formatMessage({
    defaultMessage: 'The image is being uploaded, please wait a moment.',
    description: 'Form uploading image error message'
  }),
  ErrorDialog: {
    title: intl.formatMessage({
      defaultMessage: 'This content could not be loaded',
      description: 'Default error dialog title'
    }),
    content: intl.formatMessage({
      defaultMessage: `Please refresh the page and try again. <br/>If the problem persists, contact us.`,
      description: 'Default error dialog content',
      values: {
        br: (...chunks) => <br/>
      }
    }),
    close: intl.formatMessage({
      defaultMessage: 'Close',
      description: 'Close button'
    })
  },
  dateMessages: i18nDateMessages(intl),
  fetchRoutes: []
})
