import React, { useContext } from 'react'
import styled from 'styled-components/macro'
import {Button} from "@startlibs/components";
import {ConfirmDialog} from "@startlibs/form";
import {formatDate} from "@startlibs/utils";
import {useUIDataSelector} from "../../service/hooks/useUIDataSelector";
import {
  AttachmentBox,
  AttachmentDescription,
  AttachmentDetails,
  AttachmentIcon,
  AttachmentInfoContainer
} from "../AttachmentBoxStyles";
import {Pathology} from "../../enums/RecordClass";
import {CCDA, FormatToLabel} from "../../enums/RecordFormat";
import {useDoAction} from "../../service/hooks/useDoAction";
import {UIAction} from "../../service/UIAction";
import {UploaderAction} from "../../service/UploaderAction";
import {isDicom, isMultiFile} from "../../service/utils";
import {getRecordIcon} from "../../service/utils/recordUtils";
import {dicomDateToDate} from "../../utils";
import {FormattedMessage, useIntl} from "react-intl";
import {defaultDateFormat} from "../../utils/intlDateFormat";
import { UploaderConfigContext } from '../../service/UploaderConfigContext';
import { PATIENT } from '../../enums/UserRoles';
import { jwtGetFetcher } from '../../utils/authFetch';
import { setNotification } from '../../../../../src/javascripts/components/Notifications';
import _ from 'lodash/fp';

styled

export const DeleteRecordDialog = () => {
  const intl = useIntl()
  const record = useUIDataSelector('dialogs.deleteRecord')
  const doAction = useDoAction()
  const closeDialog = () => doAction(UIAction.ToggleDeleteRecordDialog)

  const config = useContext(UploaderConfigContext)
  const deleteRecord = async() => {
    // Only do this if role is PATIENT
    if (config.role === PATIENT) {
      try {
        // If the request succeeds, do normal flow
        const caseRequest = await jwtGetFetcher(config.worklistViewerJwt)(
          "/api/patient/viewCaseRequest",
          null,
          { method: "HEAD" }
        );
        doAction(UploaderAction.Delete, record)
      } catch ([v, { status }]) {
        // On locked case (569), show alert and STOP execution
        if (status === 569) {
          setNotification({ type: "alert", msg: "This case is locked. It is not possible to delete medical records" });  
          return;
        }
      }
    }else{
      doAction(UploaderAction.Delete, record)
    }
  }

  return <ConfirmDialog
    closeDialog={closeDialog}
    title={intl.formatMessage({
      defaultMessage: "Confirm medical record deletion",
      description: "Uploader, delete record dialog, title"
    })}
    action={deleteRecord}
    confirm={<Button
      alert>{isMultiFile(record) || isDicom(record) ? intl.formatMessage({
      defaultMessage: "Delete entire medical record",
      description: "Uploader, delete record dialog, delete entire record button"
    }): intl.formatMessage({
      defaultMessage:"Delete record",
      description: "Uploader, delete record dialog, delete record button"
    })}</Button>}
    cancelLabel={intl.formatMessage({
        defaultMessage: "Cancel",
      description: "Cancel button"
      })}
  >
    <p><FormattedMessage
      defaultMessage="You are about to delete the following medical record:"
      description="Uploader, delete record dialog, confirmation message"
    /></p>
    {
      isDicom(record)
        ? <AttachmentBox css="margin-bottom:1rem;">
          <AttachmentInfoContainer>
            <AttachmentIcon icon="dicom" css="font-size:44px;" className="desktop-only"/>
            <AttachmentDescription
              className="fs-exclude"><b>{record.description} {record.info?.studyDate && `(${formatDate(dicomDateToDate(record.info?.studyDate), defaultDateFormat(intl,"-"))})`}</b></AttachmentDescription>
            <AttachmentDetails
              className="fs-exclude"><b><FormattedMessage
              defaultMessage="Modalities"
              description="Uploader, dicom attachment, modalities label"
              />: </b>{record.info?.modalities?.join(", ")} (<FormattedMessage
              defaultMessage="{quantity} series"
              description="Uploader, dicom attachment, series quantity"
              values={{quantity: record.info?.series?.length}}
              />)</AttachmentDetails>
            <AttachmentDetails className="fs-exclude"><b><FormattedMessage
              defaultMessage="Patient"
              description="Uploader, dicom attachment, patient label"
              />: </b>{record.info?.patientName}</AttachmentDetails>
          </AttachmentInfoContainer>
        </AttachmentBox>
        : <AttachmentBox css="margin-bottom:1rem;">
          <AttachmentIcon icon={getRecordIcon(record)} className="desktop-only"/>
          <AttachmentInfoContainer>
            <AttachmentDescription className="fs-exclude"><b>{record.description}</b></AttachmentDescription>
            {(record.recordClass === Pathology || record.recordFormat === CCDA) &&
              <AttachmentDetails><b><FormattedMessage
                defaultMessage="Format"
                description="Uploader, pathology record format label"
                />: </b>{FormatToLabel[record.recordFormat] || record.recordFormat}
              </AttachmentDetails>}
          </AttachmentInfoContainer>
        </AttachmentBox>
    }
    <p><FormattedMessage
      defaultMessage="<b>Are you sure you want to delete it?</b> Once confirmed this cannot be undone."
      description="Uploader, delete record dialog, confirmation message"
      values={{b: str => <b>{str}</b>}}
    /></p>
  </ConfirmDialog>
}