import {ContextMenu, Li, ListSeparator, setNotification} from '@startlibs/components'
import React from 'react'
import {
  CASE_ARCHIVED,
  CASE_DRAFT,
  CASE_REJECTED,
  CASE_REVIEWED,
  PENDING_ASSIGNMENT,
  PENDING_REQUEST,
  REQUEST_REJECTED,
  UNDER_REVIEW,
  WAITING_ACCEPTANCE,
  WAITING_APPROVAL,
  WAITING_MEDICAL_RECORDS,
  WAITING_MORE_INFORMATION
} from '../../enums/CaseState'
import { useIsLocale } from '../../hocs/IfLocale';
import {useArchiveDialog} from '../hooks/useArchiveDialog'
import { useSetAsReviewedDialog } from '../hooks/useSetAsReviewedDialog';
import {useUnarchiveDialog} from '../hooks/useUnarchiveDialog'
import { useNavigate } from 'react-router';
import { getJwt } from '../../hooks/useJwt';
import { jwtPostFetcher } from '../../utils/authFetch';
import { useSetAsAcceptedDialog } from '../hooks/useSetAsAcceptedDialog';
import { lazyUserInfo } from '../../components/WithProvider';
import { SUPER } from '../../enums/UserRole';
import { useSetAsCompletedDialog } from '../hooks/useSetAsCompletedDialog';

export const AdminCaseCardMenu = ({caseRequest, refreshList, isOpen, toggle, editCategories, editCaseNumber, toggleAsPriority, archiveReasons, refreshArchiveReasons}) => {

  const navigate = useNavigate()
  const userInfo = lazyUserInfo.read()
  const openArchive = useArchiveDialog(caseRequest, () => {refreshList(); refreshArchiveReasons()}, archiveReasons)
  const openSetReviewed = useSetAsReviewedDialog(caseRequest, refreshList)
  const openSetCompleted = useSetAsCompletedDialog(caseRequest, refreshList)
  const openSetAccepted = useSetAsAcceptedDialog(caseRequest, refreshList)
  const openUnarchive = useUnarchiveDialog(caseRequest, refreshList, true, navigate)
  const isLegal = useIsLocale({contains:'LEGAL'})
  
  const getArchiveLabel = (state) => {
    switch (state) {
      case PENDING_REQUEST:
        return <Li label="Archived" icon="unavailable" onClick={() => {toggle(); openArchive()}}/>
      case CASE_DRAFT:
      case WAITING_MEDICAL_RECORDS:
      case WAITING_APPROVAL:
      case PENDING_ASSIGNMENT:
      case WAITING_ACCEPTANCE:
      case CASE_REJECTED:
      case UNDER_REVIEW:
        return <Li label="Archived" icon="unavailable" onClick={() => {toggle(); openArchive()}}/>
      case CASE_ARCHIVED:
      case REQUEST_REJECTED:
        return <Li label="Unarchived" onClick={() => {toggle(); openUnarchive()}}/>
    }
  }
  
  const getAcceptedLabel = (state, requestId) => {
    switch (state) {
      case PENDING_REQUEST:
        return <Li label={'Accepted'} onClick={() => {
          jwtPostFetcher(getJwt())('/api/admin/acceptCaseRequest?requestId=' + requestId)
            .then(() => {
              setTimeout(
                refreshList().then(() => {
                  setNotification("Case accepted");
                })
              , 300)
            })
            .finally(
              toggle()
            )
        }} />
      case WAITING_ACCEPTANCE:
      case CASE_REJECTED:
      case UNDER_REVIEW:
        return <Li label={'Accepted'} onClick={() => {toggle(); openSetAccepted()}}/>
      case WAITING_MORE_INFORMATION:
        return <Li label={'Accepted'} onClick={() => {toggle(); openSetAccepted()}}/>
    }
  }

  const getReviewedLabel = (state) => {
    switch (state) {
      case PENDING_REQUEST:
      case CASE_DRAFT:
      case WAITING_MEDICAL_RECORDS:
      case WAITING_APPROVAL:
      case PENDING_ASSIGNMENT:
      case WAITING_ACCEPTANCE:
      case CASE_REJECTED:
      case UNDER_REVIEW:
        return <Li label={'Reviewed'} onClick={() => {toggle(); openSetReviewed()}}/>
    }
  }

  const getCompletedLabel = (state, requestId, hasReport) => {
    switch (state) {
      case CASE_REVIEWED:
      // WAITING_MORE_INFORMATION
      if(!hasReport){
        return <Li label={'Completed'} onClick={() => {
          toggle();
          openSetCompleted()
        }}/>
      }
    }
  }

  const getSetAsContextMenu = (state, requestId, hasReport) => {
    switch (state) {
      case PENDING_REQUEST:
      case CASE_DRAFT:
      case WAITING_MEDICAL_RECORDS:
      case WAITING_APPROVAL:
      case PENDING_ASSIGNMENT:
      case WAITING_ACCEPTANCE:
      case UNDER_REVIEW:
      case CASE_REJECTED:
      case CASE_ARCHIVED:
      case REQUEST_REJECTED:
        return <Li label="Set as....">
          <ContextMenu my="top left" at="top right">
            {userInfo.role === SUPER && getAcceptedLabel(state, requestId)}
            {userInfo.role === SUPER && getReviewedLabel(state)}
            {userInfo.role === SUPER && showSeparatorInsideContextMenu(state)}
            {getArchiveLabel(state)}
            {userInfo.role === SUPER && getCompletedLabel(state, requestId, hasReport)}
          </ContextMenu>
        </Li>
      case CASE_REVIEWED:
        if((!hasReport) && (userInfo.role === SUPER)){
          return <Li label="Set as....">
            <ContextMenu my="top left" at="top right">
              {userInfo.role === SUPER && getCompletedLabel(state, requestId, hasReport)}
            </ContextMenu>
          </Li>
        }
    }
  }

  const showSeparator = (state) => {
    switch (state) {
      case PENDING_REQUEST:
      case CASE_DRAFT:
      case WAITING_MEDICAL_RECORDS:
      case WAITING_APPROVAL:
      case PENDING_ASSIGNMENT:
      case WAITING_ACCEPTANCE:
      case UNDER_REVIEW:
      case CASE_REJECTED:
      case CASE_ARCHIVED:
      case REQUEST_REJECTED:
        return <ListSeparator/>
    }
  }

  const showSeparatorInsideContextMenu = (state) => {
    switch (state) {
      case PENDING_REQUEST:
      case CASE_DRAFT:
      case WAITING_MEDICAL_RECORDS:
      case WAITING_APPROVAL:
      case PENDING_ASSIGNMENT:
      case WAITING_ACCEPTANCE:
      case UNDER_REVIEW:
      case CASE_REJECTED:
      // case CASE_ARCHIVED:
      case REQUEST_REJECTED:
        return <ListSeparator/>
    }
  }

  return isOpen && <ContextMenu>
    <Li label="View" linkTo={`/admin/request/${caseRequest.requestCode}`}/>
    {editCategories && <Li label="Edit divisions" onClick={editCategories(caseRequest)}/>}
    {editCaseNumber && isLegal && <Li label="Edit case number" onClick={editCaseNumber(caseRequest)}/>}
    <Li icon={caseRequest.markedAsPriorityEpochMilli ? "star" : "star-line"} label={caseRequest.markedAsPriorityEpochMilli ? "Remove as priority" : "Mark as priority"} onClick={toggleAsPriority(caseRequest)}/>
    <ListSeparator/>
    <Li disabled={caseRequest.externalAccessBlocked} icon={"copy"} label={"Copy case link"} onClick={() => {
      const caseLink = `${window.location.origin}/access?code=${caseRequest.requestCode}${caseRequest?.preferredLanguage ? `&locale=${caseRequest?.preferredLanguage}` : ""}`
      navigator.clipboard.writeText(caseLink).then(() => setNotification("Copied link to clipboard"))
    }}/>
    <Li icon={caseRequest.externalAccessBlocked ? "unlock-line" : "lock-line"} label={caseRequest.externalAccessBlocked ? "Unlock patient access" : "Lock patient access"} 
      onClick={() => {
        if(caseRequest.externalAccessBlocked){
          jwtPostFetcher(getJwt())(`/api/admin/unblockExternalAccess?requestId=${caseRequest.id}`)
            .then((response) => {
              refreshList()
              // setCaseRequest(_.set('caseInfo.externalAccessBlocked',!caseRequest.caseInfo.externalAccessBlocked))  
            })
            .catch((error) => {
              setNotification({ type: "alert", msg: "It was not possible to unlock patient access. Please try again." })
              console.log(error)
            })
        }else{
          jwtPostFetcher(getJwt())(`/api/admin/blockExternalAccess?requestId=${caseRequest.id}`)
            .then((response) => {
              refreshList()
              // setCaseRequest(_.set('externalAccessBlocked',!caseRequest.caseInfo.externalAccessBlocked))  
              // setCaseRequest(_.update('caseInfo.externalAccessBlocked', (v) => !v))
            })
            .catch((error) => {
              setNotification({ type: "alert", msg: "It was not possible to lock patient access. Please try again." })
              console.log(error)
            })
        }
      }}
    />
    {showSeparator(caseRequest.state)}
    {getSetAsContextMenu(caseRequest.state, (caseRequest.id || caseRequest.requestId), caseRequest?.hasReport)}
  </ContextMenu>

}
