import {Errors, TextInput, WithForm} from '@startlibs/form'
import {addQueryString, formFetchWithResponse, getFetcher} from '@startlibs/utils'
import {useToggle} from '@startlibs/core';
import React, {useContext} from 'react'
import _ from 'lodash/fp'
import {CardActionButton, CardHeader, LinkSSO, SignInCard, SignInLayout} from '../components/SigninLayout';
import {EmailVerificationPage} from '../patient/status/EmailVerificationPage'
import {buildValidation, required, responseFailure} from '../utils/validation'
import {wrapPromise} from '../hooks/useSuspense'
import styled from 'styled-components';
import { Icon } from '@startlibs/components';
import { PurviewFooter } from '../components/PurviewFooter';
import {FormattedMessage, useIntl} from "react-intl";
import {LocaleContext} from "../components/I18NProvider";


export const PasswordVisibilityButton = styled(Icon) `
  position: absolute;
  right: 1rem;
  bottom: 5px;
  font-size: 24px;
  color: rgba(0,0,0,0.5);
  cursor: pointer;
  :hover {
    color: rgba(0,0,0,0.75);
  }
`

export const ACCESS_ERROR = (intl) => intl.formatMessage({
  defaultMessage: 'Invalid email',
  description: 'Expert login error, invalid email'
})

const lazyPasSystem = wrapPromise(() => getFetcher("/pasapi/systemref",null,{referrer:'/login/'}))

export const ExpertLogin = ({navigate, setCaseRequest, isNurse}) => {
  const intl = useIntl()
  const emailVerificationPage = useToggle()
  const pasSystem = lazyPasSystem.read()
  const providerInfo = {logoURL:pasSystem.systemWebHooks.systemLogoWebHook}
  const {locale} = useContext(LocaleContext)
  
  var DEFAULT_VALUES = {
    login: ''
  }
  
  const preValidation = buildValidation({
    login: [required],
  })
  const onSuccess = (req, [{jwt, url}, {status}]) => {
    // window.location = url
    if (url) {
      window.location = addQueryString(url, {locale})
      return new Promise(() => {})
    } else if (status === 256) {
      emailVerificationPage.open()
    }
  }

  const onFailure = responseFailure((data, {status}) =>
    // console.log(data)
    (status === 401 && {
      'login': ACCESS_ERROR(intl),
      'dob': ACCESS_ERROR(intl)
    }) ||
    (status === 403 && {
      'login': [intl.formatMessage({
        defaultMessage: 'This user is suspended. Please contact an administrator.',
        description: 'Expert login error, user is suspended'
      })]
    }) ||
    (status === 404 && {'login': [ACCESS_ERROR(intl)]})
  )

  if (emailVerificationPage.isOpen) {
    return <EmailVerificationPage providerInfo={providerInfo}/>
  }

  return <><SignInLayout providerInfo={providerInfo} withLocale>
    <SignInCard>
      <CardHeader>
        <h1><FormattedMessage
          defaultMessage="Sign in to Expert View"
          description="Expert login page title"
        /></h1>
        <p css="max-width:22rem;margin:1rem auto 1rem;">
          <FormattedMessage
            defaultMessage="Enter your details below:"
            description="Expert login page instructions"
            />
        </p>
      </CardHeader>
      <WithForm
        alwaysSave
        preValidation={preValidation}
        onSuccess={onSuccess}
        onFailure={onFailure}
        values={DEFAULT_VALUES}
        action={formFetchWithResponse('/api/expert/authenticate')}
      >{form => <>

        <TextInput
          autoFocus
          name="login"
          autoComplete="login"
          label={intl.formatMessage({
            defaultMessage: "Username:",
            description: "Expert login username input label"
          })}
          placeholder={intl.formatMessage({
            defaultMessage: "Enter your username",
            description: "Expert login username input placeholder"
          })}
          path="login"
        />
        <Errors/>
        <CardActionButton highlight isLoading={form.isLoading} type="submit"><FormattedMessage
          defaultMessage="Access Expert View"
          description="Expert login submit button"
        /></CardActionButton>
        {pasSystem.ssoEntities?.map(sso => <LinkSSO href={sso.url}>{sso.label || intl.formatMessage({
          defaultMessage:"Use single sign-on (SSO) instead",
          description:"Expert login SSO link"
        })}</LinkSSO>)}
        </>}</WithForm>

    </SignInCard>
  </SignInLayout>
  <PurviewFooter />
  </>
}
