import React from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components/macro'
import {Button, Dialog, Loading} from "@startlibs/components";
import {UploadStatusIcon} from "../styled/UploaderStyled";
import {useUIDataSelector} from "../../service/hooks/useUIDataSelector";
import {useDoAction} from "../../service/hooks/useDoAction";
import {useUploadQueueSelector} from "../../service/hooks/useUploadQueueSelector";
import {UIAction} from "../../service/UIAction";
import {FormattedMessage, useIntl} from "react-intl";

styled

export const CancelUploadsDialog = ({dropFileBoxRef}) => {
  const intl = useIntl()
  const uploadQueue = useUploadQueueSelector()
  const doAction = useDoAction()
  const uploadsProcessing = uploadQueue.find(_.get('uploading.length'))
  const onlyFailedUploads = uploadQueue.find(_.get('failed.length')) && !uploadsProcessing
  const closeDialog = () => doAction(UIAction.ToggleCancelUploadsDialog)
  const cancelUploadsPromissable = useUIDataSelector('dialogs.cancelUploads')
  const confirmProceed = () => {
    cancelUploadsPromissable.res()
    closeDialog()
  }

  return <Dialog
    // title={
    //   uploadQueue.length ? 'There are still uploads remaining'
    //     : (onlyFailedUploads ? 'There are failed uploads to check'
    //       : 'Uploads are now finished')
    // }
    title={
      onlyFailedUploads ? intl.formatMessage({
          defaultMessage: 'There are failed uploads to check',
          description: 'Uploader, cancel uploads dialog, check failures title'
        })
        : (  uploadQueue.length ? intl.formatMessage({
            defaultMessage: 'There are still uploads remaining',
            description: 'Uploader, cancel uploads dialog, still uploading title'
          })
          : intl.formatMessage({
            defaultMessage: 'Uploads are now finished',
            description: 'Uploader, cancel uploads dialog, finished uploads title'
          }))
    }
    closeDialog={closeDialog}
    footer={<>
      <Button
        onClick={() => {
          closeDialog();
          doAction(UIAction.ScrollToDropFilesBox)
        }}
      ><FormattedMessage
        defaultMessage="Check uploads"
        description="Uploader, cancel uploads dialog, check uploads button"
      /></Button>
      {
        !uploadQueue.length &&
        <Button highlight onClick={confirmProceed}><FormattedMessage
          defaultMessage="Proceed"
          description="Proceed button"
        /></Button>
      }
    </>}
  >
    {
      onlyFailedUploads
        ? <>
          <FormattedMessage
            defaultMessage={`<p>You are about to leave this page but there are some failed uploads to check.</p>
          <p>Please check your failed uploads before proceeding.</p>`}
            description="Uploader, cancel uploads dialog, check failures message"
            values={{p: str => <p>{str}</p>}}
          />
          <UploadStatusIcon icon="failure" failure/>
        </>
        : uploadsProcessing
          ? <>
            <FormattedMessage
              defaultMessage={`<p>You are about to leave this page but there are still uploads to be handled.</p>
            <p>Please wait for the uploads to finish or review them before leaving.</p>`}
              description="Uploader, cancel uploads dialog, still uploading message"
              values={{p: str => <p>{str}</p>}}
              />
            <Loading css="margin: 2rem auto;"/>
          </>
          : <>
            <p><FormattedMessage
              defaultMessage="All your uploads have now finished. It is safe to leave this page."
              description="Uploader, cancel uploads dialog, finished uploads message"
            /></p>
            <UploadStatusIcon icon="check" check/>
          </>
    }
  </Dialog>
}

export const CancelUploadsConfirmation = React.forwardRef((props,ref) => {
  const queueSize = useUploadQueueSelector('length')
  const doAction = useDoAction()
  const toggleDialog = () => doAction(UIAction.ToggleCancelUploadsDialog)

  React.useImperativeHandle(ref, () => ({
    confirm: () => {
      if (queueSize) {
        return toggleDialog().promise
      } else {
        return Promise.resolve()
      }
    }
  }), [queueSize])
  return null
})