import styled from 'styled-components/macro';
import {lazyProviderInfo} from "../components/WithProvider";
import {willUseSharedState} from "@startlibs/core";

styled;

export const getLanguageInfo = (locale, providerInfo, partial) =>
  providerInfo?.preferredLanguage?.options?.find(o =>
    partial
    ? o.id.includes(locale)
    : o.id == locale
  )

export const getDefaultLanguageInfo = (providerInfo) =>
  providerInfo?.preferredLanguage?.options?.find(o => o.id == (providerInfo?.preferredLanguage?.defaultLanguage || providerInfo?.preferredLanguage?.default)) ||
  providerInfo?.preferredLanguage?.options[0]



let enabled = false
let withUrlParam = false
export const I18NInitEnable = () => {
  enabled = true
  return null
}
export const shouldAddLocaleUrlParam = () => withUrlParam
export const getInitLocale = () => {
  const providerInfo = lazyProviderInfo.read()
  if (providerInfo.preferredLanguage.options.length < 2 || !enabled) {
    const defaultLanguage = getDefaultLanguageInfo(providerInfo)
    return defaultLanguage.id
  }
  withUrlParam = true
  // const providerInfo =  {}
  const params = new URLSearchParams(window.location.search)
  const urlLocale = params.get("locale")
  if (urlLocale) {
    const languageInfo = getLanguageInfo(urlLocale, providerInfo)
    if (languageInfo) {
      return languageInfo.id
    }
  }
  const storedLocally = localStorage.getItem("last-locale")
  if (storedLocally) {
    const languageInfo = getLanguageInfo(storedLocally, providerInfo)
    if (languageInfo) {
      return languageInfo.id
    }
  }
  const browserLang = getLanguageInfo(navigator.language, providerInfo)
  if (getLanguageInfo(browserLang, providerInfo, true)) {
    return browserLang
  }
  const defaultLanguage = getDefaultLanguageInfo(providerInfo)
  return defaultLanguage.id
}