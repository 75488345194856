import {Button} from '@startlibs/components'
import React from 'react'
import {ConfirmDialog, useConfirmDialog} from '../../hooks/useConfirmDialog'
import { ExpertCaseCard } from '../ExpertCaseCard';
import {getJwt} from '../../hooks/useJwt'
import {jwtPostFetcher} from '../../utils/authFetch'
import {FormattedMessage, useIntl} from "react-intl";

export const useAcceptCase = (caseRequest,onSuccess) => {
  const intl = useIntl()
  return useConfirmDialog(
    <ConfirmDialog
      title={intl.formatMessage({
        defaultMessage: "Accept case",
        description: "Expert accept case dialog title"
      })}
      action={() => jwtPostFetcher(getJwt())("/api/reviewer/acceptCase/" + (caseRequest.requestId || caseRequest.id))}
      onSuccess={onSuccess}
      closeLabel={intl.formatMessage({
        defaultMessage:"Cancel",
        description: "Cancel Button"
      })}
      notify={intl.formatMessage({
        defaultMessage: "Case accepted successfully.",
        description: "Expert accept case dialog success message"
      })}
      confirm={
        <Button highlight><FormattedMessage
          defaultMessage="Accept"
          description="Expert accept case dialog accept button"
        /></Button>
      }
    >
      <ExpertCaseCard caseRequest={caseRequest} viewOnly />
      <p><FormattedMessage
        defaultMessage="By accepting this case you agree to provide a professional opinion."
        description="Expert accept case dialog info"
      /></p>
    </ConfirmDialog>
  )
}
