import React, {useContext} from 'react'
import styled from 'styled-components/macro'
import {isMinified} from "./FileinputBox";
import {DEVICE, DISK} from "../enums/UploaderStepsManagement";
import {transparentize} from "polished";
import {getColor} from "@startlibs/utils";
import {UploaderConfigContext} from "../service/UploaderConfigContext";
import {useIsUploading} from "../service/hooks/useUploadQueueSelector";
import {FormattedMessage} from "react-intl";

const FeedbackBox = styled.div `
  background-color: ${props => transparentize(0.9, getColor('success')(props))};
  padding: 1.5rem;
  border-radius: 6px;
  margin-top: 1rem;
  h3 {
    font-size: 15px;
    margin-bottom: 0.25rem;
  }
  p {
    margin-bottom: 0;
  }
`

export const UploadingFeedback = () => {
  const {mode} = useContext(UploaderConfigContext)
  const isUploading = useIsUploading()
  if (!isMinified(mode) || !isUploading) {
    return null
  }
  return <FeedbackBox>
        <h3><FormattedMessage
          defaultMessage="Your records are being uploaded"
          description="Uploader, uploading feedback"
        /></h3>
        {mode === DEVICE  && <p><FormattedMessage
          defaultMessage="You can add more records or proceed to next step while they are being uploaded"
          description="Uploader, device mode upload feedback"
        /></p>}
        {mode === DISK    && <p><FormattedMessage
          defaultMessage="Keep your disc on the computer until all files are uploaded"
          description="Uploader, disk mode upload feedback"
        /></p>}
      </FeedbackBox>
}