import {
  Button,
  ContextMenu,
  Icon,
  Li,
  ListSeparator,
  SplitColumnsContainer
} from '@startlibs/components';
import { darken } from 'polished';
import {getColor, media} from '@startlibs/utils';
import {useNavigate} from 'react-router';
import { usePopupToggle, useToggle, willUseSharedState } from '@startlibs/core';
import React, {useEffect, useRef} from 'react'
import styled, {css} from 'styled-components';
import {ProviderTitle} from '../components/ProviderTitle'
import {PurviewFooter} from '../components/PurviewFooter';
import {lazyProviderInfo} from '../components/WithProvider'

const LandingPageWrapper = styled.div`
  padding-bottom: 8rem;
  font-size: 14px;
  .nowrap {
    white-space: nowrap;
  }
  .row {
    padding: 6rem 2rem;
    &:nth-child(even) {
      background: white;
    }
  }
  .content-wrapper {
    max-width: 1240px;
    margin: 0 auto;
  }
  .intro-row {
    .logo-wrapper {
      min-width: 260px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 2rem;
      img {
        max-width: 300px;
        margin: 2rem;
        width: 100%;
      }
    }
    ${SplitColumnsContainer} {
      align-items: center;
    }
    .intro-text-col {
      min-width: 260px;
      h1 {
        font-size: 30px;
        color: ${getColor('main')};
        margin-bottom: 1rem;
      }
      .custom-text {
        max-width: 40rem;
        color: ${getColor('gray120')};
        margin: 2rem 0;
      }
      .buttons-wrapper {
        display: flex;
        flex-wrap: wrap;
        ${media.max(994)`
          ${Button} {
            margin-bottom: 1rem;
            margin-left: 0;
            flex-basis: 250px;
          }
        `}
      }
    }
  }
  .feature-row {
    padding: 4rem 2rem;
    .content-wrapper {
      display: flex;
      align-items: center;
      .text-col {
        flex-basis: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        h2 {
          font-size: 21px;
          color: ${getColor('main')};
          margin-bottom: .5rem;
        }
        .text-wrapper {
          color: ${getColor('gray120')};
          width: 100%;
          max-width: 450px;
        }
      }
      .image-col {
        flex-basis: 50%;
        padding: 0 2rem;
        img {
          width: 100%;
          ${media.min(768)`
            &.overflow-ipad {
              margin-top:-10rem;
              margin-bottom: -10rem;
            }
            &.overflow-phones {
              margin-top: -7rem;
              margin-bottom: -4rem;
            }
          `}
        }
      }
    }
  }
  .row.cta {
    background-color: ${getColor('main')};
    text-align: center;
    h2 {
      color: white;
      margin-bottom: 2rem;
      font-size: 21px;
    }
    .cta-btn {
      background-color: white;
      color: ${getColor('main')};
      min-width: 250px;
      padding: 1.5rem 3rem 1.75rem;
      font-size: 16px;
      font-weight: bold;
      &:hover {
        background-color: rgba(255,255,255,0.925);
      }
    }
  }
  ${media.max(768)`
    .intro-row {
      padding-top: 2rem;
      .content-wrapper {
        ${SplitColumnsContainer} {
          flex-wrap: wrap;
          > div {
            flex-basis: 100%;
            justify-content: center;
            text-align: center;
          }
        }
        .logo-wrapper {
          margin-bottom: 2rem;
        }
        .intro-text-col {
          .custom-text {
            max-width: 100%;
          }
          .buttons-wrapper {
            flex-basis: 100%;
            justify-content: center;
            ${Button} ~ ${Button} {
              margin-left: 1rem;
            }
          }
        }
      }
    }
    .feature-row {
      .content-wrapper {
        flex-direction: column;
        text-align: center;
        .text-col {
          h2 {
            font-size: 18px;
            line-height: 1.3;
          }
          order: 2;
        }
        .image-col {
          order: 1;
          margin-bottom: 2rem;
          img {
            max-width: 550px;
          }
        }
      }
    }
  `}
  ${media.max(562)`
    .intro-row .content-wrapper {
      .intro-text-col {
        .buttons-wrapper {
          display: block;
          ${Button} {
            display: block;
            margin: 0 auto;
            width: 240px;
          }
          ${Button} ~ ${Button} {
            margin: 1rem auto 0;
          }
        }
      }
    }
  `}
  /* IE11 Adjustments */
  @media (max-width: 486px) and (-ms-high-contrast:none) {

    .feature-row .content-wrapper { display: block; } /* IE10 */
     *::-ms-backdrop, .feature-row .content-wrapper { display: block; } /* IE11 */

   }
`

const NavbarGetStartedContainer = styled.div `
  color: ${getColor('gray120')};
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  transition: 0.5s ease;
  opacity: 0;
  ${Button} {
    margin-left: 0.75rem;
  }
  ${media.desktop`
    border-right: 1px solid ${getColor('gray210')};
    margin-right: 1.5rem;
    padding-right: 1.5rem;
  `}
  ${media.mobile`
    display: none;
  `}
`
const NavbarSignInOptions = styled.div `
  color: ${getColor('gray120')};
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  ${Button} {
    margin-left: 0.75rem;
  }
`

const Navbar = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background: white;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  z-index: 3;
  transition: 0.5s ease;
  .logo-container {
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    width: 120px;
    height: 3rem;
    transition: 0.5s ease;
    opacity: 0;
  }
  .navbar-buttons {
    display: flex;
    align-items: center;
  }
  ${media.mobile`
    position: absolute;
    .logo-container {
      width: 100px;
    }
    ${props => props.scrolled && css`
      position: fixed;
      transform: translateY(0);
      ${NavbarSignInOptions} {
        display: none;
      }
      ${NavbarGetStartedContainer} {
        display: flex;
      }
    `}
  `}
  ${props => props.scrolled && css`
    opacity: 1;
    box-shadow: 0 0 6px 0 rgba(0,0,0,0.15);
    .logo-container {
      opacity: 1;
    }
    ${NavbarGetStartedContainer} {
      opacity: 1;
    }
  `}
`

export const [useUrgentNoticeDismissed] = willUseSharedState()

export const UrgentNotice = styled.div`
  max-width: 320px;
  margin-top: 1.5rem;
  font-size: 12px;
  color: ${getColor('alert')};
  font-weight: 600;
  ${media.max(768)`
    margin: 1.5rem auto 0;
  `}
  ${props => props.isCreateCase && css`
    max-width: 100%;
    margin-top: -1rem;
    margin-bottom: 2.5rem;
  `}
`

const SignInOptions = styled.div `
  color: ${getColor('gray120')};
  margin-top: 1.5rem;
  a {
    position: relative;
    font-weight: bold;
    color: ${getColor('main')};
    cursor: pointer;
    margin-left: 0.25rem;
    :hover {
      color: ${props => darken(0.1, getColor("main")(props))};
    }
    .text-link {
      text-decoration: underline;
    }
    ${Icon} {
      font-size: 11px;
    }
  }
  ${props => props.onFooter && `
    &, a {
      color: white;
    }
    a:hover {
      color: rgba(255,255,255,0.8);
    }
  `}
`

const SignInOptionsMenu = () => {
  return <ContextMenu withoutIcons>
    <Li label="I'm an expert" linkTo="/expert"/>
    <Li label="I'm an administrator" linkTo="/admin"/>
    <ListSeparator/>
    <Li label="Learn more about Expert View" href="https://www.purview.net/expert-view" target="_blank" rel="noopener noreferrer" />
  </ContextMenu>
}

export const LandingPage = () => {
  const navigate = useNavigate()
  const providerInfo = lazyProviderInfo.read()
  const navbarOnScroll = useToggle(false)
  const navbarTrigger = useRef()


  const patientCaseCreation = providerInfo?.patientCaseCreation

  useEffect(() => {
    const addNavbarOnScroll = () => {
      navbarOnScroll.openWith(navbarTrigger.current.getBoundingClientRect().top < 0)
    }
    addNavbarOnScroll()
    window.addEventListener("scroll",addNavbarOnScroll)
    return () => window.removeEventListener("scroll",addNavbarOnScroll)
  },[])

  const [dismissed,setDismissed] = useUrgentNoticeDismissed()

  const navbarSignInOptions = usePopupToggle()
  const headerSignInOptions = usePopupToggle()
  const footerSignInOptions = usePopupToggle()

  return <> <LandingPageWrapper>
    <ProviderTitle/>
    <Navbar scrolled={navbarOnScroll.isOpen}>
      <div className="logo-container" css={`background-image:url('${providerInfo.logoURL}');`} />
      <div className="navbar-buttons">
        {patientCaseCreation && <NavbarGetStartedContainer>
          For patients:
          <Button highlight small rounded onClick={() => navigate("/patient/start")}>Get started</Button>
        </NavbarGetStartedContainer>}
        <NavbarSignInOptions>
          Not a patient?
          <Button small highlight outline rounded withDropdown onClick={navbarSignInOptions.open}>
            Click here
            {navbarSignInOptions.isOpen &&
              <SignInOptionsMenu/>
            }
          </Button>
        </NavbarSignInOptions>
      </div>
    </Navbar>
    <div className="row intro-row">
      <div className="content-wrapper" ref={navbarTrigger}>
        <SplitColumnsContainer>
          <div className="logo-wrapper">
            <img src={providerInfo.logoURL} alt="Logo"/>
          </div>
          <div className="intro-text-col">
            <h1>Expert View™ for&nbsp;patients</h1>
            <div className="custom-text" dangerouslySetInnerHTML={{__html:providerInfo.customMessage}} />
            <div className="buttons-wrapper">
              {patientCaseCreation && <Button highlight icon="plus-circle" onClick={() => navigate("/patient/start")}>Get started</Button>}
              <Button icon="sign-out"  onClick={() => navigate("/access")}>Access existing request</Button>
            </div>
            <SignInOptions>Not a patient? <a onClick={headerSignInOptions.open}>
              <span className="text-link">Click here</span> <Icon icon="arrow-down"/>
              {headerSignInOptions.isOpen &&
                <SignInOptionsMenu/>
              }
            </a>
            </SignInOptions>
            {providerInfo.emergencyDisclaimer && !dismissed &&
              <UrgentNotice lightYellow flex>
                {providerInfo.emergencyDisclaimer}
              </UrgentNotice>
            }
        </div>
        </SplitColumnsContainer>
      </div>
    </div>
    <div className="row feature-row">
      <div className="content-wrapper">
        <div className="image-col">
          <img src="/public/landing-page/mac-description.png" />
        </div>
        <div className="text-col">
          <div className="text-wrapper">
            <h2>Describe your request</h2>
            <p>Tell us the reason for your Expert View request and any specific questions you may have.</p>
          </div>
        </div>
      </div>
    </div>
    <div className="row feature-row">
      <div className="content-wrapper">
        <div className="text-col">
          <div className="text-wrapper">
            <h2>Upload medical records</h2>
            <p>Add relevant medical records (previous diagnoses, medical images, pathology reports, test results, etc.) to your Expert View request.</p>
          </div>
        </div>
        <div className="image-col">
          <img src="/public/landing-page/mac-upload.png" />
        </div>
      </div>
    </div>
    <div className="row feature-row">
      <div className="content-wrapper">
        <div className="image-col">
          <img className="overflow-phones" src="/public/landing-page/phone-reports.png" />
        </div>
        <div className="text-col">
          <div className="text-wrapper">
            <h2>Receive a detailed report</h2>
            <p>View, print and share your resulting Expert View report.</p>
          </div>
        </div>
      </div>
    </div>
    <div className="row cta">
      <div className="content-wrapper">
        <h2>Get your Expert View™ today</h2>
        {patientCaseCreation && <Button className="cta-btn" onClick={() => navigate("/patient/start")}>Get started</Button>}
        <SignInOptions onFooter>Not a patient? <a onClick={footerSignInOptions.open}>
          <span className="text-link">Click here</span> <Icon icon="arrow-down"/>
          {footerSignInOptions.isOpen &&
            <SignInOptionsMenu/>
          }
        </a>
        </SignInOptions>
      </div>
    </div>
  </LandingPageWrapper>
  <PurviewFooter />
</>}
