export const defaultDateFormat = (intl,separator="/") => intl.formatMessage({
  defaultMessage: "MM/dd/yyyy",
  description: "Default date format"
}).replace(/\//g,separator)

export const defaultDateTimeFormat = (intl,separator="/") => intl.formatMessage({
  defaultMessage: "MM/dd/yyyy at hh:mm",
  description: "Default date time format"
}).replace(/\//g,separator)

export const simpleDateTimeFormat = (intl,separator="/") => defaultDateFormat(intl,separator) + " - hh:mm"