import React, {useContext} from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components/macro'
import {useUIDataSelector} from "../../service/hooks/useUIDataSelector";
import {
  AttachmentActions,
  AttachmentBoxFlexContainer,
  AttachmentCheckbox,
  DropdownButton,
  DropdownIcon,
  TextButton, UploadedDate
} from "../AttachmentBoxStyles";
import {UIAction} from "../../service/UIAction";
import {useDoAction} from "../../service/hooks/useDoAction";
import {ContextMenu, Icon, Li, ListSeparator, Loading} from "@startlibs/components";
import {dicomDateToDate, formatDateNoUTC} from "../../utils";
import {Accepted, Rejected} from "../../enums/RecordState";
import {UploaderConfigContext} from "../../service/UploaderConfigContext";
import {Uploaded} from "../../service/enums/RecordStatus";
import {usePopupToggle} from "@startlibs/core";
import {UploaderAction} from "../../service/UploaderAction";
import {GroupAction} from "../../service/GroupAction";
import {useNewDownload, useViewRecord} from "../../service/utils/downloadFile";
import {DicomStudy, NonCompliantDicom} from "../../enums/RecordFormat";
import {canPlay, canView} from "../../service/utils/recordUtils";
import {ADMIN, PATIENT, PROVIDER} from "../../enums/UserRoles";
import { useEffect } from 'react';
import { DEVICE, DISK } from '../../enums/UploaderStepsManagement';
import { jwtPostFetcher } from '../../utils/authFetch';
import { formatDate } from '@startlibs/utils';
import {FormattedMessage, useIntl} from "react-intl";
import {defaultDateFormat, defaultDateTimeFormat, simpleDateTimeFormat} from "../../utils/intlDateFormat";

styled

export const RecordRowActions = ({record, isMinified, group, isDragDisabled, deleteRecord}) => {
  const config = useContext(UploaderConfigContext)
  const intl = useIntl()
  const {
    role,
    mode,
    allowDownloadMedicalImages,
    isApp,
    withoutDelete,
    disabled,
    locations
  } = config
  const isSelectMode = useUIDataSelector('selectMode')
  const selectedRecords = useUIDataSelector('selectedRecords')
  const doAction = useDoAction()
  
  useEffect(() => {
    
    if(record?.recordUID && role == PATIENT && (record?.fileExtension?.toLowerCase() == 'jpg' || record?.fileExtension?.toLowerCase() == 'jpeg')){

      // Files uploaded by patient will be reorganized later. 
      // We can make sure that the files will be hidden if there are more than 10 uploaded in a device or disk.
      // We need this to make sure that the combination of 6 in a device and 8 in a disk wont be hidden.
      if(mode == DEVICE){
        var patDevJpgs = JSON.parse(window.localStorage.getItem("patDevJpgs")) || []
        if(!patDevJpgs.includes(record.recordUID)){
          patDevJpgs = patDevJpgs.concat(record.recordUID)
          window.localStorage.setItem("patDevJpgs",JSON.stringify(patDevJpgs))
        }
      }
      if(mode==DISK){
        var patDiscJpgs = JSON.parse(window.localStorage.getItem("patDiscJpgs")) || []
        if(!patDiscJpgs.includes(record.recordUID)){
          patDiscJpgs = patDiscJpgs.concat(record.recordUID)
          window.localStorage.setItem("patDiscJpgs",JSON.stringify(patDiscJpgs))
        }
      }
    }
  }, [record.recordUID])
  const submitedBy = (record) => {
    // return ''
    if(role == ADMIN && record.stateContext && record.stateContext.Submitted && record.stateContext.Submitted.by) {
      return (record.stateContext.Submitted.by.includes("@") ? (" by " +record.stateContext.Submitted.by) : record.stateContext.Submitted.by.includes("Patient") ? ' by patient' : locations.find(({id}) => id == record.stateContext.Submitted.by)?.name !== undefined ? " by " +locations.find(({id}) => id == record.stateContext.Submitted.by)?.name : '')
    }else{
      return ''
    }
  }

  if (isSelectMode) {
    return <div onClick={() => doAction(UIAction.ToggleSelectedRecord, record.key)}>
      <AttachmentCheckbox
        raw
        value={selectedRecords.includes(record.key)}
        setValue={() => doAction(UIAction.ToggleSelectedRecord, record.key)}
      />
    </div>
  }

  if (record.status !== Uploaded && record.status !== Accepted) {
    return null
  }

  if (isMinified) {
    return <AttachmentActions>
      <div className="buttons-wrapper">
        {
          !disabled && !withoutDelete &&
          <a className="link" onClick={() => doAction(UIAction.ToggleDeleteRecordDialog, record)}><FormattedMessage
            defaultMessage="Delete"
            description="Delete button"
          /></a>
        }
      </div>
    </AttachmentActions>
  }
  
  const isViewable = canView(record) && record.key !== NonCompliantDicom
  const isPlayable = canPlay(record)

  return <AttachmentActions>
    <div className="buttons-wrapper">
      {
        (record.status === Uploaded || record.status === Accepted)  && !isApp &&
        <>
          {
            // ((allowDownloadMedicalImages) || (!allowDownloadMedicalImages && record.recordClass !== Radiology) || (!allowDownloadMedicalImages && !isViewable && !isPlayable)) && <DownloadButton config={config} record={record}/>
            (allowDownloadMedicalImages  || (!allowDownloadMedicalImages && !isViewable && !isPlayable)) && <DownloadButton config={config} record={record}/>
          }
          {
            isViewable && <ViewButton config={config} record={record} hideDownloadButton={!allowDownloadMedicalImages}/>
          }
          {
            isPlayable && <ViewButton config={config} record={record} hideDownloadButton={!allowDownloadMedicalImages} icon="video" label={intl.formatMessage({
              defaultMessage: "Play",
              description: "Uploader, play button"
            })}/>
          }
        </>
      }
      {
        !disabled && !withoutDelete &&
        <DropDownButton role={role} record={record} group={group} isDragDisabled={isDragDisabled} doAction={doAction} deleteRecord={deleteRecord}/>
      }
    </div>
    <AttachmentBoxFlexContainer>
      {
        record.uploadDate &&
        <UploadedDate>
          <FormattedMessage
            defaultMessage="Uploaded"
            description="Uploader, uploaded date label"
          />: {
            formatDateNoUTC(new Date(record.uploadDate), simpleDateTimeFormat(intl,"/")).indexOf('NaN') >= 0
            ? record.info && record.info.uploadDate 
              ? formatDateNoUTC(new Date(record.info.uploadDate), simpleDateTimeFormat(intl,"/")) + submitedBy(record)
              : null
            : formatDateNoUTC(new Date(record.uploadDate), simpleDateTimeFormat(intl,"/")) + submitedBy(record)
          }
        </UploadedDate>
      }
    </AttachmentBoxFlexContainer>
  </AttachmentActions>
}

export const getFilenameFromRecord = (intl, record) => {
 
  const { description, fileExtension, info, key, recordUIDs } = record;

  // Handle NonCompliantDicom case
  if (key === "NonCompliantDicom") {
    const count = recordUIDs?.length || 0;
    if (count > 0){
      return `${count} Non compliant DICOM file${count > 1 ? 's' : ''}`;
    }else{
      return `All Non compliant DICOM files`;
    }
  }

  let name = description || (info && info.description) || (info && info.studyDescription) || "";
  // let ext = fileExtension || (info && info.fileExtension) || "";

  // Handle DicomStudy case
  if ((info && info.type === "DicomStudy") && (!name || !info.studyDescription)) {
    const studyDate = dicomDateToDate(info.studyDate)
    const formattedDate = !isNaN(studyDate) && `(${formatDate(studyDate,defaultDateFormat(intl,"-"))})`
    
    if(studyDate){
      name = `DICOM Study ${formattedDate ? formattedDate : ''}`;
    }else{
      name = `DICOM Study`;
    }
    // ext = ""
  }

  // Remove the file extension from the name if it's already present
  // if (name.toLowerCase().endsWith(`.${ext.toLowerCase()}`)) {
  //   ext = "";
  // }

  // Add file extension to the name if it's not empty
  // if (ext) {
  //   name = `${name}.${ext}`;
  // }

  return name;
}


const DownloadButton = ({config, record}) => {
  const intl = useIntl()
  const {
    requestId,
    apiEndpoints: {downloadFiles: downloadUrl},
    worklistViewerJwt,
    role
  } = config

  const startDownloadAndLog = () => {
    tryDownload()
    if(role !== PROVIDER){
      try{
        const fileName = getFilenameFromRecord(intl, record)
        jwtPostFetcher(worklistViewerJwt)(`/api/audit/study/downloadRequested?requestId=${requestId}`, {
          recordDescriptions: [fileName.trim()],
        })
      }catch(e){
        console.log(e)
      }
    }
  }

  const [tryDownload, downloadIsLoading, downloadIsStating, downloadFailed] = useNewDownload(worklistViewerJwt, record.recordUIDs || [record.recordUID], downloadUrl, record.fileName + (record.fileExtension ? "." : "") + record.fileExtension, requestId)
  return <TextButton disabled={record.quarantined} isLoading={downloadIsLoading} onClick={() => startDownloadAndLog()}>
    {
      downloadIsLoading ?
        <><Loading size={15} borderWidth={3}/><FormattedMessage
          defaultMessage="Preparing..."
          description="Uploader, download button, preparing download"
        /></>
        :
        downloadIsStating ?
          <><Icon icon="check"/><FormattedMessage
            defaultMessage="Started"
            description="Uploader, download button, started download"
          /></>
          :
          <><Icon icon="download"/><FormattedMessage
            defaultMessage="Download"
            description="Uploader, download button"
          /></>
    }
  </TextButton>
}

const ViewButton = ({config,record, icon = "view", label, hideDownloadButton = false}) => {

  const intl = useIntl()
  const {
    requestId,
    worklistViewerJwt: expertViewJwt,
    role
  } = config
  
  const openViewerAndLog = () => {
    openViewerLink()
    if(role !== PROVIDER){
      try{
        const fileName = getFilenameFromRecord(intl, record)
        jwtPostFetcher(expertViewJwt)(`/api/audit/study/viewRequested?requestId=${requestId}`, {
          recordDescriptions: [fileName.trim()],
        })
      }catch(e){
        console.log(e)
      }
    }
  }

  const [openViewerLink,viewerIsLoading] = useViewRecord(config,record, hideDownloadButton)
  // return <TextButton disabled={record.quarantined} isLoading={viewerIsLoading} onClick={openViewerLink}><Icon icon={icon}/>{label}</TextButton>
  return <TextButton disabled={record.quarantined} isLoading={viewerIsLoading} onClick={() => {
    openViewerAndLog()
  }}><Icon icon={icon}/>{label || <FormattedMessage defaultMessage="View" description="View button" />}</TextButton>
}

const DropDownButton = ({role, group, isDragDisabled, doAction, record, deleteRecord}) => {
  const intl = useIntl()
  const groups = useUIDataSelector('groups')
  const contextMenu = usePopupToggle()

  const addNewGroup = () => {
    return doAction(GroupAction.AddGroup)
  }
  const moveTo = (groupId) => doAction(GroupAction.SimpleMoveRecord, record, group.id, groupId)

  const moveToUngroup = () => {
    let ungroup = groups.filter(g => g.id === 'ungrouped')
    if (!ungroup.length) {
      doAction(GroupAction.UpdateGroups, (groups) =>
        [{name: '', id: 'ungrouped', items: []}].concat(groups.filter(g => g.id !== 'ungrouped'))
      )
    }
    moveTo("ungrouped")
  }

  return <DropdownButton onClick={contextMenu.open}>
    <DropdownIcon icon="arrow-down"/>
    {
      contextMenu.isOpen &&
      <ContextMenu>
        {
          (record.fileExtension?.toLowerCase() === "pdf" && !record.quarantined && role === ADMIN) &&
          <Li
            icon='split-doc'
            label={intl.formatMessage({
              defaultMessage:'Split document',
              description: "Uploader, split document menu item"
            })}
            onClick={() => doAction(UIAction.ToggleSplitPDFDialog,record)}
          />
        }
        {
          !isDragDisabled &&
          <Li label={intl.formatMessage({
            defaultMessage: "Move to...",
            description: "Uploader, 'move to' menu item"
          })} icon="move-group">
            <ContextMenu my="top left" at="top right">
              {
                groups.map(groupItem =>
                  groupItem.name 
                    ?
                      <Li
                        disabled={((group.id === groupItem.id) 
                          || 
                          ((record.key === NonCompliantDicom || record?.quarantined == true) && groupItem.id === 'notclass'))}
                        icon={group.id === groupItem.id && 'check'}
                        label={groupItem.name}
                        onClick={() => moveTo(groupItem.id)}
                      />
                    : null
                )}
              <ListSeparator/>
              <Li
                label={intl.formatMessage({
                  defaultMessage: "Move to a new group",
                  description: "Uploader, move to new group menu item"
                })}
                icon="plus-circle"
                onClick={() => moveTo(addNewGroup())}
              />
              {group.id !== 'ungrouped' &&
                <ListSeparator/>
              }
              {group.id !== 'ungrouped' &&
                <Li
                  disabled={group.id === 'ungrouped'}
                  icon="remove-from-group"
                  label={intl.formatMessage({
                    defaultMessage:"Remove from this group",
                    description: "Uploader, remove from group menu item"
                  })}
                  onClick={moveToUngroup}
                />
              }
            </ContextMenu>
          </Li>}
        <Li
          label={record.key === NonCompliantDicom
            ? intl.formatMessage({
              defaultMessage: "Delete non compliant files",
              description: "Uploader, delete non compliant files menu item"
            })
            : intl.formatMessage({
              defaultMessage:"Delete record",
              description: "Uploader, delete record menu item"
            })}
          icon="delete"
          onClick={deleteRecord || (() => doAction(UIAction.ToggleDeleteRecordDialog, record))}
        />
      </ContextMenu>
    }
  </DropdownButton>
}