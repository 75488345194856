import { Button, Icon } from '@startlibs/components';
import styled, { createGlobalStyle } from 'styled-components';
import {formatDate, getFetcher} from '@startlibs/utils'
import React, { useEffect, useState } from 'react'
import {useParams} from 'react-router'
import { Card, PageContainer } from '../components/PageLayout';
import { Header } from '../components/Header';
import {Navbar} from '../components/Navbar'
import {PATIENT} from '../enums/ContactRelationType'
import {ProviderReleaseError} from './errors/ProviderReleaseError'
import { PurviewFooter } from '../components/PurviewFooter';
import {ReleaseTermsContent} from '../request/medicalRelease/ReleaseTerms'
import {SignagureImageContainer, SignatureDate} from '../request/Payment'
import {jwtGetFetcher} from '../utils/authFetch'
import {willUseSuspense} from '../hooks/useSuspense'
import {FormattedMessage, useIntl} from 'react-intl';
import { getRelationLabel } from '../enums/ContactRelationKind';
import { lazyProviderInfo } from '../components/WithProvider';
import * as ReactDOMServer from 'react-dom/server';
import { useIsLocale } from '../hocs/IfLocale';
import {defaultDateFormat, defaultReformatDate, defaultReformatDateTime} from "../utils/dateFormat";

const PrintStyle = createGlobalStyle`
  @page {
    margin: 25mm 25mm 25mm 25mm;
    size: auto;
  }
  @media print {
    ${PageContainer} {
      padding: 0;
    }
    .nobreak {
      page-break-inside: avoid;
    }
    body {
      margin: 0px;
    }
    ${Card} {
      border: none;
      padding: 0;
    }
    ${Button} {
      display: none;
    }
  }
`

const useSuspense = willUseSuspense((code) =>
    getFetcher(`/api/locationForm/${code}`),
  true
)


export const MedicalReleaseDocument = () => {
  const {code} = useParams()
  return <ProviderReleaseError><LoadedMedicalReleaseDocument code={code} /></ProviderReleaseError>
}
export const LoadedMedicalReleaseDocument = ({code}) => {

  const SubHeader = styled.h4`
    font-size: 14px;
    font-weight: 500;
  `

  const intl = useIntl()
  const isNotLegal = useIsLocale({not:true,contains:"LEGAL"})

  const easyFormatDate = (date) => date ? formatDate(date, defaultDateFormat(intl)) : ""
  const providerInfo = lazyProviderInfo.read()
  const releaseInfo = useSuspense(code)
  const contacRelationType = getRelationLabel(intl,releaseInfo.contactDetailsRelationType,'representative')
  const patientName = `${releaseInfo?.firstName} ${releaseInfo?.middleName} ${releaseInfo?.lastName}`
  const entityName = providerInfo.name
  const patientDob = easyFormatDate(new Date(releaseInfo?.dob)) === "NaN/NaN/NaN"
    ? releaseInfo?.dob.replace('-','/').replace('-','/')
    : easyFormatDate(new Date(releaseInfo?.dob))


  const contactName = releaseInfo.contactDetailsRelationType === PATIENT
    ? (releaseInfo.firstName + ' ' + releaseInfo?.middleName + ' ' + releaseInfo.lastName)
    : releaseInfo.contactDetailsName

  const [htmlTerm, setHtmlTerm] = useState('')
  useEffect(() => {
    var vHtmlTerm = ReactDOMServer.renderToStaticMarkup(<ReleaseTermsContent
      contacRelationType={contacRelationType}
      patientName={patientName}
      entityName={entityName}
      contactName={contactName}
      dateExpiration={releaseInfo.medicalRecordLocationItem.dateExpiration}
      dateTo={releaseInfo.medicalRecordLocationItem.dateTo}
      dateFrom={releaseInfo.medicalRecordLocationItem.dateFrom}
      locations={[releaseInfo.medicalRecordLocationItem]}
    />)
    setHtmlTerm(vHtmlTerm)
  },[])

  return <>
    <PrintStyle />
    <Navbar withLocale/>
    <PageContainer>
      <Header
        title={intl.formatMessage({
            defaultMessage: "Authorization for Use and Disclosure of Protected Health Information",
          description: "Medical release document title",
        })}
      >
        <Button onClick={() => window.print()} icon="print" className="nowrap" css="margin-left: 1rem;"><FormattedMessage
          defaultMessage="Print"
          description="Print button"
        /></Button>
      </Header>
      <Card>
      <SubHeader><FormattedMessage
        defaultMessage="Patient Name"
        description="Patient name label"
        />: <b className='fs-exclude'>{patientName}</b></SubHeader>
      <SubHeader><FormattedMessage
        defaultMessage="Date of Birth"
        description="Patient date of birth label"
        />: <b className='fs-exclude'>{patientDob}</b></SubHeader>
        <p><FormattedMessage
          defaultMessage={`<b>Dear Healthcare Provider</b> 
- The patient named above or his/her legal representative requests that you provide to <b>Purview</b> 
pursuant to this request and authorization, any medical records in your possession from the period of 
{dateFrom} to {dateTo} regarding the Patient  {patientName} as authorized by the signed authorization below.`}
          description="Medical release document request info"
          values={{
            patientName: <span className='fs-exclude'>{patientName}</span>,
            dateFrom: defaultReformatDate(intl,"MM-dd-yyyy",releaseInfo.medicalRecordLocationItem.dateFrom),
            dateTo: defaultReformatDate(intl,"MM-dd-yyyy",releaseInfo.medicalRecordLocationItem.dateTo),
            entityName,
            b: (chunks) => <b>{chunks}</b>,
          }}
        /></p>

        <p>
          <FormattedMessage
          defaultMessage={`The following medical records release authorization was signed by {patientName} or his/her
legal guardian, representative or parent (as indicated) on {signatureDate}, and is valid through {expirationDate}.`}
            description="Medical release document expiration info"
            values={{
              patientName: <span className='fs-exclude'>{patientName}</span>,
              signatureDate: defaultReformatDateTime(intl,"MM-dd-yyyy at hh:mm",releaseInfo.medicalRecordsReleaseLicense.date),
              expirationDate: defaultReformatDate(intl,'MM-dd-yyyy',releaseInfo.medicalRecordLocationItem.dateExpiration),
              b: (chunks) =>  <b>{chunks}</b>
            }}
          />
          {/* IS LEGAL: <p><b>Dear Healthcare Provider</b> - The patient named above or his/her legal representative requests that you provide to <b>Purview</b> pursuant to this request and authorization, any medical records in your possession from the period of {releaseInfo.medicalRecordLocationItem.dateFrom.replace('-','/').replace('-','/')} to {releaseInfo.medicalRecordLocationItem.dateTo.replace('-','/').replace('-','/')} regarding the Patient <span className='fs-exclude'>{patientName}</span> as authorized by the signed authorization below.</p>*/}
        </p>
        <hr></hr>
        <br></br>
        <SubHeader><FormattedMessage
          defaultMessage="PATIENT AUTHORIZATION TO RELEASE PROTECTED HEALTH INFORMATION"
          description="Medical release document subheader"
        /></SubHeader>
        <ReleaseTermsContent
          contacRelationType={contacRelationType}
          patientName={patientName}
          entityName={entityName}
          contactName={contactName}
          dateExpiration={releaseInfo.medicalRecordLocationItem.dateExpiration}
          dateTo={releaseInfo.medicalRecordLocationItem.dateTo}
          dateFrom={releaseInfo.medicalRecordLocationItem.dateFrom}
          locations={[releaseInfo.medicalRecordLocationItem]}
          signedTerm={releaseInfo?.medicalRecordsReleaseLicense?.signedTerm}
          isNotLegal={isNotLegal}
        />
        {
          releaseInfo.medicalRecordsReleaseLicense.signature &&
          <SignagureImageContainer css="position: relative; margin-top: 1.5rem;">
            <img src={releaseInfo.medicalRecordsReleaseLicense.signature}/>
            <SignatureDate><Icon icon="check"/>
              <FormattedMessage
                defaultMessage="Signed on {date}"
                description="Medical release document signed date"
                values={{
                  date: <span>{defaultReformatDate(intl,"MM-dd-yyyy",releaseInfo.medicalRecordsReleaseLicense.date)}</span>
                }}
              />
            </SignatureDate>
          </SignagureImageContainer>
        }
        
        {releaseInfo?.medicalRecordsReleaseLicense?.signedTerm && releaseInfo?.medicalRecordsReleaseLicense?.signedTerm?.length > 0 &&
          <div>
            {
              releaseInfo?.medicalRecordsReleaseLicense?.signedByName
              ? <p><b><FormattedMessage
                  defaultMessage="Signed by"
                  description="Medical release document signed by label"
                  />: </b><b className='fs-exclude'>{releaseInfo?.medicalRecordsReleaseLicense?.signedByName}</b></p>
              : null
            }
            {
              releaseInfo?.medicalRecordsReleaseLicense?.signedByRelation
                ? <p><b><FormattedMessage
                  defaultMessage="Relationship to Patient"
                  description="Medical release document signed by relationship label"
                />: {releaseInfo?.medicalRecordsReleaseLicense?.signedByRelation}</b></p>
                : null
            }
          </div>
        }
    </Card>
  </PageContainer>
  <PurviewFooter/>
</>
}
