import React from 'react'
import styled, {css} from 'styled-components'
import {PHYSICIAN_OR_PROVIDER, SCAN_FACILITIES} from '../../enums/MedicalRecordLocationType'
import {Button, Icon} from '@startlibs/components'
import {getColor} from '@startlibs/utils'
import {createDateReformatter} from '../../utils/utils'
import { darken, desaturate, transparentize } from 'polished'
import {FormattedMessage, useIntl} from "react-intl";
import {defaultDateFormat, defaultReformatDate} from "../../utils/dateFormat";

export const ReleaseLocationCard = styled(({location,editContacts,className}) => {
  return <div className={className}>
    <ReleaseLocationContent location={location} editContacts={editContacts}/>
  </div>
})`
  padding: 1rem 1.25rem;
  background: ${props => transparentize(0.93, darken(0.012, desaturate(0.4, getColor('main')(props))))};;;
  box-shadow: 0 0 0 1px ${props => transparentize(0.7, darken(0.012, desaturate(0.4, getColor('main')(props))))};;;
  border-radius: 5px;
  margin-bottom: 1.5rem;
  position: relative;
`

const TYPE_LABEL = (intl) => ({
  [PHYSICIAN_OR_PROVIDER]:intl.formatMessage({
      defaultMessage: "Speciality",
    description: "Record request release, physician or provider label"
    }),
  [SCAN_FACILITIES]:intl.formatMessage({
    defaultMessage: "Type of scan",
    description: "Record request release, type of scan label"
  })
})

export const ReleaseLocationContent = ({location,editContacts}) => {
  const intl = useIntl()
  return <div>
    <LocationName className='fs-exclude'>{location.name}</LocationName>
    {location.contactName &&
      <LocationDetail className='fs-exclude'><b><FormattedMessage
        defaultMessage="Contact name"
        description="Record request release, contact name label"
        />:</b> {location.contactName}
    </LocationDetail>}
    <LocationDetail className='fs-exclude'>
      {location.phoneNumber && <span><b><FormattedMessage
        defaultMessage="Phone/Fax"
        description="Record request release, phone/fax label"
        />:</b> {location.phoneNumber}</span>}
      {location.email && <span><b><FormattedMessage
        defaultMessage="Email"
        description="Email label"
        />:</b> {location.email}</span>}
      {editContacts && <a className="link" onClick={editContacts}>{location.phoneNumber || location.email
        ? intl.formatMessage({
          defaultMessage: "Edit contacts",
          description: "Record request release, edit contacts link"
        }): intl.formatMessage({
          defaultMessage:"Add contact information",
          description: "Record request release, add contacts link"
        })}</a>}
    </LocationDetail>
    <LocationDetail>
      {TYPE_LABEL(intl)[location.locationType] && <span><b>{TYPE_LABEL(intl)[location.locationType]}:</b> {location.type}</span>}
      {location.city && <span><b><FormattedMessage
        defaultMessage="City"
        description="Record request release, city label"
        />:</b> {location.city}</span>}
    </LocationDetail>
  </div>
}

const LocationName = styled.div `
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0.25rem;
`

const LocationDetail = styled.div `
  margin-top: 2px;
  span + span, span + .link {
    margin-left: 1rem;
  }
`

export const ReleaseFormDetails = styled.div `
  color: rgba(0,0,0,0.5);
  border-top: 1px solid rgba(0,0,0,0.1);
  margin-top: 0.75rem;
  padding-top: 0.75rem;
  position:relative;
  min-height: 2.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${Button} {
    margin-left: 1rem;
    flex-shrink: 0;
  }
  ${props => props.warning && css`
    color: ${getColor('alert')};
    ${Icon} {
      font-size: 18px;
      vertical-align: -3px;
      margin-right: 0.5rem;
    }
  `}
`

export const ReleaseDetails = ({release,suspendedButton,isExpired, isPatient}) => {
  const intl = useIntl()
  const dateFormatter = createDateReformatter('MM-dd-yyyy',defaultDateFormat(intl))
  return  release.medicalRecordsReleaseLicense.signature
    ? <ReleaseFormDetails>
      <div>
        <div><a target="_blank" href={"/provider/medicalRelease/"+release.timedCode} className="DocumentLink">
          <Icon icon="report"/>
          <span><FormattedMessage
            defaultMessage="Medical Records Release"
            description="Record request release, release link"
          /></span></a>
          <i>(<FormattedMessage
            defaultMessage="Signed on {date}"
            description="Record request release, signature date"
            values={{date: defaultReformatDate(intl,'MM/dd/yyyy',release.medicalRecordsReleaseLicense.date.split(" ")[0])}}
          />)</i>
        </div>
        <div>
          <b><FormattedMessage
            defaultMessage="Authorized records between:"
            description="Record request release, authorized records label"
          /> </b>
          <FormattedMessage
            defaultMessage="{dateFrom} and {dateTo}"
            description="Record request release, authorized records between dates"
            values={{
              dateFrom: dateFormatter(release.medicalRecordLocationItem.dateFrom),
              dateTo: dateFormatter(release.medicalRecordLocationItem.dateTo)
            }}
          /> -
          <FormattedMessage
            defaultMessage="<b>{isExpired, select, true {Expired} other {Expires}} in:</b> {date}"
            description="Record request release, expiration date"
            values={{
              date: dateFormatter(release.medicalRecordLocationItem.dateExpiration),
              isExpired,
              b: msg => <b>{msg}</b>
            }}
          />
        </div>
      </div>
    </ReleaseFormDetails>
    : <ReleaseFormDetails css="margin-bottom:-0.25rem" warning>
      <div>
        <Icon icon="warning"/>
        <FormattedMessage
          defaultMessage="Authorization not {isPatient, select, true {yet} other {}} signed"
          description="Record request release, not signed"
          values={{isPatient}}
        />
      </div>
      {suspendedButton}
    </ReleaseFormDetails>
}
