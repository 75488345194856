import React from 'react'
import _ from 'lodash/fp'
import {ConfirmDialog, useConfirmDialog} from '../../hooks/useConfirmDialog'
import {Button} from '@startlibs/components'
import {FormattedMessage, useIntl} from "react-intl";

export const useRemoveQuestion = (form,path,i,check = ({id,...value}) => Object.values(value).filter(_.identity).length>0, dialogType = "generic") => {
  const intl = useIntl()
  const confirmRemove = () => form.setValue(path,_.pullAt([i]))

  const TITLES = {
    locations: intl.formatMessage({
      defaultMessage: "Delete location",
      description: "Medical release, confirm location deletion"
    }),
    generic: intl.formatMessage({
      defaultMessage: "Confirm field deletion",
      description: "Generic form field deletion confirmation"
    })
  }

  const ENTITY = {
    locations: intl.formatMessage({
        defaultMessage: "location",
        description: "Medical release, location label for delete dialog (i.e. are you sure you want to delete this 'location'?)"
      }),
    generic: intl.formatMessage({
      defaultMessage: "field",
      description: "Generic form field label for delete dialog (i.e. are you sure you want to delete this 'field'?)"
    })
  }

  const confirmDelete = useConfirmDialog(
    <ConfirmDialog
      title={TITLES[dialogType]}
      confirm={<Button alert><FormattedMessage
        defaultMessage="Delete"
        description="Delete button"
      /></Button>}
      action={confirmRemove}
    >
      <p><b><FormattedMessage
        defaultMessage="Are you sure you want to delete this {entity}?"
        description="Delete confirmation question"
        values={{entity: ENTITY[dialogType]}}
      /></b></p>
      <p><FormattedMessage
        defaultMessage="This cannot be undone."
        description="Delete confirmation warning"
      /></p>
    </ConfirmDialog>
  )

  return () => {
    const value = form.getValue([...path, i])
    if (check(value)) {
      confirmDelete()
    } else {
      confirmRemove()
    }
  }
}
