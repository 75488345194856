import {
  Icon,
  Tooltip,
  Loading
} from '@startlibs/components';
import {getColor, callIfFunction} from '@startlibs/utils'
import React, { useState } from 'react'
import _ from 'lodash/fp'
import styled, {css} from 'styled-components'
import { jwtGetFetcher } from '../../utils/authFetch';
import { useViewStudy } from '../../service/utils/downloadFile';
import {FormattedMessage, useIntl} from "react-intl";

const ToolsCellContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 0.25rem;
  > * {
    margin-left: 10px;
    :first-child {
      margin-left: 0;
    }
  }
`

export const ToolButton = styled(({href, badgeCount, badgeStatus, target = "_top", className, ...props}) => <Tooltip
  content={props.label}><a target={target} className={className} href={href}><Icon {...props}/></a></Tooltip>)`
  text-decoration: none;
  color: inherit;
  ${Icon} {
    font-size: 26px;
    color: ${getColor('secondary')};
  }
  ${props => (props.color || props.badgeCount) && css`
    :after {
      content: "${props => props.badgeCount ? props.badgeCount : ''}";
      font-weight: 600;
      color: white;
      font-size: 10px;
      min-width: 15px;
      height: 15px;
      line-height: 11px;
      text-align: center;
      position: absolute;
      bottom: 0;
      right: -5px;
      border-radius: 100px;
      background: ${getColor};
      border: 2px solid white;
      padding: 0 2px;
      white-space: nowrap;
      ${props => props.notReportOwner && css`
        background: transparent;
        box-shadow: inset 0 0 0 2px ${getColor};
      `}
    }
    ${props => props.notReportOwner && css`
      :before {
        content: '';
        width: 15px;
        height: 15px;
        position: absolute;
        bottom: 0;
        right: -5px;
        border-radius: 100px;
        background: white;
      }
    `}
    ${props => props.externalReport && css`
      :after, :before {
        display: none;
      }
    `}
  `}
`

export const ToolsCell = ({study, providerJwt, jwt, worklistViewerJwt, setNotification}) => {
  const {tools} = study
  const intl = useIntl()

  const [isLoading, setLoading] = useState(false)

  return  <ToolsCellContainer>
            { isLoading 
              ? <Loading />
              : <ToolButton
                  onClick={(() => {
                    setLoading(true)
                    jwtGetFetcher(worklistViewerJwt)(`/api/viewerLink?studyUids=${study.studyIuid}`)
                      .then(({viewerURL}) => {
                        setLoading(false);
                        viewerURL 
                          ? window.open(viewerURL, "_blank")
                          : setNotification({type:"alert", timeout: 4000,msg:(close) => <span><FormattedMessage
                              defaultMessage="The study could not be viewed. Please try again."
                            description="Viewer, link viva studies, study info, view error"
                            /></span>})
                      })
                      .catch((error) => {
                        setLoading(false);
                        setNotification({type:"alert", timeout: 4000,msg:(close) => <span><FormattedMessage
                            defaultMessage="The study could not be viewed! Please try again."
                          description="Viewer, link viva studies, study info, view error"
                          /></span>})
                      })
                  })}
                  href={tools['viewStudyWeblink']} key="viewStudy"
                  label={intl.formatMessage({
                    defaultMessage:"View on webviewer",
                    description:"Uploader, link viva studies, study info, view study button"
                  })} icon={"view"} target='_blank'
                />
            }
          </ToolsCellContainer>
}
