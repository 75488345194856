import {Button, Icon} from '@startlibs/components';
import React, { useState } from 'react'
import _ from 'lodash/fp';
import {Header} from '../components/Header';
import {PageContainer, PageFooter} from '../components/PageLayout';
import {PurviewFooter} from '../components/PurviewFooter';
import {RequestOverview} from '../request/RequestOverview'
import { EXPERT } from '../enums/UserRoles';
import { FeedbackDialog } from './dialogs/FeedbackDialog';
import { SuccessBox } from '../components/InfoBox';
import { jwtPostFetcher } from '../utils/authFetch';
import { getJwt } from '../hooks/useJwt';
import { useNavigate } from 'react-router';
import {FormattedMessage, useIntl} from "react-intl";

export const ExpertReadOnlyOverview = ({caseRequest,setCaseRequest, feedbackPopup}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const [isLoading, setLoading] = useState(false)

  const saveFetch = () => {
    setLoading(true)
    jwtPostFetcher(getJwt())('/api/expert/submitCoReview?requestId=' + caseRequest.requestId, {}, {method: 'POST'})
      .then((response) => {
        setCaseRequest(_.set('coReviewDateEpochMilli',true))
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
      })
  }

  return <> <PageContainer>
      <Header isExpert title={intl.formatMessage({
        defaultMessage: "Case overview",
        description: "Expert case overview tab title",
      })}>
        {
          (caseRequest.coReviewDateEpochMilli !== null) &&
            <>
              <SuccessBox css="margin-right:1rem;">
                <Icon icon="check"/><span><FormattedMessage
                defaultMessage="Case already reviewed"
                description="Expert case overview already reviewed message"
              /></span>
              </SuccessBox>
            </>
        }
        {caseRequest.coReviewDateEpochMilli === null && <Button isLoading={isLoading} highlight onClick={()=> saveFetch()}><FormattedMessage
          defaultMessage="Mark as done"
          description="Expert case overview mark as done button"
        /></Button>}
        {caseRequest.coReviewDateEpochMilli !== null && <Button onClick={() => navigate('/expert')}><FormattedMessage
          defaultMessage="View all cases"
          description="Expert case overview view all cases button"
        /></Button>}
        {/* <Button highlight onClick={() => navigate(`/expert/case/${caseRequest.requestId}/questions`)}>
          Go to questions
        </Button> */}
      </Header>
      {
         feedbackPopup.isOpen && <FeedbackDialog feedbackPopup={feedbackPopup} requestId={caseRequest.requestId} />
      }

      <RequestOverview caseRequest={caseRequest} setCaseRequest={setCaseRequest} withClinicalSummary noContacts allowDownload allowDownloadMedicalImages={caseRequest.canDownloadMedicalRecords} role={EXPERT} />
      
      <PageFooter>
        {caseRequest.coReviewDateEpochMilli === null && <Button isLoading={isLoading} highlight onClick={()=> saveFetch()}><FormattedMessage
          defaultMessage="Mark as done"
          description="Expert case overview mark as done button"
        /></Button>}
        {caseRequest.coReviewDateEpochMilli !== null && <Button onClick={() => navigate('/expert')}><FormattedMessage
          defaultMessage="View all cases"
          description="Expert case overview view all cases button"
        /></Button>}
      </PageFooter>
    </PageContainer>
    <PurviewFooter />
  </>
}
