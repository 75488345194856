import {
  Button,
  ContextMenu,
  Icon,
  Li,
  Link,
  Loading,
  SplitColumnsContainer,
  Tooltip
} from '@startlibs/components';
import {Field} from '@startlibs/form'
import {callIfFunction, getColor} from '@startlibs/utils';
import {useNavigate, useParams} from 'react-router'
import {usePopupToggle} from '@startlibs/core';
import React, {Suspense, useState} from 'react';
import _ from 'lodash/fp'
import styled from 'styled-components';
import {BoxPageContainer, BoxPageWrapper, BoxWrapperHeading} from '../../components/BoxPageLayout';
import {EmptyListPlaceholder} from '../../components/StyledTable'
import {ExpertCaseList} from './components/ExpertCaseList'
import {ExpertShareCaseDialog} from './components/ExpertShareCaseDialog'
import {ImageInputContainer, ProfilePage, ThumbnailContainer} from './components/ExpertProfileLayout';
import {NotProvided} from '../steps/ExpertAssignmentStep'
import {PurviewFooter} from '../../components/PurviewFooter';
import {ReturnButton} from '../../components/Header';
import {getAvatarBackgroundImage} from './utils/avatar'
import {getJwt} from '../../hooks/useJwt'
import {jwtGetFetcher} from '../../utils/authFetch'
import {isRichText, safeMultipleNewLineToBr} from '../../utils/utils'
import {useDeleteExpertDialog} from './hooks/useDeleteExpertDialog'
import {useDialog} from '../../hooks/useConfirmDialog'
import {useRegisterCasesUpdate, useRegisterCaseUpdate} from './hooks/useRegisterCaseUpdate'
import {useResetExpertPasswordDialog} from './hooks/useResetExpertPasswordDialog'
import {willUseSuspense} from '../../hooks/useSuspense'
import {lazyProviderInfo} from '../../components/WithProvider'
import {IfLocale} from '../../hocs/IfLocale'
import { RichTextDisplay } from '../../components/RichTextDisplay';
import {useIntl} from "react-intl";

const LoadingWrapper = styled.div`
  width: 100%;
  height: 8rem;
  border-radius: 5px;
  background: ${getColor('gray240')};
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${getColor('gray210')};
  margin-top: 1.5rem;
`

const useExpertViewSuspense = willUseSuspense((id) =>
  !id
    ? Promise.resolve()
    : jwtGetFetcher(getJwt())(`/api/expert/${id}`)
    .then(_.update('info.specialization', (v) => v || []))
    .then(_.set('id', Number(id)))
)

const useExpertCases = willUseSuspense((id) =>
  jwtGetFetcher(getJwt())(`/api/expert/${id}/cases`)
)

export const ViewExpert = () => {
  const {id} = useParams()
  const navigate = useNavigate()
  const intl = useIntl()
  const expert = useExpertViewSuspense(id)

  const openResetPassword = useResetExpertPasswordDialog(expert)
  const openDeleteDialog = useDeleteExpertDialog(expert,() => navigate("/admin/experts"))

  const contextMenu = usePopupToggle()

  return <ProfilePage viewOnly>
    <BoxPageContainer>
    <BoxPageWrapper>

      <BoxWrapperHeading withReturn>
        <ReturnButton as={Link} to="/admin/experts"><Icon icon="return"/></ReturnButton>
        <div className="header-content">
          <h2><span className="title-label">Expert:</span> <span>{expert.firstName} {expert.lastName}</span>
          </h2>
          <div className="buttons-wrapper">
            <Button.Link small to={`/admin/experts/edit/${expert.id}`} state={{prevLocation: '/admin/experts/view/' + expert.id}}>
              Edit
            </Button.Link>
            <Button small onlyDropdown onClick={contextMenu.open}>
              {
                contextMenu.isOpen &&
                <ContextMenu>
                  <Li label="Remove expert" onClick={openDeleteDialog}/>
                  <Li label="Reset password" onClick={openResetPassword}/>
                </ContextMenu>
              }
            </Button>
          </div>
        </div>
      </BoxWrapperHeading>

      <div className="profile-content">

        <div className="fields-wrapper">
          <SplitColumnsContainer viewportMinWidth={850}>
            <Field label="First Name:">
              <span>{expert.firstName || <NotProvided/>}</span>
            </Field>
            <Field label="Last name:">
              <span>{expert.lastName || <NotProvided/>}</span>
            </Field>
          </SplitColumnsContainer>
          <SplitColumnsContainer viewportMinWidth={850}>
            <Field label="Email:">
              <span>{expert.email}</span>
            </Field>
            <Field label="Phone:">
              <span>{expert.info.phone || <NotProvided/>}</span>
            </Field>
          </SplitColumnsContainer>
          <IfLocale not contains="LEGAL">
          <Field label="Specialization(s):">
            <div>
              {
                expert.info.specialization.length
                  ? expert.info.specialization.map(spec => <Tooltip whenEllipsis content={spec}><span className="specialization-item"
                                                                 key={spec}>{spec}</span></Tooltip>)
                  : <NotProvided/>
              }
            </div>
          </Field>
          </IfLocale>
          <Field label={intl.formatMessage({
            defaultMessage: "Resume",
            description: "Expert resume label"
          })+":"} className="resume">
            {
              expert.info.resume
              // ? <p dangerouslySetInnerHTML={{__html: safeMultipleNewLineToBr(expert.info.resume)}}/>
              ? <RichTextDisplay content={expert.info.resume} useSafeNewLine={!isRichText(expert.info.resume)} />
              : <NotProvided/>
            }
          </Field>
        </div>

        <div className="image-wrapper">
          <ImageInputContainer>
            <ThumbnailContainer viewOnly css={expert.picture && getAvatarBackgroundImage(expert)}>
              {!expert.picture && <Icon icon="physician"/>}
            </ThumbnailContainer>
          </ImageInputContainer>
        </div>

      </div>

      <Field label="Cases assigned to this expert:" css={`padding-right: 120px;`}/>
      <Suspense fallback={<LoadingWrapper><Loading/></LoadingWrapper>}>
        <ExpertCases id={id} expert={expert}/>
      </Suspense>
    </BoxPageWrapper>
    </BoxPageContainer>
    <PurviewFooter />
  </ProfilePage>
}

const ExpertCases = ({id,expert}) => {
  const [cases, setCases] = useState(useExpertCases(id))
  const providerInfo = lazyProviderInfo.read()
  const refreshCases = () => jwtGetFetcher(getJwt())(`/api/expert/${id}/cases`).then(setCases)

  useRegisterCaseUpdate((caseInfo, update) => setCases(cases => cases.map(currentCaseInfo => currentCaseInfo.id === caseInfo.id ? callIfFunction(update, currentCaseInfo) : currentCaseInfo)))
  useRegisterCasesUpdate((update) => setCases(cases => callIfFunction(update, cases)))

  const openShareCase = useDialog((closeDialog) => <ExpertShareCaseDialog refreshCases={refreshCases} providerInfo={providerInfo} closeDialog={closeDialog} expert={expert} />)

  if (cases.length === 0) {

    return <EmptyListPlaceholder>
      <span>There are no cases assigned to this expert yet. <a onClick={openShareCase} className="link">Click here</a> to assign a case.</span>
    </EmptyListPlaceholder>
  }
  if (cases.length > 0) {
    return <ExpertCaseList openShareCase={openShareCase} cases={cases} expert={expert} setCases={setCases}/>
  }
}
