import {
  FormValue,
  Field,
  RadioboxGroup,
  SimpleCheckbox,
  SimpleRadiobox,
  ToggleCheckbox,
  TextInput,
  ComboboxWithCustom,
  AutoComplete
} from '@startlibs/form'
import {SplitColumnsContainer} from '@startlibs/components'
import React from 'react'
import {Card, SectionHeading} from '../../components/PageLayout'
import {DIGITAL, OFFLINE} from '../../enums/PaymentMethod'
import {PriceInput} from '../../components/PriceInput'
import {getCoveredAndCharge} from './utils'
import styled from 'styled-components'


const AutoCompleteTextboxDiv = styled.div`
  display: inline-block;
  outline: 0;
  outline: none;
  padding: 0 1px;
  line-height: 100%;
`

export const PaymentDetailsForm = ({defaultFees, canSendMailToContact}) => {

  const options = defaultFees.map(fee => fee.description + ' (' + fee.feeValue + ' USD)')
  // options.unshift('Type or select a fee')

  return <>
      
      <SplitColumnsContainer>
        {/* <TextInput
          path="description"
          label="Fee description"
          placeholder="Ex: Additional consultation fee"
          mandatory
        /> */}
        <FormValue path="chargeValue">{
          (chargeValue, setChargeValue) => 
          <div id='descriptionAtc'>
            <AutoComplete
              id="descriptionAutocomplete"
              path="description"
              label="Fee description"
              placeholder="Ex: Additional consultation fee"
              mandatory
              fetchSuggestions={(value) => options.filter(option => option.toLowerCase().indexOf(value.toLowerCase()) !== -1)}
              withDropdown
              CompletedBox={ ({label, remove, value}) => label}
              EditableInput={(props) => <AutoCompleteTextboxDiv {...props} />}
              minQueryLength={1}
              transform={(value) => value.map((v,index) => {
                if(value && value.length > 0 && index === value.length - 1) {
                  var desc = defaultFees.find(fee => fee.description + ' (' + fee.feeValue + ' USD)' === v) 
                    && defaultFees.find(fee => fee.description + ' (' + fee.feeValue + ' USD)' === v).description 
                      ? defaultFees.find(fee => fee.description + ' (' + fee.feeValue + ' USD)' === v).description 
                      : ''
                  var price = defaultFees.find(fee => fee.description + ' (' + fee.feeValue + ' USD)' === v) 
                    && defaultFees.find(fee => fee.description + ' (' + fee.feeValue + ' USD)' === v).feeValue 
                      ? defaultFees.find(fee => fee.description + ' (' + fee.feeValue + ' USD)' === v).feeValue 
                      : ""
                  setChargeValue(price)
                  return desc ? desc : v
                }else{
                  return ''
                }
              })}
            />
            </div>
        }</FormValue>
        <PriceInput
          label="Out of pocket fee"
          path="chargeValue"
          placeholder="Ex: 300.00"
          mandatory
        />
      </SplitColumnsContainer>
      <RadioboxGroup
        path="paymentMethod"
        label="Payment method"
        framedBoxes
        wide
        mandatory
        horizontal
      >
        <SimpleRadiobox label="Digital payment" fieldValue={DIGITAL}/>
        <SimpleRadiobox label="Off-line payment" fieldValue={OFFLINE}/>
      </RadioboxGroup>
      <FormValue
        path="paymentMethod"
      >{paymentMethod =>
        paymentMethod === DIGITAL
          ? canSendMailToContact 
            ? <ToggleCheckbox
                path="sendEmail"
                label={<b>Send an email requesting payment when adding this fee</b>}
                css="margin-top:2rem"
                descText="You can also send the payment request by email later"
              />
            : null
          : paymentMethod === OFFLINE
          ? <Field label="Manual confirmation" css="margin-top: 1.5rem;">
            <SimpleCheckbox
              path="paid"
              label="Mark as paid"
              helpText="(in case paid externally already)"
              framedBoxes
              highlightSelected
            />
          </Field>
          : null
      }</FormValue>
  </>

}