import {Button, Link} from '@startlibs/components';
import {FieldDescription, TextInput} from '@startlibs/form';
import React from 'react';
import _ from 'lodash/fp'
import {ConfirmDialog, useConfirmDialog} from '../../../hooks/useConfirmDialog'
import {ContactCheckbox, ContactCheckboxGroup} from '../../../components/ContactCard'
import {REF_PHYSICIAN} from '../../../enums/ContactRelationType'
import {SelectorTitle} from '../RecordsStep';
import {buildValidation} from '../../../utils/validation'
import {getContact, getRelationLabel, PRIMARY} from '../../../enums/ContactRelationKind'
import {getJwt} from '../../../hooks/useJwt'
import { jwtFormFetcher } from '../../../utils/authFetch';
import {FormattedMessage, useIntl} from 'react-intl'
import {EXPERT} from '../../../enums/UserRoles'
import { lazyProviderInfo } from '../../../components/WithProvider';
import { InfoBox } from '../../../components/InfoBox';

export const useSendRemoteConsultationLink = (caseRequest, meetingURL, experts) => {

  const getChecked = (email) => emails => emails.indexOf(email)>=0
  const setValue = (email) => emails => getChecked(email)(emails) ? _.without([email],emails) : emails.concat(email)

  const referringPhysicianAsSecondary = caseRequest.contactDetails.contactRelationType !== "REF_PHYSICIAN" && caseRequest.referringPhysician.email

  const intl = useIntl()
  const primary = getContact(intl, caseRequest, PRIMARY)
  const physician = getContact(intl, caseRequest, REF_PHYSICIAN)

  const providerInfo = lazyProviderInfo.read()
  const caseContactsNotifications = providerInfo?.caseContactsNotifications

  return useConfirmDialog(
    <ConfirmDialog
      title="Send invitation"
      confirm={<Button highlight icon="email" disabled={!caseContactsNotifications && !experts.length}>Send</Button>}
      action={jwtFormFetcher(getJwt())('/api/admin/sendRemoteVideoConsultNotification?requestId=' + caseRequest.requestId)}
      values={{customMessage: "", meetingURL, sendTo: !caseContactsNotifications && experts.length === 1 ? experts.filter((expert,index) => index === 0).map(({expert}) => expert.email) : []}}
      transform={_.update('sendTo',(emails) => !experts.length && !referringPhysicianAsSecondary && caseContactsNotifications ? [primary.email] : emails)}
      preValidation={buildValidation({'sendTo': (emails) => (experts.length || referringPhysicianAsSecondary) && !emails.length && "Please select at least one contact before proceeding."})}
      notify="Email sent successfully"
      css="max-width: 46rem;"
    >
      <SelectorTitle><p>Send the consultation link to: </p>
        <Link
          className="link"
          to={`/admin/request/${caseRequest.requestCode}/patient`}
          state={{scrollToContact: true}}
        >Edit contacts</Link>
      </SelectorTitle>
      {(caseContactsNotifications || experts.length > 0) && <ContactCheckboxGroup>
        {caseContactsNotifications && <ContactCheckbox
          getValue={getChecked(primary.email)}
          setValue={setValue(primary.email)}
          path="sendTo"
          email={primary.email}
          name={primary.name}
          key="patient"
          type={primary.relationType}
        />}
        {
          caseContactsNotifications && referringPhysicianAsSecondary &&
          <ContactCheckbox
            getValue={getChecked(physician.email)}
            setValue={setValue(physician.email)}
            path="sendTo"
            name={physician.name}
            email={physician.email}
            type={getRelationLabel(intl,REF_PHYSICIAN)}
            key="Referring physician"
          />
        }
        {
          experts.map(({expert}) =>
          <ContactCheckbox
            getValue={getChecked(expert.email)}
            setValue={setValue(expert.email)}
            name={expert.firstName + " " + expert.lastName}
            email={expert.email}
            type={getRelationLabel(intl,EXPERT)}
            path="sendTo"
            key={"expert"+expert.id}
          />)
        }
      </ContactCheckboxGroup>}
      {!caseContactsNotifications && <InfoBox lightYellow css="margin: 1rem 0rem 2rem">
        Communication with case contact is disabled              
      </InfoBox>}
      {!experts.length && <FieldDescription css="margin: -1rem 0 1.5rem">Tip: Assign an expert to the case in order to invite them to the consultation.</FieldDescription>}
      <p>The above contact(s) will receive the consultation link with the following message:</p>
      <TextInput
        textarea
        autoResize
        label="Message"
        path="customMessage"
        placeholder="Insert a custom message here. Ex: Let's have a conversation at..."
      />
    </ConfirmDialog>
    ,
    [experts]
  )
}
