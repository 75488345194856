import {Fill, Slot, useConstant, useEnsureRef, useRefState, useToggle} from '@startlibs/core'
import {Loading} from '@startlibs/components'
import {callIfFunction} from '@startlibs/utils'
import React, {useEffect, useState} from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components'
import {ACTIVE, ALL, EXPERT_CASES, FILTER_LABEL_MAP} from '../../enums/CaseFilter';
import {FetchErrorDialog} from '../../pages/errors/FetchErrorDialog'
import {Pagination} from '../Pagination'
import {cachedWorklistFetcher} from '../../utils/cachedWorklistFetcher'
import {DATE, UPDATE_DATE} from '../../enums/CaseOrderBy'
import {FormattedMessage, useIntl} from "react-intl";

const translateMaxResultsToPage = ({maxResults, page, children, ...params}) => {
  return {
    first: maxResults * (page - 1),
    rows: maxResults,
    ...params
  }
}

const LoadingWorklist = styled.div`
  border-radius: 5px;
  height: 26rem;
`
const PaginationContainer = styled.div`
  text-align: center;
  margin-top: 2rem;
`


export const DashboardLoader = React.memo(React.forwardRef((
  {
    skipPagination,
    params,
    fetcher = cachedWorklistFetcher,
    updateParams,
    loading,
    setParams,
    children,
    defaultFilter = ACTIVE,
    defaultOrderBy = UPDATE_DATE,
    queryCount,
    url,
    allResults,
    defaultMaxResults,
    isExpertDashboard
  },ref) => {

  const ensuredRef = useEnsureRef(ref)
  const intl = useIntl()

  const [results, setResults] = useState()
  const lastParams = useRefState(params)
  const lastSuccessParams = useRefState(params)
  const errorDialog = useToggle()


  const maxResults = Number(params.maxResults) || defaultMaxResults
  const currentPage = Number(params.page) || 1

  const getLoadingParams = (currentParams) => ({
    page: 1,
    maxResults,
    state: defaultFilter,
    orderBy: defaultOrderBy, ..._.pickBy(_.identity, currentParams)
  })

  const loadList = (currentParams, closeLoading, refreshing) => {
    lastParams.set(currentParams)
    if (lastParams.get() === lastSuccessParams.get() && !refreshing) {
      callIfFunction(closeLoading)
      return Promise.resolve()
    }
    const loadingParams = getLoadingParams(currentParams)
    return fetcher(url, loadingParams, refreshing || !results, translateMaxResultsToPage)
      .then((result) => {
        if (lastParams.get() === currentParams) {
          lastSuccessParams.set(currentParams)
          setResults({result, key: Date.now()})
          callIfFunction(closeLoading)
        }

      })
      .catch((e) => {
        console.log(e)
        if (lastParams.get() === currentParams) {
          callIfFunction(closeLoading)
          errorDialog.open()
          updateParams(lastSuccessParams.get())
        }
      })
  }

  useEffect(() => {
    if (allResults && results && results.result.count > results.result.list.length) {
      const currentParams = lastParams.get()
      const loadingParams = getLoadingParams(currentParams)
      fetcher(url, {
        ...loadingParams,
        page: (results.result.list.length / loadingParams.maxResults) + 1
      }, false, translateMaxResultsToPage)
        .then(({list, count}) => {
          if (lastParams.get() === currentParams) {
            setResults(_.update('result.list', (prevList) => prevList.concat(list)))
          }
        })
    }
  }, [results])


  const refreshList = () => loadList(params, null, true)

  React.useImperativeHandle(ensuredRef,() => ({refreshList,results}),[results])

  useEffect(() => {
    loading.open()
    loadList(params, loading.close)
  }, [params])


  if (!results) {
    return <>
      <Fill name="QueryCount"><b><FormattedMessage defaultMessage="Loading..." description="Query count loading message"/></b></Fill>
      <LoadingWorklist><Loading size={40} borderWidth={6} absolute/></LoadingWorklist>
    </>
  }
  const {result: {count, list}, key} = results
  return <>
    <Fill name="QueryCount"><b>{loading.isOpen
      ? intl.formatMessage(({defaultMessage:'Loading...',description:'Query count loading message'}))
      : (callIfFunction(queryCount,count) ||
        <FormattedMessage
          defaultMessage="{count} {type, select, open {open} active {active} other {}} {count, plural, one {case} other {cases}} {isSearch, select, true {found} other {}}"
          values={{
            count,
            type: params.state !== ALL && params.state !== EXPERT_CASES && (params.state ? (FILTER_LABEL_MAP(intl)[params.state]||"") : (isExpertDashboard ? "open" : "active")).toLowerCase(),
            isSearch: !!params.search
          }}
          description="Query count message"
        />)}
    </b></Fill>
    <Fill name="Pagination"><Pagination
      setPageParam={(page) => () => setParams(_.set('page', page))}
      currentPage={currentPage}
      totalPages={Math.ceil((Number(count)) / maxResults)}
    /></Fill>
    {children({
      key,
      refreshList,
      queryCount: Number(count),
      list,
      params: lastSuccessParams.get(),
      setList: (setter) => setResults(_.update('result.list', setter))
    })}
    {
      errorDialog.isOpen &&
      <FetchErrorDialog closeDialog={errorDialog.close}/>
    }
    {
      !skipPagination &&
      <PaginationContainer><Slot name="Pagination"/></PaginationContainer>
    }
  </>
}))
