import React, {useRef} from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components/macro'
import {ConfirmDialog, useConfirmDialog} from "@startlibs/form";
import {Button} from "@startlibs/components";
import {useDoAction} from "../../service/hooks/useDoAction";
import {GroupAction} from "../../service/GroupAction";
import {FormattedMessage, useIntl} from "react-intl";

styled

export const useUngroup = (group) => {
  const ungroupRef = useRef()
  const doAction = useDoAction()
  const intl = useIntl()

  const confirmUngroup = useConfirmDialog(<ConfirmDialog
    title={intl.formatMessage({
      defaultMessage:`Ungroup records`,
      description: `Uploader, ungroup records dialog title`
    })}
    action={() => doAction(GroupAction.Ungroup,group)}
    confirm={<Button alert><FormattedMessage
      defaultMessage="Proceed"
      description="Proceed button"
    /></Button>}
    cancelLabel="Cancel"
    css="max-width: 42rem;"
  >
    <FormattedMessage
      defaultMessage="<p>Any records in this group will be separated and moved to the top of the records list.</p><p>Would you like to proceed?</p>"
      description="Uploader, ungroup records dialog content"
      values={{p: (...chunks) => <p>{chunks}</p>}}
    />
  </ConfirmDialog>)

  const ungroup = () => {
    if (!group.items.length) {
      doAction(GroupAction.Ungroup,group)
    } else {
      confirmUngroup()
    }
  }

  return [ungroup,ungroupRef]
}