import {Button, Dialog, Loading} from '@startlibs/components';
import {useToggle} from '@startlibs/core'
import React, {useContext, useEffect} from 'react'
import styled from 'styled-components';

import {AttachmentBox} from './AttachmentBoxStyles';
import {useUIDataSelector} from "../service/hooks/useUIDataSelector";
import {useDoAction} from "../service/hooks/useDoAction";
import {UploaderAction} from "../service/UploaderAction";
import {UIAction} from "../service/UIAction";
import {FormattedMessage, useIntl} from "react-intl";

const ProcessingBoxComponent = styled(AttachmentBox)`
  padding: 0.75rem 1.5rem 0.75rem 1.5rem;
  justify-content: center;
  font-weight: 600;
  min-height: 3rem;
  div {
    margin-right: 0.75rem;
  }
`

export const ProcessingBox = () => {
  const intl = useIntl()
  const {unprocessedFiles, isProcessing, processedFiles, dialogs: {cancelProcessing}} = useUIDataSelector()
  // const alreadyProcessedFiles = totalFilesToProcess - unprocessedFiles
  const totalFilesToProcess = processedFiles + unprocessedFiles
  const doAction = useDoAction()
  const processingTimeout = useToggle()
  const [isLoading, setIsLoading] = React.useState(false)

  useEffect(() => {
    if (isProcessing > 0) {
      processingTimeout.open()
      processingTimeout.closeAfter(800)
    }
  }, [isProcessing > 0])

  const toggleCancelProcessDialog = () => doAction(UIAction.ToggleCancelProcessingDialog, (resolve) => resolve(false))

  const cancelQueue = () => {
    doAction(UIAction.ToggleCancelProcessingDialog, (resolve) => resolve(true))
    setIsLoading(false)
  }

  if ((!unprocessedFiles || unprocessedFiles <= 0) && isProcessing <= 0) {
    // && !processingTimeout.isOpen) {
    return null
  }

  return <ProcessingBoxComponent>
    <Loading size={18} borderWidth={3}/>
    <div><FormattedMessage
      defaultMessage="Processing files..."
      description="Uploader, processing files message"
    /></div>
    {/* {unprocessedFiles > 1 && <a className="link" onClick={toggleCancelProcessDialog}>Cancel</a>} */}

    {unprocessedFiles > 0 && isProcessing > 0 && <><span style={{fontWeight: 400, marginRight: '0.5rem'}}>
      <FormattedMessage
        defaultMessage="({processedFiles}/{totalFilesToProcess} files)"
        description="Uploader, processing files number of files progress"
        values={{processedFiles, totalFilesToProcess}}
    /></span> <a className="link" onClick={toggleCancelProcessDialog}><FormattedMessage
      defaultMessage="Cancel"
      description="Cancel button"
    /></a></>}
    {
      cancelProcessing &&
      <Dialog
        closeDialog={toggleCancelProcessDialog}
        title={intl.formatMessage({
          defaultMessage: "Are you sure you wish to cancel the upload?",
          description: "Uploader, cancel upload dialog, title"
        })}
        css="max-width:52rem;"
        footer={<>
          <Button onClick={toggleCancelProcessDialog}><FormattedMessage
            defaultMessage="Continue file processing"
            description="Uploader, cancel upload dialog, continue file processing button"
          /></Button>
          <Button alert 
            isLoading={isLoading}
            onClick={() => {
              setIsLoading(true)
              cancelQueue()
            }}><FormattedMessage
            defaultMessage="Cancel queued file processing"
            description="Uploader, cancel upload dialog, cancel queued file processing button"
          /></Button>
        </>}
      >
        <FormattedMessage
          defaultMessage={`
        <p>You are about to cancel the upload.</p>
        <p><b>If you do so, incomplete DICOM studies may result.</b></p>
        <p>We suggest that you wait for file processing to finish or that you delete your DICOM studies and upload them
          again afterwards to make sure they are complete.</p>
        <p>Files that have already finished uploading will remain in your medical records list unless you delete them
          manually.</p>`}
          description="Uploader, cancel upload dialog, content"
          values={{
            p: (chunks) => <p>{chunks}</p>,
            b: (chunks) => <b>{chunks}</b>
          }}
        />
      </Dialog>
    }
  </ProcessingBoxComponent>
}
