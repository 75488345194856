import React from 'react'

export const ACTIVE = 'ACTIVE'
export const FINISHED = 'FINISHED'
export const ARCHIVE = 'ARCHIVE'
export const ALL = 'ALL'
export const PENDING = 'PENDING'
export const EXPERT_OPEN = 'EXPERT_OPEN'
export const EXPERT_REVIEWED = 'EXPERT_REVIEWED'
export const EXPERT_CASES = 'EXPERT_CASES'

export const FILTER_LABEL_MAP = (intl) => ({
  [ACTIVE]:intl.formatMessage({defaultMessage:"Active",description:"Case filter label: active"}),
  [ALL]:intl.formatMessage({defaultMessage:'All',description:"Case filter label: all"}),
  [FINISHED]:intl.formatMessage({defaultMessage:'Completed',description:"Case filter label: completed"}),
  [ARCHIVE]:intl.formatMessage({defaultMessage:'Archived',description:"Case filter label: archived"}),
  [EXPERT_OPEN]:intl.formatMessage({defaultMessage:'Open',description:"Case filter label: open"}),
  [EXPERT_REVIEWED]:intl.formatMessage({defaultMessage:'Reviewed',description:"Case filter label: reviewed"}),
  [EXPERT_CASES]:intl.formatMessage({defaultMessage:'All',description:"Case filter label: all"})
})