import React, { useContext } from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components/macro'
import {RecordRowContainer} from "./RecordRowContainer";
import {
  AttachmentBox,
  AttachmentDescription,
  AttachmentDetails,
  AttachmentIcon,
  AttachmentInfoContainer
} from "../AttachmentBoxStyles";
import {NonCompliantDicom} from "../../enums/RecordFormat";
import {formatDate} from "@startlibs/utils";
import {dicomDateToDate} from "../../utils";
import {useToggle} from "@startlibs/core";
import {ConfirmDialog} from "@startlibs/form";
import {RecordDetailsDialog} from "./RecordDetailsDialog";
import {Button, Dialog} from "@startlibs/components";
import {useDoAction} from "../../service/hooks/useDoAction";
import {UploaderAction} from "../../service/UploaderAction";
import {useRecordsSelector} from "../../service/hooks/useRecords";
import {Failed, Queued, Uploaded} from "../../service/enums/RecordStatus";
import {Uploading} from "../../enums/FileState";
import {getRowsForRecords} from "../../service/utils/recordUtils";
import { UploaderConfigContext } from '../../service/UploaderConfigContext';
import { ADMIN, EXPERT, PATIENT, PROVIDER } from '../../enums/UserRoles';
import {FormattedMessage, useIntl} from "react-intl";

styled

const toRecordFiles = records => {
  const recordsLikeInstances =
    records.map(record => _.flow(
      _.set('filename', record.info?.fileName || record.description),
      _.set('extension', record.fileExtension || record.info?.fileExtension),
    )(record))
  return ({
    failed: recordsLikeInstances.filter(_.matchesProperty('status', Failed)),
    uploaded: recordsLikeInstances.filter(_.matchesProperty('status', Uploaded)),
    uploading: recordsLikeInstances.filter(_.matchesProperty('status', Uploading)),
    files: recordsLikeInstances.filter(_.matchesProperty('status', Queued))
  })
}

export const NonCompliantDicomRecordRow = ({group, dragProvided}) => {
  const intl = useIntl()
  const {role} = useContext(UploaderConfigContext)
  const records = useRecordsSelector(_.filter(['recordFormat',NonCompliantDicom]))
  const [record] = getRowsForRecords(records)
  const doAction = useDoAction()
  const recordDetailsDialog = useToggle()
  const cancelDialog = useToggle()
  const cancelLoading = useToggle()
  const deleteLoading = useToggle()
  const deleteDialog = useToggle()
  const deleteInstanceDialog = useToggle()
  const deleteRecord = async () => {
    await deleteLoading.wait(doAction(UploaderAction.Delete,record))
    cancelDialog.close()
  }
  const cancelUpload = async () => {
    await cancelLoading.wait(doAction(UploaderAction.CancelRecord,record))
    cancelDialog.close()
  }

  if (!records.length) { return null }

  if((role === EXPERT || role === PROVIDER || role === PATIENT) && (group.id === 'notclass')){
    return null
  }

  return <RecordRowContainer
    group={group}
    record={record}
    dragProvided={dragProvided}
    cancelUpload={cancelDialog.open}
    deleteRecord={deleteDialog.open}
    withNotes={false}
    withMeta={false}
  >{isMinified =>
    <>
      <AttachmentDescription>
        <b>
          <FormattedMessage
            defaultMessage="{recordsLength} Non compliant DICOM files"
            description="Non compliant DICOM files"
            values={{recordsLength: records.length}}
          /></b> {role === ADMIN && group.id === 'notclass' && <span> (<FormattedMessage
        defaultMessage="hidden"
        description="Hidden label"
        />) </span>}<a className="light-link" onClick={recordDetailsDialog.open}><FormattedMessage
        defaultMessage="Details"
        description="Uploader, record details button"
      /></a>
      </AttachmentDescription>
      {
        recordDetailsDialog.isOpen &&
        <RecordDetailsDialog
          title={intl.formatMessage({
            defaultMessage: "Non compliant DICOM study details",
            description: "Uploader, non complaint DICOM, study details dialog title"
          })}
          subtitle={intl.formatMessage({
            defaultMessage: "Some DICOM files were incompatible with our webviewer. They are grouped together here and will be available to download them.",
            description: "Uploader, non complaint DICOM, study details dialog subtitle"
          })}
          closeDialog={recordDetailsDialog.close}
          cancelUpload={cancelDialog.open}
          deleteFile={deleteInstanceDialog.openWith}
          record={record}
          recordFiles={toRecordFiles(records)}
          withCancelSingleFile
        />
      }
      {
        cancelDialog.isOpen &&
        <Dialog
          closeDialog={cancelDialog.close}
          title={intl.formatMessage({
            defaultMessage: "Cancel non compliant DICOM upload",
            description: "Uploader, non compliant DICOM, cancel upload dialog title"
          })}
          footer={<>
            <Button alert isLoading={deleteLoading.isOpen} onClick={deleteRecord}><FormattedMessage
              defaultMessage="Delete all non compliant files"
              description="Uploader, non compliant DICOM, cancel upload and delete all files button"
            /></Button>
            <Button alert isLoading={cancelLoading.isOpen} onClick={cancelUpload}><FormattedMessage
              defaultMessage="Stop remaining uploads only"
              description="Uploader, non compliant DICOM, cancel upload button"
            /></Button>
          </>}
        >
          <p><FormattedMessage
            defaultMessage="Would you like to cancel only the remaining file uploads in queue or also delete all non compliant DICOM files?"
            description="Uploader, non compliant DICOM, cancel upload dialog message"
          /></p>
        </Dialog>
      }
      {
        deleteDialog.isOpen &&
        <Dialog
          closeDialog={deleteDialog.close}
          title={intl.formatMessage({
            defaultMessage: "Delete all non compliant files",
            description: "Uploader, non compliant DICOM, delete all files dialog title"
          })}
          footer={<>
            <Button onClick={deleteDialog.close}>Cancel</Button>
            <Button alert isLoading={deleteLoading.isOpen} onClick={deleteRecord}><FormattedMessage
              defaultMessage="Delete non compliant files"
              description="Uploader, non compliant DICOM, delete all files button"
            /></Button>
          </>}
        >
          <FormattedMessage
            defaultMessage={`<p>Would you like to delete all non compliant DICOM files already uploaded?</p>
          <p>Once confirmed this cannot be undone.</p>`}
            description="Uploader, non compliant DICOM, delete all files dialog message"
            values={{
              p: (chunks) => <p>{chunks}</p>
            }}
          />
        </Dialog>
      }
      {
        deleteInstanceDialog.isOpen &&
        <ConfirmDialog
          closeDialog={deleteInstanceDialog.close}
          action={() => doAction(UploaderAction.Delete,deleteInstanceDialog.get())}
          title={intl.formatMessage({
            defaultMessage: "Delete non compliant file",
            description: "Uploader, non compliant DICOM, delete file dialog title"
          })}
          cancelButton={<Button onClick={deleteInstanceDialog.close}><FormattedMessage
            defaultMessage="Cancel"
            description="Cancel button"
          /></Button>}
          confirm={<Button alert><FormattedMessage
            defaultMessage="Delete non compliant file"
            description="Uploader, non compliant DICOM, delete file button"
          /></Button>}
        >
          <p><FormattedMessage
            defaultMessage="You are about to delete the following file:"
            description="Uploader, non compliant DICOM, delete file dialog message"
          /></p>
          <AttachmentBox nonCompliantDeleteFileBox css="margin-bottom:1rem;">
            <AttachmentIcon icon="dicom" css="font-size:44px;" className="desktop-only"/>
            <AttachmentInfoContainer>
              <div className="file-details">
                <div><strong>{deleteInstanceDialog.isOpen.filename}</strong></div>
              </div>
            </AttachmentInfoContainer>
          </AttachmentBox>
          <p><FormattedMessage
            defaultMessage="<b>Are you sure you want to delete it?</b> Once confirmed this cannot be undone."
            description="Uploader, non compliant DICOM, delete file dialog confirmation message"
          /></p>
        </ConfirmDialog>
      }
    </>
  }</RecordRowContainer>
}