import React, {useEffect} from 'react'
import {
  AdditionalActionButtons,
  CardActionButton,
  CardHeader,
  PurviewFooter,
  SignInCard,
  SignInLayout
} from '../components/SigninLayout'
import {DatePicker, Errors, FormValue, TextInput, WithForm} from '@startlibs/form'
import _ from 'lodash/fp'
import {useNavigate} from 'react-router'
import {formatDate, formFetch, formFetchWithResponse} from '@startlibs/utils'
import {Button} from '@startlibs/components'
import {useClearSession} from '../hooks/useClearSession'
import {buildValidation, required, responseFailure} from '../utils/validation'
import {DATE_FORMAT} from '../config/DateFormat'
import {jwtGetFetcher} from '../utils/authFetch'
import {setJwt} from '../hooks/useJwt'
import {useConstant, useToggle} from '@startlibs/core'
import {getFirstPage} from './utils/patientUtils'
import {EmailVerificationPage} from './status/EmailVerificationPage'
import {getTimezoneOffset} from '../utils/utils'
import {IfLocale} from '../hocs/IfLocale'
import {FormattedMessage, useIntl} from 'react-intl'
import {defaultDateFormat} from "../utils/dateFormat";

const transformDoB = _.update('dob', (dob) => {
  return formatDate(dob, 'MM-dd-yyyy')
})

export const ACCESS_ERROR = 'Invalid patient data. Please check the fields above and try again.'


export const PatientLogin = ({providerInfo, setCaseRequest, isNurse, isRefPhysician=false}) => {
  
  const patientCaseCreation = providerInfo?.patientCaseCreation
  const navigate = useNavigate()
  const isReadOnlyShare = (requestCode) => requestCode?.indexOf("RO-")===0
  const intl = useIntl()
  // providerInfo.pasEnabled = true
  const requestCode = new URLSearchParams(window.location.search).get('code') || ''
  const DEFAULT_VALUES = useConstant(
    providerInfo.pasEnabled
      ? {email: '', requestCode}
      : (
        process.env.NODE_ENV === 'development'
        ? {dob: new Date('2025-01-01'), requestCode: requestCode || '0JZ39J', lastName: 'Test'} :
          // ? {dob: new Date('1995-06-22'), requestCode: requestCode || '5J4OMJ', lastName: 'Teste'} :
          // ? {dob: new Date('1995-06-22'), requestCode: requestCode || '92131Y', lastName: 'Do Nascimento'} :
          // ? {dob: new Date('1995-06-22'), requestCode: requestCode || 'N726R', lastName: 'Test'} :
          {dob: undefined, lastName: '', requestCode}
      )
  )
  // const DEFAULT_VALUES = useConstant({dob: undefined, lastName: '', requestCode})

  const emailVerificationPage = useToggle()

  const preValidationNew =  buildValidation({
     requestCode: [required],
     email:  [(v,k,values) => !v && providerInfo.pasEnabled && !isReadOnlyShare(values.requestCode) && required()],
     dob:  [(v,k,values) => !v && (!providerInfo.pasEnabled || isReadOnlyShare(values.requestCode)) && required()],
     lastName:  [(v,k,values) => !v && (!providerInfo.pasEnabled || isReadOnlyShare(values.requestCode)) && required()],
  })

  useClearSession()
  useEffect(() => {
    setCaseRequest(null)
  }, [])

  const onSuccess = (req, [{jwt, url, needsPasSignup}, {status}]) => {
    if (url) {
      window.location = url
      return new Promise(() => {})
    } else if (status === 256) {
      emailVerificationPage.open()
    } else {
      setJwt(jwt)
      // return jwtGetFetcher(jwt)(`/api/viewCaseRequest?timezoneOffset=${getTimezoneOffset()}`)
      return jwtGetFetcher(jwt)(`/api/patient/viewCaseRequest?timezoneOffset=${getTimezoneOffset()}`)
        .then((caseRequest) => {
          setCaseRequest(caseRequest)
          navigate(getFirstPage(caseRequest, providerInfo, isRefPhysician))
        })
    }
  }

  const onFailure = responseFailure((data, {status}) =>
    (status === 401 && {
      'lastName': ACCESS_ERROR,
      'dob': ACCESS_ERROR
    }) ||
    (status === 567 && {
      'requestCode': 'This timed access link has expired.'
    }) ||
    (status === 569 && {
      'login': ['This case is locked.']
    }) ||
    (status === 403 && {
      'email': "Invalid case or email not registered."
    }) ||
    (status === 404 && {'requestCode': ['We couldn\'t find the access code in question. Please review it and try again.']})
  )

  if (emailVerificationPage.isOpen) {
    return <EmailVerificationPage providerInfo={providerInfo}/>
  }

  return <SignInLayout providerInfo={providerInfo} withLocale>
    <SignInCard>
      <CardHeader>
        <h1>Expert View</h1>
        <p css="max-width:22rem;margin:1rem auto 1rem;">
          <FormattedMessage
            defaultMessage="To access an existing Expert View request enter the following:"
            description="Patient login form description"
          />
        </p>
      </CardHeader>
      <WithForm
        alwaysSave
        transform={transformDoB}
        preValidation={preValidationNew}
        onSuccess={onSuccess}
        onFailure={onFailure}
        values={DEFAULT_VALUES}
        action={formFetchWithResponse('/api/authenticate')}
      >{form => <>
        {providerInfo.pasEnabled
          ? 
            <>
              <TextInput
                label={intl.formatMessage({
                  defaultMessage: "Case ID",
                  description: "Patient login, case ID login input label"
                })}
                placeholder={intl.formatMessage({
                  defaultMessage: "Ex: A1B2C3",
                  description: "Patient login, case ID login input placeholder"
                })}
                path="requestCode"
                disabled={!!requestCode}
              />
              <FormValue path="requestCode">{ reqCode => 
                isReadOnlyShare(reqCode) 
                  ? 
                    <>
                      <TextInput
                        label={intl.formatMessage({defaultMessage:"Patient last name",description:"Patient last name login form"})}
                        placeholder={intl.formatMessage({
                          defaultMessage: "Ex: Smith",
                          description: "Patient login, last name placeholder"
                        })}
                        path="lastName"
                      />
                      <DatePicker
                        weekStartsMonday
                        label={intl.formatMessage({defaultMessage:"Patient date of birth",description:"Patient date of birth login form"})}
                        placeholder={intl.formatMessage({
                          defaultMessage:`Ex: {dateFormat}`,
                          description:"Patient login, date of birth placeholder"
                        },{dateFormat:defaultDateFormat(intl).toUpperCase()})}
                        format={defaultDateFormat(intl)}
                        path="dob"
                      />
                    </>
                  :
                    <TextInput
                      label={intl.formatMessage({
                        defaultMessage: "Your email address",
                        description: "Patient login, email input label"
                      })}
                      placeholder={intl.formatMessage({
                        defaultMessage: "Ex: username@email.com",
                        description: "Patient login, email input placeholder"
                      })}
                      path="email"
                    />  
              }</FormValue>
            </>
          : 
            <>
              <TextInput
                label={intl.formatMessage({
                  defaultMessage: "Case ID",
                  description: "Patient login, case ID login input label"
                })}
                placeholder={intl.formatMessage({
                  defaultMessage: "Ex: A1B2C3",
                  description: "Patient login, case ID login input placeholder"
                })}
                path="requestCode"
                disabled={!!requestCode}
              />
              <TextInput
                label={intl.formatMessage({defaultMessage:"Patient last name",description:"Patient last name login form"})}
                placeholder={intl.formatMessage({
                  defaultMessage: "Ex: Smith",
                  description: "Patient login, last name placeholder"
                })}
                path="lastName"
              />
              <DatePicker
                weekStartsMonday
                label={intl.formatMessage({defaultMessage:"Patient date of birth",description:"Patient date of birth login form"})}
                placeholder={intl.formatMessage({
                  defaultMessage:`Ex: {dateFormat}`,
                  description:"Patient login, date of birth placeholder"
                },{dateFormat:defaultDateFormat(intl).toUpperCase()})}
                format={defaultDateFormat(intl)}
                path="dob"
              />
            </>
        }
        <Errors/>
        <CardActionButton highlight isLoading={form.isLoading} type="submit">
          <FormattedMessage
            defaultMessage="Access"
            description="Patient login form submit button"
          />
        </CardActionButton>
      </>}</WithForm>
    </SignInCard>
    <IfLocale not contains="LEGAL">
      {patientCaseCreation && <AdditionalActionButtons>
        <p>
          <FormattedMessage
            defaultMessage="Looking to create an Expert View {linebreak}request at {providerName}?"
            description="Patient login form additional action description"
            values={{
              providerName: providerInfo.name,
              linebreak: <br/>
          }}
          />

        </p>
        <Button css="min-width: 13rem;" onClick={() => navigate(isNurse ? '/nurse/start' : isRefPhysician ? '/refphysician/start' : '/patient/start')}><FormattedMessage
          defaultMessage="Get started"
          description="Patient login form get started button"
        /></Button>
        </AdditionalActionButtons>
      }
    </IfLocale>
  </SignInLayout>
}
