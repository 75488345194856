import {Button, Icon} from '@startlibs/components';
import {Field} from '@startlibs/form';
import {darken, transparentize} from 'polished';
import {getColor, media} from '@startlibs/utils';
import {useToggle} from '@startlibs/core'
import React, {useEffect} from 'react'
import styled, {css} from 'styled-components';
import {CopyLinkComponent} from 'sharecomponents/src/pages/ShareDialog'
import {Card, PageContainer, PageFooter} from '../components/PageLayout';
import {Header} from '../components/Header';
import {PurviewFooter} from '../components/PurviewFooter';
import {useSendRemoteConsultationLink} from './dialogs/useSendRemoteConsutationLink'
import ClipboardJS from 'clipboard'
import {useSuspense, willUseSuspense} from '../hooks/useSuspense'
import {jwtGetFetcher} from '../utils/authFetch'
import {getJwt} from '../hooks/useJwt'
import {RemoteConsultationLog} from '../admin/steps/removeConsultation/RemoteConsultationLog'
import { FeedbackDialog } from './dialogs/FeedbackDialog';
import {FormattedMessage, useIntl} from "react-intl";

const CopyLinkComponentContainer = styled.div`
  display: flex;
  align-items: flex-end;
  position: relative;
  >:first-child {
    flex-grow: 1;
    margin-right: 1rem;
  }
`
const CopyButtonStyle = styled.div`
  position: absolute;
  right: 1px;
  top: 50%;
  transform: translateY(-50%);
  color: ${getColor('main')};
  font-weight: 600;
  font-size: 13px;
  background: ${getColor('gray240')};
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  :hover {
    color: ${props => darken(0.075, getColor("main")(props))};
  }
  ${props => props.copied && css`
    &, :hover, active {
      color: ${props => darken(0.075, getColor("success")(props))};
      cursor: default;
    }
    ${Icon} {
      font-size: 17px;
      line-height: 0;
      vertical-align: -3px;
      margin-right: 4px;
    }
  `}
`

const CopyLinkButton = ({link}) => {
  const copied = useToggle()
  const ref = React.useRef()

  useEffect(() => {
    const container = ref.current.closest('.JS-copy-focus-container')
    const btn = new ClipboardJS('.copy-to-clipboard', container ? {container} : {})
    return () => btn.destroy()
  }, [])

  return <CopyButtonStyle
    data-clipboard-text={link}
    ref={ref}
    className="copy-to-clipboard"
    copied={copied.isOpen}
    onClick={() => {
      copied.open();
      copied.closeAfter(1200)
    }}
  >{copied.isOpen ? <><Icon icon="check"/><FormattedMessage
    defaultMessage="Link copied"
    description="Link copied message"
  /></> : <FormattedMessage
    defaultMessage="Copy"
    description="Copy button"
  />}</CopyButtonStyle>
}

const useCurrentExpertsSuspense = willUseSuspense((requestId) => Promise.all([
  jwtGetFetcher(getJwt())(`/api/meetingURL?requestId=${requestId}`),
  jwtGetFetcher(getJwt())(`/api/userInfo`)
]))

export const ExpertRemoteConsultation = ({caseRequest, feedbackPopup}) => {
  const intl = useIntl()
  const [meeting, userInfo] = useCurrentExpertsSuspense(caseRequest.requestId)
  const link = meeting.url

  const copied = useToggle()

  const sendEmail = useSendRemoteConsultationLink(caseRequest, link)

  return <> <PageContainer>
    <Header isExpert title={intl.formatMessage({
      defaultMessage: "Video consultation",
      description: "Expert remote consultation page title"
    })}>
    </Header>
    {
      feedbackPopup.isOpen && <FeedbackDialog feedbackPopup={feedbackPopup} requestId={caseRequest.requestId} />
    }
    <Card>
      <p css="margin-bottom: 2rem;"><FormattedMessage
        defaultMessage={`To set up a remote video consultation session, schedule it with the
        appropriate parties and send them the following link, then click
        'Launch' to join the session at the scheduled time.`}
        description="Expert remote consultation description"
      />
      </p>
      <Field label={intl.formatMessage({
          defaultMessage: "Consultation link:",
          description: "Expert remote consultation link label"
        })}>
        <CopyLinkComponentContainer>
          <div css="position:relative">
            <CopyLinkComponent className='fs-unmask' url={link}/>
            {ClipboardJS.isSupported() && <CopyLinkButton link={link}/>}
          </div>
          <Button onClick={sendEmail} highlight icon="email"><FormattedMessage
            defaultMessage="Invite"
            description="Invite button"
          /></Button>
        </CopyLinkComponentContainer>
      </Field>
    </Card>
    <div css="text-align: right;">
      <Button.a href={link} icon="video" target="_blank"><FormattedMessage
        defaultMessage="Launch"
        description="Launch button"
      /></Button.a>
    </div>
    <RemoteConsultationLog experts={[{expert:{...userInfo,email:userInfo.login}}]} caseRequest={caseRequest} canDelete={({owner}) => owner} isExpert/>
  </PageContainer>
    <PurviewFooter/>
  </>
}
