import {
  Button,
  Dialog,
  Icon,
  Loading,
  Tooltip,
  TransitionDiv
} from '@startlibs/components';
import {Errors, Field, RadioboxGroup, SimpleRadiobox, ToggleCheckbox, WithForm} from '@startlibs/form'
import { darken } from 'polished';
import {formatDate, getColor, wrapLazy} from '@startlibs/utils'
import {Fill, Slot} from '@startlibs/core'
import {useNavigate} from 'react-router'
import {useToggle} from '@startlibs/core'
import React, {useEffect, useImperativeHandle, useRef, useState} from 'react';
import SignaturePad from 'signature_pad'
import _ from 'lodash/fp'
import styled, { css } from 'styled-components';
import {Card, PageContainer, PageFooter} from '../components/PageLayout';
import {Header} from '../components/Header'
import { PdfReportFrame } from '../request/components/PdfReportFrame';
import {PurviewFooter} from '../components/PurviewFooter'
import {RecaptchaBrandingText} from './PatientCreateRequest';
import { SuccessBox } from '../components/InfoBox';
import {TermSignature} from '../admin/steps/hooks/terms/TermSignature'
import {WAITING_MEDICAL_RECORDS} from '../enums/CaseState';
import {buildValidation} from '../utils/validation'
import {formatDateNoUTC, getTimezoneOffset} from '../utils/utils'
import {getJwt} from '../hooks/useJwt';
import {hasAcceptedAllTerms} from './utils/caseRequestUtils'
import {jwtGetFetcher, jwtPostFetcher} from '../utils/authFetch'
import { PatientFeedback } from '../components/PatientFeedback';
import {defaultDateTimeFormat} from "../utils/dateFormat";
import {FormattedMessage, useIntl} from "react-intl";
import { lazyProviderInfo } from '../components/WithProvider';
import { getFirstPage } from './utils/patientUtils';
import { setNotification } from '../components/Notifications';

const SignaturePadContainer = styled("canvas")`
  background: white;
  border: 1px solid ${getColor('gray210')};
  border-radius: 5px;
  display: block;
  margin-bottom: .5rem;
  width: 100%;
  ${props => props.height && `height: ${props.height}px`}
`

export const TermsContainer = styled.div`
  background: white;
  border: 1px solid ${getColor('gray210')};
  border-radius: 5px;
  display: block;
  margin-bottom: 2rem;
  overflow: hidden;
  position: relative;
  font-size: 12px;
  min-height: 10rem;
  .termsContent {
    max-height: 25rem;
    overflow: auto;
    padding: 1.5rem;
  }
  p {
    font-size: 12px;
  }
  h4 {
    margin-top: 1rem;
  }
  ul, ol {
    padding-left: 1.5rem;
    margin-bottom: .5rem;
    font-size: 12px;
  }
  ul {
    list-style-type: disc;
  }
  ol {
    ol {
      list-style-type: lower-alpha;
    }
  }
  li ~ li {
    margin-top: .25rem;
  }
`

const TermsInstruction = styled.div`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 1.5rem;
`

const lazyTerms = wrapLazy(() =>
  jwtGetFetcher("")('/api/serviceTerms')
)

const SignButtonsWrapper = styled.div`
  flex-shrink: 0;
`

const TabsWrapper = styled.div`
  display: flex;
  padding-bottom: 1rem;
`

const TabItem = styled.div`
  border: 1px solid ${getColor('gray210')};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  flex-grow: 1;
  flex-basis: 0;
  min-width: 60px;
  position: relative;
  padding: 0 .5rem;
  .tab-description {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
  }
  ${Icon} {
    margin-right: .25rem;
    font-size: 16px;
    vertical-align: top;
  }
  ${props => props.isActive && css`
    border-bottom: 1px solid transparent;
    font-weight: 600;
  `}
  ${props => !props.isActive && css`
    color: ${getColor('gray150')};
  `}
  ${props => props.locked && css`
    opacity: 0.5;
    background: ${getColor('gray240')};
  `}
  ${props => !props.locked && !props.isActive && css`
    cursor: pointer;
  `}
  ${props => props.isChecked && css`
    color: ${getColor('success')};
  `}
  ${props => props.isChecked && !props.isActive && css`
    background: ${getColor('lightGreen')};
  `}
  ${props => !props.isActive && !props.locked && !props.isChecked && css`
    :hover {
      background: rgba(0,0,0,0.05);
    }
  `}
  ${props => !props.isActive && !props.locked && props.isChecked && css`
    :hover {
      background: ${props => darken(0.05, getColor("lightGreen")(props))};
    }
  `}
`

export const FrameWrapper = styled.div`
  position: relative;
  :after {
    content: '';
    bottom: 4px;
    left: 0;
    right: 0;
    position: absolute;
    height: .5rem;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background: rgb(0,0,0);
    background: -moz-linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.3) 100%);
    background: -webkit-linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.3) 100%);
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.3) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
  }
`

export const DigitalSignatureField = styled(Field)`
  position: relative;
  margin-top: 1rem;
  ${Button} {
    position: absolute;
    top: 1rem;
    right: 0;
  }
  .help-text {
    color: ${getColor('gray90')};
  }
`

export const TermPDFFrame = styled(PdfReportFrame)`
  min-height: 240px;
  ${props => !props.isPending && css`
    min-height: 320px;
  `}
  ${props => props.isAdmin && css`
    min-height: 400px;
  `}
`

export const TermsCard = styled(Card)`
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
  ${Loading} {
    margin: 2rem auto;
  }
`

export const SignedIntroWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  p, .left-wrapper {
    flex-grow: 1;
  }
`

export const TermActions = styled.div`
  display: flex;
  margin: .5rem 0 1rem;
  .left-wrapper {
    flex-grow: 1;
  }
  .right-wrapper {
    flex-shrink: 0;
  }
`

export const PatientAgreements = ({caseRequest, setCaseRequest, feedbackPopup, isRefPhysician}) => {
  const terms = lazyTerms.read()
  const navigate = useNavigate()
  const isPending = !hasAcceptedAllTerms(terms,caseRequest)
  const intl = useIntl()
  const providerInfo = lazyProviderInfo.read()
  const canAccessCaseStatus = providerInfo?.patientAccessFunctions?.caseStatus

  return <>
    <PageContainer>
      <Header
        title={intl.formatMessage({
          defaultMessage: "Services agreement",
          description: "Patient service agreement header"
        })}
      >
      </Header>
      <ServiceTermsContent
        caseRequest={caseRequest}
        providerInfo={providerInfo}
        isRefPhysician={isRefPhysician}
        isPending={isPending}
        onFailure={(e,[resp,{status}]) => {
          if (status === 569) {
            navigate("/access")
            setNotification({type:"alert", msg:intl.formatMessage({
                defaultMessage:"This case is locked",
                description:"Patient service agreement, case locked notification"
              })})
          }            
        }}
        onSuccess={(values,acceptanceInfo) => {
          setCaseRequest(
            _.set("acceptanceInfo", acceptanceInfo)
          )
          navigate(canAccessCaseStatus ? "/patient/status" : getFirstPage(caseRequest, providerInfo, isRefPhysician))
        }}
        returnButton={
          canAccessCaseStatus ? <Button.Link to="/patient/status"><FormattedMessage
            defaultMessage="Return to case status"
            description="Patient service agreement, return to case status button"
            /></Button.Link>
          // : <Button.Link to={getFirstPage(caseRequest, providerInfo, isRefPhysician)}>Return</Button.Link>
          : <></>
      }
      />
    </PageContainer>
    <PurviewFooter/>
    {feedbackPopup.isOpen && <Dialog title={intl.formatMessage({
      defaultMessage: "Share feedback",
      description: "Patient service agreement, feedback dialog title"
    })} closeDialog={feedbackPopup.close}>
      <PatientFeedback caseRequest={caseRequest} feedbackPopup={feedbackPopup} isPopup />
      </Dialog>
    }
  </>
}

const TabButton = ({label, onClick, isLocked, isActive, isChecked}) =>
  <TabItem
    onClick={onClick}
    locked={isLocked}
    isActive={isActive}
    isChecked={isChecked}
  >
    <span className="tab-description">
      {isChecked && <Icon icon='check'/>}
      {label}
    </span>
    {
      isLocked && <Tooltip content="Agree & advance to view this step"/>
    }
  </TabItem>

const TermsContent = React.forwardRef(({signatureTab, caseRequest, isPending, hasAdvanced, tab}, ref) => {
  const terms = lazyTerms.read()
  const intl = useIntl()
  useEffect(() => {
    hasAdvanced.openWith(hasAdvanced.get() === true || tab.isOpen === 'signature' || Math.max(tab.isOpen, hasAdvanced.get()))
  }, [tab.isOpen])

  useImperativeHandle(ref, () => ({
    advance: () => tab.openWith(tab.get() + 1 === terms.length ? 'signature' : (tab.get() + 1)),
    previous: () => tab.openWith(tab.get() === 'signature' ? (terms.length - 1) : (tab.get() - 1))
  }),[])

  const allOpen = hasAdvanced.isOpen === true

  return <div>
  <TabsWrapper>
    {
      terms.map((term, i) => <TabButton
          key={term.id}
          isLocked={i > hasAdvanced.isOpen && !allOpen}
          isChecked={i < hasAdvanced.isOpen || allOpen}
          isActive={tab.isOpen === i}
          onClick={i <= hasAdvanced.isOpen || allOpen ? tab.willOpenWith(i) : undefined}
          label={term.serviceTermsDetails.description}
        />
      ).concat((signatureTab && isPending)
        ? <TabButton
          key="signature"
          isActive={tab.isOpen === 'signature'}
          isLocked={!allOpen}
          onClick={allOpen ? tab.willOpenWith('signature') : undefined}
          label={intl.formatMessage({
            defaultMessage: "Sign-off",
            description: "Patient service agreement, sign-off tab label"
          })}
        />
        : [])
    }
  </TabsWrapper>
    {
      terms[tab.isOpen]
        ? <ShowTerm isPending={isPending} term={terms[tab.isOpen]} agreement={caseRequest?.acceptanceInfo?.agreements?.find(({serviceTermId}) => serviceTermId === terms[tab.isOpen]?.id)}/>
        : signatureTab
    }
  </div>
})

const getViewUrl = (term,agreement) => agreement
  ? `/api/agreement/${agreement.serviceTermsDetails.documentPath}?t=${getJwt()}`
  : `/api/serviceTerms/${term.serviceTermsDetails.documentPath}`

const ShowTerm = ({term,agreement,isPending}) => {
  const intl = useIntl()
  return <div>
    {
      agreement && <Fill name="Term-Intro">
        {agreement.byRepresentative
          ? <FormattedMessage
            defaultMessage="An administrator has already confirmed your agreement to this term"
            description="Patient service agreement, term already agreed by representative"
            />
          : <FormattedMessage
            defaultMessage="You have already agreed to this required term"
            description="Patient service agreement, term already agreed"
          />
        }
      </Fill>
    }
    {
      agreement && <TermActions>
        <div className="left-wrapper">
          <SuccessBox>
            <Icon icon="check"/>
            <span>
              <FormattedMessage
                defaultMessage="{byRepresentative, select, true {Marked as agreed} other {Signed}} on {date}"
                description="Patient service agreement, term signed success message"
                values={{
                  byRepresentative: agreement.byRepresentative,
                  date: formatDateNoUTC(new Date(agreement.acceptedTermsEpochMilli),defaultDateTimeFormat(intl))
              }}
              /></span>
          </SuccessBox>
        </div>
        <div className="right-wrapper">
          <Button.a icon="download" download href={getViewUrl(term,agreement)}><FormattedMessage
            defaultMessage="Save PDF"
            description="Save PDF button"
          /></Button.a>
          <Button.a icon="print" target="_blank" href={getViewUrl(term,agreement)}><FormattedMessage
            defaultMessage="Print"
            description="Print button"
          /></Button.a>
        </div>
      </TermActions>
    }
    <FrameWrapper>
      <TermPDFFrame isPending={isPending} src={getViewUrl(term,agreement) + "#toolbar=0&navpanes=0&scrollbar=0"}/>
    </FrameWrapper>
  </div>
}
const SignatureTab = ({isPending, canAgree, caseRequest, signaturePad}) => {
  const intl = useIntl()
  return <div>
    {
      isPending
        ? <div css="position:relative">
          <TransitionDiv>
            {
              canAgree && <div>
                <DigitalSignatureField label={intl.formatMessage({
                  defaultMessage: "Digital signature",
                  description: "Patient service agreement, digital signature field label"
                })}
                  descText={<span className="help-text"><FormattedMessage
                    defaultMessage="By signing below you confirm that you have <b>read and agree to all the previous documents</b>."
                    description="Patient service agreement, digital signature field help text"
                    values={{b: (chunks) => <b>{chunks}</b>}}
                  /></span>}
                  mandatory>
                  <SignaturePadComponent signaturePad={signaturePad}/>
                  <Button small onClick={() => signaturePad.get().clear()}><FormattedMessage
                    defaultMessage="Clear signature"
                    description="Patient service agreement, clear signature button"
                  /></Button>
                </DigitalSignatureField>
              </div>
            }
          </TransitionDiv>
        </div>
        : <TermSignature isPatient caseRequest={caseRequest}/>
    }
  </div>
}

export const ServiceTermsContent =
  ({
     isPending,
     caseRequest,
     action = (values) => jwtPostFetcher(getJwt())(`/api/acceptServiceTerms`, {...values,timezoneOffset:getTimezoneOffset()}),
     onSuccess,
     returnButton,
     onFailure,
     withNonPatientSkip,
     withRecaptchaVerification
   }) => {
  const intl = useIntl()
    const tab = useToggle(0)
    const hasAdvanced = useToggle(isPending ? 0 : true)
    const termsRef = useRef()

    const signaturePad = useToggle({})
    const skipSignature = useToggle()
    const isPersisted = caseRequest?.requestId

    const [canAgree,setCanAgree] = useState(null)
    const [hasSelected,setHasSelected] = useState(false)

    useEffect(() => {
      if(isPersisted) {
        setCanAgree(true)
        setHasSelected(true)
      }
    },[])

    return <>
      {/* {isPending && !skipSignature.isOpen && <p>Please review the term(s) in the steps below and sign-off to confirm your agreement:</p>} */}
      {isPending && <p><FormattedMessage
        defaultMessage="Please review the term(s) in the steps below and sign-off to confirm your agreement:"
        description="Patient service agreement, pending agreement instructions"
      /></p>}
      {
        !isPending && <SignedIntroWrapper>
        <div className="left-wrapper">
          <Slot name="Term-Intro" inline/>
        </div>
        <Button.a icon="download" small href={`/api/agreement/zip?requestId=${caseRequest.requestId}&t=${getJwt()}`}><FormattedMessage
          defaultMessage="Download all"
          description="Download all button label"
        /></Button.a>
      </SignedIntroWrapper>
      }
      <TermsCard skipping={!canAgree}>
        {withNonPatientSkip && isPending && <RadioboxGroup  label={intl.formatMessage({
          defaultMessage: "Are you the patient/patient representative who can agree to the terms?",
          description: "Patient service agreement, patient agreement representative question"
        })} horizontal framedBoxes wide>
            <SimpleRadiobox key={'Yes'} label={intl.formatMessage({
              defaultMessage:'Yes',
              description: "Patient service agreement, patient agreement representative question, yes option"
            })} raw fieldValue={true} highlightSelected value={canAgree} setValue={() => {setCanAgree(true); setHasSelected(true)}} />
            <SimpleRadiobox key={'No'} label={intl.formatMessage({
              defaultMessage:'No',
              description: "Patient service agreement, patient agreement representative question, no option"
            })} raw fieldValue={false} highlightSelected value={canAgree} setValue={() => {setCanAgree(false); setHasSelected(true)}} />
          </RadioboxGroup>
        }

        <React.Suspense fallback={<Loading/>}>
          <TransitionDiv block>{ 
            <>
              {canAgree && !isPersisted && <hr style={{margin: '2rem 0rem'}}/>}
              {canAgree && <TermsContent
                tab={tab}
                ref={termsRef}
                caseRequest={caseRequest}
                isPending={isPending}
                hasAdvanced={hasAdvanced}
                signatureTab={<SignatureTab
                  isPending={isPending}
                  canAgree={canAgree}
                  caseRequest={caseRequest}
                  signaturePad={signaturePad}
                />}
              />}
            </>
          }</TransitionDiv>
          {
            // skipSignature.isOpen && <span>An administrator might request the agreement sign-off in the future.</span>
            !canAgree && hasSelected && <span><FormattedMessage
              defaultMessage="An administrator might request the agreement sign-off in the future."
              description="Patient service agreement, future agreement sign-off request"
            /></span>
          }
        </React.Suspense>
      </TermsCard>
      <WithForm
        alwaysSave
        action={action}
        onSuccess={onSuccess}
        onFailure={onFailure}
        transform={() => ({signature: !canAgree ? null : signaturePad.get().toDataURL("image/"), skipSignature: !canAgree})}
        preValidation={buildValidation({"": (() => (canAgree && withNonPatientSkip) && signaturePad.get().isEmpty() && intl.formatMessage(
            {
              defaultMessage: "You must agree to the terms by providing a signature above to continue.",
              description: "Patient service agreement, signature required error"
            }))})}
      >{form => <>
        <Errors/>
        <PageFooter>
          {withRecaptchaVerification &&
          <RecaptchaBrandingText>
            <FormattedMessage
              defaultMessage="This site is protected by reCAPTCHA and the Google <privacyLink>Privacy Policy</privacyLink> and <termsLink>Terms of Service</termsLink> apply."
              description="Patient service agreement, recaptcha branding text"
              values={{
                privacyLink: (chunks) => <a
                  href="https://policies.google.com/privacy"
                  rel="noopener noreferrer"
                  target="_blank"
                >{chunks}</a>,
                termsLink: (chunks) => <a
                  href="https://policies.google.com/terms"
                  rel="noopener noreferrer"
                  target="_blank"
                >{chunks}</a>
              }}
            />
          </RecaptchaBrandingText>
          }
          <SignButtonsWrapper>
            {
              isPending
                ? <>
                  {returnButton}
                  {
                    // skipSignature.isOpen
                    !canAgree
                      ? <Button disabled={hasSelected === false} type="submit" highlight isLoading={form.isLoading}><FormattedMessage
                        defaultMessage="Submit without signing"
                      description="Patient service agreement, submit without signing button"
                      /></Button>
                      : <>
                        {!!tab.isOpen && <Button onClick={() => termsRef.current.previous()}><FormattedMessage
                          defaultMessage="Previous"
                          description="Previous button"
                        /></Button>}
                        {
                          tab.isOpen === 'signature'
                          ? <Button type="submit" highlight isLoading={form.isLoading}>{isPersisted
                              ? <FormattedMessage
                                defaultMessage="Submit"
                                description="Submit button"
                              />
                              : <FormattedMessage
                                defaultMessage="Submit request"
                                description="Patient service agreement, submit request button"
                              />
                          }</Button>
                          : <Button highlight onClick={
                              (e) => {e.preventDefault() ; e.stopPropagation();termsRef.current.advance()}
                            } disabled={hasAdvanced.isOpen === false || hasSelected === false}><FormattedMessage
                              defaultMessage="Agree & advance"
                            description="Patient service agreement, agree and advance button"
                            /></Button>
                        }
                      </>
                  }
                </>
                : <>{caseRequest.state === WAITING_MEDICAL_RECORDS && <>{returnButton}</>}
                </>
            }
          </SignButtonsWrapper>
        </PageFooter>
      </>
      }</WithForm>
    </>
  }

const SignaturePadComponent = ({signaturePad}) => {
  const signatureRef = useRef()

  useEffect(() => {
    if (!signatureRef.current) {
      return
    }
    const canvas = signatureRef.current
    canvas.width = canvas.offsetWidth;
    signaturePad.openWith(new SignaturePad(canvas, {backgroundColor: "rgb(255,255,255)"}));
    const resizeEvent = () => {
      const imageData = canvas.getContext('2d').getImageData(0, 0, canvas.offsetWidth, canvas.offsetHeight);
      canvas.width = canvas.offsetWidth;
      canvas.getContext('2d').putImageData(imageData, 0, 0)
    }
    window.addEventListener("resize", resizeEvent);
    return () => window.removeEventListener("resize", resizeEvent);
  }, [])

  return <SignaturePadContainer height={120} ref={signatureRef}/>
}
