import React from 'react'

export const Waiting = "Waiting"
export const Uploading = "Uploading"
export const Uploaded = "Uploaded"
export const ConnectionError = "ConnectionError"
export const FileNotFound = "FileNotFound"
export const InvalidFilename = "InvalidFilename"
export const ProcessingError = "ProcessingError"
export const Quarantined = "Quarantined"
export const Shortlisted = "Shortlisted"
export const EmptyFile  = "EmptyFile"
export const Canceled = "Canceled"
export const Deleted = "Deleted"
export const Linked = "Imported from worklist"

export const getFileStateLabel = (intl,state) => ({
  [Quarantined]: intl.formatMessage({
    defaultMessage: "Harmful file", description: "Uploader, file error status, harmful file"
  }),
  [ProcessingError]: intl.formatMessage({
    defaultMessage: "Processing error", description: "Uploader, file error status, processing error"
  }),
  [FileNotFound]: intl.formatMessage({
    defaultMessage: "File not found", description: "Uploader, file error status, file not found"
  }),
  [InvalidFilename]: intl.formatMessage({
    defaultMessage: "Invalid filename", description: "Uploader, file error status, invalid filename"
  }),
  [ConnectionError]: intl.formatMessage({
    defaultMessage: "Connection error", description: "Uploader, file error status, connection error"
  }),
  [EmptyFile]: intl.formatMessage({
    defaultMessage: "File is empty", description: "Uploader, file error status, empty file"
  })
})[state] || state

const NO_RETRY_FAILURES = [InvalidFilename,Quarantined, EmptyFile]
export const IS_RETRY_POSSIBLE = ({failed,quarantined}) => NO_RETRY_FAILURES.indexOf(failed) <0 && !quarantined
export const IS_QUARANTINED = ({failed,quarantined}) => failed===Quarantined || quarantined

export const IS_FAILURE = (state) => [
  ConnectionError,
  FileNotFound,
  InvalidFilename,
  ProcessingError,
  Quarantined,
  Shortlisted,
  EmptyFile,
].indexOf(state) >= 0

