import { Button, Icon } from '@startlibs/components';
import { getColor} from '@startlibs/utils';
import {useToggle} from '@startlibs/core'
import React from 'react';
import _ from 'lodash/fp';
import styled, {css} from 'styled-components';
import { Card, PageContainer, PageFooter } from '../../components/PageLayout';
import { FrameWrapper, TermPDFFrame } from '../../patient/PatientSignTerms';
import { Header, ReturnButton } from '../../components/Header';
import {MarkedAsAgreed, NotSigned, SignatureRequest, Signed} from '../../enums/AgreementStatus'
import {PurviewFooter} from '../../components/PurviewFooter'
import { SignatureDate } from '../../request/Payment';
import { TextButton } from '../../components/StyledTable';
import {formatDateNoUTC} from '../../utils/utils'
import {getJwt} from '../../hooks/useJwt'
import {useMarkAsAgreed} from './hooks/terms/useMarkAsAgreed'
import {lazyTerms} from '../../patient/utils/caseRequestUtils'
import { useIntl } from 'react-intl';
import { desaturate, lighten } from 'polished';
import { lazyProviderInfo } from '../../components/WithProvider';

const TermsStep = styled.div`
  .intro {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    p {
      flex-grow: 1;
      margin: 0;
    }
    ${Button} {
      flex-shrink: 0;
    }
  }
`

const TermsList = styled.div`
  border: 1px solid ${getColor('gray210')};
  border-radius: 5px;
  overflow: hidden;
`

const StyledTermRow = styled.div`
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${getColor('gray210')};
  padding: .75rem 1rem;
  &:nth-child(odd) {
    background-color: ${props => desaturate(0.65, lighten(0.545, getColor('main')(props)))};
  }
  &:last-child {
    border-bottom: 0px;
  }
  .left-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .title {
    display: flex;
    align-terms: center;
    margin: .25rem 0;
    ${Icon} {
      font-size: 18px;
      margin-right: .5rem;
    }
    font-weight: 600;
    margin-right: 1rem;
  }
  ${TextButton} {
    flex-shrink: 0;
  }
`

const TermStatusTag = styled.div`
  font-size: 12px;
  color: ${getColor('warning')};
  display: inline-flex;
  align-items: center;
  line-height: 12px;
  border: 1px solid ${getColor('warning')};
  border-radius: 5px;
  padding: 4px 6px;
  ${Icon} {
    font-size: 15px;
    margin-right: .25rem;
  }
  ${props => props.success && css`
    border: none;
    background: ${getColor('lightGreen')};
    color: ${getColor('success')};
  `}
`

const ViewTermHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  .left-wrapper {
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    ${ReturnButton} {
      margin-right: .75rem;
    }
    .title {
      display: flex;
      align-terms: center;
      margin: .25rem 0;
      font-weight: 600;
      font-size: 14px;
      margin-right: 1rem;
      ${Icon} {
        font-size: 21px;
        margin-right: .5rem;
      }
    }
  }
  .right-wrapper {
    flex-shrink: 0;
  }
`

const getAgreementStatus = (term,agreement,caseRequest) =>
  agreement
    ? (
      agreement.byRepresentative
        ? MarkedAsAgreed
        : Signed
    )
    : (
      caseRequest.caseFlags?.requestFlags?.askForServiceTerms
        ? SignatureRequest
        : NotSigned
    )

export const AgreementsStep = ({caseRequest, setCaseRequest}) => {

  const intl = useIntl()
  const providerInfo = lazyProviderInfo.read()

  const terms = lazyTerms.read(getJwt())
  const viewTerm = useToggle()
  const markAsAgreed = useMarkAsAgreed(terms, caseRequest,setCaseRequest,intl)

  const termsWithAgreement = terms
    .map(term => [term, caseRequest.acceptanceInfo?.agreements.find(agreement => agreement.serviceTermId === term.id)])
    .concat(
      _.flatMap(
        agreement => terms.find(term => term.id === agreement.serviceTermId) ? [] : [[agreement,agreement]],
        caseRequest.acceptanceInfo?.agreements
    ))
  if (viewTerm.isOpen) {
    return <ViewSingleTerm viewTerm={viewTerm} {...viewTerm.isOpen}/>
  }

  return <>
    <PageContainer>
      <Header title="Review agreements" />
      <TermsStep>
        <div className="intro">
          <p>Review this case's agreements status:</p>
          {
            termsWithAgreement.find(([,agreement]) => !agreement)
              ? <Button small onClick={markAsAgreed}>Mark all unsigned as agreed</Button>
              : <Button.a small icon="download" href={`/api/agreement/zip?requestId=${caseRequest.requestId}&t=${getJwt()}`}>Download all</Button.a>
          }
        </div>
        <Card>
          <TermsList>
            {
              termsWithAgreement
                .map(([term, agreement, status]) =>
                  <TermRow
                    markAsAgreed={markAsAgreed}
                    viewTerm={viewTerm}
                    term={term}
                    agreement={agreement}
                    status={getAgreementStatus(term,agreement,caseRequest)}
                  />
                )
            }
          </TermsList>
        </Card>
      </TermsStep>

      <PageFooter>
        {providerInfo &&  providerInfo?.messagingEnabled === true  && <Button.Link to="../contact" highlight >
          Contact patient
        </Button.Link>}
      </PageFooter>
    </PageContainer>
    <PurviewFooter/>
  </>
}

const TermRow = ({term, viewTerm, agreement, status}) => <StyledTermRow>
  <div className="left-wrapper">
    <span className="title">
      <Icon icon="sign-doc" />
      {term.serviceTermsDetails.description}
    </span>
    {(status === Signed || status === MarkedAsAgreed) ?
      <SignatureDate>
        <span><Icon icon="check" /> {status} on {formatDateNoUTC(new Date(agreement.acceptedTermsEpochMilli),"MM/dd/yyyy at hh:mm")}</span>
      </SignatureDate>
      :
      <TermStatusTag>
        {status === NotSigned && <span>{status}</span>}
        {status === SignatureRequest && <><Icon icon="clock"/> <span>{status}</span></>}
      </TermStatusTag>
    }
  </div>
  <TextButton withIcon onClick={viewTerm.willOpenWith({term,agreement,status})}>
    <Icon icon="view" /> View
  </TextButton>
</StyledTermRow>

const getViewUrl = (term,agreement) => agreement
  ? `/api/agreement/${agreement.serviceTermsDetails.documentPath}?t=${getJwt()}`
  : `/api/serviceTerms/${term.serviceTermsDetails.documentPath}`


const ShowTerm = ({term,agreement}) => <>
<FrameWrapper>
  <TermPDFFrame isAdmin src={getViewUrl(term,agreement) + "#toolbar=0&navpanes=0&scrollbar=0"}/>
</FrameWrapper>

</>

const ViewSingleTerm = ({term,agreement,status,viewTerm}) => {

  // console.log(,)

  return  <>
    <PageContainer>
      <Header title="Review agreements - Document preview">
        <Button small highlight onClick={viewTerm.close}>View all documents</Button>
      </Header>
      <ViewTermHeader>
        <div className="left-wrapper">
          <ReturnButton returnTerms onClick={viewTerm.close}>
            <Icon icon="arrow-left-line" />
          </ReturnButton>
          <span className="title">
            <Icon icon="sign-doc" />
            {term.serviceTermsDetails.description}
          </span>
          {(status === Signed || status === MarkedAsAgreed) ?
            <SignatureDate>
              {(status === Signed || status === MarkedAsAgreed) && <span><Icon icon="check" /> {status} on {formatDateNoUTC(new Date(agreement.acceptedTermsEpochMilli),"MM/dd/yyyy at hh:mm")}</span>}
            </SignatureDate>
            :
            <TermStatusTag>
              {status === NotSigned && <span>{status}</span>}
              {status === SignatureRequest && <><Icon icon="clock"/> <span>{status}</span></>}
            </TermStatusTag>
          }
        </div>
        <div className="right-wrapper">
          <Button.a target="_blank" icon="print" href={getViewUrl(term,agreement)}>Print</Button.a>
          <Button.a icon="download" download href={getViewUrl(term,agreement)}>Download</Button.a>
        </div>
      </ViewTermHeader>

      <ShowTerm term={term} agreement={agreement} />

      <PageFooter>
        <Button.a target="_blank" icon="print" href={getViewUrl(term,agreement)}>Print</Button.a>
        <Button.a icon="download" download href={getViewUrl(term,agreement)}>Download</Button.a>
      </PageFooter>
    </PageContainer>
    <PurviewFooter/>
  </>

}
