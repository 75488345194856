import {Button} from '@startlibs/components'
import {TextInput} from '@startlibs/form'
import React from 'react'
import {ConfirmDialog, useConfirmDialog} from '../../hooks/useConfirmDialog'
import { ExpertCaseCard } from '../ExpertCaseCard';
import {getJwt} from '../../hooks/useJwt'
import {jwtPostFetcher} from '../../utils/authFetch'
import {buildValidation, required} from '../../utils/validation'
import {FormattedMessage, useIntl} from 'react-intl'

const preValidation = buildValidation({
  'reason': [required]
})

export const useDeclineCase = (caseRequest,onSuccess) => {
  const intl = useIntl()
  return useConfirmDialog(
    <ConfirmDialog
      title={intl.formatMessage({
        defaultMessage: "Decline case",
        description: "Expert decline case dialog title"
      })}
      action={(v) => jwtPostFetcher(getJwt())("/api/reviewer/rejectCase/" + (caseRequest.requestId || caseRequest.id), v)}
      onSuccess={onSuccess}
      preValidation={preValidation}
      closeLabel={intl.formatMessage({
        defaultMessage:"Cancel",
        description: "Cancel Button"
      })}
      notify={intl.formatMessage({
        defaultMessage: "Case declined successfully.",
        description: "Expert decline case dialog success message"
      })}
      confirm={<Button alert><FormattedMessage
        defaultMessage="Decline"
        description="Expert decline case dialog decline button"
      /></Button>}
    >
      <p><FormattedMessage
        defaultMessage="You will lose access to the following case:"
        description="useDeclineCase decline notification message"
      /></p>
      <ExpertCaseCard caseRequest={caseRequest} viewOnly />
      <TextInput
        textarea
        autoResize
        mandatory
        path="reason"
        label={intl.formatMessage({
          defaultMessage: "Reason:",
          description: "Expert decline case dialog reason label"
        })}
        descText={intl.formatMessage({
          defaultMessage: "This will be shared with your Expert View administrator.",
          description: "Expert decline case dialog reason description"
        })}
        placeholder={intl.formatMessage({
          defaultMessage: "Provide your reason for declining this case here.",
          description: "Expert decline case dialog reason placeholder"
        })}
      />
      <p><FormattedMessage description='useDeclineCase decline notification information message' defaultMessage='This will alert your Expert View administrator, but not the patient.' /></p>
      <p><FormattedMessage
        defaultMessage="Are you sure you want to proceed?"
        description="Expert decline case dialog confirmation message"
      /></p>
    </ConfirmDialog>
  )
}
