import React from 'react'
import {
  AdditionalActionButtons,
  CardActionButton,
  CardHeader,
  PurviewFooter,
  SignInCard,
  SignInLayout
} from '../components/SigninLayout'
import {DatePicker, Errors, TextInput, WithForm} from '@startlibs/form'
import _ from 'lodash/fp'
import {formatDate, formFetch} from '@startlibs/utils'
import {Button} from '@startlibs/components'
import {useClearSession} from '../hooks/useClearSession'
import {buildValidation, required, responseFailure} from '../utils/validation'
import {DATE_FORMAT} from '../config/DateFormat'
import {jwtFormFetcher, jwtGetFetcher} from '../utils/authFetch'
import {setJwt} from '../hooks/useJwt'
import {useConstant} from '@startlibs/core'
import {getFirstPage} from './utils/patientUtils'
import jwt_decode from 'jwt-decode'
import {useNavigate} from 'react-router'
import {getTimezoneOffset} from '../utils/utils'
import {defaultDateFormat} from "../utils/dateFormat";
import {useIntl} from "react-intl";

const transformDoB = _.update('dob', (dob) => {
  return formatDate(dob, 'MM-dd-yyyy')
})

export const ACCESS_ERROR = 'Invalid patient data. Please check the fields above and try again.'


export const PatientCaseAwareness = ({setCaseRequest, providerInfo, isNurse, isRefPhysician}) => {
  const navigate = useNavigate()
  const intl = useIntl()
  const jwt = window.JWT_URL_PARAM
  const decodedJwt = jwt_decode(window.JWT_URL_PARAM)
  const requestCode = decodedJwt.requestCode

  const DEFAULT_VALUES = useConstant({dob: undefined, lastName: '', requestCode})

  const preValidation = buildValidation({
    dob: [required],
    lastName: [required]
  })

  useClearSession()

  const onSuccess = (req, {jwt}) => {
    setJwt(jwt)
    // return jwtGetFetcher(jwt)(`/api/viewCaseRequest?timezoneOffset=${getTimezoneOffset()}`)
    return jwtGetFetcher(jwt)(`/api/patient/viewCaseRequest?timezoneOffset=${getTimezoneOffset()}`)
      .then((caseRequest) => {
        setCaseRequest(caseRequest)
        navigate(getFirstPage(caseRequest, providerInfo, isRefPhysician))
      })
  }

  const onFailure = responseFailure((data, {status}) =>
    (status === 401 && {
      'lastName': ACCESS_ERROR,
      'dob': ACCESS_ERROR
    }) ||
    (status === 403 && {
      'lastName': ACCESS_ERROR,
      'dob': ACCESS_ERROR
    }) ||
    (status === 404 && {'requestCode': ['We couldn\'t find the access code in question. Please review it and try again.']})
  )

  return <SignInLayout providerInfo={providerInfo}>
    <SignInCard>
      <CardHeader>
        <h1>
          <span css="display:block;margin-bottom:0.5rem;">Almost there!</span>
          <span css="font-size:18px;">This is your first time accessing this case</span>
        </h1>
        <p css="margin:1rem auto 2rem;">Please confirm the patient information below:</p>
      </CardHeader>
      <WithForm
        alwaysSave
        transform={transformDoB}
        preValidation={preValidation}
        onSuccess={onSuccess}
        onFailure={onFailure}
        values={DEFAULT_VALUES}
        action={jwtFormFetcher(jwt)('/api/posAuthenticate')}
      >{form => <>
        <TextInput
          label="Case ID"
          placeholder="Ex: A1B2C3"
          path="requestCode"
          disabled={!!requestCode}
        />
        <TextInput
          label="Patient last name"
          placeholder="Ex: Smith"
          path="lastName"
        />
        <DatePicker
          weekStartsMonday
          label="Patient date of birth"
          placeholder={`Ex: ${defaultDateFormat(intl).toUpperCase()}`}
          format={defaultDateFormat(intl)}
          path="dob"
        />
        <Errors/>
        <CardActionButton highlight isLoading={form.isLoading} type="submit">Access case</CardActionButton>
      </>}</WithForm>
    </SignInCard>
  </SignInLayout>
}
