
export const isRectEmpty = (rect) => rect.top === 0 && rect.bottom === 0 && rect.left === 0 && rect.right === 0

export const getTextRect = (node, pos = 0) => {
  const range = document.createRange()
  range.setStart(node, pos)
  range.setEnd(node, pos)
  const rect = range.getBoundingClientRect()
  if (isRectEmpty(rect)) { // safari fix
    const span = document.createElement('span')
    span.innerHTML = '&#8203;'
    if (pos === 0) {
      node.parentNode.insertBefore(span, node)
    } else {
      range.insertNode(span)
    }
    const spanRect = span.getBoundingClientRect()
    const parent = span.parentNode
    parent.removeChild(span)
    parent.normalize()
    return spanRect
  }
  return rect
}


export const binarySearchNextLine = (node, bottomBoundary, begin, textWindow) => {
  const max = node.textContent.length
  const end = Math.max(0, Math.min(begin + textWindow, max))
  const realWindow = end - begin
  const rect = getTextRect(node, end)
  if (realWindow <= 1) {
    if (end <= 1 && ((node.parentNode.nodeName.toUpperCase() === 'LI') || (node.parentNode.nodeName.toUpperCase() === 'A'))){ // LI fix
      if (rect.bottom < bottomBoundary)
        return null
      const newNode = document.createElement('span')
      if (node.parentNode.previousSibling) {
        node.parentNode.previousSibling.appendChild(newNode)
        return {node: newNode, rect: rect, position: 0}
      } else if (node.parentNode.parentNode.className.indexOf('field-content' >= 0)) {
        return {node: node.parentNode.parentNode, rect: node.parentNode.parentNode.getBoundingClientRect()}
      } else {
        node.parentNode.parentNode.insertBefore(node.parentNode, newNode)
        return {node: newNode, react: rect, position: 0}
      }
    }

    const range = document.createRange()
    if (end > 0) {// firefox fix
      range.setStart(node, end - 1)
      range.setEnd(node, Math.min(end + 1, node.textContent.length))
    }
    const nonCollapsedRect = range.getBoundingClientRect()
    const endTextRect = getTextRect(node, end - 1)
    if (end > 0 && (endTextRect.bottom >= bottomBoundary ||
      (nonCollapsedRect.left < rect.left && nonCollapsedRect.bottom >= bottomBoundary))) // firefox fix
    {
      return {
        position: end - 1,
        node: node,
        rect: isRectEmpty(nonCollapsedRect) ? endTextRect : nonCollapsedRect
      }
    } else if (rect.bottom >= bottomBoundary) {
      return {position: end, node: node, rect}
    } else if (node.textContent.length > end) {
      return {position: end + 1, node: node, rect: getTextRect(node, end + 1)}
    } else {
      return null
    }
  } else {
    if (rect.bottom <= bottomBoundary) {
      if (textWindow === realWindow) {
        return binarySearchNextLine(node, bottomBoundary, begin + textWindow, Math.ceil(textWindow / 2))
      } else {
        return null
      }
    } else {
      return binarySearchNextLine(node, bottomBoundary, begin, Math.ceil(textWindow / 2))
    }
  }
}

export const treeWalker = (element) =>
  document.createTreeWalker(
    element,
    NodeFilter.SHOW_ALL,
    (e) => {
      if(e.nodeType === Node.TEXT_NODE){
        return NodeFilter.FILTER_ACCEPT
      } else {
        return e.childNodes.length ? NodeFilter.FILTER_SKIP : NodeFilter.FILTER_ACCEPT
      }
    },
    false
  )
export const treeWalkerTr = (element) =>
  document.createTreeWalker(
    element,
    NodeFilter.SHOW_ALL,
    (e) => {
      if (e.nodeType === Node.TEXT_NODE || e.nodeName.toUpperCase() === 'TR') {
        return NodeFilter.FILTER_ACCEPT
      } else {
        return e.childNodes.length ? NodeFilter.FILTER_SKIP : NodeFilter.FILTER_ACCEPT
      }
    },
    false
  )

export const getTextLine = (element, bottom,useTreeWalkerOnlyText = false) => {
  const textWalker = useTreeWalkerOnlyText ? treeWalker(element, NodeFilter.SHOW_ALL) : treeWalkerTr(element, NodeFilter.SHOW_ALL)
  while (textWalker.nextNode()) {
    const current = textWalker.currentNode
    if (current.nodeType === Node.TEXT_NODE) {
      const length = current.textContent.length
      const result = binarySearchNextLine(current, bottom, 0, length)
      if (result) {
        return result
      }
    } else {
      const rect = current.getBoundingClientRect()
      if (rect.bottom > bottom) {
        return {node: current}
      }
    }
  }
  return null
}


export const getEmptySpace = (size,disclaimer) => {
  const div = document.createElement('div')
  if (!disclaimer) {
    div.className = 'page-filler'
  }
  div.style.height = size + 'px'
  return div
}

export const getPageBreak = (intl, page, totalPages, skipCounter) => {
  const div = document.createElement('div')
  const span = document.createElement('span')
  if (!skipCounter) {
    span.innerHTML = intl.formatMessage({
      defaultMessage: "Page {page}/{totalPages}",
      description: "Page counter",
    },{
      page:(page + 1), totalPages: (totalPages + 1)
    })
  }
  span.className = 'page-counter'
  div.appendChild(span)
  div.className = 'page-break'

  const clearBordersTop = document.createElement('div')
  const clearBordersBottom = document.createElement('div')
  clearBordersTop.className = 'clear-border clear-border-top'
  clearBordersBottom.className = 'clear-border clear-border-bottom'
  div.appendChild(clearBordersTop)
  div.appendChild(clearBordersBottom)

  return div
}

export const getLineHeight = (contents) => {
  const content = Array.prototype.slice.call(contents).find(el => el.textContent)
  if (!content)
    return 0
  const textWalker = document.createTreeWalker(content, NodeFilter.SHOW_TEXT, null, false)
  const text = textWalker.nextNode()
  return getTextRect(text).height
}

export const clearPagination = (container) =>
{
  Array.prototype.slice.call(container.querySelectorAll('.page-filler,.page-break,.report-header-fix-container,.remove-on-clear'))
    .forEach(e => e.parentNode.removeChild(e))
  Array.prototype.slice.call(container.querySelectorAll('.has-broken'))
    .forEach(e => e.className = "always-break-here")
  container.normalize()
}

export const getLastDiff = (separators) =>
{
  if (!separators.length) return 0
  return separators[separators.length - 1].diff
}


export const getSeparators = (nextNodes, top, opts, lastDiff, firstPage = false) => {
  const {contentPageHeight, minBreakSize, minContentSizeToBreak, contentLastPageHeight, disclaimerHeight, lastPageIsCover} = opts
  let contentBottom = top + contentPageHeight - lastDiff
  let index = nextNodes.findIndex(el => el.getBoundingClientRect().bottom > contentBottom || el.className.indexOf("always-break-here")>=0)
  const nodeToBreak = nextNodes[index]
  if (index < 0) {
    if (opts.canBeLastPage) {
      const contentLastPageBottom = top + contentLastPageHeight - lastDiff
      const lastPageIndex = nextNodes.findIndex(el => el.getBoundingClientRect().bottom > contentLastPageBottom)
      if (lastPageIndex >= 0) {
        index = lastPageIndex
        contentBottom = contentLastPageBottom
      } else {
        return {top: top, separators: []}
      }
    } else {
      return {top: top, separators: []}
    }
  }
  let result = {}
  let bottom = 0
  if (nodeToBreak.className.indexOf("signature-item") >= 0) {
    // Move the entire signature block to the next page
    const parent = nodeToBreak.closest('.signature-block');
    bottom = nodeToBreak.parentNode.getBoundingClientRect().top;
      
    // Create a page filler to push the signature to the next page
    const filler = document.createElement('div');
    filler.style.height = (contentBottom - bottom - 1) + 'px';
    filler.className = "remove-on-clear";
    
    parent.parentNode.insertBefore(filler, parent);
    parent.className = "has-broken signature-block";
    bottom = contentBottom - 1;
    result = { node: parent };
  }else{

    if (nodeToBreak.className.indexOf("always-break-here")>=0) {
      bottom = nodeToBreak.getBoundingClientRect().top
      const filler = document.createElement('div')
      filler.style.height = (contentBottom - bottom - 1) + 'px'
      filler.className = "remove-on-clear"
      nodeToBreak.parentNode.insertBefore(filler,nodeToBreak)
      nodeToBreak.className = "has-broken NODE-TO-BREAK"
      bottom = contentBottom - 1
      result = {node:nodeToBreak}
    } else if (nodeToBreak.offsetHeight < minContentSizeToBreak || nodeToBreak.getBoundingClientRect().top + minBreakSize > contentBottom || nodeToBreak.className.indexOf("field-content-block")>=0) {
      const parent = nodeToBreak.closest('.NODE-TO-BREAK')
      bottom = nodeToBreak.parentNode.getBoundingClientRect().top
      let hasNextNode = nextNodes.slice(index).findIndex(el => el.getBoundingClientRect().bottom > contentBottom || el.className.indexOf("always-break-here")>=0)
      if (hasNextNode) {
        result = {node: parent}
      } else {
        const {position, node, rect} = getTextLine(nodeToBreak, contentBottom)
        // sometimes the nodeToBreak will end after the page, but the text will will end before the page, this is a hack
        || getTextLine(nodeToBreak, contentBottom - 51)
        || getTextLine(nodeToBreak, contentBottom - 102) 
        if (position !== undefined) {
          bottom = rect.top
          result = {node, position}
        } else {
          bottom = node.getBoundingClientRect().top
          result = {node}
        }
      }
    } else {
      const {position, node, rect} = getTextLine(nodeToBreak, contentBottom)
      || getTextLine(nodeToBreak, contentBottom - 51)
      || getTextLine(nodeToBreak, contentBottom - 102) 
      // || getTextLine(nodeToBreak, contentBottom - 153)
      if (position !== undefined) {
        bottom = rect.top
        result = {node, position}
      } else {
        bottom = node.getBoundingClientRect().top
        result = {node}
      }
    }

    // We do not want to break the page on a table row if it is a vertically long row.
    // Then we will break the page on the text inside the row.
    // This operation must be done here because we need to know the bottom of the page to caculate the diff.
    let useTreeWalkerOnlyText = (contentBottom - bottom) > 200
    if(useTreeWalkerOnlyText){
      const {position, node, rect} = getTextLine(nodeToBreak, contentBottom,true)
      || getTextLine(nodeToBreak, contentBottom - 51, true)
      || getTextLine(nodeToBreak, contentBottom - 102, true) 
      if (position !== undefined) {
        bottom = rect.top
        result = {node, position}
      } else {
        bottom = node.getBoundingClientRect().top
        result = {node}
      }
    }
  }
  const diff = contentBottom - bottom
  const {top: lastTop, separators: nextSeparators} = getSeparators(nextNodes.slice(index), contentBottom, opts, diff)
  if (nextSeparators.length === 0 && contentLastPageHeight !== contentPageHeight && !firstPage && !lastPageIsCover) {
    return getSeparators(nextNodes, top, {...opts, contentPageHeight: contentLastPageHeight}, lastDiff)
  }
  return {top: lastTop, separators: [{...result, diff}, ...nextSeparators]}
}