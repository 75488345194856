import React from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components/macro'
import {RecordRowContainer} from "./RecordRowContainer";
import {DescriptionField} from "./DescriptionField";
import {AttachmentDescription, AttachmentDetails} from "../AttachmentBoxStyles";
import {Pathology} from "../../enums/RecordClass";
import {CCDA, FormatToLabel} from "../../enums/RecordFormat";
import {formatDate} from "@startlibs/utils";
import {dicomDateToDate} from "../../utils";
import {useToggle} from "@startlibs/core";
import {Button, Dialog} from "@startlibs/components";
import {UploaderAction} from "../../service/UploaderAction";
import {RecordDetailsDialog} from "./RecordDetailsDialog";
import {useDoAction} from "../../service/hooks/useDoAction";
import {FormattedMessage, useIntl} from "react-intl";

styled

export const PathologyRecordRow = ({record, group, dragProvided}) => {
  const intl = useIntl()
  const doAction = useDoAction()
  const recordDetailsDialog = useToggle()
  const cancelDialog = useToggle()
  const cancelLoading = useToggle()
  const deleteLoading = useToggle()

  const deleteRecord = async () => {
    await deleteLoading.wait(doAction(UploaderAction.Delete, record))
    cancelDialog.close()
  }

  return <RecordRowContainer
    group={group}
    record={record}
    dragProvided={dragProvided}
    cancelUpload={cancelDialog.open}
    openDetails={recordDetailsDialog.open}
    isMultiFile
  >{isMinified =>
    <>
      <AttachmentDescription className="fs-exclude">
        <b>{record.description}</b> <a className="light-link" onClick={recordDetailsDialog.open}><FormattedMessage
        defaultMessage="Details"
        description="Details button"
      /></a>
      </AttachmentDescription>
      <AttachmentDetails><b><FormattedMessage
        defaultMessage="Format"
        description="Uploader, pathology record format label"
      />: </b>{FormatToLabel[record.recordFormat] || record.recordFormat}</AttachmentDetails>
      {
        recordDetailsDialog.isOpen &&
        <RecordDetailsDialog
          title={intl.formatMessage({
            defaultMessage: "Pathology record details",
            description: "Uploader, pathology record details dialog title"
          })}
          closeDialog={recordDetailsDialog.close}
          cancelUpload={cancelDialog.open}
          record={record}
          withCancelSingleFile
        />
      }
      {
        cancelDialog.isOpen &&
        <Dialog
          closeDialog={cancelDialog.close}
          title={intl.formatMessage({
            defaultMessage: "Cancel pathology records upload",
            description: "Uploader, pathology records cancel dialog title"
          })}
          footer={<>
            <Button onClick={cancelDialog.close}><FormattedMessage
              defaultMessage="Continue upload"
              description="Uploader, pathology record cancel, continue upload button"
            /></Button>
            <Button alert isLoading={cancelLoading.isOpen} onClick={cancelLoading.willWait(deleteRecord)}><FormattedMessage
              defaultMessage="Confirm and remove record"
              description="Uploader, pathology record cancel, confirm and remove record button"
            /></Button>
          </>}
        >
          <FormattedMessage
            defaultMessage={`<p>This pathology record will be removed.</p>
          <p>Are you sure you want to proceed?</p>`}
            description="Uploader, pathology record cancel dialog content"
          />
        </Dialog>
      }
    </>
  }</RecordRowContainer>
}