let resolveJwt = null
const originalPromise = new Promise((res) => resolveJwt = res)
let jwt = originalPromise


export const setJwt = (newJwt,sessionKey) => {
  jwt = newJwt
  resolveJwt(jwt)
  if (sessionKey) {
    window.localStorage.setItem(sessionKey+"-jwt",newJwt)
  }
}
export const isJwtAvailable = () => { return jwt !== originalPromise }
export const getJwt = () => jwt
