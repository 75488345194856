import {Button, setNotification, TransitionDiv} from '@startlibs/components';
import React, { useEffect, useRef, useState } from 'react'
import {Card} from '../components/PageLayout';
import {getJwt} from '../hooks/useJwt'
import styled, { css } from 'styled-components';
import { jwtGetFetcher, jwtPostFetcher } from '../utils/authFetch';
import { Errors, Field, TextInput, useProvideErrors, WithForm } from '@startlibs/form';
import { useToggle } from '@startlibs/core';
import { getColor } from '@startlibs/utils';
import { transparentize, darken } from 'polished';
import {FormattedMessage, useIntl} from "react-intl";

export const FeedbackButtonsContainer = styled.div`
  display: flex;
  margin-top: 0.5rem;
  border-radius: 6px;
  overflow: hidden;
  min-height: 48px;
`

export const FeedbackButton = styled(Button)`
  border-radius: 0px;
  flex-basis: 15%;
  flex-grow: 1;
  font-size: 13px;
  font-weight: 600;
  box-shadow: none;
  margin-left: 0 !important;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  background: ${props => transparentize(0.85, getColor(props, true))};
  ${props => props.isSelected ? css `
    color: white;
    background: ${getColor};
    :hover {
      background: ${getColor};
    }
  `: css`
    color: ${props => darken(0.05, getColor(props, true))};
    :hover {
      background: ${props => transparentize(0.77, getColor(props, true))};
    }
  `}
`

export const PatientFeedback = ({caseRequest, isPopup = false, feedbackPopup = null, setJustSentFeedback = null}) => {
  const intl = useIntl()
  const [rating, setRating] = useState(0);
  const loadingFeedback = useToggle(false)
  const formRef = useRef()
  const [formValues, setFormValues] = useState({review: ""})
  const [ErrorProvider, errors, {setError}] = useProvideErrors()
  
  const postFeedback =  (value) => {
    setRating(value)
    jwtPostFetcher(getJwt())('/api/feedback?requestId='+caseRequest.requestId+'&presave=true',{stars: value, message: ""},{method:"PUT"})
      .then((response) => {
        
      })
      .catch((error) => {
        
      })
  }

  return <> 
        <p css="font-size:14px!important;"><FormattedMessage
          defaultMessage="Your feedback is invaluable for helping us improve for future patients."
          description="Patient feedback, thank you message"
        /></p>
        <Field label={intl.formatMessage({
          defaultMessage: "Overall, how would you rate your experience with this platform?",
          description: "Patient feedback, rating question"
        })} css="margin-bottom:0;">
        <FeedbackButtonsContainer>
          <FeedbackButton color="#EB5757" isSelected={rating === 1} onClick={() => postFeedback(1)} noFocus css="min-width:104px;"><FormattedMessage
            defaultMessage="Not so good"
            description="Patient feedback, not so good rating"
          /></FeedbackButton>
          <FeedbackButton color="#F2994A" isSelected={rating === 2} onClick={() => postFeedback(2)} noFocus><FormattedMessage
            defaultMessage="Could be improved"
            description="Patient feedback, could be improved rating"
          /></FeedbackButton>
          <FeedbackButton color="#E4BE49" isSelected={rating === 3} onClick={() => postFeedback(3)} noFocus><FormattedMessage
            defaultMessage="Just OK"
            description="Patient feedback, just OK rating"
          /></FeedbackButton>
          <FeedbackButton color="#69CB50" isSelected={rating === 4} onClick={() => postFeedback(4)} noFocus><FormattedMessage
            defaultMessage="Good"
            description="Patient feedback, good rating"
          /></FeedbackButton>
          <FeedbackButton color="#00B76A" isSelected={rating === 5} onClick={() => postFeedback(5)} noFocus><FormattedMessage
            defaultMessage="Very good"
            description="Patient feedback, very good rating"
          /></FeedbackButton>
        </FeedbackButtonsContainer>
        </Field>
        <WithForm
          values={formValues}
          ref={formRef}
          action={(values)=> {}}
          onSuccess={(e) => {
            if(!rating){
              return setError("Error", intl.formatMessage({
                defaultMessage: 'Please rate your experience above.',
                description: 'Patient feedback, rating required error'
              }))
            }
            loadingFeedback.open();
            jwtPostFetcher(getJwt())('/api/feedback?requestId='+caseRequest.requestId+'&presave=false',{stars: rating, message: e.review},{method:"PUT"})
              .then((response) => {
                setNotification({type:"success", timeout: 4000, msg: intl.formatMessage({
                    defaultMessage:'Feedback shared!',
                    description:'Patient feedback, feedback shared notification'
                  })})
                loadingFeedback.close();
                if(setJustSentFeedback){
                  setJustSentFeedback(true)
                }
                if(isPopup){
                  feedbackPopup.close();
                }
              })
              .catch((error) => {
                setError("Error", intl.formatMessage({
                  defaultMessage: 'It was not possible to send your feedback. Please try again.',
                  description: 'Patient feedback, feedback send error'
                }))
                loadingFeedback.close();
                if(isPopup){
                  feedbackPopup.close();
                }
              })
            
          }}
        >
          {form => <TransitionDiv>{rating !== 0 && <>
            <TextInput
              label={intl.formatMessage({
                defaultMessage: "Feel free to share more details about your experience",
                description: "Patient feedback, open text input label"
              })}
              path="review"
              values={null}
              textarea
              autoResize
              placeholder={intl.formatMessage({
                defaultMessage: "Please include as much information as you would like",
                description: "Patient feedback, open text input placeholder"
              })}
              css="margin-top:1.5rem;"
            />
            <ErrorProvider value={errors}>
              <Errors/>
            </ErrorProvider>
            <div css="position: relative; text-align: right; margin-top:2rem;">
              {isPopup && <Button onClick={feedbackPopup.close}><FormattedMessage
                defaultMessage="Dismiss"
                description="Dismiss button"
              /></Button>}
              {rating !== 0 && <Button highlight isLoading={loadingFeedback.isOpen} type="submit"><FormattedMessage
                defaultMessage="Share"
                description="Share button"
              /></Button>}
            </div>
            </>
        }</TransitionDiv>}
        </WithForm>
      {/* </Card> */}
    </>
}
