import {Button, Icon, Loading} from '@startlibs/components'
import { Field, TextInput, useConfirmDialog } from '@startlibs/form';
import { getColor, parseDate } from '@startlibs/utils';
import {useToggle} from '@startlibs/core'
import React from 'react'
import _, { props } from 'lodash/fp'
import styled, { css } from 'styled-components';
import { AddButton } from '../../../../components/AddButton';
import {ConfirmDialog} from '../../../../hooks/useConfirmDialog'
import {DATE_FORMAT_STR} from '../../../../enums/ReportDateFormat'
import { EditLayoutButton, ReportTextButton } from './EditLayoutButton';
import {InstitutionDetailsSelector} from './InstituionDetailsSelector'
import {LOGO_INFO_CENTER, LOGO_LEFT_INFO_RIGHT, LOGO_RIGHT_INFO_LEFT} from '../../../../enums/ReportHeaderLayout'
import {calculateAge, formatDateNoUTC, getReportResource, limitLength} from '../../../../utils/utils'
import {getJwt} from '../../../../hooks/useJwt'
import {i18nDateMessages} from '../../../../UIConfig/i18nDateMessages'
import {jwtFormFetcher} from '../../../../utils/authFetch'
import {setNotification} from '../../../../components/Notifications'
import {isStrEmpty} from '../../../../utils'
import {FormattedMessage, useIntl} from 'react-intl';
import { useEffect } from 'react';

export const CoverPage = styled.div`
  position: relative;
  margin: -2.5rem -2.5rem 5rem;
  padding: 2.5rem 2.5rem 0;
  ${props => props.isBackCover && css`
    margin-bottom: 2rem;
  `}
  ${props => props.isA4 ? `
    height: 297mm;
  `
  : `
    height: 279.4mm;
  `}
`

const TopActions = styled.div`
  display: flex;
  justify-content: space-between;
  margin: -3.25rem -1.5rem .5rem;
`

export const InstitutionHeader = styled.div`
  ${props => props.preview ? css`
    border: 1px solid transparent;
  ` : css `
    border: 1px dashed ${getColor('gray180')};
    padding: 1rem;
    margin: 0 -1.5rem;
  `
  }
  border-radius: 5px;
  display: flex;
  .organization-logo {
    flex-basis: 16rem;
    min-height: 6rem;
    background-size: contain;
    background-repeat: no-repeat;
    flex-grow: 1;
    max-width: 280px;
    ${props => props.image && `
      background-image: url('${props.image}');
    `}
    ${props => props.logoRight &&`
      background-position: right;
    `}
  }
  .organization-details {
    flex-grow: 1;
    ${props => props.logoLeft &&`
      text-align: right;
    `}
    ${props => props.centered &&`
      text-align: center;
    `}
  }
  .organization-name {
    font-weight: 600;
  }
  .city, .state, .zipcode, .country {
    display: inline;
  }
  .state + .zipcode::before {
    content: ' ';
  }
  ${props => props.centered && css`
    display: block;
    text-align: center;
    .address-line, .phone, .fax, .email {
      display: inline;
    }
    .organization-logo {
      margin: 0 auto;
      width: 16rem;
      height: 6rem;
      background-position: center center;
      margin-bottom: 1rem;
    }
    .organization-details {
      .address-details {
        display: block;
      }
      .address-line ~ .city:before,
      .fax ~ .email:before,
      .phone ~ .fax:before,
      .phone ~ .email:before {
        content: ' - ';
      }
  `}
`

export const CoverMainWrapper = styled.div`
  max-width: 400px;
  width: 100%;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%,-50%);
`

export const CoverTitle = styled.div`
  ${Loading} {
    position: absolute;
    top: 0;
    right: 0;
  }
  label {
    font-weight: normal;
    color: ${getColor('gray180')};
    font-size: 13px;
    padding-bottom: 0;
    margin-bottom: 0;
    border: 0px;
  }
  ${TextInput}, .title {
    height: 4.5rem;
    font-size: 2rem;
    color: ${getColor('main')};
    color: ${props => props.enhancedContrastColor ? props.enhancedContrastColor : getColor('main')};
    font-weight: 600;
    text-align: center;
  }
  .title {
    font-size: 32px;
    height: auto;
    margin-bottom: .5rem;
  }
  .report-date {
    text-align: center;
    font-size: 14px;
    .case-id {
      display: none;
      color: ${getColor('gray180')};
      float: none;
      font-size: 14px;
    }
  }
  .patient-info {
    padding: 1.5rem;
    border: 1px solid ${getColor('gray210')};
    border-radius: 5px;
    position: relative;
    display: flex;
    margin-top: 2.5rem;
    margin-bottom: 1rem;
    strong {
      font-size: 14px;
    }
    label {
      color: ${getColor('gray180')};
    }
    .name {
      margin-right: 1.5rem;
      flex-basis: 14rem;
      max-width: 14rem;
    }
    .dob {
      flex-basis: 180px;
    }
    ${Button} {
      position: absolute;
      top: .25rem;
      right: .25rem;
    }
  }
`

export const CoverNote = styled.div`
  position: relative;
  margin-top: 2rem;
  label {
    font-weight: normal;
    // color: ${getColor('gray180')};
    color: #636362;
    font-size: 13px;
    padding-bottom: 0;
    margin-bottom: 0;
    border: 0px;
  }
  .field-wrapper {
    ${Icon} {
      position: absolute;
      top: .25rem;
      right: 0;
      font-size: 16px;
      color: ${getColor('gray180')};
      cursor: pointer;
    }
  }
  ${Loading} {
    position: absolute;
    top: .25rem;
    right: 1.5rem;
  }
  ${AddButton} {
    ${Loading} {
      border-color: rgba(0,0,0,0.15);
      border-top-color: ${getColor('main')};
      margin-top: 2px;
    }
  }
  ${props => props.isPreview && css`
    // color: ${getColor('gray120')};
    color: #636362;
  `}
`

export const CoverFooter = styled.div`
  position: absolute;
  bottom: 0;
  left: 2.5rem;
  right: 2.5rem;
  ${props => props.isPreview && css`
    left: 0;
    right: 0;
  `}
`

export const ReportByPurview = styled.div`
  font-weight: 600;
  padding: 1rem 0;
  width: 100%;
  // color: ${getColor('gray150')};
  color: #636362;
  border-top: 1px solid ${getColor('gray180')};
  border-bottom: 1px solid ${getColor('gray180')};
  text-align: center;
  margin-bottom: 3rem;
`

export const EditorPageDivider = styled.div`
  display: flex;
  align-items: center;
  margin: 3rem -8rem;
  :before, :after {
    content: '';
    border-top: 1px dashed ${getColor('gray180')};
  }
  :before {
    flex-grow: 1;
  }
  span {
    flex-shrink: 0;
    margin: 0 .5rem;
    color: ${getColor('gray150')};
    font-style: italic;
  }
  :after {
    flex-basis: 5rem;
  }
  ${props => props.isCover && css`
    position: absolute;
    bottom: -4rem;
    left: -8rem;
    right: -8rem;
    margin: 0;
  `}
`

export const ReportField = styled.div`
  ${props => !props.subItem && css`
    margin-bottom: .25rem;
  `}
  background-color: white;
  padding: .5rem 0;
  border-radius: 5px;
  .field-heading {
    display: flex;
    align-items: center;
    padding-bottom: .25rem;
    border-bottom: 1px solid ${getColor('gray210')};
    h4 {
      flex-grow: 1;
      font-size: 15px;
      margin-bottom: 0;
      ${props => props.inactive && css`
        opacity: .35;
      `}
    }
    .field-actions {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      ${Loading} {
        margin-right: .5rem;
      }
      ${ReportTextButton} {
        ${props => props.inactive && css`
          opacity: .35;
        `}
      }
      ${Button} {
        margin-left: 1rem;
        ${props => props.inactive && css`
          opacity: 1;
        `}
      }
      .move-icon {
        font-size: 18px;
        margin-left: .5rem;
        color: ${getColor('gray150')};
        cursor: pointer;
      }
    }
  }
  .field-content {
    ${props => props.inactive && css`
      opacity: .35;
    `}
  }
  ${props => (props.isDisclaimer || props.isPreview) && css`
    .field-content {
      margin-top: .5rem;
    }
  `}
  ${props => props.isDisclaimer && props.isPreview && css`
      // color: ${getColor('gray120')};
      color: #636362;
  `}
  ${props => props.subItem && css`
    padding: .5rem 1rem;
    margin: 0 -1rem;
    margin-top: .5rem;
    .field-heading {
      border-bottom: 1px solid ${getColor('gray240')};
      margin-bottom: .5rem;
      h4 {
        font-size: 13px;
      }
    }
  `}
  ${props => props.isDraggable && css`
    border: 1px dashed transparent;
    :hover {
      border: 1px dashed ${getColor('gray210')};
      cursor: grab;
    }
    ${props => props.dragging &&`
      cursor: grabbing;
    `}
  `}
`

const getInstitutionFromProviderInfo = (providerInfo) =>
  _.isObject(providerInfo.address)
    ? {...providerInfo.address,name:providerInfo.name}
    : providerInfo

export const REPORT_HEADERS = {
  [LOGO_LEFT_INFO_RIGHT]: (institution,providerInfo, preview, origin) => <InstitutionHeader preview={preview} logoLeft image={institution?.logoPath ? getReportResource(institution.logoPath,origin) : providerInfo.logoURL}>
    <div className="organization-logo" role="img" aria-label="Organization Logo" />
    <InstitutionDetails institution={institution || getInstitutionFromProviderInfo(providerInfo)}/>
  </InstitutionHeader>,
  [LOGO_RIGHT_INFO_LEFT]: (institution,providerInfo, preview,origin) => <InstitutionHeader preview={preview} logoRight image={institution?.logoPath ? getReportResource(institution.logoPath,origin) : providerInfo.logoURL}>
    <InstitutionDetails institution={institution || getInstitutionFromProviderInfo(providerInfo)}/>
    <div className="organization-logo" role="img" aria-label="Organization Logo" />
  </InstitutionHeader>,
  [LOGO_INFO_CENTER]: (institution,providerInfo, preview,origin) => <InstitutionHeader preview={preview} centered image={institution?.logoPath ? getReportResource(institution.logoPath,origin) : providerInfo.logoURL}>
    <div className="organization-logo" role="img" aria-label="Organization Logo" />
    <InstitutionDetails institution={institution || getInstitutionFromProviderInfo(providerInfo)}/>
  </InstitutionHeader>
}

const InstitutionDetails = ({institution}) => <div className="organization-details">
    <span className="organization-name">{institution.name}</span>
    <div className="address-details">
      {institution.address && <div className="address-line">{institution.address}</div>}
      {institution.city && <span className="city">{institution.city}</span>}
      {institution.city && institution.state && <span>, </span>}
      {institution.state && <span className="state">{institution.state}</span>}
      {(institution.city || institution.state) && institution.zipCode && <span>, </span>}
      {institution.zipCode && <span className="zipcode">{institution.zipCode}</span>}
      {(institution.city || institution.state || institution.zipCode) && institution.country && <span>, </span>}
      {institution.country && <span className="country">{institution.country} </span>}
    </div>
    {institution.phoneNumber && <div className="phone"><FormattedMessage
      defaultMessage="Phone:"
      description="Phone label"
    /> {institution.phoneNumber}</div>}
    {institution.faxNumber && <div className="fax"><FormattedMessage
      defaultMessage="Fax:"
      description="Fax label"
    /> {institution.faxNumber}</div>}
    <div className="email"><FormattedMessage
      defaultMessage="Email:"
      description="Email label"
    />{institution.email}</div>
  </div>

const saveReportData = (caseRequest) => jwtFormFetcher(getJwt())(`/api/admin/reportData?requestId=${caseRequest.requestId}`, {method: 'PUT'})



export const ReportFrontCover = ({caseRequest, form, setCaseRequest, reportLayout, providerInfo, institutions, defaultInstitution}) => {
  const intl = useIntl()
  const patientDoB = parseDate(caseRequest.patientInfo.dob, new Date(), "MM-dd-yyyy")
  const coverNoteLoading = useToggle()
  const titleLoading = useToggle()

  const confirmRemoveNote = useConfirmDialog(
    <ConfirmDialog
      action={() => {form.setValue('coverNote', null);return coverNoteLoading.wait(form.submitForm())}}
      confirm={<Button alert>Remove</Button>}
      title="Are you sure you want to remove cover notes?"
    >
      This action cannot be undone.
  </ConfirmDialog>
  )

  const checkRemoveNote = () => {
    if (isStrEmpty(form.getValue("coverNote"))) {
      form.setValue('coverNote', null)
      coverNoteLoading.wait(form.submitForm())
    } else {
      confirmRemoveNote()
    }
  }

  return <CoverPage isA4={reportLayout.paperSize === "A4"}>
    <TopActions>
      <EditLayoutButton caseRequest={caseRequest} setCaseRequest={setCaseRequest}/>
      <InstitutionDetailsSelector
        caseRequest={caseRequest}
        setCaseRequest={setCaseRequest}
        institutions={institutions}
        defaultInstitution={defaultInstitution}
      />
    </TopActions>
    {
      REPORT_HEADERS[reportLayout.headerLayout](caseRequest?.report?.institutionDetails || caseRequest.institutionDetails,providerInfo)
    }
    <CoverMainWrapper>
      <CoverTitle enhancedContrastColor={providerInfo?.customColors?.enhancedContrastColor ? providerInfo.customColors.enhancedContrastColor : false}>
        <Field label="Report title">
          <TextInput
            path="title"
            transform={limitLength(50,"Title length limit reached.")}
            placeholder="Case report"
            onBlur={() => titleLoading.wait(form.submitForm())}
          />
          {titleLoading.isOpen && <Loading size={16} borderWidth={3} />}
        </Field>
        <div className="report-date">
          {
            formatDateNoUTC(new Date(), DATE_FORMAT_STR[reportLayout.dateFormat], i18nDateMessages(intl))
          }
          <span className="case-id"> (Case ID: {caseRequest.requestCode})</span>
        </div>

        <div className="patient-info">
          <div className="name">
            <label><FormattedMessage defaultMessage="Patient" description="patient label"/></label>
            <div><strong className='fs-exclude'>
              {caseRequest.patientInfo.firstName} {caseRequest.patientInfo?.middleName} {caseRequest.patientInfo.lastName}
            </strong></div>
          </div>
          <div className="dob">
            <label>Date of birth</label>
            <div><strong className='fs-exclude'>
              {formatDateNoUTC(patientDoB, "MM/dd/yyyy")} ({calculateAge(patientDoB)} years old)
            </strong></div>
          </div>
          <Button.Link small to={"/admin/request/" + caseRequest.requestCode + "/patient"}>Edit details</Button.Link>
        </div>
      </CoverTitle>
      <CoverNote>
        {
          _.isString(caseRequest?.reportData?.coverNote)
            ? <div className="field-wrapper">
                <Field label="Cover note">
                  <TextInput
                    textarea
                    transform={limitLength(800,"Cover note text length limit reached.")}
                    onBlur={() => coverNoteLoading.wait(form.submitForm())}
                    path="coverNote"
                    placeholder="Insert your note here"
                    css="min-height: 148px;"
                  />
                  <Icon
                    icon='x' onClick={checkRemoveNote}
                  />
                </Field>
                {coverNoteLoading.isOpen &&  <Loading size={16} borderWidth={3} />}
            </div>
            : <AddButton
                isLoading={coverNoteLoading.isOpen}
                onClick={() => {
                  form.setValue('coverNote', caseRequest?.institutionDetails?.coverNote ? caseRequest.institutionDetails.coverNote : '')
                  coverNoteLoading.wait(form.submitForm())
                }}
              >Add cover note
              </AddButton>
        }
      </CoverNote>

    </CoverMainWrapper>

    <CoverFooter>
      <ReportByPurview>
        Created with Purview Expert View™
      </ReportByPurview>
      <ReportDisclaimer
        form={form}
        providerInfo={providerInfo}
        setCaseRequest={setCaseRequest}
        caseRequest={caseRequest}
        reportLayout={reportLayout}
      />
    <EditorPageDivider isCover>
        <span>Cover end</span>
      </EditorPageDivider>
    </CoverFooter>

  </CoverPage>

}

export const ReportDisclaimer = ({caseRequest, setCaseRequest, reportLayout, providerInfo}) => {

  const editDisclaimer = useConfirmDialog(() =>
    <ConfirmDialog
      values={caseRequest.reportData}
      title="Edit disclaimer"
      onSuccess={(reportData) => setCaseRequest(_.set('reportData', reportData))}
      action={saveReportData(caseRequest)}
      confirm={<Button highlight>Submit</Button>}
    >
      <TextInput
        textarea
        transform={limitLength(1000,"Disclaimer text length limit reached.")}
        path="disclaimer"
        css="height: 16rem;"
      />
    </ConfirmDialog>
  )
  const toggleDisclaimer = useConfirmDialog(() =>
    <ConfirmDialog
      values={caseRequest.reportData}
      title={reportLayout.disclaimerText ? "Hide disclaimer" : "Show disclaimer"}
      onSuccess={(reportData) => setCaseRequest(_.set('reportData', reportData))}
      transform={_.update('reportLayout.disclaimerText', v => !v)}
      action={saveReportData(caseRequest)}
      confirm={<Button alert={reportLayout.disclaimerText} highlight={!reportLayout.disclaimerText}>{reportLayout.disclaimerText ? "Hide disclaimer" : "Show disclaimer"}</Button>}
    >
      <p>The disclaimer contains important data security recommendations.</p>
      <p>Are you sure you would like to {reportLayout.disclaimerText ? "hide" : "show"} it in this report?</p>
    </ConfirmDialog>
  )

  const disclaimerText = (_.isString(caseRequest.reportData.disclaimer) ? caseRequest.reportData.disclaimer : providerInfo.reportDisclaimer)

  const disabled = !disclaimerText || !reportLayout.disclaimerText

  return <ReportField inactive={disabled} isDisclaimer>
    <div className="field-heading">
      <h4>Disclaimer</h4>
      <div className="field-actions">
        {disclaimerText &&
          <ReportTextButton onClick={toggleDisclaimer}>
            {reportLayout.disclaimerText ?
              <><Icon icon="hide"/> Hide from report</>
                : <><Icon icon="view"/> Show in report</>
            }
          </ReportTextButton>
        }
        <Button small onClick={editDisclaimer}>Edit</Button>
      </div>
    </div>
    <div className="field-content">
      {
        disclaimerText ||
        <em>Automatically hidden from report. Edit content to enable this field.</em>
      }
    </div>
  </ReportField>

}
