import React, {useContext, useEffect} from 'react'
import styled from 'styled-components/macro';
import {ContextMenu, DropdownIcon, Icon, Li, Loading, TextButton} from "@startlibs/components";
import {usePopupToggle, useToggle} from "@startlibs/core";
import {LocaleContext} from "./I18NProvider";
import {lazyProviderInfo} from "./WithProvider";
import {getDefaultLanguageInfo, getLanguageInfo} from "../utils/getInitLocale";
import { getColor } from '@startlibs/utils';

styled;

const LocaleButton = styled(TextButton)`
  position: relative;
  display: flex;
  align-self: center;
  align-items: center;
  border: 1px solid ${getColor('gray210')};
  padding: 0.5rem 1.75rem 0.5rem 1rem;
  border-radius: 100px;
  font-weight: 600;
  color: ${getColor('gray90')};
  margin-right: 1.5rem;
  flex-shrink: 0;
  :hover {
    color: ${getColor('gray90')};
    background: ${getColor('gray240')};
  }
  :active {
    color: ${getColor('gray90')};
    background: ${getColor('gray230')};
  }
  ${DropdownIcon} {
    right: 0.75rem;
    font-size: 10px;
  }
`

export const ComboboxOptionDiv = styled.div`
  display: flex; 
  align-content: center;  
  align-items: center; 
  justifyContent: flex-start; 
  .iti__flag {
    margin-right: 0.75rem;
  }
`



const LocaleIcon = ({info}) => <div className={`iti__flag ${info?.symbol}`} css="margin-right:0.5rem;border-radius:2px;"/>
export const LocaleOption = ({locale,providerInfo = lazyProviderInfo.read()}) =>
    <ComboboxOptionDiv>
      <LocaleIcon info={getLanguageInfo(locale,providerInfo)}/>
      {getLanguageInfo(locale,providerInfo)?.caption}
    </ComboboxOptionDiv>

export const LocaleSelector = ({providerInfo = lazyProviderInfo.read()}) => {
  const loading = useToggle()
  const menu = usePopupToggle()

  const { locale, changeLanguage } = useContext(LocaleContext)

  const handleLanguageToggle = (newLocale) => loading.wait(changeLanguage(newLocale))

  const languageInfo = getLanguageInfo(locale,providerInfo) || getDefaultLanguageInfo(providerInfo)

  if (!languageInfo || providerInfo?.preferredLanguage?.options?.length < 2) {
    return null
  }

  return <LocaleButton onClick={menu.open} isLoading={loading.isOpen}>
    <LocaleIcon info={languageInfo}/>
    {loading.isOpen && <Loading />}
    {languageInfo?.hint}
    <DropdownIcon icon="arrow-down"/>
    {
      menu.isOpen &&
      <ContextMenu withoutIcons>
        {providerInfo?.preferredLanguage.options.map( option =>
          <Li
            key={option.id}
            label={<LocaleOption locale={option.id} providerInfo={providerInfo}/>}
            onClick={() => handleLanguageToggle(option.id)}
          />
        )}
      </ContextMenu>
    }
  </LocaleButton>
}