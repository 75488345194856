import {Icon, Dialog, DialogTitle} from '@startlibs/components';
import {customTheme, getColor, media, callIfFunction} from '@startlibs/utils'
import React, {useContext, useEffect, useState} from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components';
import {Filelist} from '../unidentified-files/Filelist';
import {jwtGetFetcher} from '../../utils/authFetch';
import {useRecordsSelector} from "../../service/hooks/useRecords";
import {useDoAction} from "../../service/hooks/useDoAction";
import {UIAction} from "../../service/UIAction";
import {UploaderConfigContext} from "../../service/UploaderConfigContext";
import {useUIDataSelector} from "../../service/hooks/useUIDataSelector";
import {FormattedMessage} from "react-intl";

const FilelistDialog = styled(Dialog)`
  max-width: 72rem;
`

export const UnidentifiedFilesWindow = () => {
  const doAction = useDoAction()
  const close = () => doAction(UIAction.ToggleUnidentifiedFilesDialog)
  const sessionOnly = () => useUIDataSelector('sessionOnly')
  const {jwt,caseId,mode,apiEndpoints:{storageHost}} = useContext(UploaderConfigContext)

  const [selectedFiles, setSelectedFiles] = useState([])
  const [filelist, setFilelist] = useState({})
  const [extensions, setExtensions] = useState([])
  const sessionOnlyStr = sessionOnly() ? '?sessionOnly=true' : ''

  const reloadExtensions = () => {
    jwtGetFetcher(jwt)(storageHost() + "/notclass/" + caseId + "/extensions" + sessionOnlyStr)
      .then((response) => {
        setExtensions(response)
      })
      .catch((exception) => {
        // Error
      })
  }

  useEffect(() => {
    reloadExtensions()
  }, [mode])

  return <FilelistDialog
    closeOnClick
    closeDialog={close}
  >
    <UnidentifiedFilesContainer>
      <div className="main-header">
        <DialogTitle><FormattedMessage
          defaultMessage="Hidden files"
          description="Uploader, unidentified files dialog title"
        /></DialogTitle>
        <p><FormattedMessage
          defaultMessage="These files do not appear to be medical records and have been hidden from the case. If you believe some may be relevant, you may review them and add them to the case."
          description="Uploader, unidentified files dialog description"
        /></p>
      </div>
      <Filelist
        selectedFiles={selectedFiles}
        setSelectedFiles={setSelectedFiles}
        filelist={filelist}
        setFilelist={setFilelist}
        extensions={extensions}
        reloadExtensions={reloadExtensions}
      />
    </UnidentifiedFilesContainer>
  </FilelistDialog>
}

const UnidentifiedFilesContainer = styled.div`
  .main-header {
    display: block;
    align-items: center;
    background: white;
    top: 0;
    h3 {
      margin: 0 1rem 0 0;
      flex-grow: 1;
    }
    a.return-records {
      font-size: 11px;
    }
  }
  .table-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .row {
      width: 100%;
      flex-basis: 100%;
      display: flex;
      padding: 1rem;
      border-bottom: 1px solid ${getColor('gray240')};
      &.table-head {
        font-weight: 600;
        color: ${getColor('gray60')};
        top: 43px;
        background: white;
      }
      .timestamp {
        flex-basis: 120px;
        flex-shrink: 0;
      }
      .details {
        flex-grow: 1;
        margin: 0 2rem;
        font-weight: 600;
        .file-name {
          color: ${getColor('gray120')};
        }
        a {
          margin-left: .5rem;
        }
      }
      .state {
        flex-shrink: 0;
        flex-basis: 130px;
        display: inline-flex;
        align-items: center;
        ${Icon} {
          font-size: 14px;
          margin-right: .5rem;
        }
        &.failure {
          color: ${getColor('alert')};
        }
      }
    }
  }
  ${customTheme("ActivityLogContainer")};
`
