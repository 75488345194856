import {
  Button,
  ContextMenu,
  DraggableContainer,
  DraggableItem,
  Icon,
  Li,
  Loading,
  Tooltip
} from '@startlibs/components';
import {
  Combobox,
  Field,
  FileInput,
  FormValue,
  RadioboxGroup,
  SimpleRadiobox,
  TabRadiobox,
  TextInput
} from '@startlibs/form';
import {_s, getColor, wrapLazy} from '@startlibs/utils';
import {
  useLazyConstant,
  usePopupToggle,
  usePrevious,
  useRefState,
  useToggle
} from '@startlibs/core';
import React, {useEffect, useRef, useState} from 'react'
import SignaturePad from 'signature_pad'
import _ from 'lodash/fp'
import styled, { css } from 'styled-components';
import {FileInputBoxStyle} from 'uploader-frontend'
import {AddButton} from '../components/AddButton';
import {ConfirmDialog, useConfirmDialog, useConfirmDialogWithProps} from '../hooks/useConfirmDialog'
import {DIGITAL, NONE, PEN} from '../enums/SignatureType'
import { InfoBox } from '../components/InfoBox';
import {buildValidation, required} from '../utils/validation'
import {defaultListAnimation} from '../UIConfig/defaultListAnimation'
import {getJwt} from '../hooks/useJwt'
import {jwtGetFetcher, jwtPostFetcher} from '../utils/authFetch'
import {useAutoScrollAndFocus} from '../hooks/useAutoScrollAndFocus'
import {FormattedMessage, useIntl} from 'react-intl';
import html2canvas from 'html2canvas';

const SignaturePadContainer = styled("canvas")`
  background: white;
  border: 1px solid ${getColor('gray210')};
  border-radius: 5px;
  display: block;
  margin-bottom: .5rem;
  width: 100%;
  ${props => props.height && `height: ${props.height}px`}
`

export const DigitalSignatureField = styled(Field)`
  position: relative;
  margin-top: 1rem;
  ${Button} {
    position: absolute;
    top: 1rem;
    right: 0;
  }
  .help-text {
    color: ${getColor('gray90')};
  }
`

export const InsertedSignaturesWrapper = styled(DraggableContainer)`
  display: flex;
  flex-wrap: wrap;
  margin: -.5rem;
  margin-bottom: 1rem;
  position: relative;
`

export const InsertedSignatureItem = styled.div`
  position: relative;
  flex-basis: 40%;
  max-width: 48%;
  border-radius: 5px;
  padding: 1rem;
  flex-grow: 1;
  background: white;
  margin: .5rem;
  ${props => !props.inReport && css`
    border: 1px dashed ${getColor('gray180')};
  `}
  ${props => props.dragging &&`
    cursor: grabbing;
  `}
  ${Button}, .field-actions {
    position: absolute;
    top: .5rem;
    right: .5rem;
  }
  .field-actions {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    ${Loading} {
      margin-right: .5rem;
    }
  }
  .signature-image {
    position: absolute;
    height: 5rem;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: 1rem;
    left: 2rem;
    right: 2rem;
    ${props => props.img && `
      background-image: url('${props.img}');
    `}
    ${props => (props.signAfter || props.nameOnly) && `
      display: none;
    `}
  }
  .signature-line {
    border-top: 1px solid rgba(0,0,0,0.15);
    margin-bottom: 0.5rem;
    z-index: 1;
    right: 1rem;
    left: 1rem;
    position: absolute;
    top: 5rem;
    ${props => props.nameOnly && css`
      display: none;
    `}
  }
  .display-name {
    font-weight: 600;
    font-size: 14px;
    margin-top: 5rem;
  }
  .move-icon {
    font-size: 18px;
    margin-left: .5rem;
    color: ${getColor('gray150')};
    cursor: pointer;
  }
  ${props => props.inReport && css`
    padding: .5rem 1rem;
    .field-actions {
      right: 1rem;
      top: 1rem;
    }
  `}
  ${props => props.inReport && !props.isPreview && css`
    padding: 3rem 1rem .5rem;
    border: 1px dashed ${getColor('gray210')};
    .signature-line {
      top: 7rem;
    }
    .signature-image {
      top: 3rem;
    }
  `}
  ${props => props.isDraggable && props.inReport && css`
    cursor: grab;
    :hover {
      cursor: grab;
    }
    ${props => props.dragging &&`
      cursor: grabbing;
    `}
  `}
`

const ManageSignatureBox = styled(InfoBox)`
  .responsible-name {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    min-width: 120px;
    > div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 100%;
    }
  }
  ${Button} {
    flex-shrink: 0;
    margin-left: 1rem;
  }
`

export const SignatureContainer = styled(FileInputBoxStyle)`
  position: relative;
  ${props => !props.uploading && css`
    background-color: white;
  `}
  ${props => props.image && !props.uploading && `
    background-image: url('${props.image}');
  `}
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-origin: content-box;
  color: ${getColor('gray150')};
  ${Icon} {
    position: absolute;
    top: 0;
    right: .5rem;
    font-size: 3rem;
  }
  :hover {
    ${Icon} {
      color: ${getColor('gray120')};
    }
  }
`

const WrittenSignatureArea = styled.div`
  background: white;
  border: 1px solid #d2d2d2;
  border-radius: 5px;
  display: block;
  margin-bottom: .5rem;
  width: 100%;
  padding-top: 2rem;
  min-height: 120px;
  align-content: center;
`
const lazySignatures = wrapLazy((type) => jwtGetFetcher(getJwt())(`/api/${type}/signatures`))

export const ExpertSignatureManagement = ({caseRequest, disabled, location, path = 'signatures',form, apiType = "expert"}) => {
  const loadedAvailableSignatures = lazySignatures.use(apiType).read()
  const [availableSignatures, setAvailableSignatures] = useState(loadedAvailableSignatures)
  const availableSignaturesRef = useRef(availableSignatures)
  availableSignaturesRef.current = availableSignatures

  const addEditDialog = useToggle()
  const createDialog = useToggle()
  const manageDialog = useToggle()

  const addSignature = (signature,previous) => form.setValues(_.update(path,(signatureList) =>
    (signatureList || []).indexOf(previous)>=0
      ? (signatureList || []).map(s => s === previous ? signature : s)
      : (signatureList || []).concat(signature)
  ))
  const removeSignature = (signature) => form.setValues(_.update(path,_.without([signature])))
  const setSignatureOrder = (from,to) => {
    form.setValues(_.update(path,_s.moveElement(from,to)))
  }

  const createAvailableSignature = (v,signature)  => {
    if (!availableSignatures.length) {
      addEditDialog.open()
    }
    setAvailableSignatures(_.unionBy('id',_,[signature]))
  }

  useEffect(() => {
    return () => {
      if (loadedAvailableSignatures !== availableSignaturesRef.current) {
        lazySignatures.refresh()
      }
    }
  },[])

  const scrollRef = useAutoScrollAndFocus(location?.state?.signatures)

  useEffect(() => {
    if (!manageDialog.isOpen && !availableSignatures.length) {
      addEditDialog.close()
    }
  },[manageDialog.isOpen])

  return <>
    <FormValue path={path}>{signatures => <>
      <span ref={scrollRef}/>
      <InsertedSignaturesWrapper
        animation={defaultListAnimation}
        disabled={signatures?.length <= 1 || disabled}
        setItemOrder={setSignatureOrder}
      >
        {(signatures || []).map(signature =>
          <DraggableItem
            DragTag={Signature}
            signature={signature}
            disabled={disabled}
            canEdit={!!availableSignatures.find(({id}) => id === signature.id)}
            editSignature={addEditDialog.willOpenWith(signature)}
            removeSignature={removeSignature}
            isDraggable={signatures?.length > 1}
          />
        )}
      </InsertedSignaturesWrapper>
      </>
    }</FormValue>

    {!disabled && <AddButton onClick={availableSignatures.length ? addEditDialog.open : createDialog.open}><FormattedMessage
      defaultMessage="Add signature"
      description="Expert review, add signature button"
    /></AddButton>}
    {
      createDialog.isOpen &&
      <CreateSignatureDialog apiType={apiType} closeDialog={createDialog.close} createAvailableSignature={createAvailableSignature}/>
    }
    <FormValue path={path}>{signatures =>
        addEditDialog.isOpen &&
        <AddEditSignatureDialog values={addEditDialog.isOpen} signaturesInUse={signatures} availableSignatures={availableSignatures} closeDialog={addEditDialog.close} addSignature={addSignature} manageSignatures={manageDialog.open} />
    }</FormValue>
    {
      manageDialog.isOpen &&
        <ManageAvailableSignaturesDialog closeDialog={manageDialog.close} createSignature={createDialog.open} availableSignatures={availableSignatures} setAvailableSignatures={setAvailableSignatures} />
    }
  </>
}

const ManageAvailableSignaturesDialog = ({availableSignatures,closeDialog,setAvailableSignatures,createSignature}) => {
  const intl = useIntl()
  const deleteSignature = useConfirmDialogWithProps((signature) =>
    <ConfirmDialog
      title={intl.formatMessage({
        defaultMessage: "Delete signature template",
        description: "Expert review, signature management, delete signature template dialog title"
      })}
      css="max-width: 46rem;"
      closeLabel={<FormattedMessage
        defaultMessage="Cancel"
        description="Cancel button"
      />}
      confirm={<Button alert><FormattedMessage
        defaultMessage="Delete"
        description="Expert review, signature management, delete signature template dialog delete button"
      /></Button>}
      action={() => jwtPostFetcher(getJwt())(`/api/signature/${signature.id}`,undefined, {method: "DELETE"})}
      onSuccess={() => setAvailableSignatures(_.differenceBy(_.get('id'),_,[signature]))}
    >
      <FormattedMessage
        defaultMessage={`<p>Please confirm deleting the <strong>{name}</strong> signature template.</p>
        <p>Signatures already added in cases or reports won't be removed from their context.</p>
        <p>This action is irreversible. Are you sure you want to delete?</p>`}
        description="Expert review, signature management, delete signature template dialog confirmation message"
        values={{
          name:signature.name,
          p: (...chunks) => <p>{chunks}</p>,
          strong: (...chunks) => <strong>{chunks}</strong>
      }}
      />
      
    </ConfirmDialog>
  )

  return <ConfirmDialog
    title={intl.formatMessage({
      defaultMessage: "Manage signature templates",
      description: "Expert review, manage signature templates dialog title"
    })}
    closeDialog={closeDialog}
    auxiliarActions={<Button highlight onClick={createSignature}><FormattedMessage
      defaultMessage="Create new signature"
      description="Expert review, manage signature templates create new signature button"
    /></Button>}
    closeLabel={intl.formatMessage({
      defaultMessage: "Close",
      description: "Close button"
    })}
    >
    {availableSignatures.length > 0 ? <>
      {availableSignatures.map(availableSignature =>
        <ManageSignatureBox flex>
          <div className="responsible-name">
            <Tooltip whenEllipsis={({target}) => target.offsetWidth < target.scrollWidth} content={availableSignature.name}>
              {availableSignature.name}
            </Tooltip>
          </div>
          <Button small onClick={deleteSignature.willOpenWith(availableSignature)}><FormattedMessage
            defaultMessage="Delete"
            description="Delete button"
          /></Button>
        </ManageSignatureBox>

      )} </>
      : <>
      <ManageSignatureBox>
        <FormattedMessage
          defaultMessage="No signatures available, click below to create one."
          description="Expert review, manage signature templates no signatures message"
        />
      </ManageSignatureBox>
    </>}
  </ConfirmDialog>
}

const Signature = ({signature,canEdit,onTouchStart,onMouseDown, disabled, children, editSignature, removeSignature, isDraggable}) => {

  const contextMenu = usePopupToggle()
  const intl = useIntl()

  const confirmRemoveSignature = useConfirmDialog(
    <ConfirmDialog
      title={intl.formatMessage({
        defaultMessage: "Confirm removing signature",
        description: "Expert review, confirm remove signature dialog title"
      })}
      action={() => removeSignature(signature)}
      confirm={<Button alert><FormattedMessage
        defaultMessage="Remove"
        description="Expert review, confirm remove signature dialog remove button"
      /></Button>}
    >
      <p><FormattedMessage
        defaultMessage="Please confirm removing this signature from the review."
        description="Expert review, confirm remove signature dialog confirmation message"
      /></p>
    </ConfirmDialog>
  )

  return <InsertedSignatureItem
    onMouseDown={onMouseDown}
    onTouchStart={onTouchStart}
    img={signature.imagePath && `/api/reportResource/${signature.imagePath}`}
    isDraggable={isDraggable}
    nameOnly={signature.signatureType === "NONE"}
    signAfter={signature.signatureType === "PEN"}
    >
    <div className="signature-content">
      <div className="signature-image" />
      <div className="signature-line" />
      <div className="display-name">{signature.name}</div>
      <div className="description">{signature.description}</div>
    </div>
    {!disabled && <Button small icon="arrow-down" onClick={contextMenu.open}>
      <FormattedMessage
        defaultMessage="Edit"
        description="Expert review, edit signature button"
      />
      {
        contextMenu.isOpen &&
          <ContextMenu>
            <Li onClick={editSignature} disabled={!canEdit} label={canEdit
              ? <FormattedMessage
                defaultMessage="Edit signature"
                description="Expert review, edit signature context menu item"
              />: <div><div><strong><FormattedMessage
                defaultMessage="Editing disabled"
                description="Expert review, edit signature context menu item disabled"
              /></strong></div><span><FormattedMessage
                defaultMessage="(this signature template was deleted)"
                description="Expert review, edit signature context menu item disabled description"
              /></span></div>} />
            <Li onClick={confirmRemoveSignature} label={intl.formatMessage({
              defaultMessage: "Remove signature",
              description: "Expert review, remove signature context menu item"
            })}/>
          </ContextMenu>
      }
    </Button>}
    {children}
  </InsertedSignatureItem>
}

const updateDefaultSignature = (signature) => ({...signature,signatureType: signature?.responsible?.imagePath ? DIGITAL : (signature?.signatureType === DIGITAL ? PEN : (signature?.signatureType || PEN))})

const AddEditSignatureDialog = ({values, signaturesInUse, availableSignatures, closeDialog, addSignature, manageSignatures}) => {

  const formRef = useRef()
  const intl = useIntl()
  const prevAvailableSignatures = usePrevious(availableSignatures)

  useEffect(() => {
    if (!prevAvailableSignatures) {
      return
    }
    const newSignatures = _.differenceBy('id',availableSignatures,prevAvailableSignatures)
    if (newSignatures.length) {
      formRef.current.setValue('responsible',newSignatures[0])
    }
    const removedSignatures = _.differenceBy('id',prevAvailableSignatures,availableSignatures)
    if (removedSignatures.length) {
      formRef.current.setValue('responsible',(responsible) => removedSignatures.find(({id})=> id === responsible.id)
        ? availableSignatures[0]
        : responsible
      )
    }
  },[availableSignatures])

  const isEdit = _.isObject(values)

  const formValues = useLazyConstant(() => {
    if (_.isObject(values)) {
      return updateDefaultSignature({responsible:availableSignatures.find(({id}) => id === values.id), ...values})
    } else {
      return updateDefaultSignature({responsible:availableSignatures[0], id: Date.now().toString(36)})
    }
  })

  return <ConfirmDialog
    closeDialog={closeDialog}
    title={isEdit
      ? intl.formatMessage({
        defaultMessage: "Edit signature",
        description: "Expert review, edit signature dialog title"
      })
      : intl.formatMessage({
        defaultMessage: "Add signature",
        description: "Expert review, add signature dialog title"
      })
  }
    formRef={formRef}
    confirmChanges={false}
    values={formValues}
    preValidation={buildValidation({
      responsible: (responsible) => responsible.id !== values?.id &&  _.find(({id}) => responsible.id === id, signaturesInUse) && intl.formatMessage({
        defaultMessage:"This signature is already added.",
        description:"Expert review, add signature dialog signature already added error"
      })
    })}
    onChange={(prev,next) => prev.responsible!==next.responsible && formRef.current.setValues(updateDefaultSignature(next))}
    action={({signatureType,responsible}) => addSignature({...responsible,signatureType},values)}
    auxiliarActions={<Button onClick={manageSignatures}><FormattedMessage
      defaultMessage="Manage signatures"
      description="Expert review, manage signatures button"
    /></Button>}
    confirm={<Button highlight>{isEdit
      ? <FormattedMessage
        defaultMessage="Save"
        description="Expert review, edit signature dialog save button"
      />
      : <FormattedMessage
        defaultMessage="Add"
        description="Expert review, add signature dialog add button"
      />}</Button>}
    css="max-width: 48rem;"
  >
    <Combobox
      path="responsible"
      label={intl.formatMessage({
        defaultMessage: "Responsible name",
        description: "Expert review, add signature dialog responsible name label"
      })}
      options={availableSignatures}
      getLabel={_.get('name')}
    />

    <FormValue path="responsible.imagePath">{ imagePath =>
    <RadioboxGroup
      path="signatureType"
      label={intl.formatMessage({
        defaultMessage: "Signature type",
        description: "Expert review, add signature dialog signature type label"
      })}
      horizontal
    >
      <SimpleRadiobox disabled={!imagePath} fieldValue={DIGITAL} label={intl.formatMessage({
        defaultMessage: "Digitally signed",
        description: "Expert review, add signature dialog digitally signed label"
      })}/>
      <SimpleRadiobox fieldValue={PEN} label={intl.formatMessage({
        defaultMessage: "Sign after printing",
        description: "Expert review, add signature dialog sign after printing label"
      })}/>
      <SimpleRadiobox fieldValue={NONE} label={intl.formatMessage({
        defaultMessage: "Name only (without signature)",
        description: "Expert review, add signature dialog name only label"
      })}/>
    </RadioboxGroup>
    }</FormValue>

  </ConfirmDialog>

}


export const checkImage = (intl,file) => {
  if (file.size > 4194304 / 4) {
    return [intl.formatMessage({
      defaultMessage: 'Your file needs to be smaller than 1mb.',
      description: 'Expert signature dialog image size error'
    })]
  }
  if (['image/gif', 'image/png', 'image/jpeg', 'image/jpg'].indexOf(file.type.toLowerCase()) < 0) {
    return [intl.formatMessage({
      defaultMessage: 'Invalid file format. Please select a .JPG or .PNG file.',
      description: 'Expert signature dialog image format error'
    })]
  }
}

const getFormDataFile = (file) => {
  const formData = new FormData()
  formData.append('file', file)
  return formData
}


const SignaturePadComponent = ({signaturePad}) => {
  const signatureRef = useRef()

  useEffect(() => {
    if (!signatureRef.current) {
      return
    }
    const canvas = signatureRef.current
    canvas.width = canvas.offsetWidth;
    signaturePad.openWith(new SignaturePad(canvas, {backgroundColor: "rgb(255,255,255)"}));
    const resizeEvent = () => {
      const imageData = canvas.getContext('2d').getImageData(0, 0, canvas.offsetWidth, canvas.offsetHeight);
      canvas.width = canvas.offsetWidth;
      canvas.getContext('2d').putImageData(imageData, 0, 0)
    }
    window.addEventListener("resize", resizeEvent);
    return () => window.removeEventListener("resize", resizeEvent);
  }, [])

  return <SignaturePadContainer height={120} ref={signatureRef}/>
}

const WRITTEN = "WRITTEN"//Write a signature"
const IMAGE = "UPLOAD"//Upload a signature"
const CANVAS = "CANVAS"//Draw a signature"
const SIGNATURE_TYPE_LABELS = (intl) => ({
  [WRITTEN]: intl.formatMessage({
    defaultMessage: "Write a signature",
    description: "Expert review, create signature, write signature method label"
  }),
  [IMAGE]: intl.formatMessage({
    defaultMessage: "Upload a signature",
    description: "Expert review, create signature, upload signature method label"
  }),
  [CANVAS]: intl.formatMessage({
    defaultMessage: "Draw a signature",
    description: "Expert review, create signature, draw signature method label"
  })
})

const CreateSignatureDialog = ({values, createAvailableSignature, closeDialog, apiType}) => {
  const intl = useIntl()
  const signatureMethods = [WRITTEN, IMAGE, CANVAS]
  const fileToUpload = useRefState()
  const picture = useToggle()
  const formRef = useRef()
  const uploadingFile = useToggle()
  const draggingOver = useToggle()
  const mode = useToggle(WRITTEN)
  const signaturePad = useToggle({})
  const uploading = uploadingFile.isOpen
  const [writtenSignature, setWrittenSignature] = useState('')
  const [image64, setImage64] = useState('')
  const [fileFromWrittenSignature, setFileFromWrittenSignature] = useState(null)
  const [isRenderingWrittenSignature, setIsRenderingWrittenSignature] = useState(false)
  const timeoutRef = useRef();
  const inputWidth = useRef();

  useEffect(() => {
    if(writtenSignature.length > 0 && mode.isOpen === WRITTEN){
      
      // Clear existing timeout to debounce the function call
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        const element = document.getElementById('signatureH1'); // Ensure the h1 has this ID
        if (element) {
            setIsRenderingWrittenSignature(true);

            html2canvas(element, {
                logging: false,
                scale: 1,
                useCORS: false, // If you're using external resources,
                windowWidth: writtenSignature.length > 18 ? element.scrollWidth : element.scrollWidth,
                windowHeight: element.scrollHeight,
                height: element.offsetHeight > 65 ? element.offsetHeight * 1.4 : element.offsetHeight * 1.4,
                width: writtenSignature.length > 18 ? element.offsetWidth : element.offsetWidth,
            }).then((canvas) => {
                const image = canvas.toDataURL('image/png');
                setImage64(image);
                const fileFromCanvasData = dataURLtoFile(image);
                setFileFromWrittenSignature(fileFromCanvasData);
                fileToUpload.set(fileFromCanvasData);
            }).catch(err => {
              console.error('Error converting to image:', err)
            })
            .finally(() => setIsRenderingWrittenSignature(false));
            ;
        }
      }, 500); // 500 ms debounce period
    }

    // Clean up function
    return () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    };
  }, [writtenSignature, mode.isOpen])

  const changeMode = (v) => {
    mode.openWith(v)
    fileToUpload.set('')
    if(v === IMAGE){
      picture.close()
    }
  }

  // useEffect(() => {
  //   if(formRef?.current && formRef?.current?.getValues('name')){
  //     setWrittenSignature(formRef?.current?.getValues('name'))
  //   }
  // }, [formRef?.current?.getValues('name')])

  const setFile = (e) => {
   
    if(mode.isOpen === IMAGE){
      e.preventDefault()
      const files = (e.nativeEvent.dataTransfer || e.nativeEvent.target).files
      if (files.length > 0) {
        const file = files[0]
        const validation = checkImage(intl,file)
        if (validation) {
          formRef.current.setError("image", validation)
          return
        }
        fileToUpload.set(file)
        const image = URL.createObjectURL(file)
        picture.openWith(image)
      }
    }else{
      const canvasData = e.toDataURL("image/")
      const fileFromCanvasData = dataURLtoFile(canvasData)
      fileToUpload.set(fileFromCanvasData)
    }
  }

  function dataURLtoFile(dataurl, filename) {
 
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
        
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    
    return new File([u8arr], filename, {type:mime});

  }


  const action = (values) => jwtPostFetcher(getJwt())(`/api/${apiType}/signature`,values,{method:"PUT"})
      .then(({id}) =>
        (fileToUpload.get()
        ? jwtPostFetcher(getJwt())(`/api/${apiType}/signaturePicture/${id}`,getFormDataFile(fileToUpload.get()))
      : Promise.resolve({}))
          .then((signature) => ({...values,id,...signature}))
      )

  return <ConfirmDialog
    closeDialog={closeDialog}
    title={intl.formatMessage({
      defaultMessage: "Create new signature",
      description: "Expert review, create signature dialog title"
    })}
    formRef={formRef}
    values={values}
    preValidation={buildValidation({name:required})}
    action={action}
    closeLabel={intl.formatMessage({
      defaultMessage:"Cancel",
      description: "Cancel button"
    })}
    onSuccess={createAvailableSignature}
    confirm={<Button highlight disabled={mode.isOpen === WRITTEN && (isRenderingWrittenSignature || writtenSignature.length == 0) }><FormattedMessage
      defaultMessage="Save"
      description="Save button"
    /></Button>}
  >
    <TextInput
      path="name"
      label={intl.formatMessage({
        defaultMessage: "Display name",
        description: "Expert review, create signature dialog display name input label"
      })}
      mandatory
      placeholder={intl.formatMessage({
        defaultMessage: "Ex: John Doe",
        description: "Expert review, create signature dialog display name input placeholder"
      })}
      onChange={setWrittenSignature}
      ref={inputWidth}
    />

    <TextInput
      path="description"
      label={intl.formatMessage({
        defaultMessage: "Description",
        description: "Expert review, create signature dialog description input label"
      })}
      placeholder={intl.formatMessage({defaultMessage:"Ex: General practitioner", description:"Expert Signature ex placeholder"})}
      textarea
      minHeight={36}
      autoResize
    />

    <Field
      css="margin-top: 2rem"
      label={intl.formatMessage({
        defaultMessage: "Digital signature",
        description: "Expert review, create signature dialog digital signature label"
      })}
      descText={intl.formatMessage({
        defaultMessage: "Select an option to electronically sign your reports",
        description: "Expert review, create signature dialog digital signature description"
      })}
    >
      <RadioboxGroup wide>
        {signatureMethods.map((value) => 
          <TabRadiobox
            key={value}
            raw
            label={SIGNATURE_TYPE_LABELS(intl)[value]}
            fieldValue={value}
            value={mode.isOpen}
            setValue={changeMode}
          />
        )}
      </RadioboxGroup>
        {mode.isOpen === IMAGE 
          ?
            <Field
              css="margin-top: 1rem"
              label={intl.formatMessage({
                defaultMessage: "Upload your signature",
                description: "Expert review, create signature dialog upload signature label"
              })}
              descText={intl.formatMessage({
                defaultMessage: "You can upload images on format JPEG or PNG",
                description: "Expert review, create signature dialog upload signature description"
              })}
            >
              <SignatureContainer
                image={picture.isOpen}
                draggingOver={draggingOver.isOpen}
                uploading={uploadingFile.isOpen}
              >
                {!picture.isOpen && !uploading &&
                  <div>
                    {draggingOver.isOpen
                      ? intl.formatMessage({
                        defaultMessage: "Drop your image here to upload it.",
                        description: "Expert review, create signature dialog drop image here to upload"
                      })
                      : intl.formatMessage({
                        defaultMessage:"Click or drag and drop an image file here to upload it.",
                        description: "Expert review, create signature dialog click or drag and drop image file to upload"
                      })}
                  </div>
                }
                {uploading && <div><Loading /> Uploading image</div>}
                <FileInput onDragEnter={draggingOver.open} onDragLeave={draggingOver.close} onChange={setFile} tabIndex={-1}/>
                <Icon icon="image" />
              </SignatureContainer>
            </Field>
          :
            mode.isOpen === CANVAS 
              ?
                <DigitalSignatureField label={intl.formatMessage({
                  defaultMessage: "Draw your signature",
                  description: "Expert review, create signature dialog draw signature label"
                })}
                  descText={intl.formatMessage({
                    defaultMessage: "Sign using the cursor in the space below",
                    description: "Expert review, create signature dialog draw signature description"
                  })} onMouseOut={() => setFile(signaturePad.get())}>
                  <SignaturePadComponent signaturePad={signaturePad}/>
                  <Button small onClick={() => signaturePad.get().clear()}><FormattedMessage
                    defaultMessage="Clear"
                    description="Expert review, create signature dialog clear button"
                  /></Button>
                </DigitalSignatureField>
              :  
              <Field
                css="margin-top: 1rem"
                label={intl.formatMessage({
                  defaultMessage: "Preview your signature",
                  description: "Expert review, create signature dialog preview signature label"
                })}
                descText={intl.formatMessage({
                  defaultMessage: 'Type your name under "Display name" and your signature will automatically populate below',
                  description: 'Expert review, create signature dialog preview signature description'
                })}
              > 
                <WrittenSignatureArea >
                  <div id={"signatureH1"} 
                      style={{
                        display: 'block',
                        marginBottom: '0.5rem',
                        width: `${inputWidth.current?.offsetWidth ? inputWidth.current?.offsetWidth : 480}px`,
                        // height: '120px',
                        alignContent: 'center',
                        padding: "0rem 1rem"
                      }}  
                  >
                    <h1 
                      style={{
                        fontFamily: "Sacramento",
                        fontSize: "44px", 
                        textAlign: "center", 
                      }}
                    >
                      {writtenSignature}
                    </h1>
                  </div>
                </WrittenSignatureArea>
                {/* <img src={image64} alt="signature" onClick={() =>{    }}/> */}
              </Field>
        }
      </Field>

  </ConfirmDialog>

}
