import {Icon} from '@startlibs/components';
import {useNavigate} from 'react-router'
import {preventDefault} from '@startlibs/utils'
import React from 'react'
import {
  CASE_ARCHIVED,
  CASE_CLOSED,
  PENDING_REQUEST,
  WAITING_MEDICAL_RECORDS
} from '../enums/CaseState';
import {
  CaseId,
  Sidebar,
  SidebarCard,
  SidebarContainer,
  SidebarDesc,
  SidebarSeparator,
  SidebarTitle,
  StepLink,
} from '../admin/steps/RequestSidebar';
import {ScrollToTop} from '../components/ScrollToTop'
import {confirmExitPage} from '../hooks/useConfirmExitPage'
import {isStateBefore} from '../request/utils';
import {IfLocale} from '../hocs/IfLocale'
import {FormattedDate, FormattedMessage} from 'react-intl'
import { START } from '../enums/UploaderManagement';
import {Match} from "../components/Match";


export const PatientRequestSidebar = ({caseRequest = {},providerInfo, isRefPhysician}) => {
  const navigate = useNavigate()

  const canAccessCaseStatus = providerInfo?.patientAccessFunctions?.caseStatus 
  const canAccessCaseOverview = providerInfo?.patientAccessFunctions?.caseOverview
  const canAccessMessaging = providerInfo?.patientAccessFunctions?.messaging 
  const canAccessPatientContactDetails = providerInfo?.patientAccessFunctions?.patientContactDetails 
  const canAccessPayment = providerInfo?.patientAccessFunctions?.payment 
  const canAccessCaseReport = providerInfo?.patientAccessFunctions?.reportData 


  const confirmRedirect = preventDefault((e) => {
    const href = e.target.pathname
    confirmExitPage().then(() => navigate(href))
  })

  //Only show the payment page if: The admin request payment or Any payment was already submitted (or marked as paid)
  const requestedPayment = caseRequest.caseFlags?.requestFlags?.askForPayments
  const showTerms = providerInfo?.serviceTerms && !isRefPhysician
  const hasPaid = caseRequest.payments && caseRequest.payments.filter(payment => payment.paid).length > 0

  const isCurrentStateBefore = isStateBefore(caseRequest && caseRequest.state)
  const isArchived = caseRequest && caseRequest.state === CASE_ARCHIVED
  
  return <Match path="/:userType/:step">{({match}) => {
    const step = match?.params && match?.params.step

    return <SidebarContainer className="hideOnPrint">
      <ScrollToTop when={step}/>
      <Sidebar>
        <SidebarCard>
          <div className="sidebar-heading">
            <SidebarDesc><FormattedMessage
              defaultMessage="CASE DETAILS"
              description="Patient sidebar, case details label"
            /></SidebarDesc>
            <SidebarTitle className='fs-exclude'>
              {caseRequest?.patientInfo?.firstName && caseRequest?.patientInfo?.lastName ? 
                <>{caseRequest?.patientInfo?.firstName} {caseRequest?.patientInfo?.middleName} {caseRequest?.patientInfo?.lastName}</>
                : <>{caseRequest.firstName} {caseRequest?.middleName} {caseRequest.lastName}</>
              }
              {/* {caseRequest.patientInfo.firstName} {caseRequest.patientInfo?.middleName} {caseRequest.patientInfo.lastName} */}
            </SidebarTitle>
            {caseRequest?.legalInfo?.caseNumber && <IfLocale contains="LEGAL">
                <CaseId><b>Case Number:</b> {caseRequest?.legalInfo?.caseNumber}</CaseId>
              </IfLocale>
            }
            {caseRequest.requestCode && <CaseId><b><FormattedMessage
              defaultMessage="Case Id"
              description="Patient sidebar, case id label"
              />:</b> {caseRequest.requestCode}</CaseId>}
          </div>
          <SidebarSeparator/>
          {
            (caseRequest.state !== CASE_CLOSED || requestedPayment) && canAccessCaseStatus &&
            <>
              {/* <SidebarSeparator/> */}
              <StepLink
                onClick={confirmRedirect}
                to="status"
                selected={step === "status"}
              >
                <Icon icon="info"/> Status
              </StepLink>
              
            </>
          }
          {canAccessMessaging &&
            <StepLink
              onClick={confirmRedirect}
              to="contact-institution"
              selected={step === "contact-institution"}
            >
              <Icon icon="email-line"/> Messaging
              {caseRequest?.collaborationMessagesToRead > 0 && <span className="notificationNumber">
                  {caseRequest?.collaborationMessagesToRead}
              </span>}
            </StepLink>
          }
          {(caseRequest.state !== CASE_CLOSED || requestedPayment) && canAccessCaseStatus && canAccessMessaging && <SidebarSeparator/>}
          <IfLocale not contains="LEGAL">{
            showTerms &&
            <StepLink
              to="agreements"
              onClick={confirmRedirect}
              disabled={caseRequest.state && isCurrentStateBefore(PENDING_REQUEST)}
              selected={step === "agreements"}
            ><Icon icon="sign-doc"/>{caseRequest?.caseFlags?.requestFlags?.askForServiceTerms
              ? <FormattedMessage
                defaultMessage="Agree to terms"
                description="Patient sideabar, agree to terms item label"
              />
              : <FormattedMessage
                defaultMessage="Agreements"
                description="Patient sideabar, agreements item label"
              />}</StepLink>
          }</IfLocale>
          {canAccessPatientContactDetails && <StepLink
              to="details"
              onClick={confirmRedirect}
              disabled={caseRequest.state && isCurrentStateBefore(PENDING_REQUEST)}
              selected={step === "details" || step === "start"}
            ><Icon icon="user-line"/><FormattedMessage defaultMessage="Patient & contact details" description='RequestSidebar patient item label'/></StepLink>
          }
          {/* <StepLink
            to="release"
            onClick={confirmRedirect}
            disabled={isCurrentStateBefore(PENDING_REQUEST)}
            selected={step === "release"}
          ><Icon icon="edit-box"/> Locate medical records</StepLink>
          <StepLink
            to="records"
            onClick={confirmRedirect}
            disabled={isCurrentStateBefore(PENDING_REQUEST)}
            selected={step === "records"}
          ><Icon icon="dicom-no-text"/> Upload medical records</StepLink> */}
           <StepLink
            to="records"
            onClick={confirmRedirect}
            disabled={isCurrentStateBefore(PENDING_REQUEST) && !isArchived}
            selected={step === "records"}
          ><Icon icon="dicom-no-text"/> <FormattedMessage
             defaultMessage="Medical records"
             description="Patient sidebar, medical records item label"
           /></StepLink>
          {
            (requestedPayment || hasPaid || caseRequest?.payments?.length > 0) && canAccessPayment &&
            <StepLink
              to="payments"
              onClick={confirmRedirect}
              selected={step === "payments"}
            ><Icon icon="payment"/> Payment</StepLink>
          }
          {
            !isCurrentStateBefore(CASE_CLOSED) && canAccessCaseReport &&
            <StepLink
              to="report"
              onClick={confirmRedirect}
              selected={step === "report"}
            ><Icon icon="checked-report"/> Final report</StepLink>
          }
        </SidebarCard>
      </Sidebar>
    </SidebarContainer>
  }
  }</Match>
}
