import React from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components/macro'
import {AttachmentBoxFooter} from "../AttachmentBoxStyles";
import {FormattedMessage} from "react-intl";

styled

export const RecordRowQuarantinedFooter = () =>
  <AttachmentBoxFooter alert>
    <div><b><FormattedMessage
      defaultMessage="Status"
      description="Uploader, record status label"
      />: </b><FormattedMessage
      defaultMessage="Quarantined"
      description="Uploader, record status, quarantined"
    /></div>
    <div><FormattedMessage
      defaultMessage="This medical record file has potentially harmful data."
      description="Uploader, record status, quarantined description"
    /></div>
  </AttachmentBoxFooter>