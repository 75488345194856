import {usePopupToggle, useToggle} from '@startlibs/core';
import {will} from '@startlibs/utils';
import {useLocation} from 'react-router'
import React, { useEffect } from 'react'
import {BoxPageContainer, BoxPageLayout, BoxPageWrapper} from '../components/BoxPageLayout';
import { CASE_CLOSED, CASE_REVIEWED, UNDER_REVIEW } from '../enums/CaseState';
import {DashboardHeader} from '../components/asyncQueryList/DashboardHeader'
import {DashboardLoader} from '../components/asyncQueryList/DashboardLoader'
import {ALL, EXPERT_CASES, EXPERT_OPEN, EXPERT_REVIEWED, FILTER_LABEL_MAP} from '../enums/CaseFilter';
import {EmptyListPlaceholder} from '../components/StyledTable';
import {ExpertCaseCard} from './ExpertCaseCard'
import {ExpertCaseCardMenu} from './ExpertCaseCardMenu'
import {PurviewFooter} from '../components/PurviewFooter';
import {ORDER_BY_LABEL, ResultsHeader, ResultsWrapper} from '../admin/dashboard/AdminResults';
import {useQueryParams} from '../hooks/useQueryParams'
import {Button, ContextMenu, Li, Loading} from '@startlibs/components'
import _ from 'lodash/fp'
import {NAME, UPDATE_DATE} from '../enums/CaseOrderBy'
import { FeedbackDialog } from './dialogs/FeedbackDialog';
import { lazyProviderInfo, lazyUserInfo } from '../components/WithProvider';
import {FormattedMessage, useIntl} from "react-intl";


const EXPERT_FILTERS = (intl) => [
  {label: intl.formatMessage({defaultMessage:"Open cases",description:"Expert filter open cases label"}) , value: ''},
  {label: intl.formatMessage({defaultMessage:"Reviewed cases",description:"Expert filter reviewed cases label"}), value: EXPERT_REVIEWED},
  {label: intl.formatMessage({defaultMessage:"All cases",description:"Expert filter all cases label"}), value: EXPERT_CASES}
]
const checkIsFiltering = (params) => params.search || params.to || params.from || params.dob

export const ExpertCaseList = ({feedbackPopup}) => {
  const location = useLocation()
  const [params, setParams, updateParams] = useQueryParams(location, ['from', 'to', 'search', 'state'])
  const loading = useToggle()
  const intl = useIntl()
  const userInfo = lazyUserInfo.read()
  const providerInfo = lazyProviderInfo.read()

  // Fullstory disabled
  // useEffect(() => {
  //   window.FS.setUserVars({
  //     displayName : userInfo.firstName + ' ' + userInfo.lastName,
  //     email : userInfo.login,
  //     id: userInfo.id,
  //     type: "EXPERT",
  //     customer: providerInfo.name
  //   })
  // }, [])

  return <BoxPageLayout responsiveWorkaround>
    <BoxPageContainer>
    <BoxPageWrapper
      fullWidth
      css="padding-top: 2rem;
      min-height: calc(100vh - 11.5rem);"
    >
      <DashboardHeader
        params={params}
        setParams={setParams}
        location={location}
        loading={loading}
        defaultMaxResults={20}
        stateFilters={EXPERT_FILTERS(intl)}
        isExpertDashboard
      />
      <DashboardLoader
        url="/api/expert/searchRequests"
        defaultFilter={EXPERT_OPEN}
        defaultOrderBy={UPDATE_DATE}
        params={params}
        defaultMaxResults={20}
        setParams={setParams}
        location={location}
        loading={loading}
        updateParams={updateParams}
        isExpertDashboard
      >{({key, refreshList, querycount, list, params: {state}}) =>
        <CaseCardList setParams={setParams} params={params} list={list} refreshList={refreshList} isLoading={loading.isOpen || state !== params.state} />
      }</DashboardLoader>
    </BoxPageWrapper>
    </BoxPageContainer>
    {
      feedbackPopup.isOpen && <FeedbackDialog feedbackPopup={feedbackPopup} />
    }
    <PurviewFooter />
  </BoxPageLayout>
}

const CaseCardList = ({params,list, setParams,isLoading, refreshList}) => {
  const isFiltering = checkIsFiltering(params)
  const intl = useIntl()
  const contextMenu = usePopupToggle()

  return <>
    <ResultsHeader>
      <h3><FormattedMessage
        defaultMessage="{filterState} cases"
        values={{
          filterState: FILTER_LABEL_MAP(intl)[params.state || EXPERT_OPEN]
        }}
      /></h3>
      <Button outline small withDropdown onClick={contextMenu.open} css="float:right">
        <FormattedMessage
          defaultMessage="Sorting by {orderType}"
          values={{
            orderType: (ORDER_BY_LABEL(intl)[params.orderBy] || ORDER_BY_LABEL(intl)[UPDATE_DATE]).toLowerCase()
          }}
          description="Case sorting by button"
        />
        {
          contextMenu.isOpen &&
          <ContextMenu>
            <Li label={ORDER_BY_LABEL(intl)[UPDATE_DATE]} icon={!params.orderBy ? "check" : undefined} onClick={() => setParams(_.unset('orderBy'))}/>
            <Li label={ORDER_BY_LABEL(intl)[NAME]} icon={params.orderBy === NAME ? "check" : undefined} onClick={() => setParams(_.set('orderBy',NAME))}/>
          </ContextMenu>
        }
      </Button>
    </ResultsHeader>
    {list.length > 0 || isLoading ?
      <ResultsWrapper useGrid={list.length > 2}>
        {
          isLoading
            ? <Loading
              size="36"
              borderWidth="5"
            />
            : <div className="cards-wrapper">
              {list.map(caseRequest => <ExpertCaseCard
                caseRequest={caseRequest}
                key={caseRequest.id}
                isResults
                linkTo={
                  caseRequest.isCoReview ? `/expert/case/${caseRequest.id}/overview` :
                  caseRequest.state === (CASE_REVIEWED || UNDER_REVIEW) ?
                    `/expert/case/${caseRequest.id}/questions`
                    : ( caseRequest.state === CASE_CLOSED ?
                      `/expert/case/${caseRequest.id}/report`
                      : `/expert/case/${caseRequest.id}`
                    )
                }
                showFooter={params.state !== EXPERT_REVIEWED}
                contextMenu={(isOpen) => <ExpertCaseCardMenu
                  isOpen={isOpen}
                  caseRequest={caseRequest}
                  refreshList={refreshList}
                />}
              />)}
            </div>
        }
      </ResultsWrapper>
      : <EmptyListPlaceholder css="margin-top: 0;" isFiltering={isFiltering}>
        <span>
          <FormattedMessage
            defaultMessage="Currently there are no {filterType} cases {isFiltering, select, true {matching your search query} other {assigned to you}}."
            values={{
              filterType:params.state !== EXPERT_CASES && FILTER_LABEL_MAP(intl)[params.state || EXPERT_OPEN]?.toLowerCase(),
              isFiltering
            }}
            description="Empty case list message"
          />
        </span>
      </EmptyListPlaceholder>
    }
  </>
}
