import {Button} from '@startlibs/components'
import {TextInput} from '@startlibs/form';
import React from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import _ from 'lodash/fp'
import {ConfirmDialog, useConfirmDialog} from '../../hooks/useConfirmDialog'
import {ContactCheckbox, ContactCheckboxGroup} from '../../components/ContactCard'
import {REF_PHYSICIAN} from '../../enums/ContactRelationType'
import {buildValidation} from '../../utils/validation'
import {getContact, getRelationLabel, PRIMARY} from '../../enums/ContactRelationKind'
import {getJwt} from '../../hooks/useJwt'
import {jwtFormFetcher} from '../../utils/authFetch'
import { InfoBox } from '../../components/InfoBox';
import { lazyProviderInfo } from '../../components/WithProvider';

export const useSendRemoteConsultationLink = (caseRequest,meetingURL) => {
  const referringPhysicianAsSecondary = caseRequest.contactDetails.contactRelationType !== "REF_PHYSICIAN" && caseRequest.referringPhysician.email
  const intl = useIntl()
  const primary = getContact(intl, caseRequest, PRIMARY)
  const physician = getContact(intl, caseRequest, REF_PHYSICIAN)

  const getChecked = (email) => emails => emails.indexOf(email)>=0
  const setValue = (email) => emails => getChecked(email)(emails) ? _.without([email],emails) : emails.concat(email)

  const providerInfo = lazyProviderInfo.read()
  const caseContactsNotifications = providerInfo?.caseContactsNotifications

  return useConfirmDialog(
    <ConfirmDialog
      title={intl.formatMessage({
        defaultMessage: "Send invitation",
        description: "Send remote consultation link dialog title"
      })}
      confirm={<Button highlight icon="email" disabled={!caseContactsNotifications}><FormattedMessage
        defaultMessage="Send"
        description="Send button"
      /></Button>}
      closeLabel={intl.formatMessage({
        defaultMessage:"Cancel",
        description:"Cancel button"
      })}
      action={jwtFormFetcher(getJwt())('/api/expert/sendRemoteVideoConsultNotification?requestId=' + caseRequest.requestId)}
      values={{customMessage: "", meetingURL, sendTo: []}}
      transform={_.update('sendTo',(emails) => !referringPhysicianAsSecondary ? [primary.email] : emails)}
      preValidation={buildValidation({'sendTo': (emails) => referringPhysicianAsSecondary && !emails.length &&
          intl.formatMessage({
            defaultMessage: "Please select at least one contact before proceeding.",
            description: "Send remote consultation link dialog email required message"
          })
      })}
      notify={intl.formatMessage({
        defaultMessage: "Email sent successfully",
        description: "Send remote consultation link dialog success message"
      })}
    >
      {caseContactsNotifications ? <>
        <p><FormattedMessage
          defaultMessage="Send the consultation link to:"
          description="Send remote consultation link dialog description"
        /></p>
        <ContactCheckboxGroup>
          <ContactCheckbox
            getValue={getChecked(primary.email)}
            setValue={setValue(primary.email)}
            path="sendTo"
            email={primary.email}
            name={primary.name}
            key="patient"
            type={primary.relationType}
          />
          {
            referringPhysicianAsSecondary &&
            <ContactCheckbox
              getValue={getChecked(physician.email)}
              setValue={setValue(physician.email)}
              path="sendTo"
              name={physician.name}
              email={physician.email}
              type={getRelationLabel(intl,REF_PHYSICIAN)}
              key="Referring physician"
            />
          }
        </ContactCheckboxGroup>
        <p><FormattedMessage
          defaultMessage="The above contact(s) will receive the consultation link with the following message:"
          description="Send remote consultation link dialog message description"
        /></p>
        <TextInput
          textarea
          autoResize
          label={intl.formatMessage({
            defaultMessage: "Message",
            description: "Send remote consultation link dialog message input label"
          })}
          path="customMessage"
          placeholder={intl.formatMessage({
            defaultMessage: "Insert a custom message here. Ex: Let's have a conversation at...",
            description: "Send remote consultation link dialog message input placeholder"
          })}
        />
      </> 
      : <InfoBox lightYellow>
          <FormattedMessage
            defaultMessage="Communication with case contacts is disabled"
            description="caseContactsNotifications communication is disabled message"
          />
        </InfoBox>
      }
    </ConfirmDialog>
  )
}
