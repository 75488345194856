import {Icon, Tooltip} from '@startlibs/components';
import {getColor} from '@startlibs/utils';
import React from 'react';
import _ from 'lodash/fp'
import styled from 'styled-components';
import {FormattedMessage} from "react-intl";

const Tag = styled(({title, ...props}) => <Tooltip whenEllipsis content={title}>
      <div  {...props}/>
    </Tooltip>
    )`
  border-radius: 8px;
  background: rgba(185, 200, 210, 0.3);
  border: 1px solid rgba(185, 200, 210, 0.6);
  display: inline-block;
  padding: 0 0.5rem;
  margin-right: 0.25rem;
  font-size: 11px;
  line-height: 14px;
  margin-top: 3px;
  cursor: pointer;
  color: rgba(0,0,0,0.75);
  max-width: 122px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: -5px;
  ${Icon} {
    font-size: 14px;
    line-height: 0;
    vertical-align: -2px;
    color: rgba(0,0,0,0.6);
  }
  :hover, :active {
    background: rgba(185, 200, 210, 0.6);
    border: 1px solid rgba(185, 200, 210, 0.9);
  }
`
const CreateOptionItem = styled.span`
  color: ${getColor('main')};
`

export const getListItem = (v) => v.newValue ? <CreateOptionItem className="whiteOnHover">Create
  option {v.newValue}</CreateOptionItem> : (_.isString(v.description) ? v.description : v)

export const StudyCell = ({setParams,  modsInStudy, numInstances, studyDesc,tagsStudy}) => {
  
  const filterTag = (tagId) => setParams(_.update('idsTagsArray', (v = "") => v.split(",").filter(_.identity).filter(id => Number(id) !== Number(tagId)).concat(tagId).join(",")), true)

  return <>
    <span>{modsInStudy}</span> - <span>{studyDesc}</span>
    <div><span css="margin-right:0.25rem"><FormattedMessage
      defaultMessage="{numInstances} images"
      values={{numInstances}}
      description="Uploader, link viva studies, study info, number of images"
    /></span>
      {
        tagsStudy.length > 0
          ? <>
              <span>
                {tagsStudy.map((tagItem) => 
                  <Tag title={tagItem?.tags?.description} key={tagItem?.tags?.idTag} 
                    onClick={() => filterTag(tagItem?.tags?.idTag)} 
                    className="fix-safari-alignment">
                      {tagItem?.tags?.description}
                  </Tag>)
                }
              </span>
            </>
          : null 
      }
    </div>
  </>
}
