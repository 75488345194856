import { Button, Icon, Loading, SplitColumnsContainer } from '@startlibs/components';
import {
  AutoComplete,
  FormValue,
  RadioboxGroup,
  RichText,
  TextInput,
  ToggleCheckbox,
  WithImageInput
} from '@startlibs/form';
import { ifUndefined, media } from '@startlibs/utils';
import React, { useRef } from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components';
import {CategoriesInput} from '../../experts/hooks/useEditCategories'
import {ConfirmDialog, useConfirmDialogWithProps} from '../../../hooks/useConfirmDialog'
import {DIVISION, OPERATOR, SUPER} from '../../../enums/UserRole'
import {InfoBox} from '../../../components/InfoBox'
import { RoleRadioBox } from '../../../components/RoleRadioBox';
import {buildValidation, confirmEmails, emailValidation, required} from '../../../utils/validation'
import {getJwt} from '../../../hooks/useJwt'
import { jwtGetFetcher, jwtPostFetcher } from '../../../utils/authFetch';
import { FormattedMessage } from 'react-intl';
import { RTF_TOOLBAR_OPTIONS_STARTLIBS } from '../../../utils/utils';
import { ImageInputContainer, ThumbnailContainer } from '../../experts/components/ExpertProfileLayout';
import { useRefState, useToggle } from '@startlibs/core';
import { IfLocale, useIsLocale } from '../../../hocs/IfLocale';

const RoleRadioGroup = styled(RadioboxGroup)`
  display: flex;
  align-items: stretch;
  ${media.max(480)`
    flex-wrap: wrap;
  `}
`
const isEmptyCategories = v => !v.filter(v => v && !_.isString(v)).length

const formatCategories = _.update('categories',_.map(_.get('id')))

export const useEditStaff = (role, userInfo, onSuccess) => {

  const formRef = useRef()
  const fileToUpload = useRefState()
  const uploadingFile = useToggle()
  const errorRef = useRef()

  const isNotLegal = useIsLocale({not:true,contains:"LEGAL"})

  const preValidation = buildValidation({
    firstName: [(v,k,values) => (!v || v?.trim() === "") && required()],
    lastName: [(v,k,values) => (!v || v?.trim() === "") && required()],
    email: [required, emailValidation],
    confirmEmail: [(v,k, values) => !values.id && confirmEmails('email')(v,k,values)],
    role: [required],
    categories: [(v,k,values) => values.role === DIVISION && isEmptyCategories(v) && required()],
    'expertInfo.specialization': [(v, k, values) => (!v || !v.length) && isNotLegal && values.expert && required()],
  })

  const onFailure = (e,[resp,{status}],form) => {
    if (status === 409){
      form.setError("email","There's an user already registered in the system with that email.")
    }
  
    if (status === 403) {
      form.setError("","This user is currently assigned to review a case. Revoke all case assignments before revoking the permission to review cases.")
    }
    if (status === 568) {
      const errorMessage = resp.replace('This c','C')
      const displayMessage = errorMessage + ". Revoke all case assignments before revoking the permission to review cases."
      form.setError("",displayMessage)
    }
    if(status === 564) {
      const errorMessage = resp.replace('This c','C')
      const displayMessage = errorMessage + ". Please unassign or complete the case before revoking the permission to review cases."
      form.setError("",displayMessage)
    }
  }

  const checkImageAndScroll = (file) => {
    if (file.size > 4194304/4) {
      setTimeout(() => errorRef.current.scrollIntoView({block: 'center', behavior: 'smooth'}), 50)
      return ['Your file needs to be smaller than 1mb.']
    }
    if (['image/gif', 'image/png', 'image/jpeg', 'image/jpg'].indexOf(file.type.toLowerCase()) < 0) {
      setTimeout(() => errorRef.current.scrollIntoView({block: 'center', behavior: 'smooth'}), 50)
      return ['Invalid file format. Please select a .JPG or .PNG file.']
    }
  }

  // Specialties autocomplete:
  let fetchedSuggestions = null

  const fetchSuggestions = (s) => {
    const fetch = fetchedSuggestions || jwtGetFetcher(getJwt())("/api/specializations").then(_.map(({label}) => label))
    fetchedSuggestions = fetch
    return fetch.then(specializations =>
      specializations.filter(t => t.toLowerCase().indexOf(s) >= 0)
    ).then(l => l.indexOf(s) >= 0 ? l : l.concat([{newValue: s}]))
  }

  const getLabel = (v) => _.isString(v.newValue) ? `Create option "${v.newValue}"` : v
  const transformA = (l) => l ? l.map(v => (v && v.newValue) || v) : l

  // Image upload
  const uploadFetcher = (adminId) => (file) => {
    const formData = new FormData()
    formData.append('file', file)
    return jwtPostFetcher(getJwt())(`/api/admin/${adminId}/picture`, formData).then(console.log)
  }


  const setFile = (file) => {
    fileToUpload.set(file)
    const image = URL.createObjectURL(file)
    formRef.current.setValue("picture",image)
    return Promise.resolve({image})
  }

  // const pictureId = ifUndefined(expert && expert.id,id)
  // const pictureId = ifUndefined(userInfo && userInfo.id,userInfo.id)


  /************************/

  return useConfirmDialogWithProps((values) => {

    const pictureId = ifUndefined(values && values.id,values.id)
    const isDivisionalWithReviewerPermission = values.role === DIVISION && userInfo.id === values.id && values.expertInfo 

    return (
      <ConfirmDialog
      onFailure={onFailure}
      // values={values}
      values={() => { return {...values, expert: values.expertInfo ? true : false, picture: !!values.picture } }}
      // _.update("picture",(image) => !!image
      // action={(v) => 
      //   values.id
      //     ? jwtPostFetcher(getJwt())("/api/admin/user",formatCategories(v), {method:"PUT"})
      //     : jwtPostFetcher(getJwt())("/api/admin/user",formatCategories(v))
      // }
      action={(v) => {
        if (fileToUpload.get()) {
          uploadingFile.open()
        }
        return values.id
          ? jwtPostFetcher(getJwt())("/api/admin/user",formatCategories(v), {method:"PUT"}).then((admin) => {
            if (fileToUpload.get()) {
              return uploadFetcher(admin.id)(fileToUpload.get()).then(() => admin)
            } else {
              return admin
            }
          })
          .finally(() => uploadingFile.close())
          : jwtPostFetcher(getJwt())("/api/admin/user",formatCategories(v)).then((admin) => {
            if (fileToUpload.get()) {
              return uploadFetcher(admin.id)(fileToUpload.get()).then(() => admin)
            } else {
              return admin
            }
          })
          .finally(() => uploadingFile.close())
      }}
      transform={_.flow(
        (v) => v.role === SUPER ? _.set('categories',[],v) : v,
        _.update('email',e => e?.trim()),
        (v) => v.expert == false || v.role === OPERATOR ? _.unset('expertInfo',v) : v, 
      )}
      onSuccess={onSuccess}
      notify={values.id ? "Changes saved successfully." : "User invited successfully."}
      preValidation={preValidation}
      title={values.id ? "Edit user details" : "Add new user"}
      confirm={<Button highlight>{values.id ? "Save changes" : "Add new user"}</Button>}
      css="max-width: 48rem;"
      formRef={formRef}
    >
      <SplitColumnsContainer viewportMinWidth={480}>
        <TextInput path="firstName" label="First name" mandatory placeholder="Ex: John" />
        <TextInput path="lastName" label="Last name" mandatory placeholder="Ex: Doe" />
      </SplitColumnsContainer>
      <SplitColumnsContainer viewportMinWidth={480}>
        <TextInput path="email" label="Email" mandatory locked={values.id} disabled={values.id} placeholder="Ex: your@email.com" />
        <TextInput path="confirmEmail" label="Confirm email" mandatory locked={values.id} disabled={values.id} raw={values.id} value={values.email} placeholder="Ex: your@email.com" />
      </SplitColumnsContainer>
      <RoleRadioGroup path="role" label="User role" mandatory>
        {role === SUPER &&
          <RoleRadioBox
            fieldValue={SUPER}
            label="Super admin"
            permissionList={<>
              <li><Icon icon="check" /> Access to cases (all)</li>
              <li><Icon icon="check" /> Manage staff (all)</li>
              <li><Icon icon="check" /> Notification settings</li>
              <li><Icon icon="check" /> Access to audit log</li>
            </>}
          />
        }
        <RoleRadioBox
          fieldValue={DIVISION}
          label="Division admin"
          permissionList={<>
            <li><Icon icon="check" /> Access to cases (division)</li>
            <li><Icon icon="check" /> Manage staff (division)</li>
            <li className="false"><Icon icon="unavailable" /> Notification settings</li>
            <li className="false"><Icon icon="unavailable" /> Access to audit log</li>
          </>}
        />
        <RoleRadioBox
          fieldValue={OPERATOR}
          label="Operator"
          permissionList={<>
            <li><Icon icon="check" /> Access to cases (division)</li>
            <li className="false"><Icon icon="unavailable" /> Manage staff</li>
            <li className="false"><Icon icon="unavailable" /> Notification settings</li>
            <li className="false"><Icon icon="unavailable" /> Access to audit log</li>
          </>}
        />
      </RoleRadioGroup>
      <ToggleCheckbox
        path="notification"
        label="Receive administrative email notifications"
        descText="According to notification settings and division restrictions (if applicable)"
      />
      {(role === SUPER || isDivisionalWithReviewerPermission  ) && <FormValue path="role">{ userRole => 
        (userRole === SUPER || userRole === DIVISION) && <>
          <ToggleCheckbox
            path="expert"
            label="This user can review cases as an Expert"
            disabled={isDivisionalWithReviewerPermission}
            descText="Activate and complete the registration (if applicable)"
          />
          <FormValue path="expert">{ expert => expert && <div
            style={{
              background: '#f0f0f0',
              padding: '1rem',
              marginTop: '-2.4rem',
              borderRadius: '6px',
              marginBottom: '1rem'
            }}
          >
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                <div style={{width: '66%'}}>
                  <TextInput
                    label="Phone:"
                    constraint={/[^\d-,+ ]/}
                    path="expertInfo.phone"
                    placeholder="+1 123-456-789"
                  />
                  <IfLocale not contains="LEGAL">
                    <AutoComplete
                      mandatory
                      label="Specialization(s):"
                      confirmIfValueKeys={['Tab']}
                      path="expertInfo.specialization"
                      fetchSuggestions={fetchSuggestions}
                      getLabel={getLabel}
                      transform={transformA}
                      bellowDescText="Use comma, enter or tab to add multiple."
                      className="specializations-input"
                    />
                  </IfLocale>
                </div>
                <div className="image-wrapper" style={{width: '33%'}}>
                  <WithImageInput preValidation={checkImageAndScroll} uploadFetcher={setFile}
                                  path={["picture"]}>{(input, {image, isLoading}) => {
                                    return (
                                      // <ImageInputContainer style={{width: '10rem', height: '10rem', marginTop: '1rem', padding: '8px'}} hasImage={image} image={image && image?.startsWith('pictureAdmin') ? `/api/admin/${pictureId}/picture?t=${getJwt()}&no-cache=${image}` : image}>
                                      <ImageInputContainer style={{width: '10rem', height: '10rem', marginTop: '1rem', padding: '8px'}} hasImage={image} image={image===true ? `/api/admin/${pictureId}/picture?t=${getJwt()}&no-cache=${image}` : image}>
                                      {!(uploadingFile.isOpen || isLoading) && input}
                                      {/* <ThumbnailContainer css={image && image?.startsWith('pictureAdmin') ? `background-image:url('/api/admin/${pictureId}/picture?t=${getJwt()}&no-cache=${image}');` : (image && `background-image:url('${image}')`)}> */}
                                      <ThumbnailContainer css={image === true ? `background-image:url('/api/admin/${pictureId}/picture?t=${getJwt()}&no-cache=${image}');` : (image && `background-image:url('${image}')`)}>
                                        {!image && <Icon icon="physician"/>}
                                        {(uploadingFile.isOpen || isLoading) && <Loading white absolute/>}
                                      </ThumbnailContainer>
                                      {!image ?
                                        <Button small disabled={uploadingFile.isOpen || isLoading}>Add picture</Button>
                                        : <Button small highlight disabled={uploadingFile.isOpen || isLoading} style={{top: '4rem'}}>Change image</Button>
                                      }
                                      </ImageInputContainer>
                                    )
                    }
                  }</WithImageInput>
                  {/* <WithImageInput preValidation={checkImageAndScroll} uploadFetcher={setFile}
                            path={["picture"]}>{(input, {image, isLoading}) =>
                    <ImageInputContainer hasImage={image} image={image===true ? `/api/expert/${pictureId}/picture?t=${getJwt()}&no-cache=${expert.picture}` : image}>
                      {!(uploadingFile.isOpen || form.isLoading || isLoading) && input}
                      <ThumbnailContainer css={image === true ? `background-image:url('/api/expert/${pictureId}/picture?t=${getJwt()}&no-cache=${expert.picture}');` : (image && `background-image:url('${image}')`)}>
                        {!image && <Icon icon="physician"/>}
                        {(uploadingFile.isOpen || isLoading) && <Loading white absolute/>}
                      </ThumbnailContainer>
                      {!image ?
                        <Button small disabled={uploadingFile.isOpen || form.isLoading || isLoading}>Add picture</Button>
                        : <Button small highlight disabled={uploadingFile.isOpen || form.isLoading || isLoading}>Change image</Button>
                      }
                    </ImageInputContainer>
                  }</WithImageInput> */}
                </div>
              </div>
            
            
            <RichText
              path="expertInfo.resume"
              label={<FormattedMessage defaultMessage="Resume:" description="admin expert resume label"/>}
              formatTools={RTF_TOOLBAR_OPTIONS_STARTLIBS}
              autoHideToolbar
            />
          </div>}</FormValue>
        </>
      }</FormValue>}
      <FormValue path="role">{role => <>
        {role && role !== SUPER
          ? <CategoriesInput mandatory={role === DIVISION} userRelated />
          : (role === SUPER && values.id && values.role !== SUPER)
          ? <InfoBox lightYellow>
            By changing an user into a super admin role it will no longer be tied to any division, but it will be able
            to access all cases from all divisions.
          </InfoBox>
        : ""}
        {
          role === OPERATOR && <FormValue path="categories">{ categories =>
            isEmptyCategories(categories) &&
            <InfoBox lightBlue withIcon><Icon icon="info"/> Operators that are not tied to any division can access cases from all divisions.</InfoBox>
          }</FormValue>
        }
      </>}</FormValue>
      </ConfirmDialog>
    )
  })

}
