import {formatRecord} from './utils'
import {useConstant, useToggle} from '@startlibs/core'
import React, {useContext, useEffect} from 'react'
import {useRecordStateManager} from './useRecordStateManager'
import {useUploader} from './useUploader'
import {uploadQueue} from './queues'
import {callIfFunction} from "@startlibs/utils";
import {UIDataProvider} from "./hooks/useUIDataSelector";
import {RecordsProvider} from "./hooks/useRecords";
import {DoActionContext} from "./hooks/useDoAction";
import {UploadQueueProvider} from "./hooks/useUploadQueueSelector";
import {UploaderConfigContext} from "./UploaderConfigContext";
import {DEFAULT_GROUPS, EnhancedRecordClassList, GroupAction} from "./GroupAction";
import {useIntl} from "react-intl";

export const UploaderManager = ({children, groups, medicalRecords, setError}) => {
  const config = useContext(UploaderConfigContext)
  const intl = useIntl()
  const getJwt = config.jwt

  const doAction = useConstant((action, ...rest) => {
    return action({
      setData: data.openWith,
      getData: data.get,
      recordManager,
      intl,
      uploader,
      config,
      setError,
      doAction
    }, ...rest)
  })

  const [records, recordManager] = useRecordStateManager(() => medicalRecords.map(formatRecord),config.appJwt,
    (records) => records ? doAction(GroupAction.SetupNewRecordsGroups,records) : doAction(GroupAction.FixUngroupedRecords),config,doAction)

  const data = useToggle({
    isProcessing: 0,
    dialogs: {},
    slowMode: false,
    completedRecords: [],
    completedHidden: [],
    selectedRecords: [],
    selectedCarequalityInstitutions: [],
    sessionOnly: false,
    collapsed: [],
    // groups: groups || DEFAULT_GROUPS
    groups:  (config.autoGrouping && !medicalRecords.length && (groups && 
      (
        (!groups.length) || 
        (
          (groups.length == 1 && ( (groups[0].id === "ungrouped") || (groups[0].id === "notclass") ) )
          ||
          (groups.length == 2 && groups.filter(g => g.id === "ungrouped").length > 0 && groups.filter(g => g.id === "notclass").length > 0)
        )
      )
    )) ? DEFAULT_GROUPS : groups || DEFAULT_GROUPS,
    cancelDialog: false, // Open and closes the dialog to cancel the upload of dicoms // set the dicom record
    cancelLoading: false, // Submiting - isLoading for the cancel operation,
    canceledDicomUIDs: [],
  })

  const uploader = useUploader(getJwt, uploadQueue, recordManager, data.get)


  useEffect(() => {
    doAction(GroupAction.FixUngroupedRecords)
  }, [])

  return <UIDataProvider value={data.isOpen}>
    <UploadQueueProvider signal={recordManager.queueSignal}>
      <RecordsProvider value={records}>
        <DoActionContext.Provider value={doAction}>
          {callIfFunction(children, data.isOpen)}
        </DoActionContext.Provider>
      </RecordsProvider>
    </UploadQueueProvider>
  </UIDataProvider>


}
