import React from 'react'
import styled from 'styled-components'
// import {Uploader} from 'uploader-frontend-legacy'
import {CASE_CLOSED, CASE_REVIEWED} from '../enums/CaseState'
import {Card, SectionHeading} from '../components/PageLayout'
import {NotProvided} from '../admin/steps/ExpertAssignmentStep'
import {isRichText} from '../utils/utils'
import {IfLocale} from '../hocs/IfLocale'
import {FormattedMessage} from 'react-intl'
import {RichTextDisplay} from '../components/RichTextDisplay'


export const FieldsList = styled.div`
  margin: -0.5rem;
  ${props => props.twoColumns && `
    display: flex;
    flex-wrap: wrap;
    > * {
      flex-basis: 46%;
      flex-grow: 1;
      &.full-width {
        flex-basis: 100%;
      }
    }
    ${ReadOnlyField} {
      margin: 0.5rem 0.5rem 1.5rem 0.5rem;
    }
  `}
  .contact-relationship {
    text-transform: capitalize;
    :before {
      content: ' ';
    }
  }
  .address-concat {
    span ~ span:before {
      content: ', '
    }
  }
  .physician-contacts {
    span ~ span:before {
      content: ' | ';
    }
  }
`
const ReadOnlyQuestion = styled.div`
  font-size: 14px;
  font-weight: 600;
  label {
    display: block;
    margin-bottom: 0.25rem;
    font-size: 12px;
    color: rgba(0,0,0,0.4);
    font-weight: 400;
  }
  & ~ & {
    margin-top: 1rem;
  }
  .answer {
    font-weight: 400;
    margin-top: .25rem;
  }
`

export const ReadOnlyField = styled.div`
  font-size: 14px;
  margin-bottom: 1.5rem;
  label {
    display: block;
    font-weight: 600;
    margin-bottom: 0.25rem;
  }
`
export const FieldDetail = styled.span`
  color: rgba(0,0,0,0.4);
  font-size: 13px;
`

export const EmptyMedicalRecordsList = styled.div`
  text-align: center;
  color: rgba(0,0,0,0.4);
  padding: 1rem 0;
  font-size: 14px;
 `

export const SpacedDiv = styled.div`
  display: block;
  height: 460px;
`


export const QuestionsAndAdditionalOverview = ({ caseRequest,  role, withAnwsers = false}) => {

  const questions = <>
    <SectionHeading>
      <h3><FormattedMessage
        defaultMessage="Questions"
        description="Overview Questions section title"
      /></h3>
    </SectionHeading>
    <Card>
      {caseRequest.caseInfo.questionsConsultant ? <>
          {caseRequest.caseInfo.questionsConsultant.length === 0 && <EmptyMedicalRecordsList><FormattedMessage
            defaultMessage="No questions provided."
            description="Overview Questions, no questions provided message"
          /></EmptyMedicalRecordsList>}
          {(caseRequest.caseInfo.questionsConsultant || []).map(({ question, answer, id }, i) =>
            <ReadOnlyQuestion key={id}>
              <label><FormattedMessage
                defaultMessage="Question {i}"
                description="Overview Questions question label"
                values={{ i: i + 1 }}
              /></label>
              {/* <div className='fs-exclude'>{question ? (question.charAt(0).toUpperCase() + question.substring(1)) : <NotProvided />}</div> */}
              <div className='fs-exclude'>{question ? <RichTextDisplay css="font-size: 14px; font-weight: 600;" content={(question.charAt(0).toUpperCase() + question.substring(1))} /> : <NotProvided />}</div>
              {withAnwsers ? <>
                {(caseRequest.state === CASE_REVIEWED || caseRequest.state === CASE_CLOSED) && !isRichText(answer) &&
                  <div className="answer fs-exclude">{
                    answer 
                    ? (answer.charAt(0).toUpperCase() + answer.substring(1)) 
                    : <NotProvided />}
                  </div>
                }
                {(caseRequest.state === CASE_REVIEWED || caseRequest.state === CASE_CLOSED) && isRichText(answer) &&
                  <div className="answer fs-exclude">{
                    answer 
                    ? <RichTextDisplay content={answer} className="answer overview fs-exclude" /> 
                    : <NotProvided />}
                  </div>
                }
              </> : <></>}
            </ReadOnlyQuestion>
          )} </>
        : <EmptyMedicalRecordsList><FormattedMessage
          defaultMessage="No questions provided."
          description="Overview Questions, no questions provided message"
        /></EmptyMedicalRecordsList>
      }
    </Card>
  </>

  const additionalInformation = <>
    <SectionHeading>
      <h3><FormattedMessage
        defaultMessage="Additional Information"
        description="Overview Additional Information section title"
      /></h3>
    </SectionHeading>
    <Card>
      {caseRequest.caseInfo.additionalFields ? <>
          {caseRequest.caseInfo.additionalFields.length === 0 && <EmptyMedicalRecordsList><FormattedMessage
            defaultMessage="No additional information provided."
            description="Overview Additional Information, no additional information provided message"
          /></EmptyMedicalRecordsList>}
          {(caseRequest.caseInfo.additionalFields || []).map(({ title, content, id }, i) =>
            <ReadOnlyQuestion key={id}>
              <label><FormattedMessage
                defaultMessage="Additional field {i}"
                description="Overview Additional Information additional field label"
                values={{ i: i + 1 }}
              /></label>
              {/* <div className='fs-exclude'>{title ? (title.charAt(0).toUpperCase() + title.substring(1)) : <NotProvided />}</div> */}
              <div className='fs-exclude'>{title ? <RichTextDisplay css="font-size: 14px; font-weight: 600;" content={(title.charAt(0).toUpperCase() + title.substring(1))} /> : <NotProvided />}</div>
              {withAnwsers ? <>
                {(caseRequest.state === CASE_REVIEWED || caseRequest.state === CASE_CLOSED) && !isRichText(content) &&
                  <div className="answer fs-exclude">{
                    content 
                    ? (content.charAt(0).toUpperCase() + content.substring(1)) 
                    : <NotProvided />}
                  </div>
                }
                {(caseRequest.state === CASE_REVIEWED || caseRequest.state === CASE_CLOSED) && isRichText(content) &&
                  <div className="answer fs-exclude">{
                    content 
                    ? <RichTextDisplay content={content} className="answer overview fs-exclude" /> 
                    : <NotProvided />}
                  </div>
                }
                </> : <></>
              }
            </ReadOnlyQuestion>
          )} </>
        : <EmptyMedicalRecordsList><FormattedMessage
          defaultMessage="No additional information provided."
          description="Overview Additional Information, no additional information provided message"
        /></EmptyMedicalRecordsList>
      }
    </Card>
  </>


  return <>
    {questions} 
    <IfLocale not contains="LEGAL">
      {additionalInformation}
    </IfLocale>
  </>
}