import React, {useContext} from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components/macro'
import {DISK} from "./enums/UploaderStepsManagement";
import {FileInputBoxStyle} from "./components/styled/UploaderStyled";
import {Button, ContextMenu, Icon, Li} from "@startlibs/components";
import {useDropFileEvents} from "./hooks/useDropFileEvents";
import {UploaderAction} from "./service/UploaderAction";
import {useConstant, useLazyConstant, usePopupToggle, useToggle} from "@startlibs/core";
import {LinkVivaStudies} from "./components/dialogs/LinkVivaStudies";
import {UIAction, useRegisterDropboxRef} from "./service/UIAction";
import {UploaderConfigContext, UploaderConfigProvider} from "./service/UploaderConfigContext";
import {useDoAction} from "./service/hooks/useDoAction";
import { useIsLocale } from '../../../src/javascripts/hocs/IfLocale';
import {FormattedMessage, useIntl} from "react-intl";
import { useNavigate } from 'react-router';

styled

const ua = window.navigator.userAgent;
const isIE = /MSIE|Trident/.test(ua);

export const DropFilesBox = () => {
  const intl = useIntl()
  const {mode, canOpenWorklist, carequalityEnabled, adminUserEmail } = useContext(UploaderConfigContext)
  const doAction = useDoAction()
  const contextMenu = usePopupToggle()
  const isLegal = useIsLocale({contains:'LEGAL'})
  const navigate = useNavigate()

  const showCareQuality = (carequalityEnabled || adminUserEmail.includes('@purview.net')) && !isLegal
  const isDragAndDropAvailable = useLazyConstant(() => {
    const div = document.createElement('div')
    return (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) && 'FormData' in window && 'FileReader' in window
  })

  const setFile = useConstant(async (event) => {
    await doAction(UploaderAction.Upload, event, navigate)
  })

  const [isDraggingOver, dragBoxRef] = useDropFileEvents((event) => {
    setFile(event)
    isDraggingOver.close()
  })
  useRegisterDropboxRef(() => dragBoxRef)

  return mode !== DISK
    ? <FileInputBoxStyle
      draggingOver={isDraggingOver.isOpen}
      ref={dragBoxRef}
      mode={mode}
    >
      {isDragAndDropAvailable
        ? <div>
          {isDraggingOver.isOpen
            ?
            <>
              <b><FormattedMessage
                defaultMessage="Drop files heres to upload them"
                description="Uploader, drop files box, is dragging instructions"
              /></b>
            </>
            :
            <>
              <Button.a highlight small css="margin-bottom:0.75rem;"><input
                type="file"
                multiple
                onChange={setFile}
              /><FormattedMessage
                defaultMessage="Select files"
                description="Uploader, drop files box, select files button"
              /></Button.a>
              {!isIE && <Button.a highlight small css="margin-bottom:0.75rem;"><input
                webkitdirectory="true" type="file" multiple
                onChange={setFile}
              /><FormattedMessage
                defaultMessage="Select folders or discs"
                description="Uploader, drop files box, select folders or discs button"
              /></Button.a>}
              {canOpenWorklist == true &&<Button.a highlight small withDropdown css="margin-bottom:0.75rem;" onClick={() => contextMenu.open()}><FormattedMessage
                defaultMessage="Import from"
                description="Uploader, drop files box, import from menu button"
              />
                {contextMenu.isOpen &&
                  <ContextMenu atRight className="noIcons">
                    <Li label={intl.formatMessage({
                      defaultMessage: "Purview Image",
                      description: "Uploader, drop files box, import from menu, Purview Image"
                    })} onClick={() => doAction(UIAction.ToggleImportStudiesDialog)} />
                    {showCareQuality && <Li label={intl.formatMessage({
                      defaultMessage: "Carequality",
                      description: "Uploader, drop files box, import from menu, Carequality"
                    })} onClick={() => doAction(UIAction.ToggleImportNewCareQualityDialog)} />}
                  </ContextMenu>
                }
              </Button.a>}
             
              <div><FormattedMessage
                defaultMessage="Or drag and drop them into this box"
                description="Uploader, drop files box, drag and drop files box instructions"
              /></div>

            </>
          }
        </div>
        :
        <div>
          <input type="file" multiple onChange={setFile}/>
          <b><FormattedMessage
            defaultMessage="Click here to select file(s) to upload"
            description="Uploader, drop files box, click here to select files to upload fallback (drag-drop not available)"
          /></b>
        </div>
      }
    </FileInputBoxStyle>
    : <FileInputBoxStyle
      draggingOver={isDraggingOver.isOpen}
      ref={dragBoxRef}
      mode={mode}
    >
      <Button.a highlight css="margin-top:1rem;"><input
        webkitdirectory="true" type="file" multiple
        onChange={setFile}
      /><FormattedMessage
        defaultMessage="Select disc"
        description="Uploader, drop files box, select disc button"
      /></Button.a>
    </FileInputBoxStyle>

}