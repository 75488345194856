import React, { useEffect, useRef, useState } from 'react'
import _ from 'lodash/fp'
import styled, {css} from 'styled-components'

import {Errors, Field, Combobox, FormValue, TextInput, useProvideErrors, WithForm, RadioboxGroup, SimpleRadiobox } from '@startlibs/form'
import { Icon, Dialog, DialogFooter, Button,ContextMenu, Li } from '@startlibs/components'
import { _s } from '@startlibs/utils';
import { useToggle, usePopupToggle } from '@startlibs/core'

import { TransitionDiv } from '../../utils/TransitionDiv';
import { jwtGetFetcher, jwtPostFetcher } from '../../utils/authFetch';
import {useUIDataSelector} from "../../service/hooks/useUIDataSelector";
import {useDoAction} from "../../service/hooks/useDoAction";
import {formatRecord} from "../../service/utils";
import {UploaderAction} from "../../service/UploaderAction";
import {GroupAction} from "../../service/GroupAction";
import {FormattedMessage, useIntl} from "react-intl";

const SubmitDialogComponent = styled(Dialog)`
  max-width: 40rem;
`

const ContextMenuWithoutIcons = styled(ContextMenu)`
  li > * {
    padding: 11px 1.5rem;
  }
  z-index: 999999;
  width: 40rem;
`



export const SubmitDialog = ({ closePopup, selectedSections,  jwt, expertViewJwt, recordId, requestId, closeParent, refreshUrl}) => {
  const intl = useIntl()
  const groups = useUIDataSelector('groups')
  const doAction = useDoAction()

  var newGroups = [].concat(groups)
  var ungroupedIndex = newGroups.findIndex(item => item.id === 'ungrouped')
  if (ungroupedIndex >= 0){
    newGroups[ungroupedIndex].name = 'Ungrouped'
  }
  
  const GROUPS_OPTIONS_LABELS = _.zipObject(newGroups.map((g) => g.id),newGroups.map((g) => g.name))
  const GROUPS_OPTIONS = Object.keys(GROUPS_OPTIONS_LABELS)

  const loadingPostInfo = useToggle(false)
  const formRef = useRef()
  const [ErrorProvider, errors, {setError}] = useProvideErrors()
  const COMBINE = "COMBINE"
  const SPLIT = "SPLIT"
  
  return <SubmitDialogComponent 
    title={intl.formatMessage({
      defaultMessage: "Split document",
      description: "Uploader, split PDF document dialog, submit dialog title"
    })}
    closeDialog={closePopup}>
    <WithForm
      values={{mode: SPLIT}}
      ref={formRef}
      action={(e) => {
        if(!e.mode){
          return setError("Error", intl.formatMessage({
            defaultMessage: 'Please select mode',
            description: 'Uploader, split PDF document dialog, select mode error'
          }))
        }
        if(e.mode === COMBINE && !e.name){
          return setError("Error", intl.formatMessage({
            defaultMessage: 'Please insert the record name',
            description: 'Uploader, split PDF document dialog, insert record name error'
          }))
        }
        if(!e.desiredGroup){
          var message = intl.formatMessage({
            defaultMessage: 'Please select a group for the {isPlural, select, true {records} other {false}}',
            description: 'Uploader, split PDF document dialog, select group error'
          },{isPlural: e.mode === SPLIT && selectedSections.length > 1})
          return setError("Error", message)
        }
        
        loadingPostInfo.open()
        let data = {
          recordId: recordId,
          storageJWT: jwt(),
          combineSingleRecord: e.mode === COMBINE ? true : false,
          group: e.desiredGroup,
          recordName: e.name,
          pages: selectedSections
        }
        
        jwtPostFetcher(expertViewJwt)(`/api/pdf/split?requestId=${requestId}`, data)
          .then((postResponse, status) => {
            jwtGetFetcher(jwt())(refreshUrl)
              .then((response) => {
                
                var medRecords = [].concat(response.medicalRecords)

                response.medicalRecords.map((item, index) => {
                  medRecords[index] = Object.assign(item.info,medRecords[index],{instances: []})
                })
                const updatedRecords = response.medicalRecords.map(formatRecord)
                doAction(UploaderAction.SetRecords,_.unionBy('recordUID',_,updatedRecords))
                doAction(GroupAction.UnionGroups,response.group.info)
                loadingPostInfo.close()
                closePopup()
                closeParent()
              })
              .catch((error) => {
                loadingPostInfo.close()
                return setError("Error", intl.formatMessage({
                  defaultMessage: "Sorry! It was not possible to update groups. Please refresh this page.",
                  description: "Uploader, split PDF document dialog, update groups error"
                }))
              })

          })
          .catch((e) => {
            loadingPostInfo.close()
            return setError("Error", intl.formatMessage({
              defaultMessage: "Sorry! It was not possible to split/combine sections.",
              description: "Uploader, split PDF document dialog, split/combine sections error"
            }))
          })

      }}
      // onSuccess={(e) => console.log(e)}
    >
      {form => 
        <>
          <RadioboxGroup path="mode" required framedBoxes highlightSelected horizontal wide css="margin-bottom:1rem;" 
            label={intl.formatMessage({
                defaultMessage: "How do you want to extract the selected sections?",
              description: "Uploader, split PDF document dialog, select mode label"
            })}
            >
            <SimpleRadiobox
              fieldValue={SPLIT}
              label={intl.formatMessage({
                defaultMessage: "Create separate documents",
                description: "Uploader, split PDF document dialog, create separate documents mode label"
              })}
            />
            <SimpleRadiobox
              fieldValue={COMBINE}
              disabled={selectedSections.length === 1}
              label={intl.formatMessage({
                defaultMessage: "Combine into one document",
                description: "Uploader, split PDF document dialog, combine into one document mode label"
              })}
            />
          </RadioboxGroup>
            <FormValue path="mode">
              {selectedMode => <TransitionDiv>
                {selectedMode == COMBINE && <TextInput
                  label={intl.formatMessage({
                    defaultMessage: "Record name",
                    description: "Uploader, split PDF document dialog, record name label"
                  })}
                  path="name"
                  placeholder={intl.formatMessage({
                    defaultMessage: "Insert the record name",
                    description: "Uploader, split PDF document dialog, insert record name placeholder"
                  })}
                />
              }
            </TransitionDiv>}
            </FormValue>

          <Combobox className="JS-blur-container"
            label={intl.formatMessage({
              defaultMessage: "Select a group for the new document(s)",
              description: "Uploader, split PDF document dialog, select group label"
            })}
            path="desiredGroup"
            options={GROUPS_OPTIONS} 
            getLabel={v => GROUPS_OPTIONS_LABELS[v]}
          />
          
          <ErrorProvider value={errors}>
            <Errors/>
          </ErrorProvider>
          <DialogFooter>
            <Button onClick={closePopup}><FormattedMessage
              defaultMessage="Cancel"
              description="Cancel button"
            /></Button>
            <Button highlight isLoading={loadingPostInfo.isOpen} type="submit"><FormattedMessage
              defaultMessage="Submit"
              description="Submit button"
            /></Button>
          </DialogFooter>
        </>
      }
    </WithForm>
  </SubmitDialogComponent>
}


